import {useCallback, useEffect, useState} from "react";

let logoutTimer;

export const useAuth =  () => {
//todo create auth hook - use lesson 187

    const [token, setToken] = useState(false);
    const [userId, setUserId] = useState(false);
    const [preferredLanguage, setPreferredLanguage] = useState(false);
    const [tokenExpirationDate, setTokenExpirationDate] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [avatar, setAvatar] = useState('');
    //useCallback means that the funtion will only run once
    //this prevents loops

    const login = useCallback((uid, pLang, fName, lName, avatar, token, expirationDate) => {
        setToken(token);
        setUserId(uid);
        setFirstName(fName);
        setLastName(lName);
        setAvatar(avatar);
        setPreferredLanguage(pLang)

        const tokenExpirationDate = expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60);
        setTokenExpirationDate(tokenExpirationDate);
        localStorage.setItem(
            'userData',
            JSON.stringify(
                {userId: uid,
                    preferredLanguage: pLang,
                    firstName: fName,
                    lastName: lName,
                    avatar: avatar,
                    token:token,
                    expiration:tokenExpirationDate.toISOString()
                })
        );

    }, []);

    const logout = useCallback( () => {
        setToken(null);
        setUserId(null);
        setTokenExpirationDate(null);
        localStorage.removeItem('userData');
    },[]);


    useEffect( () => {
        if (token && tokenExpirationDate) {
            const remainingTime = tokenExpirationDate.getTime() - new Date().getTime();
            logoutTimer = setTimeout(logout, remainingTime);
        }else {
            clearTimeout(logoutTimer);
        }
    },[token, logout, tokenExpirationDate]);
    //useEffect runs at the end of the lifecycle and because no dependencies are added, it will only run once
    // at initial render
    useEffect(() => {
        //parse converts JSON back to js data structures
        const storedData = JSON.parse(localStorage.getItem('userData'));
        if (storedData && storedData.preferredLanguage && storedData.token && new Date(storedData.expiration) > new Date()){
            login(storedData.userId, storedData.preferredLanguage, storedData.firstName, storedData.lastName, storedData.avatar,  storedData.token, new Date(storedData.expiration));
        }
    }, [login]);

    return {token, login, logout, preferredLanguage, firstName, lastName, avatar, userId}
};