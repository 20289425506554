import React, {useContext, useState} from 'react';
import {Button, Table} from "react-bootstrap";
import Translation from "../Translation/Translation";
import RequestTransModal from "../../../../shared/UIComponents/Modals/RequestTrans_Modal";
import {AuthContext} from "../../../../context/auth-context";
import './TranslationList.css';

const TranslationList = props => {
    const [showTransReq, setTransReq] = useState(false);
    const openTransReqHandler = () => setTransReq(true);
 //   const closeTransReqHandler = () => setTransReq(false);
    const auth = useContext(AuthContext);
    const token = auth.token;
//todo create a hover modal that explains what the heck the description means
    if (props.items.length === 0) {
        return (
            <div>
                <RequestTransModal show={showTransReq}/>
                <div className="col-sm-12">
                    <h2 className="col-sm-9 subHeading">We could not find a translation for that word!</h2>
                    <img className="col-sm-3 alertBunny animated bounceIn" src="/imgs/bunny@4x.png" alt="surprised looking bunny"/>
                </div>
                <div className="col-sm-8">
                {token && (
                    <Button onClick={openTransReqHandler}>Suggest a new word</Button> )
                }
                    {!token && (
                        <div className="col-sm-12">
                            <h4> <a href="/signup">Sign up</a> or <a href="/login">Login</a> to send us a translation request.</h4>
                        </div>
                    )}
                </div>
            </div>
        )
    }
    return (
       <Table className="resultsTable" striped bordered hover size="sm">
           <thead>

           </thead>
           <tbody>
            {props.items.map(translation => {
            return (
                    <Translation
                        key={translation._id}
                        english={translation.fullEnglish}
                        translated={translation.fullTranslation}
                        pov={translation.pov}
                    />
                )
            })}
           </tbody>
       </Table>
    );
};

export default TranslationList;
