import React, {Suspense} from 'react';
import {BrowserRouter as Router, Route, Redirect, Switch} from 'react-router-dom';


import Search from '../Translations/pages/Search.js';
import SIFNavBar from "../shared/UIComponents/SIFNavBar/SIFNavBar";
//import Auth from "../user/pages/Auth";
import AddTranslation from "../Translations/pages/AddTranslation";
import { AuthContext} from "../context/auth-context";
//todo context for preferred language?
import {useAuth} from "../shared/hooks/auth-hook";
import {Spinner} from "react-bootstrap";

const Signup = React.lazy(() => import('../user/pages/Signup'));
const Auth = React.lazy(() => import('../user/pages/Auth'));

//todo fix up the h1 to contain whatever the page is all about. Use the hello only in the beginning modal
//wrap entire router item in authcontext. all components within then have access to the authcontext
// when AuthContext value={} updated, all components that are listening will rerender
const App = () => {
    const {token, login, logout, preferredLanguage, firstName, lastName, avatar,  userId} = useAuth();
    let routes;
    if(token){
        routes = (
            <Switch>
                <Route path="/" exact>
                    <Search />
                </Route>
                <Route path="/addtranslation" exact>
                    <AddTranslation />
                </Route>
                <Route path="/:sterm/:stense" exact>
                    <Search/>
                </Route>
                <Redirect to="/"/>
            </Switch>
        );
    }else {
        routes = (
            <Switch>
                <Route path="/" exact>
                    <Search />
                </Route>
                <Route path="/:sterm/:stense" exact>
                    <Search/>
                </Route>
                <Route path="/login" exact>
                    <Auth/>
                </Route>
                <Route path="/signup" exact>
                    <Signup />
                </Route>
                <Redirect to="/login"/>
            </Switch>
        );
    }
        return (
            <AuthContext.Provider
                value={{
                    isLoggedIn: !!token,
                    token: token,
                    userId:userId,
                    firstName: firstName,
                    lastName: lastName,
                    avatar: avatar,
                    preferredLanguage:preferredLanguage,
                    login: login,
                    logout: logout}}
            >
                <Router>
                    <SIFNavBar/>
                    <div className="pageContainer offset-sm-2 col-sm-8">
                        <main>
                           <Suspense fallback={<div className="center"><Spinner animation="border"/></div>}>{routes}</Suspense>
                        </main>
                    </div>
                    <div className="bottomImageAnchor" alt="Happy Forest Image"></div>
                </Router>
            </AuthContext.Provider>
        )
}

export default App;