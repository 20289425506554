import React  from 'react';

import './Translation.css';

const Translation = props => {
    return (
      <tr className="animated fadeInDown">
          <td>{props.english}</td>
          <td>{props.translated.replace("_", "ɨ")}</td>
          <td>{props.pov}</td>
      </tr>
    )
}

export default Translation;