import React, {useEffect, useState, Fragment, useContext} from "react";

import TranslationList from "../Components/Search/TranslationList/TranslationList";
import SearchBar from "../Components/Search/SearchBar/SearchBar";
import RequestTransModal from "../../shared/UIComponents/Modals/RequestTrans_Modal";
import {Spinner, Row} from 'react-bootstrap';
import {AuthContext} from "../../context/auth-context";

const Search = props => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();
    const [loadedTranslations, setLoadedTranslations] = useState([]);
    const [loadedSuggestions, setLoadedSuggestions] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [tense, setTense] = useState('present');
    const [lang, setLang] = useState('');
    const auth = useContext(AuthContext);

    const [showTransReq, setTransReq] = useState(false);
 //  const openTransReqHandler = () => setTransReq(true);
    const closeTransReqHandler = () => setTransReq(false);

        /*useEffect does not want a promise*/
   /* useEffect( () => {
        const fetchSuggestions = async () => {
            try{
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}`);
                const responseData = await response.json();
                if(!response.ok) {
                    throw new Error(responseData.message);
                }
                setLoadedSuggestions(responseData.suggestions);
            }catch(err) {
                setError(err.message);
            }
        };
        fetchSuggestions();
    }, []);*/

    useEffect( () => {
        setLoadedTranslations([]);
        const fetchTranslations = async () => {
            if (searchTerm.length === 0) {
                return;
            }
            let plang;
            if(lang !== '' && lang !== auth.preferredLanguage) {
                plang = lang;
            }else if (auth.preferredLanguage){
                plang = auth.preferredLanguage;
            }else {
              setError('Whoops! No Language selected!')
            }
            try{
                setIsLoading(true);
                const response = await fetch(
                    `${process.env.REACT_APP_BACKEND_URL}/translations/` + searchTerm + '/' + tense + '/' + plang)
                const responseData = await response.json();
                if(!response.ok) {
                    throw new Error(responseData.message);
                }
                setIsLoading(false);
                setLoadedTranslations(responseData.translations);

            }catch(err) {
                setError(err.message);
            }
        };
        console.log("did i get here?")
        fetchTranslations();
    }, [searchTerm, tense, auth.preferredLanguage, lang]);

    const errorHandler = () => {
        setError(null);
    };
    const handleSearchUpdate = (searchTerm, tense, language) => {
        setTense(tense);
        if(language){
            setLang(language);
        }else if (auth.preferredLanguage){
            setLang( auth.preferredLanguage)
        }else {
            setError('Whoops, no language selected!');
        }
        setSearchTerm(searchTerm);
    }
    const handleTenseSelection = (tense) => {
        setTense(tense);
    }
    const handleLangSelection = (val) => {
        setLang(val);
    }

//TODO add params from useParams() to filter out results.
    return (
       <Fragment>
           <br/>  <br/>
           <RequestTransModal show={showTransReq} closeModal={closeTransReqHandler}/>
           <div className="characterContainer">
               <img className="character bobMainPage animated bounceInLeft" src="/imgs/Bob@4x.png" alt="bob the moose with a big smile"/>
               <img className="character squeakMainPage animated bounceInRight" src="/imgs/bunny@4x.png" alt="bunny with a smile" />
               <img className="character beaverMainPage animated bounceInRight" src="/imgs/beaver@4x.png" alt="Beaver" />
               <img className="character bearMainPage animated bounceInRight" src="/imgs/bear@4x.png" alt="Bear" />
           </div>

           <div className="containerPane searchFormContainer">
               <SearchBar prefLang={auth.preferredLanguage} onSearchTermChange={handleSearchUpdate} onTenseSelector={handleTenseSelection} onLangSelector={handleLangSelection} suggestions={loadedSuggestions}/>
           </div>
           {loadedTranslations.length >0 && searchTerm.length >0 && (
               <div className="containerPane mainTranslationPane">
                   {isLoading && <Spinner animation="grow" variant="light"/>}
                   {!isLoading && (
                   <Row>
                       <span className="veronica col-xs-3 animated jackInTheBox"><img src="/imgs/Veronica.png" alt="stern looking Veronica"/></span>
                       <div className="col-xs-8">
                           <Row>
                               <span className="mainTranslationText col-xs-12">{loadedTranslations[0].fullEnglish}</span>
                           </Row>
                            <Row>
                                <span className="mainTranslationText col-xs-12">{loadedTranslations[0].fullTranslation.replace(/_/g, "Ɨ")}</span>
                            </Row>
                       </div>
                   </Row>
                   )}
               </div>)
           }
           {searchTerm.length > 0 && (
               <div className="containerPane">
                   {isLoading && <Spinner animation="grow" variant="light"/>}
                   {!isLoading && (
                           <TranslationList items={loadedTranslations}/>
                           )
                  }
               </div>)
           }
       </Fragment>
    )
};
export default Search;