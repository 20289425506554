import {createContext} from 'react';
//used to share context across application components
export const AuthContext = createContext({
    isLoggedIn: false,
    userId: null,
    token: null,
    firstName: '',
    lastName: '',
    preferredLanguage: '',
    avatar: '',
    login: () => {},
    logout: () => {}
});

