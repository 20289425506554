import React from "react";
import './SearchBar.css';
import Autosuggest from 'react-autosuggest';
import {Form, Button, ButtonToolbar, ToggleButtonGroup, ToggleButton} from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import RemoveWords from "remove-words";
import Natural from "natural";

//todo remove hardcoded array and use axios
// Imagine you have a list of languages that you'd like to autosuggest.
let languages = []
;

const natural = Natural;
const removeWords =  RemoveWords;
const stemmer = natural.PorterStemmer;
const compare = ( a, b ) => {
    if ( a.name < b.name ){
        return -1;
    }
    if ( a.name> b.name ){
        return 1;
    }
    return 0;
}
// Teach Autosuggest how to calculate suggestions for any given input value.
const getSuggestions = value => {
    let cleanedInput = removeWords(value.trim().toLowerCase(), false, ['I', 'We', 'Is']).toString().replace(/,/g,' ');
    let inputLength = cleanedInput.length;
 const tokenizer = new natural.WordTokenizer;
    console.log(value);
   // console.log(tokenizer.tokenize(value));
    console.log(stemmer.tokenizeAndStem(value));
    return inputLength === 0 ? [] : languages.filter(lang =>
        lang.name.toLowerCase().includes(cleanedInput)
    );
//asdf
};

// When suggestion is clicked, Autosuggest needs to populate the input
// based on thnpm  clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = suggestion => suggestion.name;

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => (
    <div>
        {suggestion.name}
    </div>
);


const validationSchema = Yup.object({
    langSelectors: Yup
        .string()
        .required('add something!')
});

class SearchBar extends React.Component {
    constructor(props) {
        super(props);

        // Autosuggest is a controlled component.
        // This means that you need to provide an input value
        // and an onChange handler that updates this value (see below).
        // Suggestions also need to be provided to the Autosuggest,
        // and they are initially empty because the Autosuggest is closed.
        this.state = {
            value: '',
            suggestions: [],
            isLoading: false,
            langSelect: '',
            tenseSelect: 'none',
            langSupportsDupAndAbs: false,
            goButtonDisabled: true,
            goButtonValue: 'Make sure to select a language!'
        };
       // this.handleSearch = this.handleSearch.bind(this);
    }

    onChange = (event, { newValue }) => {
        this.setState({
            value: newValue
        });
        setTimeout(this.checkGoButtonState,0);
    };

    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onSuggestionsFetchRequested = async ({ value })  => {
        this.setState({
            suggestions: getSuggestions(value)
        });
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    newSearchTerm = (data) => {
        this.setState ({ isLoading:true});
        this.props.onSearchTermChange(this.state.value, this.state.tenseSelect, this.state.langSelect);
        this.setState ({ isLoading:false});
        setTimeout(this.checkGoButtonState,0);
    }

    tenseSelect = val => {
        if (val === this.state.tenseSelect){
            this.setState({tenseSelect: 'none'});
        }else{
            this.setState({tenseSelect: val});
        }
        setTimeout(this.checkGoButtonState,0);
    }

    langSelect = val => {

        this.setState({langSelect: val});
        setTimeout(this.checkGoButtonState,0);
        if(val === 'Mikmaw') {
            this.setState({langSupportsDupAndAbs: false});
            languages = [{name: 'able to talk -- netawey', language: 'able to talk '},
                {name: 'abstain -- wetqolsi', language: 'abstain '},
                {name: 'ache -- kitaqasi', language: 'ache '},
                {name: 'act accordingly -- tetapu\'qamiksi', language: 'act accordingly '},
                {name: 'act bad -- winsi', language: 'act bad '},
                {name: 'act in such a manner -- telqamiksi', language: 'act in such a manner '},
                {name: 'act like an old person -- kisiku\'qamiksi', language: 'act like an old person '},
                {name: 'act properly -- keknu\'qamiksi', language: 'act properly '},
                {name: 'add milk -- mlakejua\'tay', language: 'add milk '},
                {name: 'add molasses to my food -- kastio\'mia\'tay', language: 'add molasses to my food '},
                {name: 'add pepper to my food -- te\'pi\'seweya\'tay', language: 'add pepper to my food '},
                {name: 'add salt to my food -- salawa\'tay', language: 'add salt to my food '},
                {name: 'amuse myself -- papi', language: 'amuse myself '},
                {name: 'anger -- takaliey', language: 'anger '},
                {name: 'appear sickly -- kesnukamkusi', language: 'appear sickly '},
                {name: 'argue -- ki\'kaja\'si', language: 'argue '},
                {name: 'arrive -- ikai', language: 'arrive '},
                {name: 'arrive as a passenger -- pettepi', language: 'arrive as a passenger '},
                {name: 'arrive from running -- peji\'pi', language: 'arrive from running '},
                {name: 'arrive unexpectedly -- peji-ikai', language: 'arrive unexpectedly '},
                {name: 'ask for myself -- etawaqtmay', language: 'ask for myself '},
                {name: 'assist at mass -- nujo\'tekey', language: 'assist at mass '},
                {name: 'astraddle -- nasuoqkay', language: 'astraddle '},
                {name: 'bail out water -- naqani\'key', language: 'bail out water '},
                {name: 'bail water -- si\'nawey', language: 'bail water '},
                {name: 'bake variety of pastry -- amaltoqtey', language: 'bake variety of pastry '},
                {name: 'barter for goods -- kiwiksi', language: 'barter for goods '},
                {name: 'be a baker -- nuji-pipney', language: 'be a baker '},
                {name: 'be a baker -- nuttoqtey', language: 'be a baker '},
                {name: 'be a boy -- l\'pa\'tujui', language: 'be a boy '},
                {name: 'be a clerk -- nussaqikey', language: 'be a clerk '},
                {name: 'be a coroner -- nutaptekey', language: 'be a coroner '},
                {name: 'be a delivery person -- nutayji\'putekey', language: 'be a delivery person '},
                {name: 'be a discharger -- nutsaqanikei', language: 'be a discharger '},
                {name: 'be a father -- wujjewi', language: 'be a father '},
                {name: 'be a fortune teller -- nuji-wsikey', language: 'be a fortune teller '},
                {name: 'be a godparent -- kekkunawey', language: 'be a godparent '},
                {name: 'be a heavy smoker -- pikweli-wtmay', language: 'be a heavy smoker '},
                {name: 'be a hunchback -- temawiknai', language: 'be a hunchback '},
                {name: 'be a hunter -- nutantekey', language: 'be a hunter '},
                {name: 'be a loner -- tepkiseyi', language: 'be a loner '},
                {name: 'be a man -- ji\'nmui', language: 'be a man '},
                {name: 'be a member -- naspi', language: 'be a member '},
                {name: 'be a pallbearer -- pema\'tekei', language: 'be a pallbearer '},
                {name: 'be a prankster -- wetmo\'tekei', language: 'be a prankster '},
                {name: 'be a slowpoke -- paweyi', language: 'be a slowpoke '},
                {name: 'be a slowpoke -- sipke\'i', language: 'be a slowpoke '},
                {name: 'be a soldier -- sma\'knisui', language: 'be a soldier '},
                {name: 'be a sponsor -- nujo\'tekey', language: 'be a sponsor '},
                {name: 'be a virgin -- na\'skwey', language: 'be a virgin '},
                {name: 'be absent minded -- puski-weniey', language: 'be absent minded '},
                {name: 'be absent minded -- wejuowita\'si', language: 'be absent minded '},
                {name: 'be active -- seskweyi', language: 'be active '},
                {name: 'be agile -- waqasi', language: 'be agile '},
                {name: 'be alive -- mimaji', language: 'be alive '},
                {name: 'be an executioner -- nuji-ne\'patekey', language: 'be an executioner '},
                {name: 'be angry -- wekayi', language: 'be angry '},
                {name: 'be angry -- wekayi', language: 'be angry '},
                {name: 'be asthmatic -- wejlami', language: 'be asthmatic '},
                {name: 'be at fault -- tela\'lsi', language: 'be at fault '},
                {name: 'be attractive -- wikwasapewi', language: 'be attractive '},
                {name: 'be back to my senses -- sinu\'ey', language: 'be back to my senses '},
                {name: 'be bad -- winji', language: 'be bad '},
                {name: 'be bald -- memkaqnatpay', language: 'be bald '},
                {name: 'be barebottomed -- metaqikjay', language: 'be barebottomed '},
                {name: 'be barefooted -- metkatay', language: 'be barefooted '},
                {name: 'be bareheaded -- metkway', language: 'be bareheaded '},
                {name: 'be bashful -- netakeyi', language: 'be bashful '},
                {name: 'be beautiful -- kelu\'si', language: 'be beautiful '},
                {name: 'be become impatient -- siwiey', language: 'be become impatient '},
                {name: 'be become numb -- pilsa\'qiey', language: 'be become numb '},
                {name: 'be bellowing -- wetewiluai', language: 'be bellowing '},
                {name: 'be beneficial -- welapetmuey', language: 'be beneficial '},
                {name: 'be berry picking -- mewisi', language: 'be berry picking '},
                {name: 'be better -- piam-klu\'si', language: 'be better '},
                {name: 'be big in girth -- maqoqsi', language: 'be big in girth '},
                {name: 'be black -- maqteweyi', language: 'be black '},
                {name: 'be black-bottomed -- maqtewikjay', language: 'be black'},
                {name: 'be bleeding -- mal\'tewiey', language: 'be bleeding '},
                {name: 'be blinded by light -- petasi', language: 'be blinded by light '},
                {name: 'be bloody -- mal\'tewi', language: 'be bloody '},
                {name: 'be blue sky -- musqunamuksi', language: 'be blue sky '},
                {name: 'be born -- weskijinui', language: 'be born '},
                {name: 'be born -- wsitqamui', language: 'be born '},
                {name: 'be bothersome -- lukwaqani', language: 'be bothersome '},
                {name: 'be brave -- melkitai', language: 'be brave '},
                {name: 'be breastfeeding -- nuseskwey', language: 'be breastfeeding '},
                {name: 'be brownish -- we don\'t use ii', language: 'be brownish '},
                {name: 'be bundled -- paspisi', language: 'be bundled '},
                {name: 'be bundled up -- oqpisi', language: 'be bundled up '},
                {name: 'be busy -- wetmeyi', language: 'be busy '},
                {name: 'be carrying by rope or handle -- pemapilawey', language: 'be carrying by rope or handle '},
                {name: 'be charitable -- wela\'luey', language: 'be charitable '},
                {name: 'be chief -- saqmawi', language: 'be chief '},
                {name: 'be clean -- waqameyi', language: 'be clean '},
                {name: 'be clean -- waqmeyi', language: 'be clean '},
                {name: 'be clear cutting -- memkoqte\'key', language: 'be clear cutting '},
                {name: 'be cold -- kewji', language: 'be cold '},
                {name: 'be cold -- teki', language: 'be cold '},
                {name: 'be compassionate -- ewlite\'tekey', language: 'be compassionate '},
                {name: 'be conceited -- mekite\'lsi', language: 'be conceited '},
                {name: 'be confused -- awnasatpay', language: 'be confused '},
                {name: 'be covered with snow -- anqunali\'kiey', language: 'be covered with snow '},
                {name: 'be cranky -- kaquiey', language: 'be cranky '},
                {name: 'be crawling along -- pemapeki', language: 'be crawling along '},
                {name: 'be crooked -- paqjuiki', language: 'be crooked '},
                {name: 'be cross-eyed -- patalapi', language: 'be cross'},
                {name: 'be cultivating -- elkesawey', language: 'be cultivating '},
                {name: 'be dark complexioned -- maqtewikneksi', language: 'be dark complexioned '},
                {name: 'be dark tanned -- maqtewoqsi', language: 'be dark tanned '},
                {name: 'be dirty -- mejikeyi', language: 'be dirty '},
                {name: 'be disrespected -- pepsite\'lmuksi', language: 'be disrespected '},
                {name: 'be disrespectful -- pepsite\'lkey', language: 'be disrespectful '},
                {name: 'be doing -- tela\'tekey', language: 'be doing '},
                {name: 'be double jointed -- toqwanqiskay', language: 'be double jointed '},
                {name: 'be dressed raggedly -- ewluktay', language: 'be dressed raggedly '},
                {name: 'be earning -- pekwatasi', language: 'be earning '},
                {name: 'be eating well -- weltami', language: 'be eating well '},
                {name: 'be empty -- sikue\'i', language: 'be empty '},
                {name: 'be estranged from him -- puna\'tekey', language: 'be estranged from him '},
                {name: 'be evil -- winji', language: 'be evil '},
                {name: 'be exhausted -- sewiknay', language: 'be exhausted '},
                {name: 'be expensive -- meko\'ti', language: 'be expensive '},
                {name: 'be extremely good -- wesasi-klu\'si', language: 'be extremely good '},
                {name: 'be fast -- sune\'wi', language: 'be fast '},
                {name: 'be fast -- wisqisi', language: 'be fast '},
                {name: 'be fat -- maqapskisi', language: 'be fat '},
                {name: 'be fat -- wikewi', language: 'be fat '},
                {name: 'be fatty -- wikewi', language: 'be fatty '},
                {name: 'be filthy minded person -- winita\'si', language: 'be filthy minded person '},
                {name: 'be fine -- weleyi', language: 'be fine '},
                {name: 'be first -- amskweseweyi', language: 'be first '},
                {name: 'be flat-footed -- sasqikatay', language: 'be flat'},
                {name: 'be flat-headed -- pepkijatpay', language: 'be flat'},
                {name: 'be flatfooted -- sasqasitay', language: 'be flatfooted '},
                {name: 'be flea infested -- pisui', language: 'be flea infested '},
                {name: 'be flexible -- sipiki', language: 'be flexible '},
                {name: 'be foolish -- awnaseyi', language: 'be foolish '},
                {name: 'be frail -- menaje\'ji', language: 'be frail '},
                {name: 'be frightened -- we\'kwata\'si', language: 'be frightened '},
                {name: 'be from -- tleyawi', language: 'be from '},
                {name: 'be frozen -- kelji', language: 'be frozen '},
                {name: 'be full -- waju\'ey', language: 'be full '},
                {name: 'be full -- waju\'pey', language: 'be full '},
                {name: 'be funny -- kiseyi', language: 'be funny '},
                {name: 'be getting married -- malie\'wi', language: 'be getting married '},
                {name: 'be glad -- welta\'si', language: 'be glad '},
                {name: 'be godlike -- niskamewi', language: 'be godlike '},
                {name: 'be going hunting -- naji-ktantekey', language: 'be going hunting '},
                {name: 'be going to pack or get ready -- naji-ilaji', language: 'be going to pack or get ready '},
                {name: 'be greedy -- oqomeyi', language: 'be greedy '},
                {name: 'be grimy -- epaqamkiey', language: 'be grimy '},
                {name: 'be hairy -- pukna\'kwey', language: 'be hairy '},
                {name: 'be hairy of the body -- pukna\'kwey', language: 'be hairy of the body '},
                {name: 'be hatless -- metkway', language: 'be hatless '},
                {name: 'be healthy -- tajikeyi', language: 'be healthy '},
                {name: 'be holding with my left hand -- patanapji', language: 'be holding with my left hand '},
                {name: 'be honoured -- mekite\'tasi', language: 'be honoured '},
                {name: 'be hot -- epsi', language: 'be hot '},
                {name: 'be hyperactive -- waqasi', language: 'be hyperactive '},
                {name: 'be ill -- sewiknaay', language: 'be ill '},
                {name: 'be ill mannered -- penoqweyi', language: 'be ill mannered '},
                {name: 'be immature -- nutqweyi', language: 'be immature '},
                {name: 'be in crouching position -- mesqanapi', language: 'be in crouching position '},
                {name: 'be in such a way -- teleyi', language: 'be in such a way '},
                {name: 'be in the way -- wetmkuey', language: 'be in the way '},
                {name: 'be incapable of walking -- me\'sipmiey', language: 'be incapable of walking '},
                {name: 'be inside -- pisi', language: 'be inside '},
                {name: 'be jealous -- wiskueyi', language: 'be jealous '},
                {name: 'be jolly -- weskewinoqsi', language: 'be jolly '},
                {name: 'be joyful -- welte\'tekey', language: 'be joyful '},
                {name: 'be kneeling -- mutkulpukui', language: 'be kneeling '},
                {name: 'be knocking -- metete\'key', language: 'be knocking '},
                {name: 'be knowledgeable -- kejitekey', language: 'be knowledgeable '},
                {name: 'be lazy -- maleyi', language: 'be lazy '},
                {name: 'be lean -- alu\'say', language: 'be lean '},
                {name: 'be leaving -- maja\'si', language: 'be leaving '},
                {name: 'be left handed -- patanmi', language: 'be left handed '},
                {name: 'be light-complexioned -- wapikneksi', language: 'be light'},
                {name: 'be lonesome -- siwe\'i', language: 'be lonesome '},
                {name: 'be long armed -- pito\'qiptnay', language: 'be long armed '},
                {name: 'be long haired -- sa\'wkway', language: 'be long haired '},
                {name: 'be long legged -- pito\'qnikatay', language: 'be long legged '},
                {name: 'be looking persistently -- ko\'qmajeyi', language: 'be looking persistently '},
                {name: 'be lopsided -- upmiki', language: 'be lopsided '},
                {name: 'be loved -- kesaluksi', language: 'be loved '},
                {name: 'be lucky -- lokowi', language: 'be lucky '},
                {name: 'be making hay -- msikue\'key', language: 'be making hay '},
                {name: 'be making jam -- passoqsawei', language: 'be making jam '},
                {name: 'be means treated poorly -- penoqweyi', language: 'be means treated poorly '},
                {name: 'be mentally lazy -- mallikway', language: 'be mentally lazy '},
                {name: 'be mischievous -- mila\'tekey', language: 'be mischievous '},
                {name: 'be mistreated -- wino\'tasi', language: 'be mistreated '},
                {name: 'be muscular -- melkiaqasi', language: 'be muscular '},
                {name: 'be named -- teluisi', language: 'be named '},
                {name: 'be native -- l\'nui', language: 'be native '},
                {name: 'be nearsighted -- ewlapi', language: 'be nearsighted '},
                {name: 'be nice and warm -- welp&#x0268;si', language: 'be nice and warm '},
                {name: 'be noisy -- kesikaweyi', language: 'be noisy '},
                {name: 'be not doing well -- mala\'si', language: 'be not doing well '},
                {name: 'be now in a standing position -- menjipukui', language: 'be now in a standing position '},
                {name: 'be numb -- pilsa\'qiey', language: 'be numb '},
                {name: 'be oily -- mimey', language: 'be oily '},
                {name: 'be old -- kisikui', language: 'be old '},
                {name: 'be on board -- teppi', language: 'be on board '},
                {name: 'be on top -- weskitpi', language: 'be on top '},
                {name: 'be one -- newte\'ji', language: 'be one '},
                {name: 'be outside looking in -- piskwapi', language: 'be outside looking in '},
                {name: 'be overdo -- me\'ka\'tekey', language: 'be overdo '},
                {name: 'be pale -- wapistaqamuksi', language: 'be pale '},
                {name: 'be peddling along -- pemankuay', language: 'be peddling along '},
                {name: 'be persistent -- munsai', language: 'be persistent '},
                {name: 'be pitied -- ewlite\'lmuksi', language: 'be pitied '},
                {name: 'be pitiful -- ewlamuksi', language: 'be pitiful '},
                {name: 'be poling along -- pemamkwe\'kei', language: 'be poling along '},
                {name: 'be poor -- ewle\'ji', language: 'be poor '},
                {name: 'be poorly dressed -- winkutay', language: 'be poorly dressed '},
                {name: 'be poorly shoed -- winkisney', language: 'be poorly shoed '},
                {name: 'be pug-nosed -- saqitnay', language: 'be pug'},
                {name: 'be quick -- naqsa\'si', language: 'be quick '},
                {name: 'be quick tempered -- wisqi-lue\'wi', language: 'be quick tempered '},
                {name: 'be reddish -- mekwamuksi', language: 'be reddish '},
                {name: 'be removing the bark -- menipqwa\'wey', language: 'be removing the bark '},
                {name: 'be respectful -- keknueyi', language: 'be respectful '},
                {name: 'be restless -- sespa\'si', language: 'be restless '},
                {name: 'be rich -- milesi', language: 'be rich '},
                {name: 'be rich -- milesi', language: 'be rich '},
                {name: 'be running along with my head bobbing -- pematpe\'pi', language: 'be running along with my head bobbing '},
                {name: 'be running away -- wessimkway', language: 'be running away '},
                {name: 'be sad -- taqawajeyi', language: 'be sad '},
                {name: 'be sad -- wenmajita\'si', language: 'be sad '},
                {name: 'be saintly -- sape\'wi', language: 'be saintly '},
                {name: 'be scarred -- wiseyi', language: 'be scarred '},
                {name: 'be sensitive to cold -- menaji', language: 'be sensitive to cold '},
                {name: 'be sexually aroused -- massaqiey', language: 'be sexually aroused '},
                {name: 'be shaped like -- teliki', language: 'be shaped like '},
                {name: 'be shinning bright -- paqtasi', language: 'be shinning bright '},
                {name: 'be short -- toqwaqji\'ji', language: 'be short '},
                {name: 'be short and round -- jaqliki', language: 'be short and round '},
                {name: 'be short and round -- jaqliki\'ji\'ji', language: 'be short and round '},
                {name: 'be short legged -- toqwaqji\'jkikatay', language: 'be short legged '},
                {name: 'be sick -- kesnukway', language: 'be sick '},
                {name: 'be sniffing -- wejipsetami', language: 'be sniffing '},
                {name: 'be snobbish -- me\'keyi', language: 'be snobbish '},
                {name: 'be so big and round -- telapsk&#x0268;si', language: 'be so big and round '},
                {name: 'be so many years old -- te\'sipunai', language: 'be so many years old '},
                {name: 'be so many years old -- tewijeyi', language: 'be so many years old '},
                {name: 'be so weak -- tel-mnaqanay', language: 'be so weak '},
                {name: 'be sober -- pa\'keyi', language: 'be sober '},
                {name: 'be soldily -- melkiki', language: 'be soldily '},
                {name: 'be sooty -- puksetewi', language: 'be sooty '},
                {name: 'be sorry -- meskeyi', language: 'be sorry '},
                {name: 'be spooked -- amalsiktmay', language: 'be spooked '},
                {name: 'be sticky fingered -- wisqiptinay', language: 'be sticky fingered '},
                {name: 'be still sleepy -- kesatkusi', language: 'be still sleepy '},
                {name: 'be straight -- pitoqsi', language: 'be straight '},
                {name: 'be strange -- wi\'kueyi', language: 'be strange '},
                {name: 'be strong -- melkiknay', language: 'be strong '},
                {name: 'be strong enough -- tepiknay', language: 'be strong enough '},
                {name: 'be such a height -- tel-pitoqsi', language: 'be such a height '},
                {name: 'be suffering -- wenmajeyi', language: 'be suffering '},
                {name: 'be suffering from a long-term illness -- pekijipney', language: 'be suffering from a long'},
                {name: 'be surprised -- pa\'qalayi', language: 'be surprised '},
                {name: 'be surprised by news and gossip -- pa\'qastmay', language: 'be surprised by news and gossip '},
                {name: 'be sweat -- tekniey', language: 'be sweat '},
                {name: 'be swollen -- mekwi', language: 'be swollen '},
                {name: 'be talking noisily -- sespetoqsi', language: 'be talking noisily '},
                {name: 'be tall -- pitoqsi', language: 'be tall '},
                {name: 'be the age of independence -- tewipunai', language: 'be the age of independence '},
                {name: 'be the chief -- saqamawi', language: 'be the chief '},
                {name: 'be the fattest -- mawkami', language: 'be the fattest '},
                {name: 'be thick bearded -- pasi\'tuay', language: 'be thick bearded '},
                {name: 'be thick headed -- pasatpay', language: 'be thick headed '},
                {name: 'be tipsy -- weljepi', language: 'be tipsy '},
                {name: 'be tired of waiting -- siwiskmay', language: 'be tired of waiting '},
                {name: 'be tired of walking -- siwpmiey', language: 'be tired of walking '},
                {name: 'be too fat -- awsamkami', language: 'be too fat '},
                {name: 'be too short -- awsamitqwaqji\'ji', language: 'be too short '},
                {name: 'be treated well -- welo\'tasi', language: 'be treated well '},
                {name: 'be two years old -- tapuipunay', language: 'be two years old '},
                {name: 'be unattractive -- winiki', language: 'be unattractive '},
                {name: 'be unclean -- winji', language: 'be unclean '},
                {name: 'be uncomfortable -- si\'kasi', language: 'be uncomfortable '},
                {name: 'be uncomfortable -- sikajiei', language: 'be uncomfortable '},
                {name: 'be unfaithful -- sespo\'tekei', language: 'be unfaithful '},
                {name: 'be useful -- wtapsuni', language: 'be useful '},
                {name: 'be useful -- wtapsuni', language: 'be useful '},
                {name: 'be very good -- mawklu\'si', language: 'be very good '},
                {name: 'be weak -- menaqanay', language: 'be weak '},
                {name: 'be well-dressed -- welknesi', language: 'be well'},
                {name: 'be well-dressed -- welpisi', language: 'be well'},
                {name: 'be well-shoed -- welk&#x0268;sney', language: 'be well'},
                {name: 'be wet -- kaqapuey', language: 'be wet '},
                {name: 'be wet -- saqpeey', language: 'be wet '},
                {name: 'be white -- wapeyi', language: 'be white '},
                {name: 'be wide -- maqiskeyi', language: 'be wide '},
                {name: 'be worth so much -- telawti', language: 'be worth so much '},
                {name: 'be worth the price -- tepawti', language: 'be worth the price '},
                {name: 'be yellow -- watapsi', language: 'be yellow '},
                {name: 'be young -- maljewe\'jewi', language: 'be young '},
                {name: 'become a daughter-inlaw -- lu\'sue\'skwewa\'si', language: 'become a daughter'},
                {name: 'become a son-inlaw -- lu\'sukewa\'si', language: 'become a son'},
                {name: 'become bloated -- putapeka\'si', language: 'become bloated '},
                {name: 'become ecstatic -- mawiwlta\'si', language: 'become ecstatic '},
                {name: 'become large and round -- pemi-mqapsk&#x0268;si', language: 'become large and round '},
                {name: 'become old -- kisiku\'ey', language: 'become old '},
                {name: 'become tired -- kispney', language: 'become tired '},
                {name: 'begin to cry -- poqji-atkitemi', language: 'begin to cry '},
                {name: 'begin to fish -- poqji-kwitamey', language: 'begin to fish '},
                {name: 'begin to sing -- poqji-ktapekiey', language: 'begin to sing '},
                {name: 'begin to speak -- poqtewo\'kwey', language: 'begin to speak '},
                {name: 'behave in such a manner -- tela\'si', language: 'behave in such a manner '},
                {name: 'believe -- ketlamsitasi', language: 'believe '},
                {name: 'belong -- wettaqiey', language: 'belong '},
                {name: 'bend over -- paqjuika\'si', language: 'bend over '},
                {name: 'bite -- paqa\'luey', language: 'bite '},
                {name: 'blaze -- keknukwatikei', language: 'blaze '},
                {name: 'bleed -- apjiku\'niey', language: 'bleed '},
                {name: 'bleed -- pukweliku\'niey', language: 'bleed '},
                {name: 'bless myself -- klujjiewto\'si', language: 'bless myself '},
                {name: 'blow -- putuatami', language: 'blow '},
                {name: 'bow down -- matkwa\'si', language: 'bow down '},
                {name: 'brag -- kinsi', language: 'brag '},
                {name: 'break even -- apatto\'si', language: 'break even '},
                {name: 'break even in gambling -- apato\'si', language: 'break even in gambling '},
                {name: 'breast feed -- nuneskwey', language: 'breast feed '},
                {name: 'breathe -- kamlami', language: 'breathe '},
                {name: 'breathe -- wetlami', language: 'breathe '},
                {name: 'breathe hard -- kesikawlami', language: 'breathe hard '},
                {name: 'build -- ewi\'kay', language: 'build '},
                {name: 'build a fire in a stove -- mawsa\'tekey', language: 'build a fire in a stove '},
                {name: 'burn -- kaqsi', language: 'burn '},
                {name: 'burry -- wutqutay', language: 'burry '},
                {name: 'burst out crying -- paqsipketemi', language: 'burst out crying '},
                {name: 'butcher -- putaqikey', language: 'butcher '},
                {name: 'butter my bread -- amjaqmay', language: 'butter my bread '},
                {name: 'cackle -- sesaqtoqsi', language: 'cackle '},
                {name: 'calculate -- mawkiljey', language: 'calculate '},
                {name: 'card wool -- nalkwe\'key', language: 'card wool '},
                {name: 'carry a heavy load -- keskunasi', language: 'carry a heavy load '},
                {name: 'carve -- amaloqsawey', language: 'carve '},
                {name: 'cast a spell on self -- puntay', language: 'cast a spell on self '},
                {name: 'change -- sa\'se\'wa\'si', language: 'change '},
                {name: 'change a language -- sa\'se\'wi\'si', language: 'change a language '},
                {name: 'chant -- neskawey', language: 'chant '},
                {name: 'charge a high price for labour -- mekawtukwey', language: 'charge a high price for labour '},
                {name: 'chew -- saqamu\'kuey', language: 'chew '},
                {name: 'chew -- soqte\'key', language: 'chew '},
                {name: 'chop -- temte\'key', language: 'chop '},
                {name: 'clear brush or thicket by cutting -- musikte\'key', language: 'clear brush or thicket by cutting '},
                {name: 'climb over -- keskija\'si', language: 'climb over '},
                {name: 'close my eyes -- pesikwa\'si', language: 'close my eyes '},
                {name: 'coast on the river -- siawkumiei', language: 'coast on the river '},
                {name: 'collapse -- wikwiey', language: 'collapse '},
                {name: 'comb my hair -- nukwaltuko\'si', language: 'comb my hair '},
                {name: 'comb my hair with a fine toothed comb -- nalko\'si', language: 'comb my hair with a fine toothed comb '},
                {name: 'come -- wejku\'ey', language: 'come '},
                {name: 'come back -- apaja\'si', language: 'come back '},
                {name: 'come back from hunting -- aptukuli', language: 'come back from hunting '},
                {name: 'come crying -- wejkutemi', language: 'come crying '},
                {name: 'come from there -- wejiey', language: 'come from there '},
                {name: 'come here to spend the winter -- peji-punsi', language: 'come here to spend the winter '},
                {name: 'come lugging on back -- petawley', language: 'come lugging on back '},
                {name: 'come on horseback -- wejkwi\'puluey', language: 'come on horseback '},
                {name: 'come out empty handed -- putmay', language: 'come out empty handed '},
                {name: 'come paddling -- wejkwisukwi', language: 'come paddling '},
                {name: 'come paddling to shore -- wejkwina\'taqamisukwi', language: 'come paddling to shore '},
                {name: 'come riding on something -- wejkwatejimkwey', language: 'come riding on something '},
                {name: 'come running out of the woods -- panuatqa\'si', language: 'come running out of the woods '},
                {name: 'come sailing on -- wejkwaqtekey', language: 'come sailing on '},
                {name: 'come to ask permission from parents to marry their daughter -- peji-kluluey', language: 'come to ask permission from parents to marry their daughter '},
                {name: 'come with pounding feet -- wejkwitukwi\'pi', language: 'come with pounding feet '},
                {name: 'conceal something in my hand -- sekapji', language: 'conceal something in my hand '},
                {name: 'conceal something under the arm -- pemtulkomi', language: 'conceal something under the arm '},
                {name: 'confess -- pa\'qapukuey', language: 'confess '},
                {name: 'confess -- pa\'qapukuey', language: 'confess '},
                {name: 'cook -- wissukwatekey', language: 'cook '},
                {name: 'cook -- wissukway', language: 'cook '},
                {name: 'cook -- wissukway', language: 'cook '},
                {name: 'copy -- wasoqatestekey', language: 'copy '},
                {name: 'crave (eat a lot) -- mesuktaqanay', language: 'crave (eat a lot) '},
                {name: 'crawl across -- asoqmapeki', language: 'crawl across '},
                {name: 'crawl backward -- esatapeki', language: 'crawl backward '},
                {name: 'crawl underneath -- pijapeki', language: 'crawl underneath '},
                {name: 'crawl up -- toqjuapeki', language: 'crawl up '},
                {name: 'crochet -- amalisknuey', language: 'crochet '},
                {name: 'cry a long while -- pekittemi', language: 'cry a long while '},
                {name: 'cry a lot -- atkitemi', language: 'cry a lot '},
                {name: 'cry a lot -- pustemi', language: 'cry a lot '},
                {name: 'cry often -- pustemi', language: 'cry often '},
                {name: 'cry over something -- wettemi', language: 'cry over something '},
                {name: 'cry secretly -- kimtemi', language: 'cry secretly '},
                {name: 'cut logs -- tmoqta\'we\'key', language: 'cut logs '},
                {name: 'cut lumber -- esnoqney', language: 'cut lumber '},
                {name: 'cut lumber -- kmu\'je\'key', language: 'cut lumber '},
                {name: 'dab myself -- amjaqto\'si', language: 'dab myself '},
                {name: 'dance -- amalkay', language: 'dance '},
                {name: 'dance native style -- amalkay', language: 'dance native style '},
                {name: 'dance native style -- l\'nu\'kway', language: 'dance native style '},
                {name: 'debark -- pelko\'qikey', language: 'debark '},
                {name: 'defecate -- mejjukway', language: 'defecate '},
                {name: 'defecate on myself -- mejjilsi', language: 'defecate on myself '},
                {name: 'delouse -- pankwenawey', language: 'delouse '},
                {name: 'deny -- petqwasi', language: 'deny '},
                {name: 'depart -- pusi', language: 'depart '},
                {name: 'depart in a vehicle -- pusi', language: 'depart in a vehicle '},
                {name: 'descend from -- wetapeksi', language: 'descend from '},
                {name: 'descend in elevation -- metoqiey', language: 'descend in elevation '},
                {name: 'dig -- mulqwey', language: 'dig '},
                {name: 'dig for clams -- mene\'smay', language: 'dig for clams '},
                {name: 'distribute -- tepi\'key', language: 'distribute '},
                {name: 'dive -- kwetapiey', language: 'dive '},
                {name: 'do business here -- wejo\'tasi', language: 'do business here '},
                {name: 'do different kinds of work -- mil-lukwey', language: 'do different kinds of work '},
                {name: 'do good -- wela\'tekey', language: 'do good '},
                {name: 'do it correctly -- koqqaja\'tekey', language: 'do it correctly '},
                {name: 'do laundry -- kesistaqnawey', language: 'do laundry '},
                {name: 'do odd or simple jobs -- mimatu\'lukwey', language: 'do odd or simple jobs '},
                {name: 'do such work -- tel-lukwey', language: 'do such work '},
                {name: 'do what is right -- tetapua\'tekey', language: 'do what is right '},
                {name: 'do wrong -- o\'pla\'tekey', language: 'do wrong '},
                {name: 'draw water from a well or river -- naqana\'pey', language: 'draw water from a well or river '},
                {name: 'dream -- peway', language: 'dream '},
                {name: 'dream -- peway', language: 'dream '},
                {name: 'dress up -- ekna\'si', language: 'dress up '},
                {name: 'drift off to sleep -- nepay', language: 'drift off to sleep '},
                {name: 'drill -- po\'qikey', language: 'drill '},
                {name: 'drink -- esam\'qway', language: 'drink '},
                {name: 'drink alcoholic  beverage -- naqana\'may', language: 'drink alcoholic  beverage '},
                {name: 'drive -- i\'pusi', language: 'drive '},
                {name: 'drool -- pene\'sukwai', language: 'drool '},
                {name: 'droop my head -- oqolumkwa\'si', language: 'droop my head '},
                {name: 'eat -- ketaqama\'si', language: 'eat '},
                {name: 'eat -- mijisi', language: 'eat '},
                {name: 'eat -- mijisi', language: 'eat '},
                {name: 'eat -- mijji', language: 'eat '},
                {name: 'eat raw -- esk&#x0268;tami', language: 'eat raw '},
                {name: 'elope -- poqji', language: 'elope '},
                {name: 'embroider -- amaltaqi\'sawey', language: 'embroider '},
                {name: 'enlist -- akase\'wi', language: 'enlist '},
                {name: 'enquire -- pipanikesi', language: 'enquire '},
                {name: 'enter -- piskwai', language: 'enter '},
                {name: 'exchange -- sa\'se\'wi', language: 'exchange '},
                {name: 'exhale -- tewlami', language: 'exhale '},
                {name: 'exit -- tewiey', language: 'exit '},
                {name: 'fade in and out -- aluasa\'si', language: 'fade in and out '},
                {name: 'faint -- wikwiey', language: 'faint '},
                {name: 'fall -- pijiey', language: 'fall '},
                {name: 'fall apart -- sioqiey', language: 'fall apart '},
                {name: 'fall from there -- wejnisiey', language: 'fall from there '},
                {name: 'fall into the water -- paqasiey', language: 'fall into the water '},
                {name: 'fall over -- kewiey', language: 'fall over '},
                {name: 'fart -- pikti', language: 'fart '},
                {name: 'feast -- kweltami', language: 'feast '},
                {name: 'feel a rumbling inside my stomach -- metaluay', language: 'feel a rumbling inside my stomach '},
                {name: 'feel around -- ne\'tunawey', language: 'feel around '},
                {name: 'feel cold -- kewji', language: 'feel cold '},
                {name: 'feel drunk -- welaqapi', language: 'feel drunk '},
                {name: 'feel my feet are cold -- kewtaqsiki', language: 'feel my feet are cold '},
                {name: 'feel relieved -- wela\'si', language: 'feel relieved '},
                {name: 'fend for myself -- ketuapsi', language: 'fend for myself '},
                {name: 'ferry across -- asoqmu\'tekey', language: 'ferry across '},
                {name: 'fiddle -- tapia\'ji\'jk&#x0268;te\'key', language: 'fiddle '},
                {name: 'fight -- matnekey', language: 'fight '},
                {name: 'fight -- matnekey', language: 'fight '},
                {name: 'finish -- kaqa\'tekey', language: 'finish '},
                {name: 'finish -- kaqikisa\'tekey', language: 'finish '},
                {name: 'fish -- weskey', language: 'fish '},
                {name: 'fish by torchlight -- saqsikwey', language: 'fish by torchlight '},
                {name: 'fish for cod -- pejue\'key', language: 'fish for cod '},
                {name: 'fish for salmon -- plamue\'key', language: 'fish for salmon '},
                {name: 'fish for salmon -- plamue\'key', language: 'fish for salmon '},
                {name: 'fish with a net -- a\'pikey', language: 'fish with a net '},
                {name: 'flex myself -- iltaqa\'lsi', language: 'flex myself '},
                {name: 'float -- alqo\'qwey', language: 'float '},
                {name: 'float along -- pemko\'qwey', language: 'float along '},
                {name: 'float along -- weskitqo\'qey', language: 'float along '},
                {name: 'fobe at the mouth -- pisewtunai', language: 'fobe at the mouth '},
                {name: 'forget -- awanta\'si', language: 'forget '},
                {name: 'forget -- awanta\'si', language: 'forget '},
                {name: 'frown -- kwetajikwey', language: 'frown '},
                {name: 'frown -- kwetajikwey', language: 'frown '},
                {name: 'fumble and drop him -- pesoqopska\'si', language: 'fumble and drop him '},
                {name: 'gallop a horse -- kaskwi', language: 'gallop a horse '},
                {name: 'gather -- mawo\'tasi', language: 'gather '},
                {name: 'gather mushrooms -- l\'ketuekey', language: 'gather mushrooms '},
                {name: 'get a burn -- kesoqsi', language: 'get a burn '},
                {name: 'get bigger -- pemimski\'ka\'si', language: 'get bigger '},
                {name: 'get bloated -- apitay', language: 'get bloated '},
                {name: 'get blueberries -- pkwimane\'key', language: 'get blueberries '},
                {name: 'get dressed -- ekna\'si', language: 'get dressed '},
                {name: 'get flat feet -- peqanqasita\'tekei', language: 'get flat feet '},
                {name: 'get here crying -- pettemi', language: 'get here crying '},
                {name: 'get off -- kepta\'si', language: 'get off '},
                {name: 'get on a vechile -- tepa\'si', language: 'get on a vechile '},
                {name: 'get on board -- tepa\'si', language: 'get on board '},
                {name: 'get on top -- ke\'kupa\'si', language: 'get on top '},
                {name: 'get out of water slowly -- pawnatqa\'si', language: 'get out of water slowly '},
                {name: 'get up from lying position -- menja\'si', language: 'get up from lying position '},
                {name: 'get well -- ila\'si', language: 'get well '},
                {name: 'give away -- iknmuey', language: 'give away '},
                {name: 'give it a quick push -- ketmoqjekey', language: 'give it a quick push '},
                {name: 'give off light -- wasoqnawey', language: 'give off light '},
                {name: 'go about -- ala\'si', language: 'go about '},
                {name: 'go around in a circle -- kiwto\'qwa\'si', language: 'go around in a circle '},
                {name: 'go at full speed -- wisqi\'pi', language: 'go at full speed '},
                {name: 'go away -- ejikla\'si', language: 'go away '},
                {name: 'go by -- pemiey', language: 'go by '},
                {name: 'go by and crying -- pemtemi', language: 'go by and crying '},
                {name: 'go by land -- maqmikewa\'si', language: 'go by land '},
                {name: 'go by with a shoulder pack -- pemnikey', language: 'go by with a shoulder pack '},
                {name: 'go down the coast -- papka\'si', language: 'go down the coast '},
                {name: 'go down the coast by water -- papkisikwi', language: 'go down the coast by water '},
                {name: 'go down to the floor -- mesqana\'si', language: 'go down to the floor '},
                {name: 'go hunt -- nattukuli', language: 'go hunt '},
                {name: 'go hunting -- netukli', language: 'go hunting '},
                {name: 'go hunting for eels -- naji-kate\'key', language: 'go hunting for eels '},
                {name: 'go in the water -- paqasa\'si', language: 'go in the water '},
                {name: 'go into -- pija\'si', language: 'go into '},
                {name: 'go moose hunting -- naji-tia\'mue\'key', language: 'go moose hunting '},
                {name: 'go off the trail or road -- peska\'si', language: 'go off the trail or road '},
                {name: 'go on -- siawa\'si', language: 'go on '},
                {name: 'go slow -- sankewa\'si', language: 'go slow '},
                {name: 'go somewhere -- eliey', language: 'go somewhere '},
                {name: 'go through the thicket -- pematqiey', language: 'go through the thicket '},
                {name: 'go to a store -- malsano\'kuo\'mkewi', language: 'go to a store '},
                {name: 'go to defecate -- najijjukway', language: 'go to defecate '},
                {name: 'go to town -- kjikankewi', language: 'go to town '},
                {name: 'go to town -- kutana\'si', language: 'go to town '},
                {name: 'go underwater -- kwetapai', language: 'go underwater '},
                {name: 'go up the coast -- pi\'tawa\'si', language: 'go up the coast '},
                {name: 'go up to higher ground -- so\'qiey', language: 'go up to higher ground '},
                {name: 'go wrong -- amsela\'si', language: 'go wrong '},
                {name: 'go wrong -- o\'pla\'si', language: 'go wrong '},
                {name: 'gossip -- elutmakwey', language: 'gossip '},
                {name: 'groom myself -- ilapewnusi', language: 'groom myself '},
                {name: 'grope around -- aliskey', language: 'grope around '},
                {name: 'grow -- nikwey', language: 'grow '},
                {name: 'grow at a slow pace -- malikwey', language: 'grow at a slow pace '},
                {name: 'grow up -- etlikwey', language: 'grow up '},
                {name: 'guide by boat -- a\'lutasi', language: 'guide by boat '},
                {name: 'harass -- pepso\'tasi', language: 'harass '},
                {name: 'harness a horse -- eltaqnawey', language: 'harness a horse '},
                {name: 'harvest crops -- ke\'tipney', language: 'harvest crops '},
                {name: 'haul in a winning pot -- wesua\'tekey', language: 'haul in a winning pot '},
                {name: 'have a arm cut off -- temiptnay', language: 'have a arm cut off '},
                {name: 'have a bad character -- winqamiksi', language: 'have a bad character '},
                {name: 'have a beard -- witui', language: 'have a beard '},
                {name: 'have a big and round belly -- maqapsk&#x0268;tiay', language: 'have a big and round belly '},
                {name: 'have a big boat -- maqiwtuli', language: 'have a big boat '},
                {name: 'have a big head -- maqatpay', language: 'have a big head '},
                {name: 'have a big mouth -- maqtunay', language: 'have a big mouth '},
                {name: 'have a big nose -- maqisqunay', language: 'have a big nose '},
                {name: 'have a black beard -- maqtewi\'tuay', language: 'have a black beard '},
                {name: 'have a burning sensation -- wi\'s&#x0268;kesi', language: 'have a burning sensation '},
                {name: 'have a calm disposition -- wantaqeyi', language: 'have a calm disposition '},
                {name: 'have a close call -- wejueyi', language: 'have a close call '},
                {name: 'have a crippled hand -- ewliptinay', language: 'have a crippled hand '},
                {name: 'have a dirty appearance -- mejikamuksi', language: 'have a dirty appearance '},
                {name: 'have a dirty face -- mejikapewi', language: 'have a dirty face '},
                {name: 'have a dog -- wti\'i', language: 'have a dog '},
                {name: 'have a dry throat -- poqwa\'lay', language: 'have a dry throat '},
                {name: 'have a father -- nujji', language: 'have a father '},
                {name: 'have a good character -- welqamiksi', language: 'have a good character '},
                {name: 'have a good lunch on bread dipped with molasses -- welipistami', language: 'have a good lunch on bread dipped with molasses '},
                {name: 'have a large hole -- maqalqasi', language: 'have a large hole '},
                {name: 'have a left over -- piamiey', language: 'have a left over '},
                {name: 'have a leg cut off -- temikatay', language: 'have a leg cut off '},
                {name: 'have a long nose -- piji\'sqonay', language: 'have a long nose '},
                {name: 'have a long reach -- pito\'qiptinay', language: 'have a long reach '},
                {name: 'have a mean disposition -- winqamiksi', language: 'have a mean disposition '},
                {name: 'have a net -- a\'piemi', language: 'have a net '},
                {name: 'have a nice shape -- weliki', language: 'have a nice shape '},
                {name: 'have a nice winter -- welipunsi', language: 'have a nice winter '},
                {name: 'have a nightmare -- melkwiskay', language: 'have a nightmare '},
                {name: 'have a nightmare -- pewaqtmay', language: 'have a nightmare '},
                {name: 'have a nose bleed -- pa\'kitney', language: 'have a nose bleed '},
                {name: 'have a numb mouth -- pilsa\'qaqutnay', language: 'have a numb mouth '},
                {name: 'have a scarred face -- wisikway', language: 'have a scarred face '},
                {name: 'have a shadow -- wjijaqamiju\'ey', language: 'have a shadow '},
                {name: 'have a son -- wkwisi', language: 'have a son '},
                {name: 'have a stomach ache -- sewtiaay', language: 'have a stomach ache '},
                {name: 'have a strong grip -- melkapji', language: 'have a strong grip '},
                {name: 'have a strong neck -- melki\'taqnay', language: 'have a strong neck '},
                {name: 'have a tidy house -- welnmay', language: 'have a tidy house '},
                {name: 'have a totem -- ntutemi', language: 'have a totem '},
                {name: 'have a weak back -- malukunay', language: 'have a weak back '},
                {name: 'have a well heated house -- welp&#x0268;smay', language: 'have a well heated house '},
                {name: 'have a wet bottom -- saqpikjay', language: 'have a wet bottom '},
                {name: 'have all kinds if dreams -- milkusi', language: 'have all kinds if dreams '},
                {name: 'have an epileptic fit -- wejipulkwey', language: 'have an epileptic fit '},
                {name: 'have an illegitimate child -- sekewe\'key', language: 'have an illegitimate child '},
                {name: 'have an older sister -- wmisi', language: 'have an older sister '},
                {name: 'have an ugly face -- winapewi', language: 'have an ugly face '},
                {name: 'have arched feet -- walnoqsitay', language: 'have arched feet '},
                {name: 'have attacks of epileptic seizures -- puski-wjipulkwey', language: 'have attacks of epileptic seizures '},
                {name: 'have bad breath -- eloqtnemai', language: 'have bad breath '},
                {name: 'have big arms -- maqo\'qiptnay', language: 'have big arms '},
                {name: 'have big buttocks -- maqikjay', language: 'have big buttocks '},
                {name: 'have big ears -- maqistaqanay', language: 'have big ears '},
                {name: 'have big feet -- maqsitay', language: 'have big feet '},
                {name: 'have big nostrils -- maqitnay', language: 'have big nostrils '},
                {name: 'have big tongue -- maqi-wilnui', language: 'have big tongue '},
                {name: 'have broad shoulders -- keskitlmaqnay', language: 'have broad shoulders '},
                {name: 'have cold hands -- tekiptnay', language: 'have cold hands '},
                {name: 'have diarrhea -- saputai', language: 'have diarrhea '},
                {name: 'have different kinds of thoughts -- milita\'si', language: 'have different kinds of thoughts '},
                {name: 'have different names -- miluisi', language: 'have different names '},
                {name: 'have difficulty -- kitnmay', language: 'have difficulty '},
                {name: 'have good card hand -- welnay', language: 'have good card hand '},
                {name: 'have good thoughts -- welita\'si', language: 'have good thoughts '},
                {name: 'have hair -- wusapuni', language: 'have hair '},
                {name: 'have large eyes -- maqalkikway', language: 'have large eyes '},
                {name: 'have long ears -- piji\'staqanay', language: 'have long ears '},
                {name: 'have messy hair -- se\'saltukway', language: 'have messy hair '},
                {name: 'have mixed emotions -- milkwija\'si', language: 'have mixed emotions '},
                {name: 'have my eyes closed -- pesikwey', language: 'have my eyes closed '},
                {name: 'have my eyes closed -- pesikwey', language: 'have my eyes closed '},
                {name: 'have my eyes open -- wapi', language: 'have my eyes open '},
                {name: 'have seizures -- wejipulkwey', language: 'have seizures '},
                {name: 'have small pox -- lapikotewi', language: 'have small pox '},
                {name: 'have something in my eye -- pe\'si', language: 'have something in my eye '},
                {name: 'have strong odor -- kesoqwey', language: 'have strong odor '},
                {name: 'have such a circumference -- teloqsi', language: 'have such a circumference '},
                {name: 'have teeth -- wipiti', language: 'have teeth '},
                {name: 'have thick hair -- pasaltukway', language: 'have thick hair '},
                {name: 'have thin hair -- pepkwatpay', language: 'have thin hair '},
                {name: 'have tonsillitis -- ulukwiey', language: 'have tonsillitis '},
                {name: 'have tuberculosis -- samsnewi', language: 'have tuberculosis '},
                {name: 'have whiskers -- witui', language: 'have whiskers '},
                {name: 'have white hairs -- wapkwai', language: 'have white hairs '},
                {name: 'hear about it -- nutmay', language: 'hear about it '},
                {name: 'hear things -- telistaqnewa\'si', language: 'hear things '},
                {name: 'hear things -- tels&#x0268;tmay', language: 'hear things '},
                {name: 'help -- apoqnmuey', language: 'help '},
                {name: 'help myself -- apoqnmasi', language: 'help myself '},
                {name: 'hide -- mimkwasi', language: 'hide '},
                {name: 'hinder -- tekuei', language: 'hinder '},
                {name: 'hinder -- wetquluey', language: 'hinder '},
                {name: 'hint -- elo\'tekey', language: 'hint '},
                {name: 'hit -- taqtekey', language: 'hit '},
                {name: 'hoe -- elkenawey', language: 'hoe '},
                {name: 'hoe -- elkete\'key', language: 'hoe '},
                {name: 'hoist -- toqju\'pilawey', language: 'hoist '},
                {name: 'hold something under the arm -- sepulkomi', language: 'hold something under the arm '},
                {name: 'hop -- wenaqiey', language: 'hop '},
                {name: 'howl -- u\'lukwey', language: 'howl '},
                {name: 'hum -- tetuey', language: 'hum '},
                {name: 'hunt -- ketantekey', language: 'hunt '},
                {name: 'hunt eels -- kate\'key', language: 'hunt eels '},
                {name: 'hunt moose -- tia\'mue\'key', language: 'hunt moose '},
                {name: 'hunt waterfowl -- pi\'may', language: 'hunt waterfowl '},
                {name: 'hurry -- tetaqa\'si', language: 'hurry '},
                {name: 'in foster care -- anko\'tasi', language: 'in foster care '},
                {name: 'in something -- pisi', language: 'in something '},
                {name: 'inform -- kekinua\'tekey', language: 'inform '},
                {name: 'inhale -- wesuelami', language: 'inhale '},
                {name: 'jump -- wenaqiey', language: 'jump '},
                {name: 'keep a clean place -- waqamnmay', language: 'keep a clean place '},
                {name: 'kick -- tekteskawey', language: 'kick '},
                {name: 'kiss people -- weska\'qelmuey', language: 'kiss people '},
                {name: 'knead -- elu\'sknawey', language: 'knead '},
                {name: 'kneel -- mutkulpukua\'si', language: 'kneel '},
                {name: 'knit -- amalisknuey', language: 'knit '},
                {name: 'knit -- apsisknuey', language: 'knit '},
                {name: 'knit -- elisknuey', language: 'knit '},
                {name: 'laugh -- weskeweyi', language: 'laugh '},
                {name: 'laugh a lot -- puskelmi', language: 'laugh a lot '},
                {name: 'laugh at myself -- kimelmuktasi', language: 'laugh at myself '},
                {name: 'laugh secretly -- kimelmi', language: 'laugh secretly '},
                {name: 'leap along -- pemjai', language: 'leap along '},
                {name: 'learn -- eknu\'tmasi', language: 'learn '},
                {name: 'leave -- smaqa\'si', language: 'leave '},
                {name: 'let out long deep sounded breath -- pe\'sklami', language: 'let out long deep sounded breath '},
                {name: 'lick -- muskwatami', language: 'lick '},
                {name: 'lie atop -- ke\'koqpi', language: 'lie atop '},
                {name: 'lie down crossways -- taqmoqisma\'si', language: 'lie down crossways '},
                {name: 'lie down on my side -- upmisma\'si', language: 'lie down on my side '},
                {name: 'light up -- papkwesetmay', language: 'light up '},
                {name: 'light up and smoke -- papkusetmay', language: 'light up and smoke '},
                {name: 'light up to smoke -- papkwesetmay', language: 'light up to smoke '},
                {name: 'like to drink -- wikpey', language: 'like to drink '},
                {name: 'limp -- askas\'si', language: 'limp '},
                {name: 'live -- wiki', language: 'live '},
                {name: 'live a long life -- pekitawsi', language: 'live a long life '},
                {name: 'live common-law -- toqnasi', language: 'live common'},
                {name: 'live in a good location -- welikey', language: 'live in a good location '},
                {name: 'live simple -- mimatueyi', language: 'live simple '},
                {name: 'live simply -- mimatueyi', language: 'live simply '},
                {name: 'load -- tepo\'tasi', language: 'load '},
                {name: 'load a gun -- pitqasawei', language: 'load a gun '},
                {name: 'lock up -- kepjoqikey', language: 'lock up '},
                {name: 'look -- ankaptekey', language: 'look '},
                {name: 'look about -- alapi', language: 'look about '},
                {name: 'look at myself -- ankamsi', language: 'look at myself '},
                {name: 'look in a mirror -- pipnu\'jaqamay', language: 'look in a mirror '},
                {name: 'look like -- elay', language: 'look like '},
                {name: 'look like -- telamuksi', language: 'look like '},
                {name: 'look out -- tewopi', language: 'look out '},
                {name: 'look pale -- wapitlmekweyi', language: 'look pale '},
                {name: 'look tired -- kispnapewi', language: 'look tired '},
                {name: 'look toward -- elapi', language: 'look toward '},
                {name: 'love -- kesaluey', language: 'love '},
                {name: 'lug toward -- elawley', language: 'lug toward '},
                {name: 'lye -- kespukwa\'tekey', language: 'lye '},
                {name: 'make a fire inside -- mawsa\'tay', language: 'make a fire inside '},
                {name: 'make different sounds with my voice -- miltoqsi', language: 'make different sounds with my voice '},
                {name: 'make good wages -- weli-pkwatekey', language: 'make good wages '},
                {name: 'make music -- ketu\'muey', language: 'make music '},
                {name: 'make music a wind instrument -- pipukwey', language: 'make music a wind instrument '},
                {name: 'make sounds -- pemtoqsi', language: 'make sounds '},
                {name: 'make the sign of cross -- klujjiewto\'si', language: 'make the sign of cross '},
                {name: 'manage with minimum resources -- mimatueyi', language: 'manage with minimum resources '},
                {name: 'marry -- te\'wimalie\'wi', language: 'marry '},
                {name: 'measure -- enkey', language: 'measure '},
                {name: 'measure up to -- kiskajiei', language: 'measure up to '},
                {name: 'milk a cow -- jinpeknawey', language: 'milk a cow '},
                {name: 'miss a connection -- mess&#x0268;teskmay', language: 'miss a connection '},
                {name: 'move -- aja\'si', language: 'move '},
                {name: 'move aside -- esata\'si', language: 'move aside '},
                {name: 'move away -- ejikliwsi', language: 'move away '},
                {name: 'move fast -- kesikawa\'si', language: 'move fast '},
                {name: 'move here to settle down -- pejiwsi', language: 'move here to settle down '},
                {name: 'move out -- te\'wiwsi', language: 'move out '},
                {name: 'move slowly -- kekwa\'si', language: 'move slowly '},
                {name: 'move slowly -- pawa\'si', language: 'move slowly '},
                {name: 'move to lower land -- metoqiwsi', language: 'move to lower land '},
                {name: 'move up -- wenaqto\'si', language: 'move up '},
                {name: 'my skin is peeling -- pelkoqiey', language: 'my skin is peeling '},
                {name: 'name -- teluisi', language: 'name '},
                {name: 'need -- menuekey', language: 'need '},
                {name: 'occupy -- weskowa\'si', language: 'occupy '},
                {name: 'open my eyes -- wapa\'si', language: 'open my eyes '},
                {name: 'open my mouth -- pantuna\'si', language: 'open my mouth '},
                {name: 'open my mouth wide -- tawtna\'si', language: 'open my mouth wide '},
                {name: 'originate from -- tleyawi', language: 'originate from '},
                {name: 'overact -- me\'ka\'si', language: 'overact '},
                {name: 'owe -- tettuey', language: 'owe '},
                {name: 'pace back/forth -- asu\'sey', language: 'pace back/forth '},
                {name: 'pack -- ilpalikay', language: 'pack '},
                {name: 'pack my pipe -- pi\'snsi', language: 'pack my pipe '},
                {name: 'paddle along -- pemi-sukwi', language: 'paddle along '},
                {name: 'paddle along -- pemji\'mey', language: 'paddle along '},
                {name: 'paddle along the shore -- pemjajisukwi', language: 'paddle along the shore '},
                {name: 'paddle toward -- eliskuwi', language: 'paddle toward '},
                {name: 'participate in MardGras -- maltikle\'wi', language: 'participate in MardGras '},
                {name: 'participate in a feast day -- pestie\'wi', language: 'participate in a feast day '},
                {name: 'pass by -- pemi-siawa\'si', language: 'pass by '},
                {name: 'pass by and crying -- pemi-siawtemi', language: 'pass by and crying '},
                {name: 'pass by and laughing -- pemelmi', language: 'pass by and laughing '},
                {name: 'pass through -- saputa\'si', language: 'pass through '},
                {name: 'peel -- pelkoqsawey', language: 'peel '},
                {name: 'peep through -- pu\'tapa\'si', language: 'peep through '},
                {name: 'penetrate through -- saputaskiei', language: 'penetrate through '},
                {name: 'plane -- mikutekey', language: 'plane '},
                {name: 'play -- almila\'si', language: 'play '},
                {name: 'play -- mila\'si', language: 'play '},
                {name: 'play -- papi', language: 'play '},
                {name: 'play a card game -- ila\'skukwey', language: 'play a card game '},
                {name: 'play ball -- alje\'may', language: 'play ball '},
                {name: 'play cards -- ila\'skukwey', language: 'play cards '},
                {name: 'play the bagpipes -- wiskwia\'tekey', language: 'play the bagpipes '},
                {name: 'play the waltes dice game -- waltestay', language: 'play the waltes dice game '},
                {name: 'playing waltes -- waltestay', language: 'playing waltes '},
                {name: 'pluck a chicken -- pesko\'tekey', language: 'pluck a chicken '},
                {name: 'pole a boat to a certain spot -- we\'kwamkwe\'kei', language: 'pole a boat to a certain spot '},
                {name: 'pour in -- pi\'t&#x0268;pey', language: 'pour in '},
                {name: 'pray -- alasutmay', language: 'pray '},
                {name: 'pray -- alasutmay', language: 'pray '},
                {name: 'purchase from there -- pekwatelikey', language: 'purchase from there '},
                {name: 'purchase from there -- wettelikey', language: 'purchase from there '},
                {name: 'purchase from there -- wettelikey', language: 'purchase from there '},
                {name: 'put on a rouge -- mekwa\'tay', language: 'put on a rouge '},
                {name: 'put on shoes and socks -- peptiskna\'si', language: 'put on shoes and socks '},
                {name: 'put out weeds -- musika\'tekey', language: 'put out weeds '},
                {name: 'put thinks away -- masqo\'tasi', language: 'put thinks away '},
                {name: 'race -- ikatne\'wey', language: 'race '},
                {name: 'reach my destination -- we\'kwa\'si', language: 'reach my destination '},
                {name: 'read -- ekiljey', language: 'read '},
                {name: 'read properly -- ekiljey', language: 'read properly '},
                {name: 'read properly -- keknu\'kitjey', language: 'read properly '},
                {name: 'read well -- nata\'kitjey', language: 'read well '},
                {name: 'receive holy communion -- kmnie\'wi', language: 'receive holy communion '},
                {name: 'recover from an illness -- saputawsi', language: 'recover from an illness '},
                {name: 'relax -- atlasmi', language: 'relax '},
                {name: 'remove a net from the water -- mena\'pikey', language: 'remove a net from the water '},
                {name: 'remove fish from a net -- mena\'piey', language: 'remove fish from a net '},
                {name: 'reside there -- wiki', language: 'reside there '},
                {name: 'return from spending winter -- apipunsi', language: 'return from spending winter '},
                {name: 'revive myself -- minu\'nsi', language: 'revive myself '},
                {name: 'ride a horse -- ali\'puluey', language: 'ride a horse '},
                {name: 'ride along on horse back -- pemi\'puluey', language: 'ride along on horse back '},
                {name: 'rise -- wenaqa\'si', language: 'rise '},
                {name: 'rock -- alamiey', language: 'rock '},
                {name: 'row a boat -- ji\'mey', language: 'row a boat '},
                {name: 'rub myself -- etlipulsi', language: 'rub myself '},
                {name: 'rub on it -- amipusi', language: 'rub on it '},
                {name: 'run along slowly -- pemi-kekwi\'pi', language: 'run along slowly '},
                {name: 'run slowly -- kekwi\'pi', language: 'run slowly '},
                {name: 'sail -- alaqtekey', language: 'sail '},
                {name: 'sail along -- pemaqtekey', language: 'sail along '},
                {name: 'sail along by -- pemi-siaw-aqtekey', language: 'sail along by '},
                {name: 'say -- teluey', language: 'say '},
                {name: 'say -- teluey', language: 'say '},
                {name: 'scoot home -- elmnqwe\'sey', language: 'scoot home '},
                {name: 'scoot over -- elateja\'si', language: 'scoot over '},
                {name: 'scout -- nutapi', language: 'scout '},
                {name: 'scowl -- winikwa\'si', language: 'scowl '},
                {name: 'scratch -- kesipa\'lsi', language: 'scratch '},
                {name: 'scream -- se\'skwey', language: 'scream '},
                {name: 'scurry about -- alatija\'si', language: 'scurry about '},
                {name: 'search for fir boughs -- stoqne\'key', language: 'search for fir boughs '},
                {name: 'see a mirage -- telaplkikwa\'si', language: 'see a mirage '},
                {name: 'see all around -- peykwapi', language: 'see all around '},
                {name: 'see myself -- nemi\'si', language: 'see myself '},
                {name: 'see poorly -- ewlapi', language: 'see poorly '},
                {name: 'see poorly -- ewlapi', language: 'see poorly '},
                {name: 'sell -- netui\'skey', language: 'sell '},
                {name: 'sell -- netuis\'kaqtmay', language: 'sell '},
                {name: 'send a wire or telegram -- mattaqte\'key', language: 'send a wire or telegram '},
                {name: 'set out to hunt provisions -- nattukli', language: 'set out to hunt provisions '},
                {name: 'set snares -- na\'puktaqnikey', language: 'set snares '},
                {name: 'set traps -- loqte\'knikey', language: 'set traps '},
                {name: 'sew -- eli\'sawey', language: 'sew '},
                {name: 'sew by hand -- wpitni\'sawey', language: 'sew by hand '},
                {name: 'shake it -- pepuekey', language: 'shake it '},
                {name: 'shave myself -- peskwe\'may', language: 'shave myself '},
                {name: 'shop -- pekwatelikey', language: 'shop '},
                {name: 'shout -- kesikawetoqsi', language: 'shout '},
                {name: 'shout -- se\'skwey', language: 'shout '},
                {name: 'shove it -- kesmekey', language: 'shove it '},
                {name: 'shove it -- kesmekey', language: 'shove it '},
                {name: 'shovel -- qalipikey', language: 'shovel '},
                {name: 'sin -- pata\'tekey', language: 'sin '},
                {name: 'sin -- pata\'tekey', language: 'sin '},
                {name: 'sing -- ketapekiey', language: 'sing '},
                {name: 'sink in -- wesuaskiey', language: 'sink in '},
                {name: 'sit --', language: 'sit '},
                {name: 'sit -- epi', language: 'sit '},
                {name: 'sit apart from others -- tepkispi', language: 'sit apart from others '},
                {name: 'sit astraddle -- nasuoqpa\'si', language: 'sit astraddle '},
                {name: 'sit crooked -- upmikjepi', language: 'sit crooked '},
                {name: 'sit down -- epa\'si', language: 'sit down '},
                {name: 'sit down -- pempa\'si', language: 'sit down '},
                {name: 'sit hunched over facing toward -- wejkwistepi', language: 'sit hunched over facing toward '},
                {name: 'sit in the way -- ne\'l&#x0268;kopi', language: 'sit in the way '},
                {name: 'sit on the edge -- anapiw-pemkopi', language: 'sit on the edge '},
                {name: 'sit on the ground -- saqanqapi', language: 'sit on the ground '},
                {name: 'sit quietly -- wantaqpi', language: 'sit quietly '},
                {name: 'sit ready -- kiskatpi', language: 'sit ready '},
                {name: 'sit straight -- tetpaqkopi', language: 'sit straight '},
                {name: 'sit with mouth open -- pantunepi', language: 'sit with mouth open '},
                {name: 'skinny -- alu\'say', language: 'skinny '},
                {name: 'skip -- wenaqto\'si', language: 'skip '},
                {name: 'skip wrongly -- o\'pl-wnaqto\'si', language: 'skip wrongly '},
                {name: 'slbe it shut -- iltekey', language: 'slbe it shut '},
                {name: 'sled -- alioqsay', language: 'sled '},
                {name: 'sleep -- nepay', language: 'sleep '},
                {name: 'sleep easily -- naqsinpay', language: 'sleep easily '},
                {name: 'sleep until dawn -- wapnkusi', language: 'sleep until dawn '},
                {name: 'sleep with my eyes open -- wapinpay', language: 'sleep with my eyes open '},
                {name: 'slide by -- pemipqomi', language: 'slide by '},
                {name: 'slide down -- nisaqalio\'qsay', language: 'slide down '},
                {name: 'slip -- sesupa\'lukwei', language: 'slip '},
                {name: 'slouch over -- sawepi', language: 'slouch over '},
                {name: 'smarten up -- sma\'tewa\'si', language: 'smarten up '},
                {name: 'smear it on myself -- amjaqa\'lsi', language: 'smear it on myself '},
                {name: 'smell good -- welimay', language: 'smell good '},
                {name: 'smell like -- telimai', language: 'smell like '},
                {name: 'smell smokey -- wikplatewimai', language: 'smell smokey '},
                {name: 'smile -- weskowikwa\'si', language: 'smile '},
                {name: 'smoke -- kwetmay', language: 'smoke '},
                {name: 'smoke -- kwetmay', language: 'smoke '},
                {name: 'smoke meat -- pukkwana\'tekei', language: 'smoke meat '},
                {name: 'snap it off at neck -- temi\'skipekey', language: 'snap it off at neck '},
                {name: 'sneeze -- ejkwi', language: 'sneeze '},
                {name: 'soak myself -- wetqapalsi', language: 'soak myself '},
                {name: 'sold stuff from there -- wejankuay', language: 'sold stuff from there '},
                {name: 'sop up gravy with bread -- pistami', language: 'sop up gravy with bread '},
                {name: 'sound good -- weltoqsi', language: 'sound good '},
                {name: 'sound in such manner -- telikisutnay', language: 'sound in such manner '},
                {name: 'sound like -- teltoqsi', language: 'sound like '},
                {name: 'sound sick -- kesnukwetoqsi', language: 'sound sick '},
                {name: 'speak -- kelusi', language: 'speak '},
                {name: 'speak a native language -- l\'nui\'si', language: 'speak a native language '},
                {name: 'speak a native language -- l\'nui\'si', language: 'speak a native language '},
                {name: 'speak english -- aklasie\'wi\'si', language: 'speak english '},
                {name: 'speak french -- wen\'jui\'si', language: 'speak french '},
                {name: 'speak huskily -- kepi\'si', language: 'speak huskily '},
                {name: 'speak huskily -- kepi\'si', language: 'speak huskily '},
                {name: 'speak in listuguj dialect -- listukujewi\'si', language: 'speak in listuguj dialect '},
                {name: 'speak poorly -- mali\'si', language: 'speak poorly '},
                {name: 'speak slowly -- pawi\'si', language: 'speak slowly '},
                {name: 'speak slowly -- sipkis\'i', language: 'speak slowly '},
                {name: 'speak slowly; -- mali\'si', language: 'speak slowly; '},
                {name: 'speak the micmac language -- mi\'kmawi\'si', language: 'speak the micmac language '},
                {name: 'spend the night -- ketkuni', language: 'spend the night '},
                {name: 'spill -- kutatestay', language: 'spill '},
                {name: 'spill it -- kutekey', language: 'spill it '},
                {name: 'spill it -- petkutekey', language: 'spill it '},
                {name: 'spin myself -- kiwto\'q&#x0268;to\'si', language: 'spin myself '},
                {name: 'splash along -- pempaqto\'si', language: 'splash along '},
                {name: 'split by striking wuth a tool -- naskoqte\'key', language: 'split by striking wuth a tool '},
                {name: 'sprang up -- wenaqteskasi', language: 'sprang up '},
                {name: 'spread it -- amjaqamay', language: 'spread it '},
                {name: 'spread on thickly -- pasjaqamay', language: 'spread on thickly '},
                {name: 'spread rumors -- elutmakwey', language: 'spread rumors '},
                {name: 'sprint along -- pemi-wisqi\'pi', language: 'sprint along '},
                {name: 'squirm -- milipusi', language: 'squirm '},
                {name: 'stagger -- aljai', language: 'stagger '},
                {name: 'stagger along -- pemi\'pukwey', language: 'stagger along '},
                {name: 'stagger along by -- pemi-siawi\'pukwey', language: 'stagger along by '},
                {name: 'stagger slowly -- aljai', language: 'stagger slowly '},
                {name: 'stagger toward -- eli\'pukwey', language: 'stagger toward '},
                {name: 'stand in the way -- ne\'l&#x0268;pukui', language: 'stand in the way '},
                {name: 'stand it -- kaqmi', language: 'stand it '},
                {name: 'stand out -- kesamuksi', language: 'stand out '},
                {name: 'stand outside -- tewpukui', language: 'stand outside '},
                {name: 'stand ready -- kiskatpukui', language: 'stand ready '},
                {name: 'stand straight -- tetpaqapukui', language: 'stand straight '},
                {name: 'stand up -- kaqama\'si', language: 'stand up '},
                {name: 'stand up -- kaqamasi', language: 'stand up '},
                {name: 'stand up -- kaqma\'si', language: 'stand up '},
                {name: 'stand up -- pemi-qama\'si', language: 'stand up '},
                {name: 'start off by boat -- poqtisukwi', language: 'start off by boat '},
                {name: 'start off poling a boat -- poqtamwe\'kei', language: 'start off poling a boat '},
                {name: 'start to bald -- memqusaqniey', language: 'start to bald '},
                {name: 'start to fish -- poqji-kwitamey', language: 'start to fish '},
                {name: 'start to get on my feet -- pem\'pukua\'si', language: 'start to get on my feet '},
                {name: 'start to laugh -- poqji-ws&#x0268;keweyi', language: 'start to laugh '},
                {name: 'start to laugh -- poqtelmi', language: 'start to laugh '},
                {name: 'start to move -- poqjiwsi', language: 'start to move '},
                {name: 'start to scratch -- poqtapa\'tekey', language: 'start to scratch '},
                {name: 'stay up all night -- wapnapi', language: 'stay up all night '},
                {name: 'steal -- kemutney', language: 'steal '},
                {name: 'steal -- kemutney', language: 'steal '},
                {name: 'step out -- tewpukua\'si', language: 'step out '},
                {name: 'stink -- jijjemay', language: 'stink '},
                {name: 'stink -- kesley', language: 'stink '},
                {name: 'stop -- enqa\'si', language: 'stop '},
                {name: 'stop arguing -- we\'kwa\'muey', language: 'stop arguing '},
                {name: 'stop breathing -- kaqalami', language: 'stop breathing '},
                {name: 'stop crying -- puntemi', language: 'stop crying '},
                {name: 'stop crying -- puntemi', language: 'stop crying '},
                {name: 'stop laughing -- punelmi', language: 'stop laughing '},
                {name: 'stop rowing -- we\'kwiji\'mey', language: 'stop rowing '},
                {name: 'stop talking -- puntoqsi', language: 'stop talking '},
                {name: 'stretch and yawn -- sipi', language: 'stretch and yawn '},
                {name: 'strip off birchbark -- meni\'kwey', language: 'strip off birchbark '},
                {name: 'strum -- mattaqte\'key', language: 'strum '},
                {name: 'strut on -- e\'plewl&#x0268;kai', language: 'strut on '},
                {name: 'suck -- nuney', language: 'suck '},
                {name: 'suckle -- nuney', language: 'suckle '},
                {name: 'suffocate -- aptlami', language: 'suffocate '},
                {name: 'survive -- sapawsi', language: 'survive '},
                {name: 'swallow -- mesa\'tami', language: 'swallow '},
                {name: 'sway -- mato\'qiey', language: 'sway '},
                {name: 'sweep -- pewi\'key', language: 'sweep '},
                {name: 'sweep the floor -- pewi\'key', language: 'sweep the floor '},
                {name: 'sweeten my food -- sismoqna\'tay', language: 'sweeten my food '},
                {name: 'swell up -- mekpey', language: 'swell up '},
                {name: 'swim -- tekismi', language: 'swim '},
                {name: 'swim -- tekismi', language: 'swim '},
                {name: 'tag along -- poqtukwey', language: 'tag along '},
                {name: 'tag along -- wije\'wey', language: 'tag along '},
                {name: 'take a break -- atlasmi', language: 'take a break '},
                {name: 'take a break -- sinapska\'tekei', language: 'take a break '},
                {name: 'take care of a baby -- naqanu\'sey', language: 'take care of a baby '},
                {name: 'take food on a journey -- mimay', language: 'take food on a journey '},
                {name: 'take it easy -- weljaqajewa\'tekei', language: 'take it easy '},
                {name: 'take it in bare hands -- metaqapja\'si', language: 'take it in bare hands '},
                {name: 'take it with barehands -- ketapja\'si', language: 'take it with barehands '},
                {name: 'take photographs -- wasoqatestekey', language: 'take photographs '},
                {name: 'take short cut -- keskma\'si', language: 'take short cut '},
                {name: 'talk -- etlewo\'key', language: 'talk '},
                {name: 'talk a long time -- pekitewo\'kwey', language: 'talk a long time '},
                {name: 'talk in a low voice -- kimewo\'kwey', language: 'talk in a low voice '},
                {name: 'talk like -- teli\'si', language: 'talk like '},
                {name: 'talk loud -- kesikawwey', language: 'talk loud '},
                {name: 'talk slowly -- sankewi\'si', language: 'talk slowly '},
                {name: 'talk softly -- sankewowo\'kwey', language: 'talk softly '},
                {name: 'teach -- kekina\'muey', language: 'teach '},
                {name: 'tease -- ki\'kassi', language: 'tease '},
                {name: 'tell a story -- a\'tukewey', language: 'tell a story '},
                {name: 'tell something -- aknuti', language: 'tell something '},
                {name: 'think -- ankita\'si', language: 'think '},
                {name: 'think hurriedly -- nenaqita\'si', language: 'think hurriedly '},
                {name: 'think slowly -- sankewi-ankita\'si', language: 'think slowly '},
                {name: 'think so -- telta\'si', language: 'think so '},
                {name: 'thoroughly search -- panawijkatekey', language: 'thoroughly search '},
                {name: 'throw it -- elekey', language: 'throw it '},
                {name: 'throw it off -- keptekey', language: 'throw it off '},
                {name: 'tip over -- upmikjepi', language: 'tip over '},
                {name: 'tip toe -- al-kimkawey', language: 'tip toe '},
                {name: 'trace -- wasoqatestekey', language: 'trace '},
                {name: 'trap beaver -- kopitewe\'key', language: 'trap beaver '},
                {name: 'trip -- amalapaqteskmay', language: 'trip '},
                {name: 'trot -- elm&#x0268;kate\'pi', language: 'trot '},
                {name: 'trot along -- pemikate\'pi', language: 'trot along '},
                {name: 'tumble -- te\'tipjiey', language: 'tumble '},
                {name: 'turn around -- kiwaska\'si', language: 'turn around '},
                {name: 'turn around -- kiwto\'qa\'si', language: 'turn around '},
                {name: 'turn around while sitting down -- kiwask&#x0268;pasi', language: 'turn around while sitting down '},
                {name: 'turn it over quickly -- kiwaskekey', language: 'turn it over quickly '},
                {name: 'turn out unexpectedly -- piltua\'si', language: 'turn out unexpectedly '},
                {name: 'turn over while lying around -- kiwaskisma\'si', language: 'turn over while lying around '},
                {name: 'undress -- menikna\'si', language: 'undress '},
                {name: 'undress -- musika\'si', language: 'undress '},
                {name: 'urinate -- pi\'si', language: 'urinate '},
                {name: 'urinate -- seki', language: 'urinate '},
                {name: 'usually go free loading -- puski-ali-ksata\'kwey', language: 'usually go free loading '},
                {name: 'very holy -- kji- sape\'wi', language: 'very holy '},
                {name: 'vomit -- so\'qotemi', language: 'vomit '},
                {name: 'wade in water -- elasukwey', language: 'wade in water '},
                {name: 'wade thought the snow towards the road -- matali\'kiey', language: 'wade thought the snow towards the road '},
                {name: 'wager -- ika\'tekey', language: 'wager '},
                {name: 'wake up -- tukwiey', language: 'wake up '},
                {name: 'walk along the shore -- pemijajika\'si', language: 'walk along the shore '},
                {name: 'walk like an old person -- kisiku\'l&#x0268;kay', language: 'walk like an old person '},
                {name: 'walk quietly -- kimteskawey', language: 'walk quietly '},
                {name: 'walk towards the road -- matama\'si', language: 'walk towards the road '},
                {name: 'walk well -- welipmiey', language: 'walk well '},
                {name: 'walk with a light -- petasenmay', language: 'walk with a light '},
                {name: 'wander about -- se\'sa\'si', language: 'wander about '},
                {name: 'wander off unintentionally -- matua\'si', language: 'wander off unintentionally '},
                {name: 'wash my face -- kasikwa\'si', language: 'wash my face '},
                {name: 'wash myself -- kesispa\'lsi', language: 'wash myself '},
                {name: 'wear a perfume -- mimkway', language: 'wear a perfume '},
                {name: 'wear dirty clothes -- mejikkmey', language: 'wear dirty clothes '},
                {name: 'wear high heel shoes -- pitqonay', language: 'wear high heel shoes '},
                {name: 'wear proper clothing -- tetpaqkutay', language: 'wear proper clothing '},
                {name: 'whistle -- wikua\'sumkwey', language: 'whistle '},
                {name: 'whistle -- wikwa\'smukwey', language: 'whistle '},
                {name: 'whistle along -- pemi-wikwa\'smukwey', language: 'whistle along '},
                {name: 'whittle -- amalsawey', language: 'whittle '},
                {name: 'win -- kespu\'tuey', language: 'win '},
                {name: 'win it back -- apatte\'may', language: 'win it back '},
                {name: 'win it back -- kespu\'tuey', language: 'win it back '},
                {name: 'wobble -- majo\'qiey', language: 'wobble '},
                {name: 'wobble -- pemapo\'qiey', language: 'wobble '},
                {name: 'work -- elukwey', language: 'work '},
                {name: 'work at a river drive -- pesi\'kukwei', language: 'work at a river drive '},
                {name: 'work away from home -- tewlukwey', language: 'work away from home '},
                {name: 'work on a raft -- sn\'katikna\'tekey', language: 'work on a raft '},
                {name: 'work properly -- keknu\'lukwey', language: 'work properly '},
                {name: 'write -- ewi\'kikey', language: 'write '},
                {name: 'writes using mikmaq hierogolyphics -- komkwejui\'kikey', language: 'writes using mikmaq hierogolyphics '},
              /*(  {name: '-- sit', language: ''},
                {name: 'a\'lutasi -- guide by boat', language: 'a\'lutasi '},
                {name: 'a\'piemi -- have a net', language: 'a\'piemi '},
                {name: 'a\'pikey -- fish with a net', language: 'a\'pikey '},
                {name: 'a\'tukewey -- tell a story', language: 'a\'tukewey '},
                {name: 'aja\'si -- move', language: 'aja\'si '},
                {name: 'akase\'wi -- enlist', language: 'akase\'wi '},
                {name: 'aklasie\'wi\'si -- speak english', language: 'aklasie\'wi\'si '},
                {name: 'aknuti -- tell something', language: 'aknuti '},
                {name: 'al-kimkawey -- tip toe', language: 'al'},
                {name: 'ala\'si -- go about', language: 'ala\'si '},
                {name: 'alamiey -- rock', language: 'alamiey '},
                {name: 'alapi -- look about', language: 'alapi '},
                {name: 'alaqtekey -- sail', language: 'alaqtekey '},
                {name: 'alasutmay -- pray', language: 'alasutmay '},
                {name: 'alasutmay -- pray', language: 'alasutmay '},
                {name: 'alatija\'si -- scurry about', language: 'alatija\'si '},
                {name: 'ali\'puluey -- ride a horse', language: 'ali\'puluey '},
                {name: 'alioqsay -- sled', language: 'alioqsay '},
                {name: 'aliskey -- grope around', language: 'aliskey '},
                {name: 'aljai -- stagger', language: 'aljai '},
                {name: 'aljai -- stagger slowly', language: 'aljai '},
                {name: 'alje\'may -- play ball', language: 'alje\'may '},
                {name: 'almila\'si -- play', language: 'almila\'si '},
                {name: 'alqo\'qwey -- float', language: 'alqo\'qwey '},
                {name: 'alu\'say -- be lean', language: 'alu\'say '},
                {name: 'alu\'say -- skinny', language: 'alu\'say '},
                {name: 'aluasa\'si -- fade in and out', language: 'aluasa\'si '},
                {name: 'amalapaqteskmay -- trip', language: 'amalapaqteskmay '},
                {name: 'amalisknuey -- crochet', language: 'amalisknuey '},
                {name: 'amalisknuey -- knit', language: 'amalisknuey '},
                {name: 'amalkay -- dance', language: 'amalkay '},
                {name: 'amalkay -- dance native style', language: 'amalkay '},
                {name: 'amaloqsawey -- carve', language: 'amaloqsawey '},
                {name: 'amalsawey -- whittle', language: 'amalsawey '},
                {name: 'amalsiktmay -- be spooked', language: 'amalsiktmay '},
                {name: 'amaltaqi\'sawey -- embroider', language: 'amaltaqi\'sawey '},
                {name: 'amaltoqtey -- bake variety of pastry', language: 'amaltoqtey '},
                {name: 'amipusi -- rub on it', language: 'amipusi '},
                {name: 'amjaqa\'lsi -- smear it on myself', language: 'amjaqa\'lsi '},
                {name: 'amjaqamay -- spread it', language: 'amjaqamay '},
                {name: 'amjaqmay -- butter my bread', language: 'amjaqmay '},
                {name: 'amjaqto\'si -- dab myself', language: 'amjaqto\'si '},
                {name: 'amsela\'si -- go wrong', language: 'amsela\'si '},
                {name: 'amskweseweyi -- be first', language: 'amskweseweyi '},
                {name: 'anapiw-pemkopi -- sit on the edge', language: 'anapiw'},
                {name: 'ankamsi -- look at myself', language: 'ankamsi '},
                {name: 'ankaptekey -- look', language: 'ankaptekey '},
                {name: 'ankita\'si -- think', language: 'ankita\'si '},
                {name: 'anko\'tasi -- in foster care', language: 'anko\'tasi '},
                {name: 'anqunali\'kiey -- be covered with snow', language: 'anqunali\'kiey '},
                {name: 'apaja\'si -- come back', language: 'apaja\'si '},
                {name: 'apato\'si -- break even in gambling', language: 'apato\'si '},
                {name: 'apatte\'may -- win it back', language: 'apatte\'may '},
                {name: 'apatto\'si -- break even', language: 'apatto\'si '},
                {name: 'apipunsi -- return from spending winter', language: 'apipunsi '},
                {name: 'apitay -- get bloated', language: 'apitay '},
                {name: 'apjiku\'niey -- bleed', language: 'apjiku\'niey '},
                {name: 'apoqnmasi -- help myself', language: 'apoqnmasi '},
                {name: 'apoqnmuey -- help', language: 'apoqnmuey '},
                {name: 'apsisknuey -- knit', language: 'apsisknuey '},
                {name: 'aptlami -- suffocate', language: 'aptlami '},
                {name: 'aptukuli -- come back from hunting', language: 'aptukuli '},
                {name: 'askas\'si -- limp', language: 'askas\'si '},
                {name: 'asoqmapeki -- crawl across', language: 'asoqmapeki '},
                {name: 'asoqmu\'tekey -- ferry across', language: 'asoqmu\'tekey '},
                {name: 'asu\'sey -- pace back/forth', language: 'asu\'sey '},
                {name: 'atkitemi -- cry a lot', language: 'atkitemi '},
                {name: 'atlasmi -- relax', language: 'atlasmi '},
                {name: 'atlasmi -- take a break', language: 'atlasmi '},
                {name: 'awanta\'si -- forget', language: 'awanta\'si '},
                {name: 'awanta\'si -- forget', language: 'awanta\'si '},
                {name: 'awnasatpay -- be confused', language: 'awnasatpay '},
                {name: 'awnaseyi -- be foolish', language: 'awnaseyi '},
                {name: 'awsamitqwaqji\'ji -- be too short', language: 'awsamitqwaqji\'ji '},
                {name: 'awsamkami -- be too fat', language: 'awsamkami '},
                {name: 'e\'plewl&#x0268;kai -- strut on', language: 'e\'plewl&#x0268;kai '},
                {name: 'ejikla\'si -- go away', language: 'ejikla\'si '},
                {name: 'ejikliwsi -- move away', language: 'ejikliwsi '},
                {name: 'ejkwi -- sneeze', language: 'ejkwi '},
                {name: 'ekiljey -- read', language: 'ekiljey '},
                {name: 'ekiljey -- read properly', language: 'ekiljey '},
                {name: 'ekna\'si -- dress up', language: 'ekna\'si '},
                {name: 'ekna\'si -- get dressed', language: 'ekna\'si '},
                {name: 'eknu\'tmasi -- learn', language: 'eknu\'tmasi '},
                {name: 'elapi -- look toward', language: 'elapi '},
                {name: 'elasukwey -- wade in water', language: 'elasukwey '},
                {name: 'elateja\'si -- scoot over', language: 'elateja\'si '},
                {name: 'elawley -- lug toward', language: 'elawley '},
                {name: 'elay -- look like', language: 'elay '},
                {name: 'elekey -- throw it', language: 'elekey '},
                {name: 'eli\'pukwey -- stagger toward', language: 'eli\'pukwey '},
                {name: 'eli\'sawey -- sew', language: 'eli\'sawey '},
                {name: 'eliey -- go somewhere', language: 'eliey '},
                {name: 'elisknuey -- knit', language: 'elisknuey '},
                {name: 'eliskuwi -- paddle toward', language: 'eliskuwi '},
                {name: 'elkenawey -- hoe', language: 'elkenawey '},
                {name: 'elkesawey -- be cultivating', language: 'elkesawey '},
                {name: 'elkete\'key -- hoe', language: 'elkete\'key '},
                {name: 'elm&#x0268;kate\'pi -- trot', language: 'elm&#x0268;kate\'pi '},
                {name: 'elmnqwe\'sey -- scoot home', language: 'elmnqwe\'sey '},
                {name: 'elo\'tekey -- hint', language: 'elo\'tekey '},
                {name: 'eloqtnemai -- have bad breath', language: 'eloqtnemai '},
                {name: 'eltaqnawey -- harness a horse', language: 'eltaqnawey '},
                {name: 'elu\'sknawey -- knead', language: 'elu\'sknawey '},
                {name: 'elukwey -- work', language: 'elukwey '},
                {name: 'elutmakwey -- gossip', language: 'elutmakwey '},
                {name: 'elutmakwey -- spread rumors', language: 'elutmakwey '},
                {name: 'enkey -- measure', language: 'enkey '},
                {name: 'enqa\'si -- stop', language: 'enqa\'si '},
                {name: 'epa\'si -- sit down', language: 'epa\'si '},
                {name: 'epaqamkiey -- be grimy', language: 'epaqamkiey '},
                {name: 'epi -- sit', language: 'epi '},
                {name: 'epsi -- be hot', language: 'epsi '},
                {name: 'esam\'qway -- drink', language: 'esam\'qway '},
                {name: 'esata\'si -- move aside', language: 'esata\'si '},
                {name: 'esatapeki -- crawl backward', language: 'esatapeki '},
                {name: 'esk&#x0268;tami -- eat raw', language: 'esk&#x0268;tami '},
                {name: 'esnoqney -- cut lumber', language: 'esnoqney '},
                {name: 'etawaqtmay -- ask for myself', language: 'etawaqtmay '},
                {name: 'etlewo\'key -- talk', language: 'etlewo\'key '},
                {name: 'etlikwey -- grow up', language: 'etlikwey '},
                {name: 'etlipulsi -- rub myself', language: 'etlipulsi '},
                {name: 'ewi\'kay -- build', language: 'ewi\'kay '},
                {name: 'ewi\'kikey -- write', language: 'ewi\'kikey '},
                {name: 'ewlamuksi -- be pitiful', language: 'ewlamuksi '},
                {name: 'ewlapi -- be nearsighted', language: 'ewlapi '},
                {name: 'ewlapi -- see poorly', language: 'ewlapi '},
                {name: 'ewlapi -- see poorly', language: 'ewlapi '},
                {name: 'ewle\'ji -- be poor', language: 'ewle\'ji '},
                {name: 'ewliptinay -- have a crippled hand', language: 'ewliptinay '},
                {name: 'ewlite\'lmuksi -- be pitied', language: 'ewlite\'lmuksi '},
                {name: 'ewlite\'tekey -- be compassionate', language: 'ewlite\'tekey '},
                {name: 'ewluktay -- be dressed raggedly', language: 'ewluktay '},
                {name: 'i\'pusi -- drive', language: 'i\'pusi '},
                {name: 'ika\'tekey -- wager', language: 'ika\'tekey '},
                {name: 'ikai -- arrive', language: 'ikai '},
                {name: 'ikatne\'wey -- race', language: 'ikatne\'wey '},
                {name: 'iknmuey -- give away', language: 'iknmuey '},
                {name: 'ila\'si -- get well', language: 'ila\'si '},
                {name: 'ila\'skukwey -- play a card game', language: 'ila\'skukwey '},
                {name: 'ila\'skukwey -- play cards', language: 'ila\'skukwey '},
                {name: 'ilapewnusi -- groom myself', language: 'ilapewnusi '},
                {name: 'ilpalikay -- pack', language: 'ilpalikay '},
                {name: 'iltaqa\'lsi -- flex myself', language: 'iltaqa\'lsi '},
                {name: 'iltekey -- slbe it shut', language: 'iltekey '},
                {name: 'jaqliki -- be short and round', language: 'jaqliki '},
                {name: 'jaqliki\'ji\'ji -- be short and round', language: 'jaqliki\'ji\'ji '},
                {name: 'ji\'mey -- row a boat', language: 'ji\'mey '},
                {name: 'ji\'nmui -- be a man', language: 'ji\'nmui '},
                {name: 'jijjemay -- stink', language: 'jijjemay '},
                {name: 'jinpeknawey -- milk a cow', language: 'jinpeknawey '},
                {name: 'kamlami -- breathe', language: 'kamlami '},
                {name: 'kaqa\'tekey -- finish', language: 'kaqa\'tekey '},
                {name: 'kaqalami -- stop breathing', language: 'kaqalami '},
                {name: 'kaqama\'si -- stand up', language: 'kaqama\'si '},
                {name: 'kaqamasi -- stand up', language: 'kaqamasi '},
                {name: 'kaqapuey -- be wet', language: 'kaqapuey '},
                {name: 'kaqikisa\'tekey -- finish', language: 'kaqikisa\'tekey '},
                {name: 'kaqma\'si -- stand up', language: 'kaqma\'si '},
                {name: 'kaqmi -- stand it', language: 'kaqmi '},
                {name: 'kaqsi -- burn', language: 'kaqsi '},
                {name: 'kaquiey -- be cranky', language: 'kaquiey '},
                {name: 'kasikwa\'si -- wash my face', language: 'kasikwa\'si '},
                {name: 'kaskwi -- gallop a horse', language: 'kaskwi '},
                {name: 'kastio\'mia\'tay -- add molasses to my food', language: 'kastio\'mia\'tay '},
                {name: 'kate\'key -- hunt eels', language: 'kate\'key '},
                {name: 'ke\'koqpi -- lie atop', language: 'ke\'koqpi '},
                {name: 'ke\'kupa\'si -- get on top', language: 'ke\'kupa\'si '},
                {name: 'ke\'tipney -- harvest crops', language: 'ke\'tipney '},
                {name: 'kejitekey -- be knowledgeable', language: 'kejitekey '},
                {name: 'kekina\'muey -- teach', language: 'kekina\'muey '},
                {name: 'kekinua\'tekey -- inform', language: 'kekinua\'tekey '},
                {name: 'kekkunawey -- be a godparent', language: 'kekkunawey '},
                {name: 'keknu\'kitjey -- read properly', language: 'keknu\'kitjey '},
                {name: 'keknu\'lukwey -- work properly', language: 'keknu\'lukwey '},
                {name: 'keknu\'qamiksi -- act properly', language: 'keknu\'qamiksi '},
                {name: 'keknueyi -- be respectful', language: 'keknueyi '},
                {name: 'keknukwatikei -- blaze', language: 'keknukwatikei '},
                {name: 'kekwa\'si -- move slowly', language: 'kekwa\'si '},
                {name: 'kekwi\'pi -- run slowly', language: 'kekwi\'pi '},
                {name: 'kelji -- be frozen', language: 'kelji '},
                {name: 'kelu\'si -- be beautiful', language: 'kelu\'si '},
                {name: 'kelusi -- speak', language: 'kelusi '},
                {name: 'kemutney -- steal', language: 'kemutney '},
                {name: 'kemutney -- steal', language: 'kemutney '},
                {name: 'kepi\'si -- speak huskily', language: 'kepi\'si '},
                {name: 'kepi\'si -- speak huskily', language: 'kepi\'si '},
                {name: 'kepjoqikey -- lock up', language: 'kepjoqikey '},
                {name: 'kepta\'si -- get off', language: 'kepta\'si '},
                {name: 'keptekey -- throw it off', language: 'keptekey '},
                {name: 'kesaluey -- love', language: 'kesaluey '},
                {name: 'kesaluksi -- be loved', language: 'kesaluksi '},
                {name: 'kesamuksi -- stand out', language: 'kesamuksi '},
                {name: 'kesatkusi -- be still sleepy', language: 'kesatkusi '},
                {name: 'kesikawa\'si -- move fast', language: 'kesikawa\'si '},
                {name: 'kesikawetoqsi -- shout', language: 'kesikawetoqsi '},
                {name: 'kesikaweyi -- be noisy', language: 'kesikaweyi '},
                {name: 'kesikawlami -- breathe hard', language: 'kesikawlami '},
                {name: 'kesikawwey -- talk loud', language: 'kesikawwey '},
                {name: 'kesipa\'lsi -- scratch', language: 'kesipa\'lsi '},
                {name: 'kesispa\'lsi -- wash myself', language: 'kesispa\'lsi '},
                {name: 'kesistaqnawey -- do laundry', language: 'kesistaqnawey '},
                {name: 'keskija\'si -- climb over', language: 'keskija\'si '},
                {name: 'keskitlmaqnay -- have broad shoulders', language: 'keskitlmaqnay '},
                {name: 'keskma\'si -- take short cut', language: 'keskma\'si '},
                {name: 'keskunasi -- carry a heavy load', language: 'keskunasi '},
                {name: 'kesley -- #VALUE!', language: 'kesley '},
                {name: 'kesley -- stink', language: 'kesley '},
                {name: 'kesmekey -- shove it', language: 'kesmekey '},
                {name: 'kesmekey -- shove it', language: 'kesmekey '},
                {name: 'kesnukamkusi -- appear sickly', language: 'kesnukamkusi '},
                {name: 'kesnukway -- be sick', language: 'kesnukway '},
                {name: 'kesnukwetoqsi -- sound sick', language: 'kesnukwetoqsi '},
                {name: 'kesoqsi -- get a burn', language: 'kesoqsi '},
                {name: 'kesoqwey -- have strong odor', language: 'kesoqwey '},
                {name: 'kespu\'tuey -- win', language: 'kespu\'tuey '},
                {name: 'kespu\'tuey -- win it back', language: 'kespu\'tuey '},
                {name: 'kespukwa\'tekey -- lye', language: 'kespukwa\'tekey '},
                {name: 'ketantekey -- hunt', language: 'ketantekey '},
                {name: 'ketapekiey -- sing', language: 'ketapekiey '},
                {name: 'ketapja\'si -- take it with barehands', language: 'ketapja\'si '},
                {name: 'ketaqama\'si -- eat', language: 'ketaqama\'si '},
                {name: 'ketkuni -- spend the night', language: 'ketkuni '},
                {name: 'ketlamsitasi -- believe', language: 'ketlamsitasi '},
                {name: 'ketmoqjekey -- give it a quick push', language: 'ketmoqjekey '},
                {name: 'ketu\'muey -- make music', language: 'ketu\'muey '},
                {name: 'ketuapsi -- fend for myself', language: 'ketuapsi '},
                {name: 'kewiey -- fall over', language: 'kewiey '},
                {name: 'kewji -- be cold', language: 'kewji '},
                {name: 'kewji -- feel cold', language: 'kewji '},
                {name: 'kewtaqsiki -- feel my feet are cold', language: 'kewtaqsiki '},
                {name: 'ki\'kaja\'si -- argue', language: 'ki\'kaja\'si '},
                {name: 'ki\'kassi -- tease', language: 'ki\'kassi '},
                {name: 'kimelmi -- laugh secretly', language: 'kimelmi '},
                {name: 'kimelmuktasi -- laugh at myself', language: 'kimelmuktasi '},
                {name: 'kimewo\'kwey -- talk in a low voice', language: 'kimewo\'kwey '},
                {name: 'kimtemi -- cry secretly', language: 'kimtemi '},
                {name: 'kimteskawey -- walk quietly', language: 'kimteskawey '},
                {name: 'kinsi -- brag', language: 'kinsi '},
                {name: 'kiseyi -- be funny', language: 'kiseyi '},
                {name: 'kisiku\'ey -- become old', language: 'kisiku\'ey '},
                {name: 'kisiku\'l&#x0268;kay -- walk like an old person', language: 'kisiku\'l&#x0268;kay '},
                {name: 'kisiku\'qamiksi -- act like an old person', language: 'kisiku\'qamiksi '},
                {name: 'kisikui -- be old', language: 'kisikui '},
                {name: 'kiskajiei -- measure up to', language: 'kiskajiei '},
                {name: 'kiskatpi -- sit ready', language: 'kiskatpi '},
                {name: 'kiskatpukui -- stand ready', language: 'kiskatpukui '},
                {name: 'kispnapewi -- look tired', language: 'kispnapewi '},
                {name: 'kispney -- become tired', language: 'kispney '},
                {name: 'kitaqasi -- ache', language: 'kitaqasi '},
                {name: 'kitnmay -- have difficulty', language: 'kitnmay '},
                {name: 'kiwask&#x0268;pasi -- turn around while sitting down', language: 'kiwask&#x0268;pasi '},
                {name: 'kiwaska\'si -- turn around', language: 'kiwaska\'si '},
                {name: 'kiwaskekey -- turn it over quickly', language: 'kiwaskekey '},
                {name: 'kiwaskisma\'si -- turn over while lying around', language: 'kiwaskisma\'si '},
                {name: 'kiwiksi -- barter for goods', language: 'kiwiksi '},
                {name: 'kiwto\'q&#x0268;to\'si -- spin myself', language: 'kiwto\'q&#x0268;to\'si '},
                {name: 'kiwto\'qa\'si -- turn around', language: 'kiwto\'qa\'si '},
                {name: 'kiwto\'qwa\'si -- go around in a circle', language: 'kiwto\'qwa\'si '},
                {name: 'kji- sape\'wi -- very holy', language: 'kji'},
                {name: 'kjikankewi -- go to town', language: 'kjikankewi '},
                {name: 'klujjiewto\'si -- bless myself', language: 'klujjiewto\'si '},
                {name: 'klujjiewto\'si -- make the sign of cross', language: 'klujjiewto\'si '},
                {name: 'kmnie\'wi -- receive holy communion', language: 'kmnie\'wi '},
                {name: 'kmu\'je\'key -- cut lumber', language: 'kmu\'je\'key '},
                {name: 'ko\'qmajeyi -- be looking persistently', language: 'ko\'qmajeyi '},
                {name: 'komkwejui\'kikey -- writes using mikmaq hierogolyphics', language: 'komkwejui\'kikey '},
                {name: 'kopitewe\'key -- trap beaver', language: 'kopitewe\'key '},
                {name: 'koqqaja\'tekey -- do it correctly', language: 'koqqaja\'tekey '},
                {name: 'kutana\'si -- go to town', language: 'kutana\'si '},
                {name: 'kutatestay -- spill', language: 'kutatestay '},
                {name: 'kutekey -- spill it', language: 'kutekey '},
                {name: 'kweltami -- feast', language: 'kweltami '},
                {name: 'kwetajikwey -- frown', language: 'kwetajikwey '},
                {name: 'kwetajikwey -- frown', language: 'kwetajikwey '},
                {name: 'kwetapai -- go underwater', language: 'kwetapai '},
                {name: 'kwetapiey -- dive', language: 'kwetapiey '},
                {name: 'kwetmay -- smoke', language: 'kwetmay '},
                {name: 'kwetmay -- smoke', language: 'kwetmay '},
                {name: 'l\'ketuekey -- gather mushrooms', language: 'l\'ketuekey '},
                {name: 'l\'nu\'kway -- dance native style', language: 'l\'nu\'kway '},
                {name: 'l\'nui -- be native', language: 'l\'nui '},
                {name: 'l\'nui\'si -- speak a native language', language: 'l\'nui\'si '},
                {name: 'l\'nui\'si -- speak a native language', language: 'l\'nui\'si '},
                {name: 'l\'pa\'tujui -- be a boy', language: 'l\'pa\'tujui '},
                {name: 'lapikotewi -- have small pox', language: 'lapikotewi '},
                {name: 'listukujewi\'si -- speak in listuguj dialect', language: 'listukujewi\'si '},
                {name: 'lokowi -- be lucky', language: 'lokowi '},
                {name: 'loqte\'knikey -- set traps', language: 'loqte\'knikey '},
                {name: 'lu\'sue\'skwewa\'si -- become a daughter-inlaw', language: 'lu\'sue\'skwewa\'si '},
                {name: 'lu\'sukewa\'si -- become a son-inlaw', language: 'lu\'sukewa\'si '},
                {name: 'lukwaqani -- be bothersome', language: 'lukwaqani '},
                {name: 'maja\'si -- be leaving', language: 'maja\'si '},
                {name: 'majo\'qiey -- wobble', language: 'majo\'qiey '},
                {name: 'mal\'tewi -- be bloody', language: 'mal\'tewi '},
                {name: 'mal\'tewiey -- be bleeding', language: 'mal\'tewiey '},
                {name: 'mala\'si -- be not doing well', language: 'mala\'si '},
                {name: 'maleyi -- be lazy', language: 'maleyi '},
                {name: 'mali\'si -- speak poorly', language: 'mali\'si '},
                {name: 'mali\'si -- speak slowly;', language: 'mali\'si '},
                {name: 'malie\'wi -- be getting married', language: 'malie\'wi '},
                {name: 'malikwey -- grow at a slow pace', language: 'malikwey '},
                {name: 'maljewe\'jewi -- be young', language: 'maljewe\'jewi '},
                {name: 'mallikway -- be mentally lazy', language: 'mallikway '},
                {name: 'malsano\'kuo\'mkewi -- go to a store', language: 'malsano\'kuo\'mkewi '},
                {name: 'maltikle\'wi -- participate in MardGras', language: 'maltikle\'wi '},
                {name: 'malukunay -- have a weak back', language: 'malukunay '},
                {name: 'maqalkikway -- have large eyes', language: 'maqalkikway '},
                {name: 'maqalqasi -- have a large hole', language: 'maqalqasi '},
                {name: 'maqapsk&#x0268;tiay -- have a big and round belly', language: 'maqapsk&#x0268;tiay '},
                {name: 'maqapskisi -- be fat', language: 'maqapskisi '},
                {name: 'maqapskusuay -- have large testicles', language: 'maqapskusuay '},
                {name: 'maqatpay -- have a big head', language: 'maqatpay '},
                {name: 'maqi-wilnui -- have big tongue', language: 'maqi'},
                {name: 'maqikjay -- have big buttocks', language: 'maqikjay '},
                {name: 'maqiskeyi -- be wide', language: 'maqiskeyi '},
                {name: 'maqisqunay -- have a big nose', language: 'maqisqunay '},
                {name: 'maqistaqanay -- have big ears', language: 'maqistaqanay '},
                {name: 'maqitnay -- have big nostrils', language: 'maqitnay '},
                {name: 'maqiwtuli -- have a big boat', language: 'maqiwtuli '},
                {name: 'maqmikewa\'si -- go by land', language: 'maqmikewa\'si '},
                {name: 'maqo\'qiptnay -- have big arms', language: 'maqo\'qiptnay '},
                {name: 'maqoqsi -- be big in girth', language: 'maqoqsi '},
                {name: 'maqsitay -- have big feet', language: 'maqsitay '},
                {name: 'maqteweyi -- be black', language: 'maqteweyi '},
                {name: 'maqtewi\'tuay -- have a black beard', language: 'maqtewi\'tuay '},
                {name: 'maqtewikjay -- be black-bottomed', language: 'maqtewikjay '},
                {name: 'maqtewikneksi -- be dark complexioned', language: 'maqtewikneksi '},
                {name: 'maqtewoqsi -- be dark tanned', language: 'maqtewoqsi '},
                {name: 'maqtunay -- have a big mouth', language: 'maqtunay '},
                {name: 'masqo\'tasi -- put thinks away', language: 'masqo\'tasi '},
                {name: 'massaqiey -- be sexually aroused', language: 'massaqiey '},
                {name: 'matali\'kiey -- wade thought the snow towards the road', language: 'matali\'kiey '},
                {name: 'matama\'si -- walk towards the road', language: 'matama\'si '},
                {name: 'matkwa\'si -- bow down', language: 'matkwa\'si '},
                {name: 'matnekey -- fight', language: 'matnekey '},
                {name: 'matnekey -- fight', language: 'matnekey '},
                {name: 'mato\'qiey -- sway', language: 'mato\'qiey '},
                {name: 'mattaqte\'key -- send a wire or telegram', language: 'mattaqte\'key '},
                {name: 'mattaqte\'key -- strum', language: 'mattaqte\'key '},
                {name: 'matua\'si -- wander off unintentionally', language: 'matua\'si '},
                {name: 'mawiwlta\'si -- become ecstatic', language: 'mawiwlta\'si '},
                {name: 'mawkami -- be the fattest', language: 'mawkami '},
                {name: 'mawkiljey -- calculate', language: 'mawkiljey '},
                {name: 'mawklu\'si -- be very good', language: 'mawklu\'si '},
                {name: 'mawo\'tasi -- gather', language: 'mawo\'tasi '},
                {name: 'mawsa\'tay -- make a fire inside', language: 'mawsa\'tay '},
                {name: 'mawsa\'tekey -- build a fire in a stove', language: 'mawsa\'tekey '},
                {name: 'me\'ka\'si -- overact', language: 'me\'ka\'si '},
                {name: 'me\'ka\'tekey -- be overdo', language: 'me\'ka\'tekey '},
                {name: 'me\'keyi -- be snobbish', language: 'me\'keyi '},
                {name: 'me\'sipmiey -- be incapable of walking', language: 'me\'sipmiey '},
                {name: 'mejikamuksi -- have a dirty appearance', language: 'mejikamuksi '},
                {name: 'mejikapewi -- have a dirty face', language: 'mejikapewi '},
                {name: 'mejikeyi -- be dirty', language: 'mejikeyi '},
                {name: 'mejikkmey -- wear dirty clothes', language: 'mejikkmey '},
                {name: 'mejjilsi -- defecate on myself', language: 'mejjilsi '},
                {name: 'mejjukway -- defecate', language: 'mejjukway '},
                {name: 'mekawtukwey -- charge a high price for labour', language: 'mekawtukwey '},
                {name: 'mekite\'lsi -- be conceited', language: 'mekite\'lsi '},
                {name: 'mekite\'tasi -- be honoured', language: 'mekite\'tasi '},
                {name: 'meko\'ti -- be expensive', language: 'meko\'ti '},
                {name: 'mekpey -- swell up', language: 'mekpey '},
                {name: 'mekwa\'tay -- put on a rouge', language: 'mekwa\'tay '},
                {name: 'mekwamuksi -- be reddish', language: 'mekwamuksi '},
                {name: 'mekwi -- be swollen', language: 'mekwi '},
                {name: 'melkapji -- have a strong grip', language: 'melkapji '},
                {name: 'melki\'taqnay -- have a strong neck', language: 'melki\'taqnay '},
                {name: 'melkiaqasi -- be muscular', language: 'melkiaqasi '},
                {name: 'melkiki -- be soldily', language: 'melkiki '},
                {name: 'melkiknay -- be strong', language: 'melkiknay '},
                {name: 'melkitai -- be brave', language: 'melkitai '},
                {name: 'melkwiskay -- have a nightmare', language: 'melkwiskay '},
                {name: 'memkaqnatpay -- be bald', language: 'memkaqnatpay '},
                {name: 'memkoqte\'key -- be clear cutting', language: 'memkoqte\'key '},
                {name: 'memqusaqniey -- start to bald', language: 'memqusaqniey '},
                {name: 'mena\'piey -- remove fish from a net', language: 'mena\'piey '},
                {name: 'mena\'pikey -- remove a net from the water', language: 'mena\'pikey '},
                {name: 'menaje\'ji -- be frail', language: 'menaje\'ji '},
                {name: 'menaji -- be sensitive to cold', language: 'menaji '},
                {name: 'menaqanay -- be weak', language: 'menaqanay '},
                {name: 'mene\'smay -- dig for clams', language: 'mene\'smay '},
                {name: 'meni\'kwey -- strip off birchbark', language: 'meni\'kwey '},
                {name: 'menikna\'si -- undress', language: 'menikna\'si '},
                {name: 'menipqwa\'wey -- be removing the bark', language: 'menipqwa\'wey '},
                {name: 'menja\'si -- get up from lying position', language: 'menja\'si '},
                {name: 'menjipukui -- be now in a standing position', language: 'menjipukui '},
                {name: 'menuekey -- need', language: 'menuekey '},
                {name: 'mesa\'tami -- swallow', language: 'mesa\'tami '},
                {name: 'meskeyi -- be sorry', language: 'meskeyi '},
                {name: 'mesqana\'si -- go down to the floor', language: 'mesqana\'si '},
                {name: 'mesqanapi -- be in crouching position', language: 'mesqanapi '},
                {name: 'mess&#x0268;teskmay -- miss a connection', language: 'mess&#x0268;teskmay '},
                {name: 'mesuktaqanay -- crave (eat a lot)', language: 'mesuktaqanay '},
                {name: 'metaluay -- feel a rumbling inside my stomach', language: 'metaluay '},
                {name: 'metaqapja\'si -- take it in bare hands', language: 'metaqapja\'si '},
                {name: 'metaqikjay -- be barebottomed', language: 'metaqikjay '},
                {name: 'metete\'key -- be knocking', language: 'metete\'key '},
                {name: 'metkatay -- be barefooted', language: 'metkatay '},
                {name: 'metkway -- be bareheaded', language: 'metkway '},
                {name: 'metkway -- be hatless', language: 'metkway '},
                {name: 'metoqiey -- descend in elevation', language: 'metoqiey '},
                {name: 'metoqiwsi -- move to lower land', language: 'metoqiwsi '},
                {name: 'mewisi -- be berry picking', language: 'mewisi '},
                {name: 'mi\'kmawi\'si -- speak the micmac language', language: 'mi\'kmawi\'si '},
                {name: 'mijisi -- eat', language: 'mijisi '},
                {name: 'mijisi -- eat', language: 'mijisi '},
                {name: 'mijji -- eat', language: 'mijji '},
                {name: 'mikutekey -- plane', language: 'mikutekey '},
                {name: 'mil-lukwey -- do different kinds of work', language: 'mil'},
                {name: 'mila\'si -- play', language: 'mila\'si '},
                {name: 'mila\'tekey -- be mischievous', language: 'mila\'tekey '},
                {name: 'milesi -- be rich', language: 'milesi '},
                {name: 'milesi -- be rich', language: 'milesi '},
                {name: 'milipusi -- squirm', language: 'milipusi '},
                {name: 'milita\'si -- have different kinds of thoughts', language: 'milita\'si '},
                {name: 'milkusi -- have all kinds if dreams', language: 'milkusi '},
                {name: 'milkwija\'si -- have mixed emotions', language: 'milkwija\'si '},
                {name: 'miltoqsi -- make different sounds with my voice', language: 'miltoqsi '},
                {name: 'miluisi -- have different names', language: 'miluisi '},
                {name: 'mimaji -- be alive', language: 'mimaji '},
                {name: 'mimatu\'lukwey -- do odd or simple jobs', language: 'mimatu\'lukwey '},
                {name: 'mimatueyi -- live simple', language: 'mimatueyi '},
                {name: 'mimatueyi -- live simply', language: 'mimatueyi '},
                {name: 'mimatueyi -- manage with minimum resources', language: 'mimatueyi '},
                {name: 'mimay -- take food on a journey', language: 'mimay '},
                {name: 'mimey -- be oily', language: 'mimey '},
                {name: 'mimkwasi -- hide', language: 'mimkwasi '},
                {name: 'mimkway -- wear a perfume', language: 'mimkway '},
                {name: 'minu\'nsi -- revive myself', language: 'minu\'nsi '},
                {name: 'mlakejua\'tay -- add milk', language: 'mlakejua\'tay '},
                {name: 'msikue\'key -- be making hay', language: 'msikue\'key '},
                {name: 'mulqwey -- dig', language: 'mulqwey '},
                {name: 'munsai -- be persistent', language: 'munsai '},
                {name: 'musika\'si -- undress', language: 'musika\'si '},
                {name: 'musika\'tekey -- put out weeds', language: 'musika\'tekey '},
                {name: 'musikte\'key -- clear brush or thicket by cutting', language: 'musikte\'key '},
                {name: 'muskwatami -- lick', language: 'muskwatami '},
                {name: 'musqunamuksi -- be blue sky', language: 'musqunamuksi '},
                {name: 'mutkulpukua\'si -- kneel', language: 'mutkulpukua\'si '},
                {name: 'mutkulpukui -- be kneeling', language: 'mutkulpukui '},
                {name: 'na\'puktaqnikey -- set snares', language: 'na\'puktaqnikey '},
                {name: 'na\'skwey -- be a virgin', language: 'na\'skwey '},
                {name: 'naji-ilaji -- be going to pack or get ready', language: 'naji'},
                {name: 'naji-kate\'key -- go hunting for eels', language: 'naji'},
                {name: 'naji-ktantekey -- be going hunting', language: 'naji'},
                {name: 'naji-tia\'mue\'key -- go moose hunting', language: 'naji'},
                {name: 'najijjukway -- go to defecate', language: 'najijjukway '},
                {name: 'nalko\'si -- comb my hair with a fine toothed comb', language: 'nalko\'si '},
                {name: 'nalkwe\'key -- card wool', language: 'nalkwe\'key '},
                {name: 'naqana\'may -- drink alcoholic  beverage', language: 'naqana\'may '},
                {name: 'naqana\'pey -- draw water from a well or river', language: 'naqana\'pey '},
                {name: 'naqani\'key -- bail out water', language: 'naqani\'key '},
                {name: 'naqanu\'sey -- take care of a baby', language: 'naqanu\'sey '},
                {name: 'naqsa\'si -- be quick', language: 'naqsa\'si '},
                {name: 'naqsinpay -- sleep easily', language: 'naqsinpay '},
                {name: 'naskoqte\'key -- split by striking wuth a tool', language: 'naskoqte\'key '},
                {name: 'naspi -- be a member', language: 'naspi '},
                {name: 'nasuoqkay -- astraddle', language: 'nasuoqkay '},
                {name: 'nasuoqpa\'si -- sit astraddle', language: 'nasuoqpa\'si '},
                {name: 'nata\'kitjey -- read well', language: 'nata\'kitjey '},
                {name: 'nattukli -- set out to hunt provisions', language: 'nattukli '},
                {name: 'nattukuli -- go hunt', language: 'nattukuli '},
                {name: 'ne\'l&#x0268;kopi -- sit in the way', language: 'ne\'l&#x0268;kopi '},
                {name: 'ne\'l&#x0268;pukui -- stand in the way', language: 'ne\'l&#x0268;pukui '},
                {name: 'ne\'tunawey -- feel around', language: 'ne\'tunawey '},
                {name: 'nemi\'si -- see myself', language: 'nemi\'si '},
                {name: 'nenaqita\'si -- think hurriedly', language: 'nenaqita\'si '},
                {name: 'nepay -- drift off to sleep', language: 'nepay '},
                {name: 'nepay -- sleep', language: 'nepay '},
                {name: 'neskawey -- chant', language: 'neskawey '},
                {name: 'netakeyi -- be bashful', language: 'netakeyi '},
                {name: 'netawey -- able to talk', language: 'netawey '},
                {name: 'netui\'skey -- sell', language: 'netui\'skey '},
                {name: 'netuis\'kaqtmay -- sell', language: 'netuis\'kaqtmay '},
                {name: 'netukli -- go hunting', language: 'netukli '},
                {name: 'newte\'ji -- be one', language: 'newte\'ji '},
                {name: 'nikwey -- grow', language: 'nikwey '},
                {name: 'nisaqalio\'qsay -- slide down', language: 'nisaqalio\'qsay '},
                {name: 'niskamewi -- be godlike', language: 'niskamewi '},
                {name: 'ntutemi -- have a totem', language: 'ntutemi '},
                {name: 'nuji-ne\'patekey -- be an executioner', language: 'nuji'},
                {name: 'nuji-pipney -- be a baker', language: 'nuji'},
                {name: 'nuji-wsikey -- be a fortune teller', language: 'nuji'},
                {name: 'nujji -- have a father', language: 'nujji '},
                {name: 'nujo\'tekey -- assist at mass', language: 'nujo\'tekey '},
                {name: 'nujo\'tekey -- be a sponsor', language: 'nujo\'tekey '},
                {name: 'nukwaltuko\'si -- comb my hair', language: 'nukwaltuko\'si '},
                {name: 'nuneskwey -- breast feed', language: 'nuneskwey '},
                {name: 'nuney -- suck', language: 'nuney '},
                {name: 'nuney -- suckle', language: 'nuney '},
                {name: 'nuseskwey -- be breastfeeding', language: 'nuseskwey '},
                {name: 'nussaqikey -- be a clerk', language: 'nussaqikey '},
                {name: 'nutantekey -- be a hunter', language: 'nutantekey '},
                {name: 'nutapi -- scout', language: 'nutapi '},
                {name: 'nutaptekey -- be a coroner', language: 'nutaptekey '},
                {name: 'nutayji\'putekey -- be a delivery person', language: 'nutayji\'putekey '},
                {name: 'nutmay -- hear about it', language: 'nutmay '},
                {name: 'nutqweyi -- be immature', language: 'nutqweyi '},
                {name: 'nutsaqanikei -- be a discharger', language: 'nutsaqanikei '},
                {name: 'nuttoqtey -- be a baker', language: 'nuttoqtey '},
                {name: 'o\'pl-wnaqto\'si -- skip wrongly', language: 'o\'pl'},
                {name: 'o\'pla\'si -- go wrong', language: 'o\'pla\'si '},
                {name: 'o\'pla\'tekey -- do wrong', language: 'o\'pla\'tekey '},
                {name: 'oqolumkwa\'si -- droop my head', language: 'oqolumkwa\'si '},
                {name: 'oqomeyi -- be greedy', language: 'oqomeyi '},
                {name: 'oqpisi -- be bundled up', language: 'oqpisi '},
                {name: 'pa\'keyi -- be sober', language: 'pa\'keyi '},
                {name: 'pa\'kitney -- have a nose bleed', language: 'pa\'kitney '},
                {name: 'pa\'qalayi -- be surprised', language: 'pa\'qalayi '},
                {name: 'pa\'qapukuey -- confess', language: 'pa\'qapukuey '},
                {name: 'pa\'qapukuey -- confess', language: 'pa\'qapukuey '},
                {name: 'pa\'qastmay -- be surprised by news and gossip', language: 'pa\'qastmay '},
                {name: 'panawijkatekey -- thoroughly search', language: 'panawijkatekey '},
                {name: 'pankwenawey -- delouse', language: 'pankwenawey '},
                {name: 'pantuna\'si -- open my mouth', language: 'pantuna\'si '},
                {name: 'pantunepi -- sit with mouth open', language: 'pantunepi '},
                {name: 'panuatqa\'si -- come running out of the woods', language: 'panuatqa\'si '},
                {name: 'papi -- amuse myself', language: 'papi '},
                {name: 'papi -- play', language: 'papi '},
                {name: 'papka\'si -- go down the coast', language: 'papka\'si '},
                {name: 'papkisikwi -- go down the coast by water', language: 'papkisikwi '},
                {name: 'papkusetmay -- light up and smoke', language: 'papkusetmay '},
                {name: 'papkwesetmay -- light up', language: 'papkwesetmay '},
                {name: 'papkwesetmay -- light up to smoke', language: 'papkwesetmay '},
                {name: 'paqa\'luey -- bite', language: 'paqa\'luey '},
                {name: 'paqasa\'si -- go in the water', language: 'paqasa\'si '},
                {name: 'paqasiey -- fall into the water', language: 'paqasiey '},
                {name: 'paqjuika\'si -- bend over', language: 'paqjuika\'si '},
                {name: 'paqjuiki -- be crooked', language: 'paqjuiki '},
                {name: 'paqsipketemi -- burst out crying', language: 'paqsipketemi '},
                {name: 'paqtasi -- be shinning bright', language: 'paqtasi '},
                {name: 'pasaltukway -- have thick hair', language: 'pasaltukway '},
                {name: 'pasatpay -- be thick headed', language: 'pasatpay '},
                {name: 'pasi\'tuay -- be thick bearded', language: 'pasi\'tuay '},
                {name: 'pasjaqamay -- spread on thickly', language: 'pasjaqamay '},
                {name: 'paspisi -- be bundled', language: 'paspisi '},
                {name: 'passoqsawei -- be making jam', language: 'passoqsawei '},
                {name: 'pata\'tekey -- sin', language: 'pata\'tekey '},
                {name: 'pata\'tekey -- sin', language: 'pata\'tekey '},
                {name: 'patalapi -- be cross-eyed', language: 'patalapi '},
                {name: 'patanapji -- be holding with my left hand', language: 'patanapji '},
                {name: 'patanmi -- be left handed', language: 'patanmi '},
                {name: 'pawa\'si -- move slowly', language: 'pawa\'si '},
                {name: 'paweyi -- be a slowpoke', language: 'paweyi '},
                {name: 'pawi\'si -- speak slowly', language: 'pawi\'si '},
                {name: 'pawnatqa\'si -- get out of water slowly', language: 'pawnatqa\'si '},
                {name: 'pe\'si -- have something in my eye', language: 'pe\'si '},
                {name: 'pe\'sklami -- let out long deep sounded breath', language: 'pe\'sklami '},
                {name: 'peji\'pi -- arrive from running', language: 'peji\'pi '},
                {name: 'peji-ikai -- arrive unexpectedly', language: 'peji'},
                {name: 'peji-kluluey -- come to ask permission from parents to marry their daughter', language: 'peji'},
                {name: 'peji-punsi -- come here to spend the winter', language: 'peji'},
                {name: 'pejiwsi -- move here to settle down', language: 'pejiwsi '},
                {name: 'pejue\'key -- fish for cod', language: 'pejue\'key '},
                {name: 'pekijipney -- be suffering from a long-term illness', language: 'pekijipney '},
                {name: 'pekitawsi -- live a long life', language: 'pekitawsi '},
                {name: 'pekitewo\'kwey -- talk a long time', language: 'pekitewo\'kwey '},
                {name: 'pekittemi -- cry a long while', language: 'pekittemi '},
                {name: 'pekwatasi -- be earning', language: 'pekwatasi '},
                {name: 'pekwatelikey -- purchase from there', language: 'pekwatelikey '},
                {name: 'pekwatelikey -- shop', language: 'pekwatelikey '},
                {name: 'pelko\'qikey -- debark', language: 'pelko\'qikey '},
                {name: 'pelkoqiey -- my skin is peeling', language: 'pelkoqiey '},
                {name: 'pelkoqsawey -- peel', language: 'pelkoqsawey '},
                {name: 'pem\'pukua\'si -- start to get on my feet', language: 'pem\'pukua\'si '},
                {name: 'pema\'tekei -- be a pallbearer', language: 'pema\'tekei '},
                {name: 'pemamkwe\'kei -- be poling along', language: 'pemamkwe\'kei '},
                {name: 'pemankuay -- be peddling along', language: 'pemankuay '},
                {name: 'pemapeki -- be crawling along', language: 'pemapeki '},
                {name: 'pemapilawey -- be carrying by rope or handle', language: 'pemapilawey '},
                {name: 'pemapo\'qiey -- wobble', language: 'pemapo\'qiey '},
                {name: 'pemaqtekey -- sail along', language: 'pemaqtekey '},
                {name: 'pematpe\'pi -- be running along with my head bobbing', language: 'pematpe\'pi '},
                {name: 'pematqiey -- go through the thicket', language: 'pematqiey '},
                {name: 'pemelmi -- pass by and laughing', language: 'pemelmi '},
                {name: 'pemi\'pukwey -- stagger along', language: 'pemi\'pukwey '},
                {name: 'pemi\'puluey -- ride along on horse back', language: 'pemi\'puluey '},
                {name: 'pemi-kekwi\'pi -- run along slowly', language: 'pemi'},
                {name: 'pemi-mqapsk&#x0268;si -- become large and round', language: 'pemi'},
                {name: 'pemi-qama\'si -- stand up', language: 'pemi'},
                {name: 'pemi-siaw-aqtekey -- sail along by', language: 'pemi'},
                {name: 'pemi-siawa\'si -- pass by', language: 'pemi'},
                {name: 'pemi-siawi\'pukwey -- stagger along by', language: 'pemi'},
                {name: 'pemi-siawtemi -- pass by and crying', language: 'pemi'},
                {name: 'pemi-sukwi -- paddle along', language: 'pemi'},
                {name: 'pemi-wikwa\'smukwey -- whistle along', language: 'pemi'},
                {name: 'pemi-wisqi\'pi -- sprint along', language: 'pemi'},
                {name: 'pemiey -- go by', language: 'pemiey '},
                {name: 'pemijajika\'si -- walk along the shore', language: 'pemijajika\'si '},
                {name: 'pemikate\'pi -- trot along', language: 'pemikate\'pi '},
                {name: 'pemimski\'ka\'si -- get bigger', language: 'pemimski\'ka\'si '},
                {name: 'pemipqomi -- slide by', language: 'pemipqomi '},
                {name: 'pemjai -- leap along', language: 'pemjai '},
                {name: 'pemjajisukwi -- paddle along the shore', language: 'pemjajisukwi '},
                {name: 'pemji\'mey -- paddle along', language: 'pemji\'mey '},
                {name: 'pemko\'qwey -- float along', language: 'pemko\'qwey '},
                {name: 'pemnikey -- go by with a shoulder pack', language: 'pemnikey '},
                {name: 'pempa\'si -- sit down', language: 'pempa\'si '},
                {name: 'pempaqto\'si -- splash along', language: 'pempaqto\'si '},
                {name: 'pemtemi -- go by and crying', language: 'pemtemi '},
                {name: 'pemtoqsi -- make sounds', language: 'pemtoqsi '},
                {name: 'pemtulkomi -- conceal something under the arm', language: 'pemtulkomi '},
                {name: 'pene\'sukwai -- drool', language: 'pene\'sukwai '},
                {name: 'penoqweyi -- be ill mannered', language: 'penoqweyi '},
                {name: 'penoqweyi -- be means treated poorly', language: 'penoqweyi '},
                {name: 'pepkijatpay -- be flat-headed', language: 'pepkijatpay '},
                {name: 'pepkwatpay -- have thin hair', language: 'pepkwatpay '},
                {name: 'pepsite\'lkey -- be disrespectful', language: 'pepsite\'lkey '},
                {name: 'pepsite\'lmuksi -- be disrespected', language: 'pepsite\'lmuksi '},
                {name: 'pepso\'tasi -- harass', language: 'pepso\'tasi '},
                {name: 'peptiskna\'si -- put on shoes and socks', language: 'peptiskna\'si '},
                {name: 'pepuekey -- shake it', language: 'pepuekey '},
                {name: 'peqanqasita\'tekei -- get flat feet', language: 'peqanqasita\'tekei '},
                {name: 'pesi\'kukwei -- work at a river drive', language: 'pesi\'kukwei '},
                {name: 'pesikwa\'si -- close my eyes', language: 'pesikwa\'si '},
                {name: 'pesikwey -- have my eyes closed', language: 'pesikwey '},
                {name: 'pesikwey -- have my eyes closed', language: 'pesikwey '},
                {name: 'peska\'si -- go off the trail or road', language: 'peska\'si '},
                {name: 'pesko\'tekey -- pluck a chicken', language: 'pesko\'tekey '},
                {name: 'peskwe\'may -- shave myself', language: 'peskwe\'may '},
                {name: 'pesoqopska\'si -- fumble and drop him', language: 'pesoqopska\'si '},
                {name: 'pestie\'wi -- participate in a feast day', language: 'pestie\'wi '},
                {name: 'petasenmay -- walk with a light', language: 'petasenmay '},
                {name: 'petasi -- be blinded by light', language: 'petasi '},
                {name: 'petawley -- come lugging on back', language: 'petawley '},
                {name: 'petkutekey -- spill it', language: 'petkutekey '},
                {name: 'petqwasi -- deny', language: 'petqwasi '},
                {name: 'pettemi -- get here crying', language: 'pettemi '},
                {name: 'pettepi -- arrive as a passenger', language: 'pettepi '},
                {name: 'pewaqtmay -- have a nightmare', language: 'pewaqtmay '},
                {name: 'peway -- dream', language: 'peway '},
                {name: 'peway -- dream', language: 'peway '},
                {name: 'pewi\'key -- sweep', language: 'pewi\'key '},
                {name: 'pewi\'key -- sweep the floor', language: 'pewi\'key '},
                {name: 'peykwapi -- see all around', language: 'peykwapi '},
                {name: 'pi\'may -- hunt waterfowl', language: 'pi\'may '},
                {name: 'pi\'si -- urinate', language: 'pi\'si '},
                {name: 'pi\'snsi -- pack my pipe', language: 'pi\'snsi '},
                {name: 'pi\'t&#x0268;pey -- pour in', language: 'pi\'t&#x0268;pey '},
                {name: 'pi\'tawa\'si -- go up the coast', language: 'pi\'tawa\'si '},
                {name: 'piam-klu\'si -- be better', language: 'piam'},
                {name: 'piamiey -- have a left over', language: 'piamiey '},
                {name: 'pija\'si -- go into', language: 'pija\'si '},
                {name: 'pijapeki -- crawl underneath', language: 'pijapeki '},
                {name: 'piji\'sqonay -- have a long nose', language: 'piji\'sqonay '},
                {name: 'piji\'staqanay -- have long ears', language: 'piji\'staqanay '},
                {name: 'pijiey -- fall', language: 'pijiey '},
                {name: 'pikti -- fart', language: 'pikti '},
                {name: 'pikweli-wtmay -- be a heavy smoker', language: 'pikweli'},
                {name: 'pilsa\'qaqutnay -- have a numb mouth', language: 'pilsa\'qaqutnay '},
                {name: 'pilsa\'qiey -- be become numb', language: 'pilsa\'qiey '},
                {name: 'pilsa\'qiey -- be numb', language: 'pilsa\'qiey '},
                {name: 'piltua\'si -- turn out unexpectedly', language: 'piltua\'si '},
                {name: 'pipanikesi -- enquire', language: 'pipanikesi '},
                {name: 'pipnu\'jaqamay -- look in a mirror', language: 'pipnu\'jaqamay '},
                {name: 'pipukwey -- make music a wind instrument', language: 'pipukwey '},
                {name: 'pisewtunai -- fobe at the mouth', language: 'pisewtunai '},
                {name: 'pisi -- be inside', language: 'pisi '},
                {name: 'pisi -- in something', language: 'pisi '},
                {name: 'piskwai -- enter', language: 'piskwai '},
                {name: 'piskwapi -- be outside looking in', language: 'piskwapi '},
                {name: 'pistami -- sop up gravy with bread', language: 'pistami '},
                {name: 'pisui -- be flea infested', language: 'pisui '},
                {name: 'pito\'qiptinay -- have a long reach', language: 'pito\'qiptinay '},
                {name: 'pito\'qiptnay -- be long armed', language: 'pito\'qiptnay '},
                {name: 'pito\'qnikatay -- be long legged', language: 'pito\'qnikatay '},
                {name: 'pitoqsi -- be straight', language: 'pitoqsi '},
                {name: 'pitoqsi -- be tall', language: 'pitoqsi '},
                {name: 'pitqasawei -- load a gun', language: 'pitqasawei '},
                {name: 'pitqonay -- wear high heel shoes', language: 'pitqonay '},
                {name: 'pkwimane\'key -- get blueberries', language: 'pkwimane\'key '},
                {name: 'plamue\'key -- fish for salmon', language: 'plamue\'key '},
                {name: 'plamue\'key -- fish for salmon', language: 'plamue\'key '},
                {name: 'po\'qikey -- drill', language: 'po\'qikey '},
                {name: 'poqji -- elope', language: 'poqji '},
                {name: 'poqji-atkitemi -- begin to cry', language: 'poqji'},
                {name: 'poqji-ktapekiey -- begin to sing', language: 'poqji'},
                {name: 'poqji-kwitamey -- begin to fish', language: 'poqji'},
                {name: 'poqji-kwitamey -- start to fish', language: 'poqji'},
                {name: 'poqji-ws&#x0268;keweyi -- start to laugh', language: 'poqji'},
                {name: 'poqjiwsi -- start to move', language: 'poqjiwsi '},
                {name: 'poqtamwe\'kei -- start off poling a boat', language: 'poqtamwe\'kei '},
                {name: 'poqtapa\'tekey -- start to scratch', language: 'poqtapa\'tekey '},
                {name: 'poqtelmi -- start to laugh', language: 'poqtelmi '},
                {name: 'poqtewo\'kwey -- begin to speak', language: 'poqtewo\'kwey '},
                {name: 'poqtisukwi -- start off by boat', language: 'poqtisukwi '},
                {name: 'poqtukwey -- tag along', language: 'poqtukwey '},
                {name: 'poqwa\'lay -- have a dry throat', language: 'poqwa\'lay '},
                {name: 'pu\'tapa\'si -- peep through', language: 'pu\'tapa\'si '},
                {name: 'pukkwana\'tekei -- smoke meat', language: 'pukkwana\'tekei '},
                {name: 'pukna\'kwey -- be hairy', language: 'pukna\'kwey '},
                {name: 'pukna\'kwey -- be hairy of the body', language: 'pukna\'kwey '},
                {name: 'puksetewi -- be sooty', language: 'puksetewi '},
                {name: 'pukweliku\'niey -- bleed', language: 'pukweliku\'niey '},
                {name: 'puna\'tekey -- be estranged from him', language: 'puna\'tekey '},
                {name: 'punelmi -- stop laughing', language: 'punelmi '},
                {name: 'puntay -- cast a spell on self', language: 'puntay '},
                {name: 'puntemi -- stop crying', language: 'puntemi '},
                {name: 'puntemi -- stop crying', language: 'puntemi '},
                {name: 'puntoqsi -- stop talking', language: 'puntoqsi '},
                {name: 'pusi -- depart', language: 'pusi '},
                {name: 'pusi -- depart in a vehicle', language: 'pusi '},
                {name: 'puskelmi -- laugh a lot', language: 'puskelmi '},
                {name: 'puski-ali-ksata\'kwey -- usually go free loading', language: 'puski'},
                {name: 'puski-weniey -- be absent minded', language: 'puski'},
                {name: 'puski-wjipulkwey -- have attacks of epileptic seizures', language: 'puski'},
                {name: 'pustemi -- cry a lot', language: 'pustemi '},
                {name: 'pustemi -- cry often', language: 'pustemi '},
                {name: 'putapeka\'si -- become bloated', language: 'putapeka\'si '},
                {name: 'putaqikey -- butcher', language: 'putaqikey '},
                {name: 'putmay -- come out empty handed', language: 'putmay '},
                {name: 'putuatami -- blow', language: 'putuatami '},
                {name: 'qalipikey -- shovel', language: 'qalipikey '},
                {name: 'sa\'se\'wa\'si -- change', language: 'sa\'se\'wa\'si '},
                {name: 'sa\'se\'wi -- exchange', language: 'sa\'se\'wi '},
                {name: 'sa\'se\'wi\'si -- change a language', language: 'sa\'se\'wi\'si '},
                {name: 'sa\'wkway -- be long haired', language: 'sa\'wkway '},
                {name: 'salawa\'tay -- add salt to my food', language: 'salawa\'tay '},
                {name: 'samsnewi -- have tuberculosis', language: 'samsnewi '},
                {name: 'sankewa\'si -- go slow', language: 'sankewa\'si '},
                {name: 'sankewi\'si -- talk slowly', language: 'sankewi\'si '},
                {name: 'sankewi-ankita\'si -- think slowly', language: 'sankewi'},
                {name: 'sankewowo\'kwey -- talk softly', language: 'sankewowo\'kwey '},
                {name: 'sapawsi -- survive', language: 'sapawsi '},
                {name: 'sape\'wi -- be saintly', language: 'sape\'wi '},
                {name: 'saputa\'si -- pass through', language: 'saputa\'si '},
                {name: 'saputai -- have diarrhea', language: 'saputai '},
                {name: 'saputaskiei -- penetrate through', language: 'saputaskiei '},
                {name: 'saputawsi -- recover from an illness', language: 'saputawsi '},
                {name: 'saqamawi -- be the chief', language: 'saqamawi '},
                {name: 'saqamu\'kuey -- chew', language: 'saqamu\'kuey '},
                {name: 'saqanqapi -- sit on the ground', language: 'saqanqapi '},
                {name: 'saqitnay -- be pug-nosed', language: 'saqitnay '},
                {name: 'saqmawi -- be chief', language: 'saqmawi '},
                {name: 'saqpeey -- be wet', language: 'saqpeey '},
                {name: 'saqpikjay -- have a wet bottom', language: 'saqpikjay '},
                {name: 'saqsikwey -- fish by torchlight', language: 'saqsikwey '},
                {name: 'sasqasitay -- be flatfooted', language: 'sasqasitay '},
                {name: 'sasqikatay -- be flat-footed', language: 'sasqikatay '},
                {name: 'sawepi -- slouch over', language: 'sawepi '},
                {name: 'se\'sa\'si -- wander about', language: 'se\'sa\'si '},
                {name: 'se\'saltukway -- have messy hair', language: 'se\'saltukway '},
                {name: 'se\'skwey -- scream', language: 'se\'skwey '},
                {name: 'se\'skwey -- shout', language: 'se\'skwey '},
                {name: 'sekapji -- conceal something in my hand', language: 'sekapji '},
                {name: 'sekewe\'key -- have an illegitimate child', language: 'sekewe\'key '},
                {name: 'seki -- urinate', language: 'seki '},
                {name: 'sepulkomi -- hold something under the arm', language: 'sepulkomi '},
                {name: 'sesaqtoqsi -- cackle', language: 'sesaqtoqsi '},
                {name: 'seskweyi -- be active', language: 'seskweyi '},
                {name: 'sespa\'si -- be restless', language: 'sespa\'si '},
                {name: 'sespetoqsi -- be talking noisily', language: 'sespetoqsi '},
                {name: 'sespewo\'kwey -- #VALUE!', language: 'sespewo\'kwey '},
                {name: 'sespo\'tekei -- be unfaithful', language: 'sespo\'tekei '},
                {name: 'sesupa\'lukwei -- slip', language: 'sesupa\'lukwei '},
                {name: 'sewiknaay -- be ill', language: 'sewiknaay '},
                {name: 'sewiknay -- be exhausted', language: 'sewiknay '},
                {name: 'sewtiaay -- have a stomach ache', language: 'sewtiaay '},
                {name: 'si\'kasi -- be uncomfortable', language: 'si\'kasi '},
                {name: 'si\'nawey -- bail water', language: 'si\'nawey '},
                {name: 'siawa\'si -- go on', language: 'siawa\'si '},
                {name: 'siawkumiei -- coast on the river', language: 'siawkumiei '},
                {name: 'sikajiei -- be uncomfortable', language: 'sikajiei '},
                {name: 'sikue\'i -- be empty', language: 'sikue\'i '},
                {name: 'sinapska\'tekei -- take a break', language: 'sinapska\'tekei '},
                {name: 'sinu\'ey -- be back to my senses', language: 'sinu\'ey '},
                {name: 'sioqiey -- fall apart', language: 'sioqiey '},
                {name: 'sipi -- stretch and yawn', language: 'sipi '},
                {name: 'sipiki -- be flexible', language: 'sipiki '},
                {name: 'sipke\'i -- be a slowpoke', language: 'sipke\'i '},
                {name: 'sipkis\'i -- speak slowly', language: 'sipkis\'i '},
                {name: 'sismoqna\'tay -- sweeten my food', language: 'sismoqna\'tay '},
                {name: 'siwe\'i -- be lonesome', language: 'siwe\'i '},
                {name: 'siwiey -- be become impatient', language: 'siwiey '},
                {name: 'siwiskmay -- be tired of waiting', language: 'siwiskmay '},
                {name: 'siwpmiey -- be tired of walking', language: 'siwpmiey '},
                {name: 'sma\'knisui -- be a soldier', language: 'sma\'knisui '},
                {name: 'sma\'tewa\'si -- smarten up', language: 'sma\'tewa\'si '},
                {name: 'smaqa\'si -- leave', language: 'smaqa\'si '},
                {name: 'sn\'katikna\'tekey -- work on a raft', language: 'sn\'katikna\'tekey '},
                {name: 'so\'qiey -- go up to higher ground', language: 'so\'qiey '},
                {name: 'so\'qotemi -- vomit', language: 'so\'qotemi '},
                {name: 'soqte\'key -- chew', language: 'soqte\'key '},
                {name: 'stoqne\'key -- search for fir boughs', language: 'stoqne\'key '},
                {name: 'sune\'wi -- be fast', language: 'sune\'wi '},
                {name: 'tajikeyi -- be healthy', language: 'tajikeyi '},
                {name: 'takaliey -- anger', language: 'takaliey '},
                {name: 'tapia\'ji\'jk&#x0268;te\'key -- fiddle', language: 'tapia\'ji\'jk&#x0268;te\'key '},
                {name: 'tapuipunay -- be two years old', language: 'tapuipunay '},
                {name: 'taqawajeyi -- be sad', language: 'taqawajeyi '},
                {name: 'taqmoqisma\'si -- lie down crossways', language: 'taqmoqisma\'si '},
                {name: 'taqtekey -- hit', language: 'taqtekey '},
                {name: 'tawtna\'si -- open my mouth wide', language: 'tawtna\'si '},
                {name: 'te\'pi\'seweya\'tay -- add pepper to my food', language: 'te\'pi\'seweya\'tay '},
                {name: 'te\'sipunai -- be so many years old', language: 'te\'sipunai '},
                {name: 'te\'tipjiey -- tumble', language: 'te\'tipjiey '},
                {name: 'te\'wimalie\'wi -- marry', language: 'te\'wimalie\'wi '},
                {name: 'te\'wiwsi -- move out', language: 'te\'wiwsi '},
                {name: 'teki -- be cold', language: 'teki '},
                {name: 'tekiptnay -- have cold hands', language: 'tekiptnay '},
                {name: 'tekismi -- swim', language: 'tekismi '},
                {name: 'tekismi -- swim', language: 'tekismi '},
                {name: 'tekniey -- be sweat', language: 'tekniey '},
                {name: 'tekteskawey -- kick', language: 'tekteskawey '},
                {name: 'tekuei -- hinder', language: 'tekuei '},
                {name: 'tel-lukwey -- do such work', language: 'tel'},
                {name: 'tel-mnaqanay -- be so weak', language: 'tel'},
                {name: 'tel-pitoqsi -- be such a height', language: 'tel'},
                {name: 'tela\'lsi -- be at fault', language: 'tela\'lsi '},
                {name: 'tela\'si -- behave in such a manner', language: 'tela\'si '},
                {name: 'tela\'tekey -- be doing', language: 'tela\'tekey '},
                {name: 'telamuksi -- look like', language: 'telamuksi '},
                {name: 'telaplkikwa\'si -- see a mirage', language: 'telaplkikwa\'si '},
                {name: 'telapsk&#x0268;si -- be so big and round', language: 'telapsk&#x0268;si '},
                {name: 'telawti -- be worth so much', language: 'telawti '},
                {name: 'teleyi -- be in such a way', language: 'teleyi '},
                {name: 'teli\'si -- talk like', language: 'teli\'si '},
                {name: 'teliki -- be shaped like', language: 'teliki '},
                {name: 'telikisutnay -- sound in such manner', language: 'telikisutnay '},
                {name: 'telimai -- smell like', language: 'telimai '},
                {name: 'telistaqnewa\'si -- hear things', language: 'telistaqnewa\'si '},
                {name: 'teloqsi -- have such a circumference', language: 'teloqsi '},
                {name: 'telqamiksi -- act in such a manner', language: 'telqamiksi '},
                {name: 'tels&#x0268;tmay -- hear things', language: 'tels&#x0268;tmay '},
                {name: 'telta\'si -- think so', language: 'telta\'si '},
                {name: 'teltoqsi -- sound like', language: 'teltoqsi '},
                {name: 'teluey -- say', language: 'teluey '},
                {name: 'teluey -- say', language: 'teluey '},
                {name: 'teluisi -- be named', language: 'teluisi '},
                {name: 'teluisi -- name', language: 'teluisi '},
                {name: 'temawiknai -- be a hunchback', language: 'temawiknai '},
                {name: 'temi\'skipekey -- snap it off at neck', language: 'temi\'skipekey '},
                {name: 'temikatay -- have a leg cut off', language: 'temikatay '},
                {name: 'temiptnay -- have a arm cut off', language: 'temiptnay '},
                {name: 'temte\'key -- chop', language: 'temte\'key '},
                {name: 'tepa\'si -- get on a vechile', language: 'tepa\'si '},
                {name: 'tepa\'si -- get on board', language: 'tepa\'si '},
                {name: 'tepawti -- be worth the price', language: 'tepawti '},
                {name: 'tepi\'key -- distribute', language: 'tepi\'key '},
                {name: 'tepiknay -- be strong enough', language: 'tepiknay '},
                {name: 'tepkiseyi -- be a loner', language: 'tepkiseyi '},
                {name: 'tepkispi -- sit apart from others', language: 'tepkispi '},
                {name: 'tepo\'tasi -- load', language: 'tepo\'tasi '},
                {name: 'teppi -- be on board', language: 'teppi '},
                {name: 'tetapu\'qamiksi -- act accordingly', language: 'tetapu\'qamiksi '},
                {name: 'tetapua\'tekey -- do what is right', language: 'tetapua\'tekey '},
                {name: 'tetaqa\'si -- hurry', language: 'tetaqa\'si '},
                {name: 'tetpaqapukui -- stand straight', language: 'tetpaqapukui '},
                {name: 'tetpaqkopi -- sit straight', language: 'tetpaqkopi '},
                {name: 'tetpaqkutay -- wear proper clothing', language: 'tetpaqkutay '},
                {name: 'tettuey -- owe', language: 'tettuey '},
                {name: 'tetuey -- hum', language: 'tetuey '},
                {name: 'tewiey -- exit', language: 'tewiey '},
                {name: 'tewijeyi -- be so many years old', language: 'tewijeyi '},
                {name: 'tewipunai -- be the age of independence', language: 'tewipunai '},
                {name: 'tewlami -- exhale', language: 'tewlami '},
                {name: 'tewlukwey -- work away from home', language: 'tewlukwey '},
                {name: 'tewopi -- look out', language: 'tewopi '},
                {name: 'tewpukua\'si -- step out', language: 'tewpukua\'si '},
                {name: 'tewpukui -- stand outside', language: 'tewpukui '},
                {name: 'tia\'mue\'key -- hunt moose', language: 'tia\'mue\'key '},
                {name: 'tleyawi -- be from', language: 'tleyawi '},
                {name: 'tleyawi -- originate from', language: 'tleyawi '},
                {name: 'tmoqta\'we\'key -- cut logs', language: 'tmoqta\'we\'key '},
                {name: 'toqju\'pilawey -- hoist', language: 'toqju\'pilawey '},
                {name: 'toqjuapeki -- crawl up', language: 'toqjuapeki '},
                {name: 'toqnasi -- live common-law', language: 'toqnasi '},
                {name: 'toqwanqiskay -- be double jointed', language: 'toqwanqiskay '},
                {name: 'toqwaqji\'ji -- be short', language: 'toqwaqji\'ji '},
                {name: 'toqwaqji\'jkikatay -- be short legged', language: 'toqwaqji\'jkikatay '},
                {name: 'tukwiey -- wake up', language: 'tukwiey '},
                {name: 'u\'lukwey -- howl', language: 'u\'lukwey '},
                {name: 'ulukwiey -- have tonsillitis', language: 'ulukwiey '},
                {name: 'upmiki -- be lopsided', language: 'upmiki '},
                {name: 'upmikjepi -- sit crooked', language: 'upmikjepi '},
                {name: 'upmikjepi -- tip over', language: 'upmikjepi '},
                {name: 'upmisma\'si -- lie down on my side', language: 'upmisma\'si '},
                {name: 'waju\'ey -- be full', language: 'waju\'ey '},
                {name: 'waju\'pey -- be full', language: 'waju\'pey '},
                {name: 'walnoqsitay -- have arched feet', language: 'walnoqsitay '},
                {name: 'waltestay -- play the waltes dice game', language: 'waltestay '},
                {name: 'waltestay -- playing waltes', language: 'waltestay '},
                {name: 'wantaqeyi -- have a calm disposition', language: 'wantaqeyi '},
                {name: 'wantaqpi -- sit quietly', language: 'wantaqpi '},
                {name: 'wapa\'si -- open my eyes', language: 'wapa\'si '},
                {name: 'wapeyi -- be white', language: 'wapeyi '},
                {name: 'wapi -- have my eyes open', language: 'wapi '},
                {name: 'wapikneksi -- be light-complexioned', language: 'wapikneksi '},
                {name: 'wapinpay -- sleep with my eyes open', language: 'wapinpay '},
                {name: 'wapistaqamuksi -- be pale', language: 'wapistaqamuksi '},
                {name: 'wapitlmekweyi -- look pale', language: 'wapitlmekweyi '},
                {name: 'wapkwai -- have white hairs', language: 'wapkwai '},
                {name: 'wapnapi -- stay up all night', language: 'wapnapi '},
                {name: 'wapnkusi -- sleep until dawn', language: 'wapnkusi '},
                {name: 'waqameyi -- be clean', language: 'waqameyi '},
                {name: 'waqamnmay -- keep a clean place', language: 'waqamnmay '},
                {name: 'waqasi -- be agile', language: 'waqasi '},
                {name: 'waqasi -- be hyperactive', language: 'waqasi '},
                {name: 'waqmeyi -- be clean', language: 'waqmeyi '},
                {name: 'wasoqatestekey -- copy', language: 'wasoqatestekey '},
                {name: 'wasoqatestekey -- take photographs', language: 'wasoqatestekey '},
                {name: 'wasoqatestekey -- trace', language: 'wasoqatestekey '},
                {name: 'wasoqnawey -- give off light', language: 'wasoqnawey '},
                {name: 'watapsi -- be yellow', language: 'watapsi '},
                {name: 'we don\'t use ii -- be brownish', language: 'we don\'t use ii '},
                {name: 'we\'kwa\'muey -- stop arguing', language: 'we\'kwa\'muey '},
                {name: 'we\'kwa\'si -- reach my destination', language: 'we\'kwa\'si '},
                {name: 'we\'kwamkwe\'kei -- pole a boat to a certain spot', language: 'we\'kwamkwe\'kei '},
                {name: 'we\'kwata\'si -- be frightened', language: 'we\'kwata\'si '},
                {name: 'we\'kwiji\'mey -- stop rowing', language: 'we\'kwiji\'mey '},
                {name: 'wejankuay -- sold stuff from there', language: 'wejankuay '},
                {name: 'wejiey -- come from there', language: 'wejiey '},
                {name: 'wejipsetami -- be sniffing', language: 'wejipsetami '},
                {name: 'wejipulkwey -- have an epileptic fit', language: 'wejipulkwey '},
                {name: 'wejipulkwey -- have seizures', language: 'wejipulkwey '},
                {name: 'wejku\'ey -- come', language: 'wejku\'ey '},
                {name: 'wejkutemi -- come crying', language: 'wejkutemi '},
                {name: 'wejkwaqtekey -- come sailing on', language: 'wejkwaqtekey '},
                {name: 'wejkwatejimkwey -- come riding on something', language: 'wejkwatejimkwey '},
                {name: 'wejkwi\'puluey -- come on horseback', language: 'wejkwi\'puluey '},
                {name: 'wejkwina\'taqamisukwi -- come paddling to shore', language: 'wejkwina\'taqamisukwi '},
                {name: 'wejkwistepi -- sit hunched over facing toward', language: 'wejkwistepi '},
                {name: 'wejkwisukwi -- come paddling', language: 'wejkwisukwi '},
                {name: 'wejkwitukwi\'pi -- come with pounding feet', language: 'wejkwitukwi\'pi '},
                {name: 'wejlami -- be asthmatic', language: 'wejlami '},
                {name: 'wejnisiey -- fall from there', language: 'wejnisiey '},
                {name: 'wejo\'tasi -- do business here', language: 'wejo\'tasi '},
                {name: 'wejueyi -- have a close call', language: 'wejueyi '},
                {name: 'wejuowita\'si -- be absent minded', language: 'wejuowita\'si '},
                {name: 'wekayi -- be angry', language: 'wekayi '},
                {name: 'wekayi -- be angry', language: 'wekayi '},
                {name: 'wela\'luey -- be charitable', language: 'wela\'luey '},
                {name: 'wela\'si -- feel relieved', language: 'wela\'si '},
                {name: 'wela\'tekey -- do good', language: 'wela\'tekey '},
                {name: 'welapetmuey -- be beneficial', language: 'welapetmuey '},
                {name: 'welaqapi -- feel drunk', language: 'welaqapi '},
                {name: 'weleyi -- be fine', language: 'weleyi '},
                {name: 'weli-pkwatekey -- make good wages', language: 'weli'},
                {name: 'welikey -- live in a good location', language: 'welikey '},
                {name: 'weliki -- have a nice shape', language: 'weliki '},
                {name: 'welimay -- smell good', language: 'welimay '},
                {name: 'welipistami -- have a good lunch on bread dipped with molasses', language: 'welipistami '},
                {name: 'welipmiey -- walk well', language: 'welipmiey '},
                {name: 'welipunsi -- have a nice winter', language: 'welipunsi '},
                {name: 'welita\'si -- have good thoughts', language: 'welita\'si '},
                {name: 'weljaqajewa\'tekei -- take it easy', language: 'weljaqajewa\'tekei '},
                {name: 'weljepi -- be tipsy', language: 'weljepi '},
                {name: 'welk&#x0268;sney -- be well-shoed', language: 'welk&#x0268;sney '},
                {name: 'welknesi -- be well-dressed', language: 'welknesi '},
                {name: 'welnay -- have good card hand', language: 'welnay '},
                {name: 'welnmay -- have a tidy house', language: 'welnmay '},
                {name: 'welo\'tasi -- be treated well', language: 'welo\'tasi '},
                {name: 'welp&#x0268;si -- be nice and warm', language: 'welp&#x0268;si '},
                {name: 'welp&#x0268;smay -- have a well heated house', language: 'welp&#x0268;smay '},
                {name: 'welpisi -- be well-dressed', language: 'welpisi '},
                {name: 'welqamiksi -- have a good character', language: 'welqamiksi '},
                {name: 'welta\'si -- be glad', language: 'welta\'si '},
                {name: 'weltami -- be eating well', language: 'weltami '},
                {name: 'welte\'tekey -- be joyful', language: 'welte\'tekey '},
                {name: 'weltoqsi -- sound good', language: 'weltoqsi '},
                {name: 'wen\'jui\'si -- speak french', language: 'wen\'jui\'si '},
                {name: 'wenaqa\'si -- rise', language: 'wenaqa\'si '},
                {name: 'wenaqiey -- hop', language: 'wenaqiey '},
                {name: 'wenaqiey -- jump', language: 'wenaqiey '},
                {name: 'wenaqteskasi -- sprang up', language: 'wenaqteskasi '},
                {name: 'wenaqto\'si -- move up', language: 'wenaqto\'si '},
                {name: 'wenaqto\'si -- skip', language: 'wenaqto\'si '},
                {name: 'wenmajeyi -- be suffering', language: 'wenmajeyi '},
                {name: 'wenmajita\'si -- be sad', language: 'wenmajita\'si '},
                {name: 'wesasi-klu\'si -- be extremely good', language: 'wesasi'},
                {name: 'weska\'qelmuey -- kiss people', language: 'weska\'qelmuey '},
                {name: 'weskeweyi -- laugh', language: 'weskeweyi '},
                {name: 'weskewinoqsi -- be jolly', language: 'weskewinoqsi '},
                {name: 'weskey -- fish', language: 'weskey '},
                {name: 'weskijinui -- be born', language: 'weskijinui '},
                {name: 'weskitpi -- be on top', language: 'weskitpi '},
                {name: 'weskitqo\'qey -- float along', language: 'weskitqo\'qey '},
                {name: 'weskowa\'si -- occupy', language: 'weskowa\'si '},
                {name: 'weskowikwa\'si -- smile', language: 'weskowikwa\'si '},
                {name: 'wessimkway -- be running away', language: 'wessimkway '},
                {name: 'wesua\'tekey -- haul in a winning pot', language: 'wesua\'tekey '},
                {name: 'wesuaskiey -- sink in', language: 'wesuaskiey '},
                {name: 'wesuelami -- inhale', language: 'wesuelami '},
                {name: 'wetapeksi -- descend from', language: 'wetapeksi '},
                {name: 'wetewiluai -- be bellowing', language: 'wetewiluai '},
                {name: 'wetlami -- breathe', language: 'wetlami '},
                {name: 'wetmeyi -- be busy', language: 'wetmeyi '},
                {name: 'wetmkuey -- be in the way', language: 'wetmkuey '},
                {name: 'wetmo\'tekei -- be a prankster', language: 'wetmo\'tekei '},
                {name: 'wetqapalsi -- soak myself', language: 'wetqapalsi '},
                {name: 'wetqolsi -- abstain', language: 'wetqolsi '},
                {name: 'wetquluey -- hinder', language: 'wetquluey '},
                {name: 'wettaqiey -- belong', language: 'wettaqiey '},
                {name: 'wettelikey -- purchase from there', language: 'wettelikey '},
                {name: 'wettelikey -- purchase from there', language: 'wettelikey '},
                {name: 'wettemi -- cry over something', language: 'wettemi '},
                {name: 'wi\'kueyi -- be strange', language: 'wi\'kueyi '},
                {name: 'wi\'s&#x0268;kesi -- have a burning sensation', language: 'wi\'s&#x0268;kesi '},
                {name: 'wije\'wey -- tag along', language: 'wije\'wey '},
                {name: 'wikewi -- be fat', language: 'wikewi '},
                {name: 'wikewi -- be fatty', language: 'wikewi '},
                {name: 'wiki -- live', language: 'wiki '},
                {name: 'wiki -- reside there', language: 'wiki '},
                {name: 'wikpey -- like to drink', language: 'wikpey '},
                {name: 'wikplatewimai -- smell smokey', language: 'wikplatewimai '},
                {name: 'wikua\'sumkwey -- whistle', language: 'wikua\'sumkwey '},
                {name: 'wikwa\'smukwey -- whistle', language: 'wikwa\'smukwey '},
                {name: 'wikwasapewi -- be attractive', language: 'wikwasapewi '},
                {name: 'wikwiey -- collapse', language: 'wikwiey '},
                {name: 'wikwiey -- faint', language: 'wikwiey '},
                {name: 'winapewi -- have an ugly face', language: 'winapewi '},
                {name: 'winiki -- be unattractive', language: 'winiki '},
                {name: 'winikwa\'si -- scowl', language: 'winikwa\'si '},
                {name: 'winita\'si -- be filthy minded person', language: 'winita\'si '},
                {name: 'winji -- be bad', language: 'winji '},
                {name: 'winji -- be evil', language: 'winji '},
                {name: 'winji -- be unclean', language: 'winji '},
                {name: 'winkisney -- be poorly shoed', language: 'winkisney '},
                {name: 'winkutay -- be poorly dressed', language: 'winkutay '},
                {name: 'wino\'tasi -- be mistreated', language: 'wino\'tasi '},
                {name: 'winqamiksi -- have a bad character', language: 'winqamiksi '},
                {name: 'winqamiksi -- have a mean disposition', language: 'winqamiksi '},
                {name: 'winsi -- act bad', language: 'winsi '},
                {name: 'wipiti -- have teeth', language: 'wipiti '},
                {name: 'wiseyi -- be scarred', language: 'wiseyi '},
                {name: 'wisikway -- have a scarred face', language: 'wisikway '},
                {name: 'wiskueyi -- be jealous', language: 'wiskueyi '},
                {name: 'wiskwia\'tekey -- play the bagpipes', language: 'wiskwia\'tekey '},
                {name: 'wisqi\'pi -- go at full speed', language: 'wisqi\'pi '},
                {name: 'wisqi-lue\'wi -- be quick tempered', language: 'wisqi'},
                {name: 'wisqiptinay -- be sticky fingered', language: 'wisqiptinay '},
                {name: 'wisqisi -- be fast', language: 'wisqisi '},
                {name: 'wissukwatekey -- cook', language: 'wissukwatekey '},
                {name: 'wissukway -- cook', language: 'wissukway '},
                {name: 'wissukway -- cook', language: 'wissukway '},
                {name: 'witui -- have a beard', language: 'witui '},
                {name: 'witui -- have whiskers', language: 'witui '},
                {name: 'wjijaqamiju\'ey -- have a shadow', language: 'wjijaqamiju\'ey '},
                {name: 'wkwisi -- have a son', language: 'wkwisi '},
                {name: 'wmisi -- have an older sister', language: 'wmisi '},
                {name: 'wpitni\'sawey -- sew by hand', language: 'wpitni\'sawey '},
                {name: 'wsitqamui -- be born', language: 'wsitqamui '},
                {name: 'wtapsuni -- be useful', language: 'wtapsuni '},
                {name: 'wtapsuni -- be useful', language: 'wtapsuni '},
                {name: 'wti\'i -- have a dog', language: 'wti\'i '},
                {name: 'wujjewi -- be a father', language: 'wujjewi '},
                {name: 'wusapuni -- have hair', language: 'wusapuni '},
                {name: 'wutqutay -- burry', language: 'wutqutay '},*/]
        }
        else if(val === 'Maliseet') {
            this.setState({langSupportsDupAndAbs: true});
           languages = [
               {name: 'abut -- samopu', language: 'abut -- samopu'},
               {name: 'abut -- samte', language: 'abut -- samte'},
               {name: 'act -- ektuwiw', language: 'act -- ektuwiw'},
               {name: 'act -- yalhantuwiw', language: 'act -- yalhantuwiw'},
               {name: 'act a little bit crazy -- amuwiyahsu', language: 'act a little bit crazy -- amuwiyahsu'},
               {name: 'act animal -- weyossisuwomtu', language: 'act animal -- weyossisuwomtu'},
               {name: 'act big -- kinomahtu', language: 'act big -- kinomahtu'},
               {name: 'act from what was expected -- piltuhmahtu', language: 'act from what was expected -- piltuhmahtu'},
               {name: 'act like -- papehtomahtu', language: 'act like -- papehtomahtu'},
               {name: 'act like man -- skitapewomtu', language: 'act like man -- skitapewomtu'},
               {name: 'act mature -- kcitomomtu', language: 'act mature -- kcitomomtu'},
               {name: 'act part appropriately -- wewessu', language: 'act part appropriately -- wewessu'},
               {name: 'act thus -- olomahtu', language: 'act thus -- olomahtu'},
               {name: 'add more and more -- aqamimawwikhike', language: 'add more and more -- aqamimawwikhike'},
               {name: 'adhere -- siccoqe', language: 'adhere -- siccoqe'},
               {name: 'affect self -- oliyusu', language: 'affect self -- oliyusu'},
               {name: 'agitate by talking -- ksomokikesu', language: 'agitate by talking -- ksomokikesu'},
               {name: 'agree -- tetpahma', language: 'agree -- tetpahma'},
               {name: 'aim a gun jokingly around -- koluskakhom', language: 'aim a gun jokingly around -- koluskakhom'},
               {name: 'aim upward -- motkayakhom', language: 'aim upward -- motkayakhom'},
               {name: 'allow self -- kiselsu', language: 'allow self -- kiselsu'},
               {name: 'announce self -- kinuwehlosuwiw', language: 'announce self -- kinuwehlosuwiw'},
               {name: 'answer quickly -- nokosaasitewtom', language: 'answer quickly -- nokosaasitewtom'},
               {name: 'ante up -- ecuwiw', language: 'ante up -- ecuwiw'},
               {name: 'appear -- messuwessu', language: 'appear -- messuwessu'},
               {name: 'argue a lot -- wikiluwesu', language: 'argue a lot -- wikiluwesu'},
               {name: 'argue vehemently -- kakawalomikewtom', language: 'argue vehemently -- kakawalomikewtom'},
               {name: 'arise from sitting -- nomocessu', language: 'arise from sitting -- nomocessu'},
               {name: 'arrange -- lewotahsu', language: 'arrange -- lewotahsu'},
               {name: 'arrest -- tqonke', language: 'arrest -- tqonke'},
               {name: 'arrive -- pecituwiye', language: 'arrive -- pecituwiye'},
               {name: 'arrive at wrong time -- wapolipeciye', language: 'arrive at wrong time -- wapolipeciye'},
               {name: 'arrive by train -- oqehe', language: 'arrive by train -- oqehe'},
               {name: 'arrive early -- miyahsipeciye', language: 'arrive early -- miyahsipeciye'},
               {name: 'arrive frightened -- petpayu', language: 'arrive frightened -- petpayu'},
               {name: 'arrive from there -- cipeciye', language: 'arrive from there -- cipeciye'},
               {name: 'arrive hungry -- petolamu', language: 'arrive hungry -- petolamu'},
               {name: 'arrive hurriedly -- cestehsin', language: 'arrive hurriedly -- cestehsin'},
               {name: 'arrive it comes about -- peciyewiw', language: 'arrive it comes about -- peciyewiw'},
               {name: 'arrive leaving tracks -- petaptu', language: 'arrive leaving tracks -- petaptu'},
               {name: 'arrive walking hurriedly -- peciksihke', language: 'arrive walking hurriedly -- peciksihke'},
               {name: 'arrive with line of followers behind -- petamkustahsu', language: 'arrive with line of followers behind -- petamkustahsu'},
               {name: 'ask for payment of money earned -- mututom', language: 'ask for payment of money earned -- mututom'},
               {name: 'assassinate -- komucinehpahtike', language: 'assassinate -- komucinehpahtike'},
               {name: 'assemble bottom(s) of basket(s) -- likcihike', language: 'assemble bottom(s) of basket(s) -- likcihike'},
               {name: 'assume fetal position -- ptoqapskuhucin', language: 'assume fetal position -- ptoqapskuhucin'},
               {name: 'attend church -- witimiye', language: 'attend church -- witimiye'},
               {name: 'avoid eye-contact -- qolapu', language: 'avoid eye-contact -- qolapu'},
               {name: 'backfire -- piktuwiw', language: 'backfire -- piktuwiw'},
               {name: 'backfire -- sehtapeskote', language: 'backfire -- sehtapeskote'},
               {name: 'bail out boat -- okihike', language: 'bail out boat -- okihike'},
               {name: 'bait -- wakonihike', language: 'bait -- wakonihike'},
               {name: 'bake in oven -- aponaqote', language: 'bake in oven -- aponaqote'},
               {name: 'bang loudly -- cipokihtihike', language: 'bang loudly -- cipokihtihike'},
               {name: 'bank house with earth -- moloqahkomikhike', language: 'bank house with earth -- moloqahkomikhike'},
               {name: 'bank with soil -- moloqamkihike', language: 'bank with soil -- moloqamkihike'},
               {name: 'barber -- musawe', language: 'barber -- musawe'},
               {name: 'bargain -- athomahsu', language: 'bargain -- athomahsu'},
               {name: 'bark like a dog -- olomussuwokile', language: 'bark like a dog -- olomussuwokile'},
               {name: 'bark loudly -- cipokokile', language: 'bark loudly -- cipokokile'},
               {name: 'bark thus -- olokile', language: 'bark thus -- olokile'},
               {name: 'bask in sun -- ksalosuhke', language: 'bask in sun -- ksalosuhke'},
               {name: 'bat -- tokotom', language: 'bat -- tokotom'},
               {name: 'bat left- -- ehetuwitokotom', language: 'bat left- -- ehetuwitokotom'},
               {name: 'bat left-handed -- pahtatokotom', language: 'bat left-handed -- pahtatokotom'},
               {name: 'bat right-handed -- tinahkatokotom', language: 'bat right-handed -- tinahkatokotom'},
               {name: 'bead -- qapsishike', language: 'bead -- qapsishike'},
               {name: 'bear children at close intervals -- nokosahtihike', language: 'bear children at close intervals -- nokosahtihike'},
               {name: 'bear children at short intervals -- nokosacuwe', language: 'bear children at short intervals -- nokosacuwe'},
               {name: 'bear children often -- sawecuwe', language: 'bear children often -- sawecuwe'},
               {name: 'bear so many children -- kescuwe', language: 'bear so many children -- kescuwe'},
               {name: 'bear too many children -- samcuwe', language: 'bear too many children -- samcuwe'},
               {name: 'bear up in the face of adversity -- sakolitehe', language: 'bear up in the face of adversity -- sakolitehe'},
               {name: 'beat -- kakawtehson', language: 'beat -- kakawtehson'},
               {name: 'become angry -- peciluwehe', language: 'become angry -- peciluwehe'},
               {name: 'become cloudy in morning -- spasaluhkessu', language: 'become cloudy in morning -- spasaluhkessu'},
               {name: 'become different -- piluwiye', language: 'become different -- piluwiye'},
               {name: 'become dislocated from sudden impact -- coceposkehtehson', language: 'become dislocated from sudden impact -- coceposkehtehson'},
               {name: 'become known -- nutaqot', language: 'become known -- nutaqot'},
               {name: 'become overcast -- nemsotayaluhkiye', language: 'become overcast -- nemsotayaluhkiye'},
               {name: 'become overcast -- psonaluhkiye', language: 'become overcast -- psonaluhkiye'},
               {name: 'become shorter as it dries -- cilkipahte', language: 'become shorter as it dries -- cilkipahte'},
               {name: 'become shorter as s/he dries -- cilkipahsu', language: 'become shorter as s/he dries -- cilkipahsu'},
               {name: 'become tangled -- cihpolatokesson', language: 'become tangled -- cihpolatokesson'},
               {name: 'become tangled -- ciqolatokesson', language: 'become tangled -- ciqolatokesson'},
               {name: 'become tangled by flapping around -- ciqolatokihtehson', language: 'become tangled by flapping around -- ciqolatokihtehson'},
               {name: 'become wide-eyed from being hit -- cipalokiqehtehsin', language: 'become wide-eyed from being hit -- cipalokiqehtehsin'},
               {name: 'beg -- motutomesku', language: 'beg -- motutomesku'},
               {name: 'beg for food -- kewasu', language: 'beg for food -- kewasu'},
               {name: 'beg for money -- tutoma', language: 'beg for money -- tutoma'},
               {name: 'begin carrying load -- macewole', language: 'begin carrying load -- macewole'},
               {name: 'begin to carve -- macesawe', language: 'begin to carve -- macesawe'},
               {name: 'begin to gather driftwood -- macekonse', language: 'begin to gather driftwood -- macekonse'},
               {name: 'begin to get scared -- macehpayu', language: 'begin to get scared -- macehpayu'},
               {name: 'begin to pound ash to obtain basket splints -- macekpehtihike', language: 'begin to pound ash to obtain basket splints -- macekpehtihike'},
               {name: 'begin to tell story -- maciyacomu', language: 'begin to tell story -- maciyacomu'},
               {name: 'begin to vibrate -- macoskehpute', language: 'begin to vibrate -- macoskehpute'},
               {name: 'behave eccentrically -- piluwomahtu', language: 'behave eccentrically -- piluwomahtu'},
               {name: 'behave in particular way -- kinuwomtu', language: 'behave in particular way -- kinuwomtu'},
               {name: 'behave like chief -- sakomawomtu', language: 'behave like chief -- sakomawomtu'},
               {name: 'behave like child -- wasisuwomtu', language: 'behave like child -- wasisuwomtu'},
               {name: 'behave like sick person -- ksinuhkewomtu', language: 'behave like sick person -- ksinuhkewomtu'},
               {name: 'behave like white -- ikolisomanuwomtu', language: 'behave like white -- ikolisomanuwomtu'},
               {name: 'behave like woman -- ehpituwomtu', language: 'behave like woman -- ehpituwomtu'},
               {name: 'behave roughly -- kawaskomtu', language: 'behave roughly -- kawaskomtu'},
               {name: 'behave s/he is mean -- mocomahtu', language: 'behave s/he is mean -- mocomahtu'},
               {name: 'behave strangely -- mahcuwomtu', language: 'behave strangely -- mahcuwomtu'},
               {name: 'behave thus -- olhoma', language: 'behave thus -- olhoma'},
               {name: 'believe -- wolamsotom', language: 'believe -- wolamsotom'},
               {name: 'believe self to be alone -- qotuhkatahamsu', language: 'believe self to be alone -- qotuhkatahamsu'},
               {name: 'belong -- ihpu', language: 'belong -- ihpu'},
               {name: 'belong -- ihte', language: 'belong -- ihte'},
               {name: 'belong there -- ihtolahte', language: 'belong there -- ihtolahte'},
               {name: 'belong there -- toleyawiw', language: 'belong there -- toleyawiw'},
               {name: 'bend at joint -- pkuwoskot', language: 'bend at joint -- pkuwoskot'},
               {name: 'bend down behind something -- akuwuhucin', language: 'bend down behind something -- akuwuhucin'},
               {name: 'bend down facing away -- olomiwakcuhucin', language: 'bend down facing away -- olomiwakcuhucin'},
               {name: 'bend down into something -- pisiwakcuhucin', language: 'bend down into something -- pisiwakcuhucin'},
               {name: 'bend over -- cituwessu', language: 'bend over -- cituwessu'},
               {name: 'bend to side to balance canoe -- ktoqonosu', language: 'bend to side to balance canoe -- ktoqonosu'},
               {name: 'bet -- pettuwiw', language: 'bet -- pettuwiw'},
               {name: 'bet heavily -- tkiqamke', language: 'bet heavily -- tkiqamke'},
               {name: 'bid -- pituwiw', language: 'bid -- pituwiw'},
               {name: 'bind first in one direction and then back in the opposite direction -- ehetuwapekopehtahsu', language: 'bind first in one direction and then back in the opposite direction -- ehetuwapekopehtahsu'},
               {name: 'bind quickly -- nokosayakopehtahsu', language: 'bind quickly -- nokosayakopehtahsu'},
               {name: 'bite -- pokehtamu', language: 'bite -- pokehtamu'},
               {name: 'bite and holds on -- mecimoluwe', language: 'bite and holds on -- mecimoluwe'},
               {name: 'blame -- oqikemu', language: 'blame -- oqikemu'},
               {name: 'blaze up -- ksamkole', language: 'blaze up -- ksamkole'},
               {name: 'bleed profusely -- kakawiqqiye', language: 'bleed profusely -- kakawiqqiye'},
               {name: 'bleed thus -- liqqiye', language: 'bleed thus -- liqqiye'},
               {name: 'bleed to death -- sikteqqiye', language: 'bleed to death -- sikteqqiye'},
               {name: 'blend soft substance by stirring -- awskacokihike', language: 'blend soft substance by stirring -- awskacokihike'},
               {name: 'blink -- lapiqessu', language: 'blink -- lapiqessu'},
               {name: 'blink quickly -- sahsipiskiqehpusu', language: 'blink quickly -- sahsipiskiqehpusu'},
               {name: 'blink repeatedly -- piskiqehpusu', language: 'blink repeatedly -- piskiqehpusu'},
               {name: 'bloom -- psqahsuwe', language: 'bloom -- psqahsuwe'},
               {name: 'blow -- pomatokolamson', language: 'blow -- pomatokolamson'},
               {name: 'blow down -- suhkolamsuke', language: 'blow down -- suhkolamsuke'},
               {name: 'blow in all directions -- sisselamson', language: 'blow in all directions -- sisselamson'},
               {name: 'blow inside-out -- aputekolamsukewiw', language: 'blow inside-out -- aputekolamsukewiw'},
               {name: 'blow nose -- soniku', language: 'blow nose -- soniku'},
               {name: 'blow off in the wind -- monolamson', language: 'blow off in the wind -- monolamson'},
               {name: 'blow violently -- cipokolamson', language: 'blow violently -- cipokolamson'},
               {name: 'bluff -- qetpawotahsu', language: 'bluff -- qetpawotahsu'},
               {name: 'boil away -- sihkolapsqesu', language: 'boil away -- sihkolapsqesu'},
               {name: 'boil dry -- sihkolapsqesoma', language: 'boil dry -- sihkolapsqesoma'},
               {name: 'boil dry -- sihkolapsqete', language: 'boil dry -- sihkolapsqete'},
               {name: 'borrow too much -- samocanomahsu', language: 'borrow too much -- samocanomahsu'},
               {name: 'bother by touching -- sispeyutom', language: 'bother by touching -- sispeyutom'},
               {name: 'bother by touching -- sisseyutom', language: 'bother by touching -- sisseyutom'},
               {name: 'bottom out -- psahkonahtehson', language: 'bottom out -- psahkonahtehson'},
               {name: 'bounce -- sqaqson', language: 'bounce -- sqaqson'},
               {name: 'bounce along -- pomtehsin', language: 'bounce along -- pomtehsin'},
               {name: 'bow head -- cituwamqewu', language: 'bow head -- cituwamqewu'},
               {name: 'brace self -- ceconitutom', language: 'brace self -- ceconitutom'},
               {name: 'braid fast -- nokosayatokonike', language: 'braid fast -- nokosayatokonike'},
               {name: 'braid neatly -- wolatokonike', language: 'braid neatly -- wolatokonike'},
               {name: 'braid own hair -- latokonomuwewsu', language: 'braid own hair -- latokonomuwewsu'},
               {name: 'break -- pskesson', language: 'break -- pskesson'},
               {name: 'break -- pskessosson', language: 'break -- pskessosson'},
               {name: 'break arm falling against something -- tomiptinehtehsin', language: 'break arm falling against something -- tomiptinehtehsin'},
               {name: 'break camp -- suhkacu', language: 'break camp -- suhkacu'},
               {name: 'break camp -- wisuwacu', language: 'break camp -- wisuwacu'},
               {name: 'break down -- wekesson', language: 'break down -- wekesson'},
               {name: 'break from bending -- tomakiye', language: 'break from bending -- tomakiye'},
               {name: 'break in -- apqotehtihike', language: 'break in -- apqotehtihike'},
               {name: 'break in two -- tomesson', language: 'break in two -- tomesson'},
               {name: 'break in two from striking -- tomtehsin', language: 'break in two from striking -- tomtehsin'},
               {name: 'break into small pieces -- paskamkesson', language: 'break into small pieces -- paskamkesson'},
               {name: 'break leg -- tomikonatessu', language: 'break leg -- tomikonatessu'},
               {name: 'break leg by falling against something -- tomikonahtehsin', language: 'break leg by falling against something -- tomikonahtehsin'},
               {name: 'break lump-like objects with hand(s) -- paskapskonike', language: 'break lump-like objects with hand(s) -- paskapskonike'},
               {name: 'break neck by falling by something -- tomiqehtehsin', language: 'break neck by falling by something -- tomiqehtehsin'},
               {name: 'break out into sweat -- muskalosu', language: 'break out into sweat -- muskalosu'},
               {name: 'break soft objects objects burst with hand(s) -- paskocokonike', language: 'break soft objects objects burst with hand(s) -- paskocokonike'},
               {name: 'break through -- saputehtihike', language: 'break through -- saputehtihike'},
               {name: 'break when hit -- tomtuhuke', language: 'break when hit -- tomtuhuke'},
               {name: 'breathe fast -- kakawolatom', language: 'breathe fast -- kakawolatom'},
               {name: 'breathe hard -- motelatom', language: 'breathe hard -- motelatom'},
               {name: 'breathe heavily -- ksolatom', language: 'breathe heavily -- ksolatom'},
               {name: 'breathe out -- nutelatom', language: 'breathe out -- nutelatom'},
               {name: 'breathe rapidly -- sahsolatom', language: 'breathe rapidly -- sahsolatom'},
               {name: 'breathe rapidly and shallowly -- sahsolatomihpusu', language: 'breathe rapidly and shallowly -- sahsolatomihpusu'},
               {name: 'breathe slowly -- menakatolatom', language: 'breathe slowly -- menakatolatom'},
               {name: 'breathe thus -- ollatom', language: 'breathe thus -- ollatom'},
               {name: 'breathe with difficulty -- motecokonoma', language: 'breathe with difficulty -- motecokonoma'},
               {name: 'brew morning tea -- spasitihkosu', language: 'brew morning tea -- spasitihkosu'},
               {name: 'brew tea -- tihkosu', language: 'brew tea -- tihkosu'},
               {name: 'bring load -- petonahsu', language: 'bring load -- petonahsu'},
               {name: 'brown quickly -- sahsaqosu', language: 'brown quickly -- sahsaqosu'},
               {name: 'brown quickly -- sahsaqote', language: 'brown quickly -- sahsaqote'},
               {name: 'bubble up -- pukcolakomitehe', language: 'bubble up -- pukcolakomitehe'},
               {name: 'bubble up -- pukcolakomiye', language: 'bubble up -- pukcolakomiye'},
               {name: 'bubble up shaking sand and pebbles -- sakhakomitehpute', language: 'bubble up shaking sand and pebbles -- sakhakomitehpute'},
               {name: 'buckle due to heat -- asuwahqaqote', language: 'buckle due to heat -- asuwahqaqote'},
               {name: 'build extension on house -- ankuhke', language: 'build extension on house -- ankuhke'},
               {name: 'build house for self -- wihkosu', language: 'build house for self -- wihkosu'},
               {name: 'build house in off-limits location way of something -- komicihkosu', language: 'build house in off-limits location way of something -- komicihkosu'},
               {name: 'build house(s) -- wihke', language: 'build house(s) -- wihke'},
               {name: 'build houses for living -- wikke', language: 'build houses for living -- wikke'},
               {name: 'build roaring fire -- memhuhtutuwe', language: 'build roaring fire -- memhuhtutuwe'},
               {name: 'build up -- petson', language: 'build up -- petson'},
               {name: 'bum -- motutoma', language: 'bum -- motutoma'},
               {name: 'bum -- motutomesku', language: 'bum -- motutomesku'},
               {name: 'bump head against something -- pakahqehsimsu', language: 'bump head against something -- pakahqehsimsu'},
               {name: 'bump head against something -- pakahqehsin', language: 'bump head against something -- pakahqehsin'},
               {name: 'bump head against something -- pakahqehtehsimsu', language: 'bump head against something -- pakahqehtehsimsu'},
               {name: 'bump head against something -- pakahqehtehsin', language: 'bump head against something -- pakahqehtehsin'},
               {name: 'bump its head -- pakahqehson', language: 'bump its head -- pakahqehson'},
               {name: 'burn by overcooking -- pkiksawe', language: 'burn by overcooking -- pkiksawe'},
               {name: 'burn fast -- kakawaqote', language: 'burn fast -- kakawaqote'},
               {name: 'burn in two -- tomaqote', language: 'burn in two -- tomaqote'},
               {name: 'burn intensely -- wisokamkole', language: 'burn intensely -- wisokamkole'},
               {name: 'burn onto cooking pot -- sitapsqesu', language: 'burn onto cooking pot -- sitapsqesu'},
               {name: 'burn onto cooking pot -- sitapsqete', language: 'burn onto cooking pot -- sitapsqete'},
               {name: 'burn poorly -- mocamkole', language: 'burn poorly -- mocamkole'},
               {name: 'burn self -- kihkaqosu', language: 'burn self -- kihkaqosu'},
               {name: 'burn slowly -- menakatamkole', language: 'burn slowly -- menakatamkole'},
               {name: 'burn the inside out -- aputaqosu', language: 'burn the inside out -- aputaqosu'},
               {name: 'burn to death -- siktiyaqosu', language: 'burn to death -- siktiyaqosu'},
               {name: 'burn too hot -- samamkole', language: 'burn too hot -- samamkole'},
               {name: 'burn with pointy flame -- qinusqamkole', language: 'burn with pointy flame -- qinusqamkole'},
               {name: 'burrow soft earth -- kcitacokessu', language: 'burrow soft earth -- kcitacokessu'},
               {name: 'burst -- paskesson', language: 'burst -- paskesson'},
               {name: 'burst -- paskocokopolike', language: 'burst -- paskocokopolike'},
               {name: 'burst from laughing -- paskelomu', language: 'burst from laughing -- paskelomu'},
               {name: 'burst into flames -- psqolesson', language: 'burst into flames -- psqolesson'},
               {name: 'burst into song -- psqentu', language: 'burst into song -- psqentu'},
               {name: 'burst into tears -- psqotemu', language: 'burst into tears -- psqotemu'},
               {name: 'burst open by tearing -- paskinesson', language: 'burst open by tearing -- paskinesson'},
               {name: 'burst out crying -- wisiyelomu', language: 'burst out crying -- wisiyelomu'},
               {name: 'burst when being cooked -- paskaqosu', language: 'burst when being cooked -- paskaqosu'},
               {name: 'burst when being cooked -- paskaqote', language: 'burst when being cooked -- paskaqote'},
               {name: 'butt -- cituwamqehponosu', language: 'butt -- cituwamqehponosu'},
               {name: 'butt in -- piscokhusu', language: 'butt in -- piscokhusu'},
               {name: 'buy -- ankuwe', language: 'buy -- ankuwe'},
               {name: 'buy -- monuwike', language: 'buy -- monuwike'},
               {name: 'buy -- motekonuhke', language: 'buy -- motekonuhke'},
               {name: 'buy and sells -- ankuwankuwike', language: 'buy and sells -- ankuwankuwike'},
               {name: 'buy buys goods -- komutankuwe', language: 'buy buys goods -- komutankuwe'},
               {name: 'buy food ahead of time -- nihkaniwihqonike', language: 'buy food ahead of time -- nihkaniwihqonike'},
               {name: 'buy foolishly -- koluskankuwe', language: 'buy foolishly -- koluskankuwe'},
               {name: 'buy large quantity -- mokiyankuwe', language: 'buy large quantity -- mokiyankuwe'},
               {name: 'call -- wihqikesu', language: 'call -- wihqikesu'},
               {name: 'calm down -- conopekiye', language: 'calm down -- conopekiye'},
               {name: 'carve -- amalhaqsawe', language: 'carve -- amalhaqsawe'},
               {name: 'carve fancily -- amalhaqsawewiw', language: 'carve fancily -- amalhaqsawewiw'},
               {name: 'cast -- latokihtesta', language: 'cast -- latokihtesta'},
               {name: 'cast shadow -- pokotahte', language: 'cast shadow -- pokotahte'},
               {name: 'cave in -- tuwalokamkessu', language: 'cave in -- tuwalokamkessu'},
               {name: 'celebrate mass -- olomeske', language: 'celebrate mass -- olomeske'},
               {name: 'chafe -- esuwacqihpute', language: 'chafe -- esuwacqihpute'},
               {name: 'change -- acehlosu', language: 'change -- acehlosu'},
               {name: 'change -- acehtahsu', language: 'change -- acehtahsu'},
               {name: 'change -- ataciye', language: 'change -- ataciye'},
               {name: 'change abruptly -- acesson', language: 'change abruptly -- acesson'},
               {name: 'change clothes -- atsewe', language: 'change clothes -- atsewe'},
               {name: 'change color -- accossu', language: 'change color -- accossu'},
               {name: 'change into something else -- qolopitpot', language: 'change into something else -- qolopitpot'},
               {name: 'change mind -- qolopitahasu', language: 'change mind -- qolopitahasu'},
               {name: 'change mind -- qolopiye', language: 'change mind -- qolopiye'},
               {name: 'change name -- aciwiyusu', language: 'change name -- aciwiyusu'},
               {name: 'change opinion -- qolopiwolamsotom', language: 'change opinion -- qolopiwolamsotom'},
               {name: 'change own shoes -- atuwemu', language: 'change own shoes -- atuwemu'},
               {name: 'change pitching arm -- acosqone', language: 'change pitching arm -- acosqone'},
               {name: 'change price -- kahsolutike', language: 'change price -- kahsolutike'},
               {name: 'change price(s) on item(s) for sale -- atolutike', language: 'change price(s) on item(s) for sale -- atolutike'},
               {name: 'change residence -- atute', language: 'change residence -- atute'},
               {name: 'change seat -- atqepu', language: 'change seat -- atqepu'},
               {name: 'charge -- nihkancanomahsu', language: 'charge -- nihkancanomahsu'},
               {name: 'charge fair price(s) -- wollumsu', language: 'charge fair price(s) -- wollumsu'},
               {name: 'charge fair prices -- wolahmahsu', language: 'charge fair prices -- wolahmahsu'},
               {name: 'charge good price(s) -- wollutike', language: 'charge good price(s) -- wollutike'},
               {name: 'charge high price(s) -- ksolumsu', language: 'charge high price(s) -- ksolumsu'},
               {name: 'charge high price(s) -- ksolutike', language: 'charge high price(s) -- ksolutike'},
               {name: 'charge high prices -- spiqolutike', language: 'charge high prices -- spiqolutike'},
               {name: 'charge lower prices -- nahkalutike', language: 'charge lower prices -- nahkalutike'},
               {name: 'charge outrageous prices -- wiwonasolutike', language: 'charge outrageous prices -- wiwonasolutike'},
               {name: 'charge price for services -- awotuhke', language: 'charge price for services -- awotuhke'},
               {name: 'charge reasonable -- wewolutike', language: 'charge reasonable -- wewolutike'},
               {name: 'charge such a price(s) -- ollutike', language: 'charge such a price(s) -- ollutike'},
               {name: 'charge too much -- ksahmahsu', language: 'charge too much -- ksahmahsu'},
               {name: 'charge too much -- samolutike', language: 'charge too much -- samolutike'},
               {name: 'chase along -- pomiphoqe', language: 'chase along -- pomiphoqe'},
               {name: 'chase from place to place -- yaliphoqe', language: 'chase from place to place -- yaliphoqe'},
               {name: 'cheat in writing -- pahculuwewikhike', language: 'cheat in writing -- pahculuwewikhike'},
               {name: 'check for nits -- apqenike', language: 'check for nits -- apqenike'},
               {name: 'check for nits -- konasiske', language: 'check for nits -- konasiske'},
               {name: 'chew -- nukcokotamu', language: 'chew -- nukcokotamu'},
               {name: 'chew gum -- lihqekotamu', language: 'chew gum -- lihqekotamu'},
               {name: 'chew it with crunching sound -- motiyamkotamu', language: 'chew it with crunching sound -- motiyamkotamu'},
               {name: 'chew well -- wolihqeksu', language: 'chew well -- wolihqeksu'},
               {name: 'chew well with popping sound -- wolipaskihqekotahsu', language: 'chew well with popping sound -- wolipaskihqekotahsu'},
               {name: 'chip stone -- lapskihtihike', language: 'chip stone -- lapskihtihike'},
               {name: 'choke -- neqotom', language: 'choke -- neqotom'},
               {name: 'choke -- psulu', language: 'choke -- psulu'},
               {name: 'chop apart -- tomtihike', language: 'chop apart -- tomtihike'},
               {name: 'chop limbs off tree -- psketqonhike', language: 'chop limbs off tree -- psketqonhike'},
               {name: 'churn butter -- toqihike', language: 'churn butter -- toqihike'},
               {name: 'circulate -- wiwonicuwon', language: 'circulate -- wiwonicuwon'},
               {name: 'circulate -- yaliyewiw', language: 'circulate -- yaliyewiw'},
               {name: 'clap hands -- cokoptihike', language: 'clap hands -- cokoptihike'},
               {name: 'clean -- pehkihtahsu', language: 'clean -- pehkihtahsu'},
               {name: 'clean -- wolihtahsu', language: 'clean -- wolihtahsu'},
               {name: 'clean house -- wisunike', language: 'clean house -- wisunike'},
               {name: 'clean up thoroughly -- kihkapehkihtahsu', language: 'clean up thoroughly -- kihkapehkihtahsu'},
               {name: 'clear -- musqot', language: 'clear -- musqot'},
               {name: 'clear fast -- musqotesson', language: 'clear fast -- musqotesson'},
               {name: 'clear land -- psuhike', language: 'clear land -- psuhike'},
               {name: 'clear suddenly -- pehqesson', language: 'clear suddenly -- pehqesson'},
               {name: 'clear throat -- woliqtakonuhusu', language: 'clear throat -- woliqtakonuhusu'},
               {name: 'clear-cut land -- musikhike', language: 'clear-cut land -- musikhike'},
               {name: 'climb around -- yalatuwe', language: 'climb around -- yalatuwe'},
               {name: 'climb away -- olomatuwe', language: 'climb away -- olomatuwe'},
               {name: 'climb down -- kahpotayatuwe', language: 'climb down -- kahpotayatuwe'},
               {name: 'climb down -- poneqatuwe', language: 'climb down -- poneqatuwe'},
               {name: 'climb halfway up -- epahsatuwe', language: 'climb halfway up -- epahsatuwe'},
               {name: 'climb hurriedly -- ahqatuwe', language: 'climb hurriedly -- ahqatuwe'},
               {name: 'climb in -- pisatuwe', language: 'climb in -- pisatuwe'},
               {name: 'climb in various ways -- milatuwe', language: 'climb in various ways -- milatuwe'},
               {name: 'climb out -- nutiyatuwe', language: 'climb out -- nutiyatuwe'},
               {name: 'climb to here -- petatuwe', language: 'climb to here -- petatuwe'},
               {name: 'climb toward here -- ckuwatuwe', language: 'climb toward here -- ckuwatuwe'},
               {name: 'climb up -- ewepatuwe', language: 'climb up -- ewepatuwe'},
               {name: 'climb up -- tqatuwe', language: 'climb up -- tqatuwe'},
               {name: 'climb up high -- spatuwe', language: 'climb up high -- spatuwe'},
               {name: 'climb up to escape -- tqatuwephuwe', language: 'climb up to escape -- tqatuwephuwe'},
               {name: 'climb upward -- spiqatuwe', language: 'climb upward -- spiqatuwe'},
               {name: 'close curtain(s) -- kpekopolike', language: 'close curtain(s) -- kpekopolike'},
               {name: 'close eyes -- piskiqewu', language: 'close eyes -- piskiqewu'},
               {name: 'close the curtains -- ahsuwekopolike', language: 'close the curtains -- ahsuwekopolike'},
               {name: 'collapse -- musikesson', language: 'collapse -- musikesson'},
               {name: 'collapse -- musikessu', language: 'collapse -- musikessu'},
               {name: 'collapse quickly -- suhkessu', language: 'collapse quickly -- suhkessu'},
               {name: 'color fancily -- amalhocossawiye', language: 'color fancily -- amalhocossawiye'},
               {name: 'color incorrectly -- wapolocossawiye', language: 'color incorrectly -- wapolocossawiye'},
               {name: 'comb own hair -- naskuhusu', language: 'comb own hair -- naskuhusu'},
               {name: 'come and eats supper -- peciwolaqihpu', language: 'come and eats supper -- peciwolaqihpu'},
               {name: 'come back -- apaciyewiw', language: 'come back -- apaciyewiw'},
               {name: 'come back to life -- apatawsuwiw', language: 'come back to life -- apatawsuwiw'},
               {name: 'come following -- peciphoqe', language: 'come following -- peciphoqe'},
               {name: 'come from there -- tapekopu', language: 'come from there -- tapekopu'},
               {name: 'come from there -- toqapekte', language: 'come from there -- toqapekte'},
               {name: 'come into view -- sakhalokittiyhe', language: 'come into view -- sakhalokittiyhe'},
               {name: 'come into view -- sakhiyewiw', language: 'come into view -- sakhiyewiw'},
               {name: 'come into view -- sakholiqehe', language: 'come into view -- sakholiqehe'},
               {name: 'come out into view -- muskiyewiw', language: 'come out into view -- muskiyewiw'},
               {name: 'come to buy -- petankuwe', language: 'come to buy -- petankuwe'},
               {name: 'come to pass -- pecitpiye', language: 'come to pass -- pecitpiye'},
               {name: 'come toward here -- ckuwyewiw', language: 'come toward here -- ckuwyewiw'},
               {name: 'come toward here pulling -- ckunahsu', language: 'come toward here pulling -- ckunahsu'},
               {name: 'come toward here way baring teeth -- ckuwapitetutom', language: 'come toward here way baring teeth -- ckuwapitetutom'},
               {name: 'compete -- amke', language: 'compete -- amke'},
               {name: 'conclude -- mettokot', language: 'conclude -- mettokot'},
               {name: 'concoct something to eat -- ksawenike', language: 'concoct something to eat -- ksawenike'},
               {name: 'consist of various items newspaper with different articles) -- milte', language: 'consist of various items newspaper with different articles) -- milte'},
               {name: 'contain liquid -- tuhpe', language: 'contain liquid -- tuhpe'},
               {name: 'continue for a long time -- sipkatokot', language: 'continue for a long time -- sipkatokot'},
               {name: 'continue on way -- nankomiye', language: 'continue on way -- nankomiye'},
               {name: 'continue singing -- olomintu', language: 'continue singing -- olomintu'},
               {name: 'continue telling story -- ahtolacomu', language: 'continue telling story -- ahtolacomu'},
               {name: 'continue to rain -- aptolan', language: 'continue to rain -- aptolan'},
               {name: 'continue to shine -- aptahsu', language: 'continue to shine -- aptahsu'},
               {name: 'continue to snow -- apcipsan', language: 'continue to snow -- apcipsan'},
               {name: 'contort self -- milakitutom', language: 'contort self -- milakitutom'},
               {name: 'contribute generously -- ktanaqitepahke', language: 'contribute generously -- ktanaqitepahke'},
               {name: 'contribute too much -- mamhunitepahke', language: 'contribute too much -- mamhunitepahke'},
               {name: 'convert -- qolopiye', language: 'convert -- qolopiye'},
               {name: 'convert to another religion -- qolopipapahtom', language: 'convert to another religion -- qolopipapahtom'},
               {name: 'convince self -- wolamuhusu', language: 'convince self -- wolamuhusu'},
               {name: 'cook -- suksahqe', language: 'cook -- suksahqe'},
               {name: 'cook as much as possible -- memhuwahqe', language: 'cook as much as possible -- memhuwahqe'},
               {name: 'cook as much food as possible for self -- memhuwahqosu', language: 'cook as much food as possible for self -- memhuwahqosu'},
               {name: 'cook fancy dishes -- amalhahqe', language: 'cook fancy dishes -- amalhahqe'},
               {name: 'cook for self -- suksahqosu', language: 'cook for self -- suksahqosu'},
               {name: 'cook large quantity -- ktanaqahqe', language: 'cook large quantity -- ktanaqahqe'},
               {name: 'cook large quantity of food -- mokiyahqe', language: 'cook large quantity of food -- mokiyahqe'},
               {name: 'cook many different things -- milahqe', language: 'cook many different things -- milahqe'},
               {name: 'cook noontime meal -- pasqiyahqe', language: 'cook noontime meal -- pasqiyahqe'},
               {name: 'cook on -- siccokaqote', language: 'cook on -- siccokaqote'},
               {name: 'cook on -- sitaqote', language: 'cook on -- sitaqote'},
               {name: 'cook quickly -- sahsaqosu', language: 'cook quickly -- sahsaqosu'},
               {name: 'cook quickly -- sahsaqote', language: 'cook quickly -- sahsaqote'},
               {name: 'cook slowly -- menakataqote', language: 'cook slowly -- menakataqote'},
               {name: 'cook supper -- wolaqahqe', language: 'cook supper -- wolaqahqe'},
               {name: 'cook together -- wawikaqote', language: 'cook together -- wawikaqote'},
               {name: 'cook until it is white -- wapaqote', language: 'cook until it is white -- wapaqote'},
               {name: 'cook until s/he is white -- wapaqosu', language: 'cook until s/he is white -- wapaqosu'},
               {name: 'cook with what ingredients s/he has available -- mamatuwahqe', language: 'cook with what ingredients s/he has available -- mamatuwahqe'},
               {name: 'cool self -- tkewokehlosu', language: 'cool self -- tkewokehlosu'},
               {name: 'cough -- neqhom', language: 'cough -- neqhom'},
               {name: 'cough -- pikcineqhom', language: 'cough -- pikcineqhom'},
               {name: 'cough from having swallowed something into windpipe -- neqholusu', language: 'cough from having swallowed something into windpipe -- neqholusu'},
               {name: 'cough from smoke -- neqholuse', language: 'cough from smoke -- neqholuse'},
               {name: 'cough up liquid -- neqhope', language: 'cough up liquid -- neqhope'},
               {name: 'cough violently -- memhuwineqhom', language: 'cough violently -- memhuwineqhom'},
               {name: 'count -- okimqot', language: 'count -- okimqot'},
               {name: 'count -- okimqot', language: 'count -- okimqot'},
               {name: 'count -- okisu', language: 'count -- okisu'},
               {name: 'count -- olokimqosu', language: 'count -- olokimqosu'},
               {name: 'cover -- wissekhusu', language: 'cover -- wissekhusu'},
               {name: 'cover completely -- psonekte', language: 'cover completely -- psonekte'},
               {name: 'cover eyes -- wisseqensu', language: 'cover eyes -- wisseqensu'},
               {name: 'cover face with mask -- wissekikuhusu', language: 'cover face with mask -- wissekikuhusu'},
               {name: 'cover self with soil -- moloqamkuhusu', language: 'cover self with soil -- moloqamkuhusu'},
               {name: 'cover up hammering -- kpotehtihike', language: 'cover up hammering -- kpotehtihike'},
               {name: 'cover with cloth -- kpekopolike', language: 'cover with cloth -- kpekopolike'},
               {name: 'crack -- paskoloqessu', language: 'crack -- paskoloqessu'},
               {name: 'crack to dryness -- psikipahsu', language: 'crack to dryness -- psikipahsu'},
               {name: 'crack to dryness -- psikipahte', language: 'crack to dryness -- psikipahte'},
               {name: 'crave for some kind of food -- kotuhtamu', language: 'crave for some kind of food -- kotuhtamu'},
               {name: 'crawl along -- pomapeku', language: 'crawl along -- pomapeku'},
               {name: 'crawl around -- yalapeku', language: 'crawl around -- yalapeku'},
               {name: 'crawl out -- nutiyapeku', language: 'crawl out -- nutiyapeku'},
               {name: 'crawl over -- skituwapeku', language: 'crawl over -- skituwapeku'},
               {name: 'crawl over something -- pasitapeku', language: 'crawl over something -- pasitapeku'},
               {name: 'crawl over something quickly -- pasitapekessu', language: 'crawl over something quickly -- pasitapekessu'},
               {name: 'croak -- kaktaqsu', language: 'croak -- kaktaqsu'},
               {name: 'crumble -- nuhkamkihike', language: 'crumble -- nuhkamkihike'},
               {name: 'crumble and the particles fall -- poneqamkiye', language: 'crumble and the particles fall -- poneqamkiye'},
               {name: 'crumble into small pieces -- suhkamkesson', language: 'crumble into small pieces -- suhkamkesson'},
               {name: 'crumple in pleats -- aqacokessu', language: 'crumple in pleats -- aqacokessu'},
               {name: 'curl own hair -- topuwuhusu', language: 'curl own hair -- topuwuhusu'},
               {name: 'curl up into ball -- mawapskuhucin', language: 'curl up into ball -- mawapskuhucin'},
               {name: 'curtain -- ahsuwekopolike', language: 'curtain -- ahsuwekopolike'},
               {name: 'curtain -- panekopolike', language: 'curtain -- panekopolike'},
               {name: 'curve -- pimsqessu', language: 'curve -- pimsqessu'},
               {name: 'curve downward -- cituwessu', language: 'curve downward -- cituwessu'},
               {name: 'cut -- sapsawewiw', language: 'cut -- sapsawewiw'},
               {name: 'cut basket-weavers using gauge -- likpesawe', language: 'cut basket-weavers using gauge -- likpesawe'},
               {name: 'cut hair -- musawe', language: 'cut hair -- musawe'},
               {name: 'cut into ground as it flows -- mulahkecuwon', language: 'cut into ground as it flows -- mulahkecuwon'},
               {name: 'cut into logs -- kuwhahqe', language: 'cut into logs -- kuwhahqe'},
               {name: 'cut own hair -- mususu', language: 'cut own hair -- mususu'},
               {name: 'cut self badly -- kakawsusu', language: 'cut self badly -- kakawsusu'},
               {name: 'cut so many trees -- kehsonahqe', language: 'cut so many trees -- kehsonahqe'},
               {name: 'cut thus -- olsawe', language: 'cut thus -- olsawe'},
               {name: 'cut wood -- laqtihike', language: 'cut wood -- laqtihike'},
               {name: 'cut wood -- nutaqtihike', language: 'cut wood -- nutaqtihike'},
               {name: 'dance Native dance -- skicinuwoka', language: 'dance Native dance -- skicinuwoka'},
               {name: 'dance alone -- qotuhkaka', language: 'dance alone -- qotuhkaka'},
               {name: 'dance along -- pomoka', language: 'dance along -- pomoka'},
               {name: 'dance around -- yalka', language: 'dance around -- yalka'},
               {name: 'dance barefoot -- sahsaksitehtehkom', language: 'dance barefoot -- sahsaksitehtehkom'},
               {name: 'dance fancily -- amalhipomoka', language: 'dance fancily -- amalhipomoka'},
               {name: 'dance fancily -- amalhoka', language: 'dance fancily -- amalhoka'},
               {name: 'dance in front -- nihkanka', language: 'dance in front -- nihkanka'},
               {name: 'dance sideways -- nomocintehkom', language: 'dance sideways -- nomocintehkom'},
               {name: 'dance sideways -- nomocintehkomuhsin', language: 'dance sideways -- nomocintehkomuhsin'},
               {name: 'dance sliding backward -- sehtayacqihtehkom', language: 'dance sliding backward -- sehtayacqihtehkom'},
               {name: 'dance sliding forward -- nihkanacqihtehkom', language: 'dance sliding forward -- nihkanacqihtehkom'},
               {name: 'dance sliding sideways -- nomocinacqihtehkom', language: 'dance sliding sideways -- nomocinacqihtehkom'},
               {name: 'dance slowly -- menakatka', language: 'dance slowly -- menakatka'},
               {name: 'dance so hard -- tuttehkom', language: 'dance so hard -- tuttehkom'},
               {name: 'dance the hunter\'s dance -- kotunkewka', language: 'dance the hunter\'s dance -- kotunkewka'},
               {name: 'dance thus -- olka', language: 'dance thus -- olka'},
               {name: 'dance to exhaustion -- sikteka', language: 'dance to exhaustion -- sikteka'},
               {name: 'dance until end -- kihkaka', language: 'dance until end -- kihkaka'},
               {name: 'dance well -- woloka', language: 'dance well -- woloka'},
               {name: 'dare -- pecikinapiyiw', language: 'dare -- pecikinapiyiw'},
               {name: 'deal -- okonuwe', language: 'deal -- okonuwe'},
               {name: 'deal around the table -- wiwonokonuwe', language: 'deal around the table -- wiwonokonuwe'},
               {name: 'deal to self -- okonuwewsu', language: 'deal to self -- okonuwewsu'},
               {name: 'deal using left hand -- pahtakonuwe', language: 'deal using left hand -- pahtakonuwe'},
               {name: 'decapitate self -- tomiqehlosu', language: 'decapitate self -- tomiqehlosu'},
               {name: 'decorate Christmas tree -- amalhatokuhutahsu', language: 'decorate Christmas tree -- amalhatokuhutahsu'},
               {name: 'decorate by weaving sweetgrass into part of basket -- tomuwikhike', language: 'decorate by weaving sweetgrass into part of basket -- tomuwikhike'},
               {name: 'decorate string -- amalhatokihike', language: 'decorate string -- amalhatokihike'},
               {name: 'defecate -- cokuhke', language: 'defecate -- cokuhke'},
               {name: 'defecate from drinking too much -- pskocossomu', language: 'defecate from drinking too much -- pskocossomu'},
               {name: 'deflate -- sinpaskuhse', language: 'deflate -- sinpaskuhse'},
               {name: 'deflate -- sinpaskuhsewiw', language: 'deflate -- sinpaskuhsewiw'},
               {name: 'delete -- ketuwikhike', language: 'delete -- ketuwikhike'},
               {name: 'demand much -- milokitom', language: 'demand much -- milokitom'},
               {name: 'deodorize self -- kahsaqhehlosu', language: 'deodorize self -- kahsaqhehlosu'},
               {name: 'depart with hurried and audible steps -- macehtestike', language: 'depart with hurried and audible steps -- macehtestike'},
               {name: 'depend on other people -- nihkalutuwe', language: 'depend on other people -- nihkalutuwe'},
               {name: 'deposit money in bank -- penkihike', language: 'deposit money in bank -- penkihike'},
               {name: 'derail -- pasittehson', language: 'derail -- pasittehson'},
               {name: 'develop body hair early -- miyahsuwesu', language: 'develop body hair early -- miyahsuwesu'},
               {name: 'die from -- cine', language: 'die from -- cine'},
               {name: 'die from fall -- siktehsin', language: 'die from fall -- siktehsin'},
               {name: 'die from shock -- pskikitessu', language: 'die from shock -- pskikitessu'},
               {name: 'die hard -- sipine', language: 'die hard -- sipine'},
               {name: 'die of old age -- mehtatom', language: 'die of old age -- mehtatom'},
               {name: 'die of old age -- mehtatomuwiw', language: 'die of old age -- mehtatomuwiw'},
               {name: 'die quickly -- nokosane', language: 'die quickly -- nokosane'},
               {name: 'die with much suffering -- sikine', language: 'die with much suffering -- sikine'},
               {name: 'dig -- walke', language: 'dig -- walke'},
               {name: 'dig a hole underneath -- neqalokuhke', language: 'dig a hole underneath -- neqalokuhke'},
               {name: 'dig clams -- monesse', language: 'dig clams -- monesse'},
               {name: 'dig clams -- olonesse', language: 'dig clams -- olonesse'},
               {name: 'dig deep hole -- mulayalke', language: 'dig deep hole -- mulayalke'},
               {name: 'dig punches holes -- lalokihike', language: 'dig punches holes -- lalokihike'},
               {name: 'dig quickly -- kakawalke', language: 'dig quickly -- kakawalke'},
               {name: 'dig so much -- kehsalke', language: 'dig so much -- kehsalke'},
               {name: 'dig thus -- lalke', language: 'dig thus -- lalke'},
               {name: 'dig up ground noisily and angrily -- nikcahke', language: 'dig up ground noisily and angrily -- nikcahke'},
               {name: 'dip -- sqihike', language: 'dip -- sqihike'},
               {name: 'dip water from container -- osihhom', language: 'dip water from container -- osihhom'},
               {name: 'disappear -- mehtaqhe', language: 'disappear -- mehtaqhe'},
               {name: 'disembark and unloads household goods and establishes residence -- kahpotayute', language: 'disembark and unloads household goods and establishes residence -- kahpotayute'},
               {name: 'dislocate -- coceposkesson', language: 'dislocate -- coceposkesson'},
               {name: 'dislocate from sudden impact -- coceposkehtehsin', language: 'dislocate from sudden impact -- coceposkehtehsin'},
               {name: 'dissent -- piluwiwolamsotom', language: 'dissent -- piluwiwolamsotom'},
               {name: 'dive -- komoku', language: 'dive -- komoku'},
               {name: 'dive -- komoqotkuhu', language: 'dive -- komoqotkuhu'},
               {name: 'dive into the water with heels disappearing last -- cuwahpitepoqonessu', language: 'dive into the water with heels disappearing last -- cuwahpitepoqonessu'},
               {name: 'dive while flying -- pakskalituwiye', language: 'dive while flying -- pakskalituwiye'},
               {name: 'divide -- niktuwicuwon', language: 'divide -- niktuwicuwon'},
               {name: 'doze -- epahsikuhu', language: 'doze -- epahsikuhu'},
               {name: 'doze off -- ckuwakiye', language: 'doze off -- ckuwakiye'},
               {name: 'doze off -- kopskiye', language: 'doze off -- kopskiye'},
               {name: 'drag self along -- pomacqimsu', language: 'drag self along -- pomacqimsu'},
               {name: 'drain -- sinkolacuwon', language: 'drain -- sinkolacuwon'},
               {name: 'drain gradually -- sinkolawse', language: 'drain gradually -- sinkolawse'},
               {name: 'drain out gradually -- sinkolawsewiw', language: 'drain out gradually -- sinkolawsewiw'},
               {name: 'drain out quickly -- sinkolasson', language: 'drain out quickly -- sinkolasson'},
               {name: 'draw easily -- wolahsone', language: 'draw easily -- wolahsone'},
               {name: 'dream -- olqahsu', language: 'dream -- olqahsu'},
               {name: 'dream -- puwihtu', language: 'dream -- puwihtu'},
               {name: 'dribble -- sokopotune', language: 'dribble -- sokopotune'},
               {name: 'drift view -- sakhoqimu', language: 'drift view -- sakhoqimu'},
               {name: 'drink -- lossomu', language: 'drink -- lossomu'},
               {name: 'drink a lot -- mokiyapuwe', language: 'drink a lot -- mokiyapuwe'},
               {name: 'drink alcoholic beverage -- kotuhsomu', language: 'drink alcoholic beverage -- kotuhsomu'},
               {name: 'drink alcoholic beverages often -- wikossomu', language: 'drink alcoholic beverages often -- wikossomu'},
               {name: 'drink around -- yalcokossomu', language: 'drink around -- yalcokossomu'},
               {name: 'drink around -- yalossomu', language: 'drink around -- yalossomu'},
               {name: 'drink around -- yalpekonoma', language: 'drink around -- yalpekonoma'},
               {name: 'drink jigger of liquor -- cilakonehta', language: 'drink jigger of liquor -- cilakonehta'},
               {name: 'drink repeatedly -- iqonossomu', language: 'drink repeatedly -- iqonossomu'},
               {name: 'drink self to death -- siktehsomu', language: 'drink self to death -- siktehsomu'},
               {name: 'drink shot of liquor -- tokomosu', language: 'drink shot of liquor -- tokomosu'},
               {name: 'drink so much that s/he cries -- sehsossomu', language: 'drink so much that s/he cries -- sehsossomu'},
               {name: 'drink something sweet -- mahqanossomu', language: 'drink something sweet -- mahqanossomu'},
               {name: 'drink too much -- mamhunossomu', language: 'drink too much -- mamhunossomu'},
               {name: 'drink too much -- samossomu', language: 'drink too much -- samossomu'},
               {name: 'drip -- sipelpektehson', language: 'drip -- sipelpektehson'},
               {name: 'drip red -- pqehpehe', language: 'drip red -- pqehpehe'},
               {name: 'drip red -- pqekpektehson', language: 'drip red -- pqekpektehson'},
               {name: 'drip repeatedly -- psqopehtehson', language: 'drip repeatedly -- psqopehtehson'},
               {name: 'drive around -- yaloqittu', language: 'drive around -- yaloqittu'},
               {name: 'drive self crazy working -- wiwonasoluhke', language: 'drive self crazy working -- wiwonasoluhke'},
               {name: 'drive taxi -- yalacqiptahsu', language: 'drive taxi -- yalacqiptahsu'},
               {name: 'drizzle -- apsapskolan', language: 'drizzle -- apsapskolan'},
               {name: 'drop anchor -- cuhponapsqe', language: 'drop anchor -- cuhponapsqe'},
               {name: 'drown -- piscope', language: 'drown -- piscope'},
               {name: 'drown -- pucoqossu', language: 'drown -- pucoqossu'},
               {name: 'drown out others w/ voice -- komittaqsu', language: 'drown out others w/ voice -- komittaqsu'},
               {name: 'drum -- kotuhmahsu', language: 'drum -- kotuhmahsu'},
               {name: 'duck hide -- kathucin', language: 'duck hide -- kathucin'},
               {name: 'duck hide -- kathucu', language: 'duck hide -- kathucu'},
               {name: 'duck out of sight -- akuwihtehsin', language: 'duck out of sight -- akuwihtehsin'},
               {name: 'dump it out -- sukayewotun', language: 'dump it out -- sukayewotun'},
               {name: 'dye -- cossike', language: 'dye -- cossike'},
               {name: 'dye -- olocossawiye', language: 'dye -- olocossawiye'},
               {name: 'dye using wrong color -- wapolocossawiye', language: 'dye using wrong color -- wapolocossawiye'},
               {name: 'dye using wrong color -- wapolocossike', language: 'dye using wrong color -- wapolocossike'},
               {name: 'earn -- pqahtu', language: 'earn -- pqahtu'},
               {name: 'eat -- mitsu', language: 'eat -- mitsu'},
               {name: 'eat a lot -- mokehpu', language: 'eat a lot -- mokehpu'},
               {name: 'eat around -- yalihpu', language: 'eat around -- yalihpu'},
               {name: 'eat badly -- mocihpu', language: 'eat badly -- mocihpu'},
               {name: 'eat breakfast -- spasihpu', language: 'eat breakfast -- spasihpu'},
               {name: 'eat continuously -- munsahpu', language: 'eat continuously -- munsahpu'},
               {name: 'eat early -- miyahsihpu', language: 'eat early -- miyahsihpu'},
               {name: 'eat excrement -- mickonke', language: 'eat excrement -- mickonke'},
               {name: 'eat expensive food -- spihpu', language: 'eat expensive food -- spihpu'},
               {name: 'eat fast -- kakawihpu', language: 'eat fast -- kakawihpu'},
               {name: 'eat frequently -- qasqehpu', language: 'eat frequently -- qasqehpu'},
               {name: 'eat greasy -- mimihpu', language: 'eat greasy -- mimihpu'},
               {name: 'eat heavy -- tkiqihpu', language: 'eat heavy -- tkiqihpu'},
               {name: 'eat like pig -- kskonoqe', language: 'eat like pig -- kskonoqe'},
               {name: 'eat like pig -- noskonoqe', language: 'eat like pig -- noskonoqe'},
               {name: 'eat lunch -- lancuwiw', language: 'eat lunch -- lancuwiw'},
               {name: 'eat many different foods -- milihpu', language: 'eat many different foods -- milihpu'},
               {name: 'eat meat -- wiyuhsihpu', language: 'eat meat -- wiyuhsihpu'},
               {name: 'eat messily -- tolcokihpu', language: 'eat messily -- tolcokihpu'},
               {name: 'eat more -- aqamihpu', language: 'eat more -- aqamihpu'},
               {name: 'eat people -- pomawsuwinuwihpu', language: 'eat people -- pomawsuwinuwihpu'},
               {name: 'eat repeatedly between meals -- iqonihpu', language: 'eat repeatedly between meals -- iqonihpu'},
               {name: 'eat so much s/he feels as if s/he is bursting -- paskihpu', language: 'eat so much s/he feels as if s/he is bursting -- paskihpu'},
               {name: 'eat supper -- wolaqihpu', language: 'eat supper -- wolaqihpu'},
               {name: 'eat sweet food -- mahqanihpu', language: 'eat sweet food -- mahqanihpu'},
               {name: 'eat thus -- lihpu', language: 'eat thus -- lihpu'},
               {name: 'eat to heart\'s content -- memhuwihpu', language: 'eat to heart\'s content -- memhuwihpu'},
               {name: 'eat too much -- samihpu', language: 'eat too much -- samihpu'},
               {name: 'eat unripe apples -- cikonewihpu', language: 'eat unripe apples -- cikonewihpu'},
               {name: 'eat until full -- psonihpu', language: 'eat until full -- psonihpu'},
               {name: 'eat very little -- wahkacihpu', language: 'eat very little -- wahkacihpu'},
               {name: 'eat well -- wolihpu', language: 'eat well -- wolihpu'},
               {name: 'eat what s/he has available -- mamatuwihpu', language: 'eat what s/he has available -- mamatuwihpu'},
               {name: 'eat with fingers -- nimcokonike', language: 'eat with fingers -- nimcokonike'},
               {name: 'eat with others -- wicihpu', language: 'eat with others -- wicihpu'},
               {name: 'eavesdrop -- komitalokossewu', language: 'eavesdrop -- komitalokossewu'},
               {name: 'eavesdrop -- komutsotom', language: 'eavesdrop -- komutsotom'},
               {name: 'effervesce -- pukteske', language: 'effervesce -- pukteske'},
               {name: 'embrocate -- sunpekonosu', language: 'embrocate -- sunpekonosu'},
               {name: 'embroider using yarn -- amalhatokiqahsu', language: 'embroider using yarn -- amalhatokiqahsu'},
               {name: 'emigrate -- maciyute', language: 'emigrate -- maciyute'},
               {name: 'end -- eqtokot', language: 'end -- eqtokot'},
               {name: 'end there -- mette', language: 'end there -- mette'},
               {name: 'endure -- cikawiyusu', language: 'endure -- cikawiyusu'},
               {name: 'enter -- ksentu', language: 'enter -- ksentu'},
               {name: 'enter by jumping in -- ksetkuhu', language: 'enter by jumping in -- ksetkuhu'},
               {name: 'enter by jumping in -- ksetokku', language: 'enter by jumping in -- ksetokku'},
               {name: 'entertain -- wiqhopaluwe', language: 'entertain -- wiqhopaluwe'},
               {name: 'enthrall -- sekpayacomu', language: 'enthrall -- sekpayacomu'},
               {name: 'exaggerate own need -- aqamhutike', language: 'exaggerate own need -- aqamhutike'},
               {name: 'experience something through eyes -- lapiqehe', language: 'experience something through eyes -- lapiqehe'},
               {name: 'explain precisely -- papehtokehkikemu', language: 'explain precisely -- papehtokehkikemu'},
               {name: 'extend -- pomaqte', language: 'extend -- pomaqte'},
               {name: 'extend -- pomossin', language: 'extend -- pomossin'},
               {name: 'extend as sun changes position -- akuwahte', language: 'extend as sun changes position -- akuwahte'},
               {name: 'extend away -- olomahseke', language: 'extend away -- olomahseke'},
               {name: 'extend away -- olomakome', language: 'extend away -- olomakome'},
               {name: 'extend away -- olomapskihke', language: 'extend away -- olomapskihke'},
               {name: 'extend away -- olomaqte', language: 'extend away -- olomaqte'},
               {name: 'extend away -- olomotone', language: 'extend away -- olomotone'},
               {name: 'extend beyond point it needed to reach -- siyepekte', language: 'extend beyond point it needed to reach -- siyepekte'},
               {name: 'extend from there -- macepu', language: 'extend from there -- macepu'},
               {name: 'extend here -- ckuwhute', language: 'extend here -- ckuwhute'},
               {name: 'extend in straight line -- sasokatokahte', language: 'extend in straight line -- sasokatokahte'},
               {name: 'extend in straight line -- sasokatokopu', language: 'extend in straight line -- sasokatokopu'},
               {name: 'extend into view -- sakhatokopu', language: 'extend into view -- sakhatokopu'},
               {name: 'extend out into the water -- mataweyu', language: 'extend out into the water -- mataweyu'},
               {name: 'extend out into the water -- milawapskahte', language: 'extend out into the water -- milawapskahte'},
               {name: 'extend past -- tutaqte', language: 'extend past -- tutaqte'},
               {name: 'extend stay -- ksacu', language: 'extend stay -- ksacu'},
               {name: 'extend thus -- oltokopu', language: 'extend thus -- oltokopu'},
               {name: 'extend thus -- oltokte', language: 'extend thus -- oltokte'},
               {name: 'extend to there -- petopu', language: 'extend to there -- petopu'},
               {name: 'extend to there -- pette', language: 'extend to there -- pette'},
               {name: 'extend upward -- spiqamkahte', language: 'extend upward -- spiqamkahte'},
               {name: 'extend visit -- ksacessu', language: 'extend visit -- ksacessu'},
               {name: 'fade -- kahsaqote', language: 'fade -- kahsaqote'},
               {name: 'fade gradually -- kahsuhsewiw', language: 'fade gradually -- kahsuhsewiw'},
               {name: 'fail to get married -- aluwahkatom', language: 'fail to get married -- aluwahkatom'},
               {name: 'fail to ignite -- aluwahkihtehson', language: 'fail to ignite -- aluwahkihtehson'},
               {name: 'fail to occur -- aluwesson', language: 'fail to occur -- aluwesson'},
               {name: 'fail to start -- aluwiyewiw', language: 'fail to start -- aluwiyewiw'},
               {name: 'fail to stay awake -- aluwispasu', language: 'fail to stay awake -- aluwispasu'},
               {name: 'faint -- wihqiye', language: 'faint -- wihqiye'},
               {name: 'fall and hits bottom -- psahkonahtehsin', language: 'fall and hits bottom -- psahkonahtehsin'},
               {name: 'fall and hits bottom -- psahkonahtehsu', language: 'fall and hits bottom -- psahkonahtehsu'},
               {name: 'fall and hits ground -- psahkonahtehson', language: 'fall and hits ground -- psahkonahtehson'},
               {name: 'fall and hits heavily -- tkiqtehsin', language: 'fall and hits heavily -- tkiqtehsin'},
               {name: 'fall and hits heavily -- tkiqtehson', language: 'fall and hits heavily -- tkiqtehson'},
               {name: 'fall and hurts self -- poneqtehsin', language: 'fall and hurts self -- poneqtehsin'},
               {name: 'fall and is destroyed -- sesomiponeqiyewiw', language: 'fall and is destroyed -- sesomiponeqiyewiw'},
               {name: 'fall and lands -- pokossu', language: 'fall and lands -- pokossu'},
               {name: 'fall and lands stiffly -- citonaqtehsin', language: 'fall and lands stiffly -- citonaqtehsin'},
               {name: 'fall covering surface -- skittehsin', language: 'fall covering surface -- skittehsin'},
               {name: 'fall farther and farther back in line -- nawkusu', language: 'fall farther and farther back in line -- nawkusu'},
               {name: 'fall flat on back -- sipelektehsin', language: 'fall flat on back -- sipelektehsin'},
               {name: 'fall forward -- cituwiye', language: 'fall forward -- cituwiye'},
               {name: 'fall forward -- nihkantehsin', language: 'fall forward -- nihkantehsin'},
               {name: 'fall forward and lands -- nihkantehson', language: 'fall forward and lands -- nihkantehson'},
               {name: 'fall just right -- wolihtehsin', language: 'fall just right -- wolihtehsin'},
               {name: 'fall often -- wikamu', language: 'fall often -- wikamu'},
               {name: 'fall on knees -- petkuptehsin', language: 'fall on knees -- petkuptehsin'},
               {name: 'fall so hard -- tuttehsin', language: 'fall so hard -- tuttehsin'},
               {name: 'fall straight down and lands -- nahkahtehsin', language: 'fall straight down and lands -- nahkahtehsin'},
               {name: 'fall straight down and lands -- nahkahtehson', language: 'fall straight down and lands -- nahkahtehson'},
               {name: 'fall thickly -- maspihtehsin', language: 'fall thickly -- maspihtehsin'},
               {name: 'fall through and lands -- saputehtehsin', language: 'fall through and lands -- saputehtehsin'},
               {name: 'fall with smacking sound -- motecokihtehsin', language: 'fall with smacking sound -- motecokihtehsin'},
               {name: 'fart as s/he coughs -- pikcineqhom', language: 'fart as s/he coughs -- pikcineqhom'},
               {name: 'fart as s/he lifts something heavy -- pikcahsonu', language: 'fart as s/he lifts something heavy -- pikcahsonu'},
               {name: 'fart loudly -- memhuwipiktu', language: 'fart loudly -- memhuwipiktu'},
               {name: 'feed self -- mitsuhutolosu', language: 'feed self -- mitsuhutolosu'},
               {name: 'feel alone -- qotuhkatahasu', language: 'feel alone -- qotuhkatahasu'},
               {name: 'feel changes better -- atomolsu', language: 'feel changes better -- atomolsu'},
               {name: 'feel cold to the touch -- tkomomqot', language: 'feel cold to the touch -- tkomomqot'},
               {name: 'feel content -- sankewomolsu', language: 'feel content -- sankewomolsu'},
               {name: 'feel good -- wolomolsu', language: 'feel good -- wolomolsu'},
               {name: 'feel good to the touch -- wolomomqosu', language: 'feel good to the touch -- wolomomqosu'},
               {name: 'feel good to the touch -- wolomomqot', language: 'feel good to the touch -- wolomomqot'},
               {name: 'feel happy -- miyawomolsu', language: 'feel happy -- miyawomolsu'},
               {name: 'feel heavy -- tkiqomomqot', language: 'feel heavy -- tkiqomomqot'},
               {name: 'feel inclined in that direction -- oloqomolsin', language: 'feel inclined in that direction -- oloqomolsin'},
               {name: 'feel invincible -- kinomolsu', language: 'feel invincible -- kinomolsu'},
               {name: 'feel poorly -- ktomakomolsu', language: 'feel poorly -- ktomakomolsu'},
               {name: 'feel prickly -- kawhuwemikot', language: 'feel prickly -- kawhuwemikot'},
               {name: 'feel rough to the touch -- kawomomqosu', language: 'feel rough to the touch -- kawomomqosu'},
               {name: 'feel rough to the touch -- kawomomqot', language: 'feel rough to the touch -- kawomomqot'},
               {name: 'feel shy -- tqesuwomolsu', language: 'feel shy -- tqesuwomolsu'},
               {name: 'feel so much -- tutomolsu', language: 'feel so much -- tutomolsu'},
               {name: 'feel thus -- olomolsu', language: 'feel thus -- olomolsu'},
               {name: 'feel thus -- olomomqot', language: 'feel thus -- olomomqot'},
               {name: 'feel thus to the touch -- olomomqosu', language: 'feel thus to the touch -- olomomqosu'},
               {name: 'feel uncomfortable -- nikiwomolsu', language: 'feel uncomfortable -- nikiwomolsu'},
               {name: 'fell trees -- kipiksawe', language: 'fell trees -- kipiksawe'},
               {name: 'fell trees -- kuwhahsu', language: 'fell trees -- kuwhahsu'},
               {name: 'fib -- pakotahsu', language: 'fib -- pakotahsu'},
               {name: 'fight around -- yaluwalke', language: 'fight around -- yaluwalke'},
               {name: 'fight back -- asitehtem', language: 'fight back -- asitehtem'},
               {name: 'fight back -- luta', language: 'fight back -- luta'},
               {name: 'fight fiercely -- kakawwalke', language: 'fight fiercely -- kakawwalke'},
               {name: 'fight repeatedly -- ahtolihponosu', language: 'fight repeatedly -- ahtolihponosu'},
               {name: 'fight thus -- oluwalke', language: 'fight thus -- oluwalke'},
               {name: 'fight using head -- woniyakonihponosu', language: 'fight using head -- woniyakonihponosu'},
               {name: 'fight with head down -- cituwamqehponosu', language: 'fight with head down -- cituwamqehponosu'},
               {name: 'fight with self -- matonosu', language: 'fight with self -- matonosu'},
               {name: 'file -- kittahsu', language: 'file -- kittahsu'},
               {name: 'fill -- psonaqhe', language: 'fill -- psonaqhe'},
               {name: 'fill a container -- tuwahtahsu', language: 'fill a container -- tuwahtahsu'},
               {name: 'fill gradually with water -- tuhpewse', language: 'fill gradually with water -- tuhpewse'},
               {name: 'fill own pipe -- pitsonosu', language: 'fill own pipe -- pitsonosu'},
               {name: 'fill pipe -- pitsone', language: 'fill pipe -- pitsone'},
               {name: 'fill pipe often -- sawepitsone', language: 'fill pipe often -- sawepitsone'},
               {name: 'fill up -- psonesson', language: 'fill up -- psonesson'},
               {name: 'fill up gradually with liquid -- psonpewse', language: 'fill up gradually with liquid -- psonpewse'},
               {name: 'fill up gradually with liquid -- psonpewsewiw', language: 'fill up gradually with liquid -- psonpewsewiw'},
               {name: 'filter through -- ktansecuwon', language: 'filter through -- ktansecuwon'},
               {name: 'find out -- wewisu', language: 'find out -- wewisu'},
               {name: 'fire gun accidentally when finger slips while cocking trigger -- malomahkonoma', language: 'fire gun accidentally when finger slips while cocking trigger -- malomahkonoma'},
               {name: 'fit in place -- memopu', language: 'fit in place -- memopu'},
               {name: 'fit in place -- memte', language: 'fit in place -- memte'},
               {name: 'fit snugly -- skikte', language: 'fit snugly -- skikte'},
               {name: 'fit through -- memiye', language: 'fit through -- memiye'},
               {name: 'fit tightly -- sonahte', language: 'fit tightly -- sonahte'},
               {name: 'fit tightly -- sonte', language: 'fit tightly -- sonte'},
               {name: 'flare -- cipokahtuwe', language: 'flare -- cipokahtuwe'},
               {name: 'flare quickly -- cipokahtuwessu', language: 'flare quickly -- cipokahtuwessu'},
               {name: 'flare up -- sakhamkole', language: 'flare up -- sakhamkole'},
               {name: 'flare up suddenly -- muskuwatte', language: 'flare up suddenly -- muskuwatte'},
               {name: 'flatten out as it dries -- tetpekuhsewiw', language: 'flatten out as it dries -- tetpekuhsewiw'},
               {name: 'flatten out from being rained on -- sokotecokapawe', language: 'flatten out from being rained on -- sokotecokapawe'},
               {name: 'flee across -- ksokaphuwe', language: 'flee across -- ksokaphuwe'},
               {name: 'flee by going into hiding -- ksaciphuwe', language: 'flee by going into hiding -- ksaciphuwe'},
               {name: 'flee from place to place -- yaliphuwe', language: 'flee from place to place -- yaliphuwe'},
               {name: 'flee to here -- peciphuwe', language: 'flee to here -- peciphuwe'},
               {name: 'flee to there -- limacephuwe', language: 'flee to there -- limacephuwe'},
               {name: 'flee to there -- liphuwe', language: 'flee to there -- liphuwe'},
               {name: 'flicker -- wapikcehpute', language: 'flicker -- wapikcehpute'},
               {name: 'flip over and lands upside-down -- ktoqihtehsin', language: 'flip over and lands upside-down -- ktoqihtehsin'},
               {name: 'flip over backward -- apuckolessu', language: 'flip over backward -- apuckolessu'},
               {name: 'flip over easily -- nenehson', language: 'flip over easily -- nenehson'},
               {name: 'float high -- spoqohon', language: 'float high -- spoqohon'},
               {name: 'float up -- ewepoqahtuhukewiw', language: 'float up -- ewepoqahtuhukewiw'},
               {name: 'flood -- kompe', language: 'flood -- kompe'},
               {name: 'flood quickly -- kompessu', language: 'flood quickly -- kompessu'},
               {name: 'floorboard are clean -- peksoke', language: 'floorboard are clean -- peksoke'},
               {name: 'flop in relaxed position -- mawaqopu', language: 'flop in relaxed position -- mawaqopu'},
               {name: 'flow along -- pomicuwon', language: 'flow along -- pomicuwon'},
               {name: 'flow around -- wiwonicuwon', language: 'flow around -- wiwonicuwon'},
               {name: 'flow around something -- amonicuwon', language: 'flow around something -- amonicuwon'},
               {name: 'flow back -- wesuwecuwon', language: 'flow back -- wesuwecuwon'},
               {name: 'flow back reversing falls -- sehtacuwon', language: 'flow back reversing falls -- sehtacuwon'},
               {name: 'flow below -- emehkecuwon', language: 'flow below -- emehkecuwon'},
               {name: 'flow by -- tucicuwon', language: 'flow by -- tucicuwon'},
               {name: 'flow down -- poneqicuwon', language: 'flow down -- poneqicuwon'},
               {name: 'flow downhill -- kcitahkecuwon', language: 'flow downhill -- kcitahkecuwon'},
               {name: 'flow everywhere -- sissecuwon', language: 'flow everywhere -- sissecuwon'},
               {name: 'flow fast -- kakawicuwon', language: 'flow fast -- kakawicuwon'},
               {name: 'flow in -- pistoqehe', language: 'flow in -- pistoqehe'},
               {name: 'flow in four directions stream dividing into four branches) -- newicuwon', language: 'flow in four directions stream dividing into four branches) -- newicuwon'},
               {name: 'flow in opposite direction -- qolopicuwon', language: 'flow in opposite direction -- qolopicuwon'},
               {name: 'flow in that direction -- oloqicuwon', language: 'flow in that direction -- oloqicuwon'},
               {name: 'flow many different ways over rocks -- milapskicuwon', language: 'flow many different ways over rocks -- milapskicuwon'},
               {name: 'flow nicely -- wolatokot', language: 'flow nicely -- wolatokot'},
               {name: 'flow out -- nutecuwon', language: 'flow out -- nutecuwon'},
               {name: 'flow out over grass -- matawaskiye', language: 'flow out over grass -- matawaskiye'},
               {name: 'flow out over gravel -- matawamkiye', language: 'flow out over gravel -- matawamkiye'},
               {name: 'flow over -- pasicicuwon', language: 'flow over -- pasicicuwon'},
               {name: 'flow over -- skituwicuwon', language: 'flow over -- skituwicuwon'},
               {name: 'flow rapidly -- ksicuwon', language: 'flow rapidly -- ksicuwon'},
               {name: 'flow roughly -- sikicuwon', language: 'flow roughly -- sikicuwon'},
               {name: 'flow through -- sapicuwon', language: 'flow through -- sapicuwon'},
               {name: 'flow together -- astuwicuwon', language: 'flow together -- astuwicuwon'},
               {name: 'flow unfavorably -- mocicuwon', language: 'flow unfavorably -- mocicuwon'},
               {name: 'flow well -- wolicuwon', language: 'flow well -- wolicuwon'},
               {name: 'flutter -- matekipite', language: 'flutter -- matekipite'},
               {name: 'fold clothes well -- wolekonike', language: 'fold clothes well -- wolekonike'},
               {name: 'follow and develops from a less severe illness -- napessu', language: 'follow and develops from a less severe illness -- napessu'},
               {name: 'follow and forth -- esuwiphoqe', language: 'follow and forth -- esuwiphoqe'},
               {name: 'follow around -- yaliphoqatom', language: 'follow around -- yaliphoqatom'},
               {name: 'follow behind -- enuhkawe', language: 'follow behind -- enuhkawe'},
               {name: 'fool around -- milehtake', language: 'fool around -- milehtake'},
               {name: 'force self to pray -- cikawimiye', language: 'force self to pray -- cikawimiye'},
               {name: 'force way in -- cikawiksehe', language: 'force way in -- cikawiksehe'},
               {name: 'force way in -- cikawipithusu', language: 'force way in -- cikawipithusu'},
               {name: 'force way out -- cikawinutehe', language: 'force way out -- cikawinutehe'},
               {name: 'force way through -- cikawessu', language: 'force way through -- cikawessu'},
               {name: 'forecast the weather -- kisqe', language: 'forecast the weather -- kisqe'},
               {name: 'forget what s/he is talking about -- wontemsu', language: 'forget what s/he is talking about -- wontemsu'},
               {name: 'fork -- niktuwikon', language: 'fork -- niktuwikon'},
               {name: 'fork -- niktuwiku', language: 'fork -- niktuwiku'},
               {name: 'freeze along edge -- sopacu', language: 'freeze along edge -- sopacu'},
               {name: 'freeze along edge -- sopaton', language: 'freeze along edge -- sopaton'},
               {name: 'freeze along shore -- sonutpekocu', language: 'freeze along shore -- sonutpekocu'},
               {name: 'freeze forming crust on snow -- siqhewiw', language: 'freeze forming crust on snow -- siqhewiw'},
               {name: 'freeze hard -- sakolocu', language: 'freeze hard -- sakolocu'},
               {name: 'frown -- mociqewu', language: 'frown -- mociqewu'},
               {name: 'fumble -- polapskonike', language: 'fumble -- polapskonike'},
               {name: 'gain weight -- macekamu', language: 'gain weight -- macekamu'},
               {name: 'gamble -- kemopoluwiw', language: 'gamble -- kemopoluwiw'},
               {name: 'gasp for air -- aluwinehse', language: 'gasp for air -- aluwinehse'},
               {name: 'gasp for air -- aluwolatom', language: 'gasp for air -- aluwolatom'},
               {name: 'gasp for air -- kpolatom', language: 'gasp for air -- kpolatom'},
               {name: 'gather -- maqehke', language: 'gather -- maqehke'},
               {name: 'gather balsam fir resin -- puhpukhawiqqe', language: 'gather balsam fir resin -- puhpukhawiqqe'},
               {name: 'gather crop -- pkonahsu', language: 'gather crop -- pkonahsu'},
               {name: 'gather driftwood -- okonse', language: 'gather driftwood -- okonse'},
               {name: 'gather driftwood -- qahnuskuhke', language: 'gather driftwood -- qahnuskuhke'},
               {name: 'gather driftwood toward here -- ckuhkonse', language: 'gather driftwood toward here -- ckuhkonse'},
               {name: 'gather driftwood up to there -- petkonse', language: 'gather driftwood up to there -- petkonse'},
               {name: 'gather driftwood while moving away from here -- olomokonse', language: 'gather driftwood while moving away from here -- olomokonse'},
               {name: 'gather firewood -- piwsokuhke', language: 'gather firewood -- piwsokuhke'},
               {name: 'gather for some worthy cause -- maqenike', language: 'gather for some worthy cause -- maqenike'},
               {name: 'gather up and arranges -- maqayewotahsu', language: 'gather up and arranges -- maqayewotahsu'},
               {name: 'gather wood chips -- piyaqtihikonihke', language: 'gather wood chips -- piyaqtihikonihke'},
               {name: 'get groceries from there -- tonike', language: 'get groceries from there -- tonike'},
               {name: 'get things for self -- kolonomuwewsu', language: 'get things for self -- kolonomuwewsu'},
               {name: 'give self medicine -- pilsuhusu', language: 'give self medicine -- pilsuhusu'},
               {name: 'give to self -- milsu', language: 'give to self -- milsu'},
               {name: 'give too little -- nuhtoqamiluwe', language: 'give too little -- nuhtoqamiluwe'},
               {name: 'glance all around -- psonapessu', language: 'glance all around -- psonapessu'},
               {name: 'glance around -- yalapessu', language: 'glance around -- yalapessu'},
               {name: 'glance around looking -- kiluwapessu', language: 'glance around looking -- kiluwapessu'},
               {name: 'glance back -- apolapessu', language: 'glance back -- apolapessu'},
               {name: 'glance in -- ksiyapessu', language: 'glance in -- ksiyapessu'},
               {name: 'glance out -- nutiyapessu', language: 'glance out -- nutiyapessu'},
               {name: 'glance out quickly -- sakhapehpusu', language: 'glance out quickly -- sakhapehpusu'},
               {name: 'glance over toward here -- ckuwapessu', language: 'glance over toward here -- ckuwapessu'},
               {name: 'glance quickly -- sahsapu', language: 'glance quickly -- sahsapu'},
               {name: 'glance quickly and repeatedly -- sahsapehpusu', language: 'glance quickly and repeatedly -- sahsapehpusu'},
               {name: 'glance underneath -- neqapessu', language: 'glance underneath -- neqapessu'},
               {name: 'glance up -- spiqapessu', language: 'glance up -- spiqapessu'},
               {name: 'glare -- wisokahtuwe', language: 'glare -- wisokahtuwe'},
               {name: 'glide -- suwwake', language: 'glide -- suwwake'},
               {name: 'glide around -- yalaku', language: 'glide around -- yalaku'},
               {name: 'glide downhill -- motapetomehewiw', language: 'glide downhill -- motapetomehewiw'},
               {name: 'glimmer -- apsahtuwesson', language: 'glimmer -- apsahtuwesson'},
               {name: 'glimmer faintly -- malahtuwesson', language: 'glimmer faintly -- malahtuwesson'},
               {name: 'glitter -- possaqhehpusu', language: 'glitter -- possaqhehpusu'},
               {name: 'glitter -- wisokahtuwesson', language: 'glitter -- wisokahtuwesson'},
               {name: 'gossip a lot -- tpolukemosku', language: 'gossip a lot -- tpolukemosku'},
               {name: 'govern self -- sakomawamsu', language: 'govern self -- sakomawamsu'},
               {name: 'govern unjustly -- wapolisakomawiw', language: 'govern unjustly -- wapolisakomawiw'},
               {name: 'grab on -- kolapocessu', language: 'grab on -- kolapocessu'},
               {name: 'grab things and takes them -- keciptahsu', language: 'grab things and takes them -- keciptahsu'},
               {name: 'grease -- mimhike', language: 'grease -- mimhike'},
               {name: 'grind using mortar and pestle -- toqihike', language: 'grind using mortar and pestle -- toqihike'},
               {name: 'grope around finding way -- yalihkonike', language: 'grope around finding way -- yalihkonike'},
               {name: 'grope way along -- natunike', language: 'grope way along -- natunike'},
               {name: 'grow -- ceconikon', language: 'grow -- ceconikon'},
               {name: 'grow differently -- piluwikon', language: 'grow differently -- piluwikon'},
               {name: 'grow fast -- nokosakon', language: 'grow fast -- nokosakon'},
               {name: 'grow fast -- nokosaku', language: 'grow fast -- nokosaku'},
               {name: 'grow here and there -- yalikon', language: 'grow here and there -- yalikon'},
               {name: 'grow onto something -- sicikon', language: 'grow onto something -- sicikon'},
               {name: 'grow out -- sakhikon', language: 'grow out -- sakhikon'},
               {name: 'grow slowly -- nakaku', language: 'grow slowly -- nakaku'},
               {name: 'grow too much -- samikon', language: 'grow too much -- samikon'},
               {name: 'grow up -- maceku', language: 'grow up -- maceku'},
               {name: 'grow younger -- sehtakotone', language: 'grow younger -- sehtakotone'},
               {name: 'growl -- nikimu', language: 'growl -- nikimu'},
               {name: 'guide -- nuthutahsu', language: 'guide -- nuthutahsu'},
               {name: 'hail from there -- toqapeksu', language: 'hail from there -- toqapeksu'},
               {name: 'hallucinate from having drunk too much alcohol -- harissuwine', language: 'hallucinate from having drunk too much alcohol -- harissuwine'},
               {name: 'hang -- ekhucin', language: 'hang -- ekhucin'},
               {name: 'hang -- ekhucu', language: 'hang -- ekhucu'},
               {name: 'hang around -- yalekhucin', language: 'hang around -- yalekhucin'},
               {name: 'hang around -- yalekhucu', language: 'hang around -- yalekhucu'},
               {name: 'hang around -- yalhucin', language: 'hang around -- yalhucin'},
               {name: 'hang around -- yalhucu', language: 'hang around -- yalhucu'},
               {name: 'hang around -- yaliewehe', language: 'hang around -- yaliewehe'},
               {name: 'hang clothes everywhere to dry -- psonekhucuwole', language: 'hang clothes everywhere to dry -- psonekhucuwole'},
               {name: 'hang clothes over something -- skituwekhucewotahsu', language: 'hang clothes over something -- skituwekhucewotahsu'},
               {name: 'hang clothes over something to dry -- ekhucewotahsu', language: 'hang clothes over something to dry -- ekhucewotahsu'},
               {name: 'hang clothes to dry -- ekhucuwole', language: 'hang clothes to dry -- ekhucuwole'},
               {name: 'hang clothes to dry over things every which way and without clothespins -- sahkossuwiekhucuwole', language: 'hang clothes to dry over things every which way and without clothespins -- sahkossuwiekhucuwole'},
               {name: 'hang crooked -- asuwekhute', language: 'hang crooked -- asuwekhute'},
               {name: 'hang on something -- napitekhute', language: 'hang on something -- napitekhute'},
               {name: 'hang out -- nutiyatokopu', language: 'hang out -- nutiyatokopu'},
               {name: 'hang over -- skituwekhute', language: 'hang over -- skituwekhute'},
               {name: 'hang parallel -- tetpatokuhucin', language: 'hang parallel -- tetpatokuhucin'},
               {name: 'hang parallel -- tetpatokuhute', language: 'hang parallel -- tetpatokuhute'},
               {name: 'hang solidly close -- sicinapicikolte', language: 'hang solidly close -- sicinapicikolte'},
               {name: 'hang tough -- sipekhucin', language: 'hang tough -- sipekhucin'},
               {name: 'hang upside-down -- cituwekhucin', language: 'hang upside-down -- cituwekhucin'},
               {name: 'hang upside-down -- cituwekhucu', language: 'hang upside-down -- cituwekhucu'},
               {name: 'hang upside-down -- cituwekhute', language: 'hang upside-down -- cituwekhute'},
               {name: 'happen -- wolitpiye', language: 'happen -- wolitpiye'},
               {name: 'happen by chance -- ahtopileyu', language: 'happen by chance -- ahtopileyu'},
               {name: 'happen concurrently -- tetpitpiye', language: 'happen concurrently -- tetpitpiye'},
               {name: 'happen in the morning -- spasonukot', language: 'happen in the morning -- spasonukot'},
               {name: 'happen in various ways -- milessu', language: 'happen in various ways -- milessu'},
               {name: 'happen instantly -- sesolahkinaqot', language: 'happen instantly -- sesolahkinaqot'},
               {name: 'happen separately -- coceponukot', language: 'happen separately -- coceponukot'},
               {name: 'happen suddenly -- sesolahkitpiye', language: 'happen suddenly -- sesolahkitpiye'},
               {name: 'happen thus -- litpiye', language: 'happen thus -- litpiye'},
               {name: 'happen thus -- litpot', language: 'happen thus -- litpot'},
               {name: 'happen thus -- olonukot', language: 'happen thus -- olonukot'},
               {name: 'harrow -- nukkesawe', language: 'harrow -- nukkesawe'},
               {name: 'harvest -- pkonahsuwiw', language: 'harvest -- pkonahsuwiw'},
               {name: 'harvest ash trees -- wikpihke', language: 'harvest ash trees -- wikpihke'},
               {name: 'harvest by shaking plant -- puwahqonike', language: 'harvest by shaking plant -- puwahqonike'},
               {name: 'harvest plants -- pisunke', language: 'harvest plants -- pisunke'},
               {name: 'hate self -- musqitahamsu', language: 'hate self -- musqitahamsu'},
               {name: 'haul in -- wihqoname', language: 'haul in -- wihqoname'},
               {name: 'hay -- pskihqehke', language: 'hay -- pskihqehke'},
               {name: 'head in that direction to get something -- oloqicuwihtu', language: 'head in that direction to get something -- oloqicuwihtu'},
               {name: 'heal -- kikehtahsu', language: 'heal -- kikehtahsu'},
               {name: 'heal -- kikehtahsuwiw', language: 'heal -- kikehtahsuwiw'},
               {name: 'heal -- nickisu', language: 'heal -- nickisu'},
               {name: 'heal -- nickiw', language: 'heal -- nickiw'},
               {name: 'heal onto something -- sicinickisu', language: 'heal onto something -- sicinickisu'},
               {name: 'hear -- nutom', language: 'hear -- nutom'},
               {name: 'hear -- nutoma', language: 'hear -- nutoma'},
               {name: 'hear self -- nutasu', language: 'hear self -- nutasu'},
               {name: 'hear wrong -- polsotom', language: 'hear wrong -- polsotom'},
               {name: 'heat water -- ksopestahqe', language: 'heat water -- ksopestahqe'},
               {name: 'hedge bets -- ecuwiw', language: 'hedge bets -- ecuwiw'},
               {name: 'heel of h/ shoes are heard when s/he walks -- moteqonehtehsin', language: 'heel of h/ shoes are heard when s/he walks -- moteqonehtehsin'},
               {name: 'help out -- wicuhkekemu', language: 'help out -- wicuhkekemu'},
               {name: 'help out because s/he is sympathetic s/he helps the poor -- ktomakelke', language: 'help out because s/he is sympathetic s/he helps the poor -- ktomakelke'},
               {name: 'help self -- wicuhkemsu', language: 'help self -- wicuhkemsu'},
               {name: 'hemorrhage through nose and mouth -- cehtu', language: 'hemorrhage through nose and mouth -- cehtu'},
               {name: 'hesitate out of shyness -- ehetsu', language: 'hesitate out of shyness -- ehetsu'},
               {name: 'hiccup -- kehtepsulu', language: 'hiccup -- kehtepsulu'},
               {name: 'hide -- kalsu', language: 'hide -- kalsu'},
               {name: 'hide anger -- kaciluwehe', language: 'hide anger -- kaciluwehe'},
               {name: 'hide behind something -- akuwitutom', language: 'hide behind something -- akuwitutom'},
               {name: 'hide behind something -- kacitutom', language: 'hide behind something -- kacitutom'},
               {name: 'hide things -- katsu', language: 'hide things -- katsu'},
               {name: 'hill earth around stalks of plants -- psotaqhike', language: 'hill earth around stalks of plants -- psotaqhike'},
               {name: 'hit against something -- pkiktehson', language: 'hit against something -- pkiktehson'},
               {name: 'hit lightly -- cocihtehson', language: 'hit lightly -- cocihtehson'},
               {name: 'hit something and stops completely -- mecimtehson', language: 'hit something and stops completely -- mecimtehson'},
               {name: 'hit something soft and stops completely -- mecimcoktehson', language: 'hit something soft and stops completely -- mecimcoktehson'},
               {name: 'hit the shore -- nahtokahtehsin', language: 'hit the shore -- nahtokahtehsin'},
               {name: 'hit thus -- lihtehson', language: 'hit thus -- lihtehson'},
               {name: 'hog -- aqamhutike', language: 'hog -- aqamhutike'},
               {name: 'hog food -- kolike', language: 'hog food -- kolike'},
               {name: 'hold a lot -- mokeposkasu', language: 'hold a lot -- mokeposkasu'},
               {name: 'hold a lot -- mokeposkat', language: 'hold a lot -- mokeposkat'},
               {name: 'hold enough -- tepiposkasu', language: 'hold enough -- tepiposkasu'},
               {name: 'hold enough -- tepiposkat', language: 'hold enough -- tepiposkat'},
               {name: 'hold liquor -- sipossomu', language: 'hold liquor -- sipossomu'},
               {name: 'hold on to something and is dragged along -- pomacqiye', language: 'hold on to something and is dragged along -- pomacqiye'},
               {name: 'hold on using hands -- kolapocu', language: 'hold on using hands -- kolapocu'},
               {name: 'hold self back with arm(s) -- cecononsu', language: 'hold self back with arm(s) -- cecononsu'},
               {name: 'hold so much -- poskasu', language: 'hold so much -- poskasu'},
               {name: 'hold so much -- poskat', language: 'hold so much -- poskat'},
               {name: 'hold something with teeth -- kolotamu', language: 'hold something with teeth -- kolotamu'},
               {name: 'holler loud and long -- sipkihtaqsu', language: 'holler loud and long -- sipkihtaqsu'},
               {name: 'holler out -- nutekakaluwe', language: 'holler out -- nutekakaluwe'},
               {name: 'hook fish -- ptahma', language: 'hook fish -- ptahma'},
               {name: 'hook fish -- ptihike', language: 'hook fish -- ptihike'},
               {name: 'hook fish -- ptohom', language: 'hook fish -- ptohom'},
               {name: 'hook onto -- napittehson', language: 'hook onto -- napittehson'},
               {name: 'hook onto s/he is ringer -- napittehsin', language: 'hook onto s/he is ringer -- napittehsin'},
               {name: 'hop around on one foot -- epolekathom', language: 'hop around on one foot -- epolekathom'},
               {name: 'hop sideways -- nomocintestike', language: 'hop sideways -- nomocintestike'},
               {name: 'howl -- kiwattaqsu', language: 'howl -- kiwattaqsu'},
               {name: 'howl loudly -- cipokewehtaqsu', language: 'howl loudly -- cipokewehtaqsu'},
               {name: 'huddle up -- mawonosu', language: 'huddle up -- mawonosu'},
               {name: 'hunt -- cihkonaqcuhke', language: 'hunt -- cihkonaqcuhke'},
               {name: 'hunt -- kotunotahsu', language: 'hunt -- kotunotahsu'},
               {name: 'hunt -- qaqsossuhke', language: 'hunt -- qaqsossuhke'},
               {name: 'hunt at night using light -- nipayahse', language: 'hunt at night using light -- nipayahse'},
               {name: 'hunt bear -- muwinehke', language: 'hunt bear -- muwinehke'},
               {name: 'hunt birds -- sipsuhke', language: 'hunt birds -- sipsuhke'},
               {name: 'hunt chickens -- ehemuhke', language: 'hunt chickens -- ehemuhke'},
               {name: 'hunt deer -- otukke', language: 'hunt deer -- otukke'},
               {name: 'hunt from concealed place -- tuhtihike', language: 'hunt from concealed place -- tuhtihike'},
               {name: 'hunt grouse -- mociyehsuhke', language: 'hunt grouse -- mociyehsuhke'},
               {name: 'hunt moose -- musuhke', language: 'hunt moose -- musuhke'},
               {name: 'hunt porpoise -- cuspeske', language: 'hunt porpoise -- cuspeske'},
               {name: 'hunt raccoons -- esponsuhke', language: 'hunt raccoons -- esponsuhke'},
               {name: 'hunt seal pups -- ahkiqsiske', language: 'hunt seal pups -- ahkiqsiske'},
               {name: 'hunt seals -- ahkiqqe', language: 'hunt seals -- ahkiqqe'},
               {name: 'hunt squirrels -- mihkuwehke', language: 'hunt squirrels -- mihkuwehke'},
               {name: 'hunt woodcock -- neqskihqessuhke', language: 'hunt woodcock -- neqskihqessuhke'},
               {name: 'hurt self -- sikiyusu', language: 'hurt self -- sikiyusu'},
               {name: 'imitate sound -- lihtakuwe', language: 'imitate sound -- lihtakuwe'},
               {name: 'imitate various ways -- milokehkoluhtomake', language: 'imitate various ways -- milokehkoluhtomake'},
               {name: 'improvise a living -- mamatuwoluhke', language: 'improvise a living -- mamatuwoluhke'},
               {name: 'increase in quantity -- olomel', language: 'increase in quantity -- olomel'},
               {name: 'inhale -- wihqolatom', language: 'inhale -- wihqolatom'},
               {name: 'inhale tobacco smoke -- wihqipkotehtamu', language: 'inhale tobacco smoke -- wihqipkotehtamu'},
               {name: 'inhale tobacco smoke repeatedly -- wihqipkotehtamiye', language: 'inhale tobacco smoke repeatedly -- wihqipkotehtamiye'},
               {name: 'injure self by handling something dangerous clumsily -- nasoluhte', language: 'injure self by handling something dangerous clumsily -- nasoluhte'},
               {name: 'insert finger into rectum -- napalokiqensu', language: 'insert finger into rectum -- napalokiqensu'},
               {name: 'insert head -- pisqewu', language: 'insert head -- pisqewu'},
               {name: 'insist s/he is right -- cikawiwolame', language: 'insist s/he is right -- cikawiwolame'},
               {name: 'intend to .. -- oloqitahasu', language: 'intend to .. -- oloqitahasu'},
               {name: 'interrupt -- komittehsin', language: 'interrupt -- komittehsin'},
               {name: 'interrupt -- tomikesu', language: 'interrupt -- tomikesu'},
               {name: 'interrupt by speaking -- komitewestu', language: 'interrupt by speaking -- komitewestu'},
               {name: 'introduce something new -- mitsonekosu', language: 'introduce something new -- mitsonekosu'},
               {name: 'intrude -- cikawiksehe', language: 'intrude -- cikawiksehe'},
               {name: 'investigate -- naskahsu', language: 'investigate -- naskahsu'},
               {name: 'iron -- citonekisawe', language: 'iron -- citonekisawe'},
               {name: 'issue orders -- olokitom', language: 'issue orders -- olokitom'},
               {name: 'join in -- witahke', language: 'join in -- witahke'},
               {name: 'join in conversation -- witewestu', language: 'join in conversation -- witewestu'},
               {name: 'join in the chase -- wiciphoqe', language: 'join in the chase -- wiciphoqe'},
               {name: 'joke -- pahpuwe', language: 'joke -- pahpuwe'},
               {name: 'jump -- nihkanotoqqiw', language: 'jump -- nihkanotoqqiw'},
               {name: 'jump -- oltoqhu', language: 'jump -- oltoqhu'},
               {name: 'jump across -- ksokatkuhu', language: 'jump across -- ksokatkuhu'},
               {name: 'jump ahead -- ankuhtehsin', language: 'jump ahead -- ankuhtehsin'},
               {name: 'jump ahead -- ankuhtehson', language: 'jump ahead -- ankuhtehson'},
               {name: 'jump ahead -- ankuhtehsonuwiw', language: 'jump ahead -- ankuhtehsonuwiw'},
               {name: 'jump ahead -- ankutkuhu', language: 'jump ahead -- ankutkuhu'},
               {name: 'jump ahead -- nihkantokkuwiw', language: 'jump ahead -- nihkantokkuwiw'},
               {name: 'jump all over the place -- psonotokku', language: 'jump all over the place -- psonotokku'},
               {name: 'jump along -- pomotoqqiye', language: 'jump along -- pomotoqqiye'},
               {name: 'jump around -- yalenskiye', language: 'jump around -- yalenskiye'},
               {name: 'jump around -- yalotokkuwiw', language: 'jump around -- yalotokkuwiw'},
               {name: 'jump around -- yalotoqqiye', language: 'jump around -- yalotoqqiye'},
               {name: 'jump around -- yalotoqqiyewiw', language: 'jump around -- yalotoqqiyewiw'},
               {name: 'jump ashore -- nahtokatkuhu', language: 'jump ashore -- nahtokatkuhu'},
               {name: 'jump back and forth -- esuhketkuhu', language: 'jump back and forth -- esuhketkuhu'},
               {name: 'zover -- pasittokku', language: 'zover -- pasittokku'},
               {name: 'jump up and down quickly -- sasotokku', language: 'jump up and down quickly -- sasotokku'},
               {name: 'jump when startled -- ciptehsin', language: 'jump when startled -- ciptehsin'},
               {name: 'jump when startled -- ciptehsu', language: 'jump when startled -- ciptehsu'},
               {name: 'keep butting in -- ahtolipiscokhusu', language: 'keep butting in -- ahtolipiscokhusu'},
               {name: 'keep diary -- wikhikonakonimsu', language: 'keep diary -- wikhikonakonimsu'},
               {name: 'keep dripping -- ahtolopehtehson', language: 'keep dripping -- ahtolopehtehson'},
               {name: 'keep going out -- munsanutehe', language: 'keep going out -- munsanutehe'},
               {name: 'keep hanging -- ahtolekhucu', language: 'keep hanging -- ahtolekhucu'},
               {name: 'keep laughing -- ahtolelomu', language: 'keep laughing -- ahtolelomu'},
               {name: 'keep lying -- ahtolikolusku', language: 'keep lying -- ahtolikolusku'},
               {name: 'keep making a sound -- ahtolihtaqot', language: 'keep making a sound -- ahtolihtaqot'},
               {name: 'keep on eating -- tolcokihpu', language: 'keep on eating -- tolcokihpu'},
               {name: 'keep on going around -- olomiyaliye', language: 'keep on going around -- olomiyaliye'},
               {name: 'keep on going in -- munsaksehe', language: 'keep on going in -- munsaksehe'},
               {name: 'keep on in spite of obstacle -- cikawiyusu', language: 'keep on in spite of obstacle -- cikawiyusu'},
               {name: 'keep on laughing -- aptelomu', language: 'keep on laughing -- aptelomu'},
               {name: 'keep on singing -- tolcokintu', language: 'keep on singing -- tolcokintu'},
               {name: 'keep on sitting -- munsahqepu', language: 'keep on sitting -- munsahqepu'},
               {name: 'keep on talking -- ahtolihtaqsu', language: 'keep on talking -- ahtolihtaqsu'},
               {name: 'keep on writing -- ahtoluwikhike', language: 'keep on writing -- ahtoluwikhike'},
               {name: 'keep on yawning -- munsaikotohom', language: 'keep on yawning -- munsaikotohom'},
               {name: 'keep praying -- ahtolimiye', language: 'keep praying -- ahtolimiye'},
               {name: 'keep sitting there -- ahtoluhqepu', language: 'keep sitting there -- ahtoluhqepu'},
               {name: 'keep stealing -- ahtolikomutone', language: 'keep stealing -- ahtolikomutone'},
               {name: 'keep striking -- ahtolihtehson', language: 'keep striking -- ahtolihtehson'},
               {name: 'keep talking -- ahtolewestu', language: 'keep talking -- ahtolewestu'},
               {name: 'keep telling lies -- ahtolikoluskacomu', language: 'keep telling lies -- ahtolikoluskacomu'},
               {name: 'keep waiting -- ahtolaskuwasu', language: 'keep waiting -- ahtolaskuwasu'},
               {name: 'keep working -- ahtololuhke', language: 'keep working -- ahtololuhke'},
               {name: 'keep working -- ahtololuhkewiw', language: 'keep working -- ahtololuhkewiw'},
               {name: 'kill game -- nehpahtike', language: 'kill game -- nehpahtike'},
               {name: 'kill self -- nehpuhusu', language: 'kill self -- nehpuhusu'},
               {name: 'kindle morning fire -- spasotutuwe', language: 'kindle morning fire -- spasotutuwe'},
               {name: 'kneel and stands up again -- petkupessu', language: 'kneel and stands up again -- petkupessu'},
               {name: 'kneel down -- petkupiye', language: 'kneel down -- petkupiye'},
               {name: 'knit -- mecimtokehtahsu', language: 'knit -- mecimtokehtahsu'},
               {name: 'knock at the door -- motehtihike', language: 'knock at the door -- motehtihike'},
               {name: 'knock wind out of self by falling something -- kpolamtehsin', language: 'knock wind out of self by falling something -- kpolamtehsin'},
               {name: 'know -- kcicihtu', language: 'know -- kcicihtu'},
               {name: 'know a lot -- kcicihtaqehtuhsiw', language: 'know a lot -- kcicihtaqehtuhsiw'},
               {name: 'know how to fight -- tawwalke', language: 'know how to fight -- tawwalke'},
               {name: 'know what to do -- wewoluhke', language: 'know what to do -- wewoluhke'},
               {name: 'know where s/he is going -- wewiye', language: 'know where s/he is going -- wewiye'},
               {name: 'lag behind -- qotahke', language: 'lag behind -- qotahke'},
               {name: 'land -- cicokehe', language: 'land -- cicokehe'},
               {name: 'land -- pokosson', language: 'land -- pokosson'},
               {name: 'land -- wolipokosson', language: 'land -- wolipokosson'},
               {name: 'land an angle -- asuwipokosson', language: 'land an angle -- asuwipokosson'},
               {name: 'land and grabs -- kolomihtehsin', language: 'land and grabs -- kolomihtehsin'},
               {name: 'land softly -- nahnaktehsin', language: 'land softly -- nahnaktehsin'},
               {name: 'land softly -- nuhkonahtehsin', language: 'land softly -- nuhkonahtehsin'},
               {name: 'last forever -- askomiye', language: 'last forever -- askomiye'},
               {name: 'last long time -- sipkotuwiw', language: 'last long time -- sipkotuwiw'},
               {name: 'laugh all the time -- mecimelomu', language: 'laugh all the time -- mecimelomu'},
               {name: 'laugh easily -- skewelomu', language: 'laugh easily -- skewelomu'},
               {name: 'laugh loud -- cipokelomu', language: 'laugh loud -- cipokelomu'},
               {name: 'laugh loud -- memhuwelomu', language: 'laugh loud -- memhuwelomu'},
               {name: 'laugh silently -- kikimelomu', language: 'laugh silently -- kikimelomu'},
               {name: 'laugh so hard -- tutelomu', language: 'laugh so hard -- tutelomu'},
               {name: 'laugh so hard that s/he cries -- sehselomu', language: 'laugh so hard that s/he cries -- sehselomu'},
               {name: 'laugh very hard -- siktiyelomu', language: 'laugh very hard -- siktiyelomu'},
               {name: 'lay big eggs -- kinawone', language: 'lay big eggs -- kinawone'},
               {name: 'lay bricks -- olsekonike', language: 'lay bricks -- olsekonike'},
               {name: 'lay egg(s) -- ponatom', language: 'lay egg(s) -- ponatom'},
               {name: 'lay head down -- olqehsin', language: 'lay head down -- olqehsin'},
               {name: 'lay roundish eggs -- ptoqawone', language: 'lay roundish eggs -- ptoqawone'},
               {name: 'lay small eggs -- apsawone', language: 'lay small eggs -- apsawone'},
               {name: 'lead a good life -- wolilitu', language: 'lead a good life -- wolilitu'},
               {name: 'lead a life of poverty -- ktomakilitu', language: 'lead a life of poverty -- ktomakilitu'},
               {name: 'lead the prayers -- nihkaninucimiye', language: 'lead the prayers -- nihkaninucimiye'},
               {name: 'lead the way leaving tracks -- nihkanaptu', language: 'lead the way leaving tracks -- nihkanaptu'},
               {name: 'leaf out -- paskipokesson', language: 'leaf out -- paskipokesson'},
               {name: 'leak through -- ecicuwon', language: 'leak through -- ecicuwon'},
               {name: 'lean in that direction -- oloqitehe', language: 'lean in that direction -- oloqitehe'},
               {name: 'lean over something -- pasituhucin', language: 'lean over something -- pasituhucin'},
               {name: 'learn -- kehkimsu', language: 'learn -- kehkimsu'},
               {name: 'learn slowly -- nakanonom', language: 'learn slowly -- nakanonom'},
               {name: 'leave -- wisamacehe', language: 'leave -- wisamacehe'},
               {name: 'leave angry -- maceluwehe', language: 'leave angry -- maceluwehe'},
               {name: 'leave backing up -- sehtamacehe', language: 'leave backing up -- sehtamacehe'},
               {name: 'leave by boat -- pusu', language: 'leave by boat -- pusu'},
               {name: 'leave from there -- cimacehe', language: 'leave from there -- cimacehe'},
               {name: 'leave in a hurry -- wiwisamacehe', language: 'leave in a hurry -- wiwisamacehe'},
               {name: 'leave very quickly -- maceliqehe', language: 'leave very quickly -- maceliqehe'},
               {name: 'lengthen a braid -- ankuwatokonike', language: 'lengthen a braid -- ankuwatokonike'},
               {name: 'level gravel -- tetpamkihike', language: 'level gravel -- tetpamkihike'},
               {name: 'lie -- koluskewestu', language: 'lie -- koluskewestu'},
               {name: 'lie -- kolusku', language: 'lie -- kolusku'},
               {name: 'lie -- pahtayewestu', language: 'lie -- pahtayewestu'},
               {name: 'lie across -- ksokahsin', language: 'lie across -- ksokahsin'},
               {name: 'lie across -- ksokayaqopu', language: 'lie across -- ksokayaqopu'},
               {name: 'lie across -- ksokayaqte', language: 'lie across -- ksokayaqte'},
               {name: 'lie across -- pasicossin', language: 'lie across -- pasicossin'},
               {name: 'lie around -- yalossin', language: 'lie around -- yalossin'},
               {name: 'lie around -- yalte', language: 'lie around -- yalte'},
               {name: 'lie down -- lossin', language: 'lie down -- lossin'},
               {name: 'lie down -- wolossin', language: 'lie down -- wolossin'},
               {name: 'lie face down -- ktoqossin', language: 'lie face down -- ktoqossin'},
               {name: 'lie facing away -- olomaskotehsin', language: 'lie facing away -- olomaskotehsin'},
               {name: 'lie facing s/he continues lying there -- olomossin', language: 'lie facing s/he continues lying there -- olomossin'},
               {name: 'lie flat -- sokotopokahte', language: 'lie flat -- sokotopokahte'},
               {name: 'lie naked -- setolehsin', language: 'lie naked -- setolehsin'},
               {name: 'lie on -- tuwossin', language: 'lie on -- tuwossin'},
               {name: 'lie open -- apqanokahte', language: 'lie open -- apqanokahte'},
               {name: 'lie piled up -- pomamkahte', language: 'lie piled up -- pomamkahte'},
               {name: 'lie still -- sankewossin', language: 'lie still -- sankewossin'},
               {name: 'lie straight -- pomahqossin', language: 'lie straight -- pomahqossin'},
               {name: 'lie stretched out straight -- sipelatokossin', language: 'lie stretched out straight -- sipelatokossin'},
               {name: 'lie there -- pomaqte', language: 'lie there -- pomaqte'},
               {name: 'lie under oath -- spiwolamewikoluskuhtahsu', language: 'lie under oath -- spiwolamewikoluskuhtahsu'},
               {name: 'lie with face uncovered -- paniqehsin', language: 'lie with face uncovered -- paniqehsin'},
               {name: 'lift leg and holds it up -- ewepikonawu', language: 'lift leg and holds it up -- ewepikonawu'},
               {name: 'lift something heavy -- pikcahsonu', language: 'lift something heavy -- pikcahsonu'},
               {name: 'lift something heavy -- tkiqsoniye', language: 'lift something heavy -- tkiqsoniye'},
               {name: 'light cigarette -- psqolehtoma', language: 'light cigarette -- psqolehtoma'},
               {name: 'like own looks -- wolinasu', language: 'like own looks -- wolinasu'},
               {name: 'like the place so much that s/he stays longer -- ksatke', language: 'like the place so much that s/he stays longer -- ksatke'},
               {name: 'like to be there -- wikuwaciiyu', language: 'like to be there -- wikuwaciiyu'},
               {name: 'like to eat -- wikihpu', language: 'like to eat -- wikihpu'},
               {name: 'like to fight -- wikuwacimatonahke', language: 'like to fight -- wikuwacimatonahke'},
               {name: 'like to laugh -- wikuwatelomu', language: 'like to laugh -- wikuwatelomu'},
               {name: 'like to plant garden(s) -- wikkihke', language: 'like to plant garden(s) -- wikkihke'},
               {name: 'like to read -- wikuwatokisu', language: 'like to read -- wikuwatokisu'},
               {name: 'like very much to be in bed -- ksacossin', language: 'like very much to be in bed -- ksacossin'},
               {name: 'limp along -- pomahkahamu', language: 'limp along -- pomahkahamu'},
               {name: 'limp around -- yalahkahamu', language: 'limp around -- yalahkahamu'},
               {name: 'limp markedly -- isikonahkahamu', language: 'limp markedly -- isikonahkahamu'},
               {name: 'linger a long time before dying -- sipawsu', language: 'linger a long time before dying -- sipawsu'},
               {name: 'lisp -- saktokilluwehtaqsu', language: 'lisp -- saktokilluwehtaqsu'},
               {name: 'lisp -- toqilluwehtaqsu', language: 'lisp -- toqilluwehtaqsu'},
               {name: 'listen as s/he goes along -- pomiciksotom', language: 'listen as s/he goes along -- pomiciksotom'},
               {name: 'listen attentively -- tpostom', language: 'listen attentively -- tpostom'},
               {name: 'listen well -- ksostom', language: 'listen well -- ksostom'},
               {name: 'listen well -- woliciksotom', language: 'listen well -- woliciksotom'},
               {name: 'live alone -- qotuhkanu', language: 'live alone -- qotuhkanu'},
               {name: 'live alone -- qotupihtom', language: 'live alone -- qotupihtom'},
               {name: 'live crazy life -- wiwonasipomawsu', language: 'live crazy life -- wiwonasipomawsu'},
               {name: 'live for so long a time -- qonawsu', language: 'live for so long a time -- qonawsu'},
               {name: 'live for so long a time -- qonawsuwiw', language: 'live for so long a time -- qonawsuwiw'},
               {name: 'live forever -- askomawsu', language: 'live forever -- askomawsu'},
               {name: 'live forever -- askomawsuwiw', language: 'live forever -- askomawsuwiw'},
               {name: 'live hard life -- sikawsu', language: 'live hard life -- sikawsu'},
               {name: 'live in same building with other(s) -- wicike', language: 'live in same building with other(s) -- wicike'},
               {name: 'live intensely -- kakawawsu', language: 'live intensely -- kakawawsu'},
               {name: 'live long time -- sipkawsu', language: 'live long time -- sipkawsu'},
               {name: 'live longer -- siyepawsu', language: 'live longer -- siyepawsu'},
               {name: 'live on what s/he has available -- mamatuwawsu', language: 'live on what s/he has available -- mamatuwawsu'},
               {name: 'live there -- lawsu', language: 'live there -- lawsu'},
               {name: 'live through hard times -- siqonawsu', language: 'live through hard times -- siqonawsu'},
               {name: 'live until daybreak -- cehqonawsu', language: 'live until daybreak -- cehqonawsu'},
               {name: 'live well -- wolawsu', language: 'live well -- wolawsu'},
               {name: 'live well -- wolipomawsu', language: 'live well -- wolipomawsu'},
               {name: 'load a gun -- pitkose', language: 'load a gun -- pitkose'},
               {name: 'load items into boat -- tepute', language: 'load items into boat -- tepute'},
               {name: 'load something stick-like -- teponahqe', language: 'load something stick-like -- teponahqe'},
               {name: 'lock -- mecimsokhike', language: 'lock -- mecimsokhike'},
               {name: 'lock by itself -- laktehson', language: 'lock by itself -- laktehson'},
               {name: 'lock self in -- lakhusu', language: 'lock self in -- lakhusu'},
               {name: 'lock trigger incorrectly -- wapolahkonoma', language: 'lock trigger incorrectly -- wapolahkonoma'},
               {name: 'lock when it closes -- mecimsoktehson', language: 'lock when it closes -- mecimsoktehson'},
               {name: 'look Passamaquoddy -- skicinuwinaqot', language: 'look Passamaquoddy -- skicinuwinaqot'},
               {name: 'look amazed -- assokiqe', language: 'look amazed -- assokiqe'},
               {name: 'look angry -- wisokiluwehenaqsu', language: 'look angry -- wisokiluwehenaqsu'},
               {name: 'look angry -- wisokiqewu', language: 'look angry -- wisokiqewu'},
               {name: 'look angry expression) -- kiniqewu', language: 'look angry expression) -- kiniqewu'},
               {name: 'look as if it is about to depart -- macenaqot', language: 'look as if it is about to depart -- macenaqot'},
               {name: 'look as if it is in a hurry -- tucinaqot', language: 'look as if it is in a hurry -- tucinaqot'},
               {name: 'look as if s/he is in a hurry -- tucinaqsu', language: 'look as if s/he is in a hurry -- tucinaqsu'},
               {name: 'look bad -- mocinaqot', language: 'look bad -- mocinaqot'},
               {name: 'look bad -- mocinaqsu', language: 'look bad -- mocinaqsu'},
               {name: 'look bad to self -- mocinasu', language: 'look bad to self -- mocinasu'},
               {name: 'look bare -- emesokinaqot', language: 'look bare -- emesokinaqot'},
               {name: 'look barely alive -- wahkacinaqsu', language: 'look barely alive -- wahkacinaqsu'},
               {name: 'look better -- mewiyawinaqot', language: 'look better -- mewiyawinaqot'},
               {name: 'look better -- mewiyawinaqsu', language: 'look better -- mewiyawinaqsu'},
               {name: 'look big -- kininaqot', language: 'look big -- kininaqot'},
               {name: 'look big -- kininaqsu', language: 'look big -- kininaqsu'},
               {name: 'look black -- mokosewinaqot', language: 'look black -- mokosewinaqot'},
               {name: 'look black -- mokosewinaqsu', language: 'look black -- mokosewinaqsu'},
               {name: 'look bright -- possenaqot', language: 'look bright -- possenaqot'},
               {name: 'look chaotic -- wiwonasinaqot', language: 'look chaotic -- wiwonasinaqot'},
               {name: 'look clean -- pehkinaqot', language: 'look clean -- pehkinaqot'},
               {name: 'look clean -- pehkinaqsu', language: 'look clean -- pehkinaqsu'},
               {name: 'look cluttered -- sispecokinaqot', language: 'look cluttered -- sispecokinaqot'},
               {name: 'look cluttered -- sissenaqot', language: 'look cluttered -- sissenaqot'},
               {name: 'look composed -- sankewinaqsu', language: 'look composed -- sankewinaqsu'},
               {name: 'look crazy -- wiwonasinaqsu', language: 'look crazy -- wiwonasinaqsu'},
               {name: 'look crazy -- wonatominenaqot', language: 'look crazy -- wonatominenaqot'},
               {name: 'look crowded -- cocinaqot', language: 'look crowded -- cocinaqot'},
               {name: 'look cute -- wihqinaqsu', language: 'look cute -- wihqinaqsu'},
               {name: 'look dark -- piskahtenaqot', language: 'look dark -- piskahtenaqot'},
               {name: 'look dark -- piskalokinaqot', language: 'look dark -- piskalokinaqot'},
               {name: 'look dark -- piskinaqot', language: 'look dark -- piskinaqot'},
               {name: 'look different -- piluwinaqot', language: 'look different -- piluwinaqot'},
               {name: 'look different -- piluwinaqsu', language: 'look different -- piluwinaqsu'},
               {name: 'look different from what was expected -- piltuwinaqot', language: 'look different from what was expected -- piltuwinaqot'},
               {name: 'look different from what was expected -- piltuwinaqsu', language: 'look different from what was expected -- piltuwinaqsu'},
               {name: 'look discouraging -- peqosinaqot', language: 'look discouraging -- peqosinaqot'},
               {name: 'look dressy -- seskinaqot', language: 'look dressy -- seskinaqot'},
               {name: 'look easy -- komasinaqsu', language: 'look easy -- komasinaqsu'},
               {name: 'look easy to do -- komasinaqot', language: 'look easy to do -- komasinaqot'},
               {name: 'look empty -- siqenaqot', language: 'look empty -- siqenaqot'},
               {name: 'look empty -- siqenaqsu', language: 'look empty -- siqenaqsu'},
               {name: 'look even -- tetpinaqot', language: 'look even -- tetpinaqot'},
               {name: 'look for work -- kiluwoluhke', language: 'look for work -- kiluwoluhke'},
               {name: 'look frail -- macikossu', language: 'look frail -- macikossu'},
               {name: 'look frightening -- sekpanaqot', language: 'look frightening -- sekpanaqot'},
               {name: 'look from there -- tapu', language: 'look from there -- tapu'},
               {name: 'look full -- psoninaqot', language: 'look full -- psoninaqot'},
               {name: 'look good -- wolinaqot', language: 'look good -- wolinaqot'},
               {name: 'look good -- wolinaqsu', language: 'look good -- wolinaqsu'},
               {name: 'look happy -- wolitahasuwinaqot', language: 'look happy -- wolitahasuwinaqot'},
               {name: 'look hollow -- piqenaqot', language: 'look hollow -- piqenaqot'},
               {name: 'look hollow -- piqenaqsu', language: 'look hollow -- piqenaqsu'},
               {name: 'look in that direction -- oloqapu', language: 'look in that direction -- oloqapu'},
               {name: 'look like Native person -- skicinuwinaqsu', language: 'look like Native person -- skicinuwinaqsu'},
               {name: 'look like bad weather -- mocokiskotinaqot', language: 'look like bad weather -- mocokiskotinaqot'},
               {name: 'look like book -- wikhikoninaqot', language: 'look like book -- wikhikoninaqot'},
               {name: 'look lonely -- kiwacinaqot', language: 'look lonely -- kiwacinaqot'},
               {name: 'look lonely -- kiwacinaqsu', language: 'look lonely -- kiwacinaqsu'},
               {name: 'look lonely -- kiwaciqewu', language: 'look lonely -- kiwaciqewu'},
               {name: 'look lonely with smoke coming from the chimney -- kiwacipkotete', language: 'look lonely with smoke coming from the chimney -- kiwacipkotete'},
               {name: 'look long -- pittoksuwinaqsu', language: 'look long -- pittoksuwinaqsu'},
               {name: 'look messy -- moccokinaqot', language: 'look messy -- moccokinaqot'},
               {name: 'look messy -- moccokinaqsu', language: 'look messy -- moccokinaqsu'},
               {name: 'look messy -- moconomewinaqot', language: 'look messy -- moconomewinaqot'},
               {name: 'look old -- kaninaqot', language: 'look old -- kaninaqot'},
               {name: 'look old -- kaninaqsu', language: 'look old -- kaninaqsu'},
               {name: 'look old -- kcinaqsu', language: 'look old -- kcinaqsu'},
               {name: 'look older -- pomikotonenaqsu', language: 'look older -- pomikotonenaqsu'},
               {name: 'look pitiful -- ktomakiqewu', language: 'look pitiful -- ktomakiqewu'},
               {name: 'look pitiful -- suwinaqot', language: 'look pitiful -- suwinaqot'},
               {name: 'look pitiful -- suwinaqsu', language: 'look pitiful -- suwinaqsu'},
               {name: 'look proud -- paliqetutom', language: 'look proud -- paliqetutom'},
               {name: 'look proud -- paliqewu', language: 'look proud -- paliqewu'},
               {name: 'look sad -- pihtasokiqenaqsu', language: 'look sad -- pihtasokiqenaqsu'},
               {name: 'look saggy -- sukacokinaqot', language: 'look saggy -- sukacokinaqot'},
               {name: 'look saggy -- sukacokinaqsu', language: 'look saggy -- sukacokinaqsu'},
               {name: 'look scared -- seksuwinaqsu', language: 'look scared -- seksuwinaqsu'},
               {name: 'look scary -- cipalokinaqot', language: 'look scary -- cipalokinaqot'},
               {name: 'look scary -- cipalokinaqsu', language: 'look scary -- cipalokinaqsu'},
               {name: 'look sexy -- maskinaqot', language: 'look sexy -- maskinaqot'},
               {name: 'look sick -- ksinuhkewinaqsu', language: 'look sick -- ksinuhkewinaqsu'},
               {name: 'look sickly -- ksinuhkewapewiw', language: 'look sickly -- ksinuhkewapewiw'},
               {name: 'look small -- apsinaqot', language: 'look small -- apsinaqot'},
               {name: 'look small -- apsinaqsu', language: 'look small -- apsinaqsu'},
               {name: 'look smoky -- pkotewapotasu', language: 'look smoky -- pkotewapotasu'},
               {name: 'look sorrowful -- psahkeyuwinaqot', language: 'look sorrowful -- psahkeyuwinaqot'},
               {name: 'look sorrowful -- wewehsinaqot', language: 'look sorrowful -- wewehsinaqot'},
               {name: 'look sorrowful -- wewehsinaqsu', language: 'look sorrowful -- wewehsinaqsu'},
               {name: 'look strange -- assokapotasu', language: 'look strange -- assokapotasu'},
               {name: 'look strong brawny -- sakolinaqsu', language: 'look strong brawny -- sakolinaqsu'},
               {name: 'look tense -- ceconinaqsu', language: 'look tense -- ceconinaqsu'},
               {name: 'look there -- lapu', language: 'look there -- lapu'},
               {name: 'look thus -- likon', language: 'look thus -- likon'},
               {name: 'look thus -- likson', language: 'look thus -- likson'},
               {name: 'look thus -- liku', language: 'look thus -- liku'},
               {name: 'look thus -- linaqot', language: 'look thus -- linaqot'},
               {name: 'look thus -- linaqsu', language: 'look thus -- linaqsu'},
               {name: 'look to be in poor condition -- ktomakinaqsu', language: 'look to be in poor condition -- ktomakinaqsu'},
               {name: 'look to be in poor condition -- naskanaqot', language: 'look to be in poor condition -- naskanaqot'},
               {name: 'look to be in poor condition -- naskanaqsu', language: 'look to be in poor condition -- naskanaqsu'},
               {name: 'look to the left -- pahtayapu', language: 'look to the left -- pahtayapu'},
               {name: 'look toward here -- ckuwapu', language: 'look toward here -- ckuwapu'},
               {name: 'look untrustworthy -- sanaqsu', language: 'look untrustworthy -- sanaqsu'},
               {name: 'look vast -- nawinaqot', language: 'look vast -- nawinaqot'},
               {name: 'look very large -- kinahantuwinaqot', language: 'look very large -- kinahantuwinaqot'},
               {name: 'look very large -- kinahantuwinaqsu', language: 'look very large -- kinahantuwinaqsu'},
               {name: 'look weak -- kuhpewinaqot', language: 'look weak -- kuhpewinaqot'},
               {name: 'look white -- wapinaqot', language: 'look white -- wapinaqot'},
               {name: 'look white -- wapinaqsu', language: 'look white -- wapinaqsu'},
               {name: 'look wintry -- puninaqot', language: 'look wintry -- puninaqot'},
               {name: 'look with indecent intent -- yalimocapu', language: 'look with indecent intent -- yalimocapu'},
               {name: 'look with telescope -- laputihike', language: 'look with telescope -- laputihike'},
               {name: 'look wrong -- wapolikon', language: 'look wrong -- wapolikon'},
               {name: 'loosen -- ewehkatokuhse', language: 'loosen -- ewehkatokuhse'},
               {name: 'loosen gradually -- ewehkatokuhsewiw', language: 'loosen gradually -- ewehkatokuhsewiw'},
               {name: 'loosen up the ground -- nuhkamkihike', language: 'loosen up the ground -- nuhkamkihike'},
               {name: 'lop from tree(s) -- musikatqonhike', language: 'lop from tree(s) -- musikatqonhike'},
               {name: 'lose baby hair -- atuwe', language: 'lose baby hair -- atuwe'},
               {name: 'lose breath in strong wind -- kpolamuhsuke', language: 'lose breath in strong wind -- kpolamuhsuke'},
               {name: 'lose grip -- kcoskapociye', language: 'lose grip -- kcoskapociye'},
               {name: 'lose weight -- mehtaluwosehlosu', language: 'lose weight -- mehtaluwosehlosu'},
               {name: 'lose weight -- mehtaluwosiye', language: 'lose weight -- mehtaluwosiye'},
               {name: 'love -- kseltom', language: 'love -- kseltom'},
               {name: 'love children -- ksiyaqsu', language: 'love children -- ksiyaqsu'},
               {name: 'lower shade blind -- nahkayekopolike', language: 'lower shade blind -- nahkayekopolike'},
               {name: 'make -- kolhikonihke', language: 'make -- kolhikonihke'},
               {name: 'make agreement -- kishoma', language: 'make agreement -- kishoma'},
               {name: 'make false teeth -- wipitke', language: 'make false teeth -- wipitke'},
               {name: 'make fancy baskets -- amalhiposonutehke', language: 'make fancy baskets -- amalhiposonutehke'},
               {name: 'make self -- oliyusu', language: 'make self -- oliyusu'},
               {name: 'maneuver well -- tawtoku', language: 'maneuver well -- tawtoku'},
               {name: 'mark by carving wood) -- cilaqsawe', language: 'mark by carving wood) -- cilaqsawe'},
               {name: 'mark for identification -- kinuwaskutike', language: 'mark for identification -- kinuwaskutike'},
               {name: 'massage -- sunpekonike', language: 'massage -- sunpekonike'},
               {name: 'masturbate to orgasm -- peskhihpulsu', language: 'masturbate to orgasm -- peskhihpulsu'},
               {name: 'matter more -- piyemokimqot', language: 'matter more -- piyemokimqot'},
               {name: 'mean -- itomuwiw', language: 'mean -- itomuwiw'},
               {name: 'mean -- minuwiw', language: 'mean -- minuwiw'},
               {name: 'mean that -- liminuwiw', language: 'mean that -- liminuwiw'},
               {name: 'measure -- tpaskusu', language: 'measure -- tpaskusu'},
               {name: 'measure -- tpaskutike', language: 'measure -- tpaskutike'},
               {name: 'measure accurately -- wolitpihike', language: 'measure accurately -- wolitpihike'},
               {name: 'mellow with age -- senahqemokiyewiw', language: 'mellow with age -- senahqemokiyewiw'},
               {name: 'melt -- apuwiyewiw', language: 'melt -- apuwiyewiw'},
               {name: 'melt gradually -- qasqomiye', language: 'melt gradually -- qasqomiye'},
               {name: 'melt little by little -- qasqomehe', language: 'melt little by little -- qasqomehe'},
               {name: 'melt rapidly -- qasqomessu', language: 'melt rapidly -- qasqomessu'},
               {name: 'menstruate -- kisuhsuwessu', language: 'menstruate -- kisuhsuwessu'},
               {name: 'milk -- colokinihike', language: 'milk -- colokinihike'},
               {name: 'mind well -- ksostom', language: 'mind well -- ksostom'},
               {name: 'misdeal -- wapolokonuwe', language: 'misdeal -- wapolokonuwe'},
               {name: 'mouth off jealously -- piluwotune', language: 'mouth off jealously -- piluwotune'},
               {name: 'move -- atutewiw', language: 'move -- atutewiw'},
               {name: 'move abruptly to different location -- acessu', language: 'move abruptly to different location -- acessu'},
               {name: 'move along making noise with feet -- menakattestike', language: 'move along making noise with feet -- menakattestike'},
               {name: 'move around -- yaluhsewiw', language: 'move around -- yaluhsewiw'},
               {name: 'move around -- yalutewiw', language: 'move around -- yalutewiw'},
               {name: 'move aside on bench make room for someone else -- semayatopu', language: 'move aside on bench make room for someone else -- semayatopu'},
               {name: 'move away -- maciyute', language: 'move away -- maciyute'},
               {name: 'move away -- olomute', language: 'move away -- olomute'},
               {name: 'move away bit by bit -- olomatokuhsewiw', language: 'move away bit by bit -- olomatokuhsewiw'},
               {name: 'move away quickly -- semassu', language: 'move away quickly -- semassu'},
               {name: 'move back -- apatute', language: 'move back -- apatute'},
               {name: 'move backward in seat -- sehtayatopu', language: 'move backward in seat -- sehtayatopu'},
               {name: 'move body thus -- olhokessu', language: 'move body thus -- olhokessu'},
               {name: 'move downstairs -- ponekute', language: 'move downstairs -- ponekute'},
               {name: 'move easily -- ewehkoskessu', language: 'move easily -- ewehkoskessu'},
               {name: 'move h/ hand out of the way quickly -- poliliptinessu', language: 'move h/ hand out of the way quickly -- poliliptinessu'},
               {name: 'move hand out of the way -- poliliptinewu', language: 'move hand out of the way -- poliliptinewu'},
               {name: 'move hand(s) methodically -- kinuwiptinessu', language: 'move hand(s) methodically -- kinuwiptinessu'},
               {name: 'move in -- ksiyute', language: 'move in -- ksiyute'},
               {name: 'move in -- pisute', language: 'move in -- pisute'},
               {name: 'move in various ways -- milihpusu', language: 'move in various ways -- milihpusu'},
               {name: 'move it is going badly -- mocuhsewiw', language: 'move it is going badly -- mocuhsewiw'},
               {name: 'move items down and arranges them -- poneqewotahsu', language: 'move items down and arranges them -- poneqewotahsu'},
               {name: 'move items from one place to another and arranges them -- atewotahsu', language: 'move items from one place to another and arranges them -- atewotahsu'},
               {name: 'move items up and arranges them -- spiqewotahsu', language: 'move items up and arranges them -- spiqewotahsu'},
               {name: 'move legs into various positions -- milikonawu', language: 'move legs into various positions -- milikonawu'},
               {name: 'move out -- nutiyute', language: 'move out -- nutiyute'},
               {name: 'move quickly out of sight -- kacessu', language: 'move quickly out of sight -- kacessu'},
               {name: 'move there -- lute', language: 'move there -- lute'},
               {name: 'move to different location -- aciye', language: 'move to different location -- aciye'},
               {name: 'move to here -- petute', language: 'move to here -- petute'},
               {name: 'move upstairs -- spikute', language: 'move upstairs -- spikute'},
               {name: 'move upward without making any noise -- spiqikikimskahsu', language: 'move upward without making any noise -- spiqikikimskahsu'},
               {name: 'move without making any noise -- kikimskahsu', language: 'move without making any noise -- kikimskahsu'},
               {name: 'nag constantly -- mecimtaqsu', language: 'nag constantly -- mecimtaqsu'},
               {name: 'nail crooked -- asuwikolaqhike', language: 'nail crooked -- asuwikolaqhike'},
               {name: 'narrow -- cihcipiqe', language: 'narrow -- cihcipiqe'},
               {name: 'narrow -- cihciqpeke', language: 'narrow -- cihciqpeke'},
               {name: 'narrow -- wehqakon', language: 'narrow -- wehqakon'},
               {name: 'navigate by dead reckoning -- nulomiye', language: 'navigate by dead reckoning -- nulomiye'},
               {name: 'nod off wherever s/he goes -- yalakiye', language: 'nod off wherever s/he goes -- yalakiye'},
               {name: 'nod off while watching television -- pskosakihwe', language: 'nod off while watching television -- pskosakihwe'},
               {name: 'notice things -- ksahapotom', language: 'notice things -- ksahapotom'},
               {name: 'nurse she is wet-nurse -- nunuwe', language: 'nurse she is wet-nurse -- nunuwe'},
               {name: 'obey -- ciksotom', language: 'obey -- ciksotom'},
               {name: 'object -- lalomikewtom', language: 'object -- lalomikewtom'},
               {name: 'obstruct -- komitaqte', language: 'obstruct -- komitaqte'},
               {name: 'obstruct -- komitte', language: 'obstruct -- komitte'},
               {name: 'occur day -- ehtahsikiskahkiw', language: 'occur day -- ehtahsikiskahkiw'},
               {name: 'ooze out under pressure -- colokpe', language: 'ooze out under pressure -- colokpe'},
               {name: 'open -- apqotesson', language: 'open -- apqotesson'},
               {name: 'open eyes -- apskapu', language: 'open eyes -- apskapu'},
               {name: 'open eyes wide -- kinalokiqewu', language: 'open eyes wide -- kinalokiqewu'},
               {name: 'open gradually -- apqotewsewiw', language: 'open gradually -- apqotewsewiw'},
               {name: 'open mouth and holds it open -- apqalokotunewu', language: 'open mouth and holds it open -- apqalokotunewu'},
               {name: 'open out -- talokahte', language: 'open out -- talokahte'},
               {name: 'open out to the ocean -- nutepiqe', language: 'open out to the ocean -- nutepiqe'},
               {name: 'open up -- mitasson', language: 'open up -- mitasson'},
               {name: 'open up the curtains -- panekopolike', language: 'open up the curtains -- panekopolike'},
               {name: 'originate -- maciyapeksu', language: 'originate -- maciyapeksu'},
               {name: 'outlive -- siyepawsu', language: 'outlive -- siyepawsu'},
               {name: 'overflow -- pasitpe', language: 'overflow -- pasitpe'},
               {name: 'oversleep -- kespoqsu', language: 'oversleep -- kespoqsu'},
               {name: 'owe -- canomahsu', language: 'owe -- canomahsu'},
               {name: 'owe so much -- olcanomahsu', language: 'owe so much -- olcanomahsu'},
               {name: 'owe too much -- samocanomahsu', language: 'owe too much -- samocanomahsu'},
               {name: 'own -- kuhusumiw', language: 'own -- kuhusumiw'},
               {name: 'own -- tpeltom', language: 'own -- tpeltom'},
               {name: 'own -- tpeltomuwiw', language: 'own -- tpeltomuwiw'},
               {name: 'own self -- tpelomosu', language: 'own self -- tpelomosu'},
               {name: 'own things -- milcehsuwe', language: 'own things -- milcehsuwe'},
               {name: 'own things -- pilcehsuwe', language: 'own things -- pilcehsuwe'},
               {name: 'pace off so much distance -- olomelku', language: 'pace off so much distance -- olomelku'},
               {name: 'pack -- pitkoma', language: 'pack -- pitkoma'},
               {name: 'pack often -- sawepitkoma', language: 'pack often -- sawepitkoma'},
               {name: 'paddle -- suke', language: 'paddle -- suke'},
               {name: 'paddle -- tahapiye', language: 'paddle -- tahapiye'},
               {name: 'paddle across -- ksokasuku', language: 'paddle across -- ksokasuku'},
               {name: 'paddle alone -- silom', language: 'paddle alone -- silom'},
               {name: 'paddle along -- pomisuku', language: 'paddle along -- pomisuku'},
               {name: 'paddle backward -- sehtawtahapiye', language: 'paddle backward -- sehtawtahapiye'},
               {name: 'paddle in stern of canoe and steers -- cihcihke', language: 'paddle in stern of canoe and steers -- cihcihke'},
               {name: 'paddle in the bow -- nihkanke', language: 'paddle in the bow -- nihkanke'},
               {name: 'paddle open -- panisuku', language: 'paddle open -- panisuku'},
               {name: 'paddle out into water -- milawisuku', language: 'paddle out into water -- milawisuku'},
               {name: 'paddle standing up -- sehkahapiye', language: 'paddle standing up -- sehkahapiye'},
               {name: 'paddle there -- lisuku', language: 'paddle there -- lisuku'},
               {name: 'paddle to shore -- cicokasuku', language: 'paddle to shore -- cicokasuku'},
               {name: 'paddle to some point -- tokkisuku', language: 'paddle to some point -- tokkisuku'},
               {name: 'paddle view -- sakhisuku', language: 'paddle view -- sakhisuku'},
               {name: 'paint -- lilomonhike', language: 'paint -- lilomonhike'},
               {name: 'paint colors thus -- olocossike', language: 'paint colors thus -- olocossike'},
               {name: 'paint fancily -- amalhocossike', language: 'paint fancily -- amalhocossike'},
               {name: 'pant -- sahsolatom', language: 'pant -- sahsolatom'},
               {name: 'pant -- sahsolatomihpusu', language: 'pant -- sahsolatomihpusu'},
               {name: 'paper -- pilasqhike', language: 'paper -- pilasqhike'},
               {name: 'parboil something -- puskolahqe', language: 'parboil something -- puskolahqe'},
               {name: 'part hair -- psetokahma', language: 'part hair -- psetokahma'},
               {name: 'participate in fight -- wicihponosu', language: 'participate in fight -- wicihponosu'},
               {name: 'participate with others in burning -- witamkolenike', language: 'participate with others in burning -- witamkolenike'},
               {name: 'pawn -- kolahtahsu', language: 'pawn -- kolahtahsu'},
               {name: 'pawn -- kolahtake', language: 'pawn -- kolahtake'},
               {name: 'pay -- apenke', language: 'pay -- apenke'},
               {name: 'pay a lot -- ksapenke', language: 'pay a lot -- ksapenke'},
               {name: 'pay a lot -- ktanaqapenke', language: 'pay a lot -- ktanaqapenke'},
               {name: 'pay all s/he owes -- kihkayapenke', language: 'pay all s/he owes -- kihkayapenke'},
               {name: 'pay forever -- askomapenke', language: 'pay forever -- askomapenke'},
               {name: 'pay in advance -- nihkanapenke', language: 'pay in advance -- nihkanapenke'},
               {name: 'pay late -- metsapenke', language: 'pay late -- metsapenke'},
               {name: 'pay less than standard price -- nuhtoqayapenke', language: 'pay less than standard price -- nuhtoqayapenke'},
               {name: 'pay poorly -- mocapenke', language: 'pay poorly -- mocapenke'},
               {name: 'pay that much -- lapenke', language: 'pay that much -- lapenke'},
               {name: 'peel bark -- kecitolasenike', language: 'peel bark -- kecitolasenike'},
               {name: 'peel bark -- monhiqe', language: 'peel bark -- monhiqe'},
               {name: 'peel bark -- pqonhike', language: 'peel bark -- pqonhike'},
               {name: 'peel off -- monessu', language: 'peel off -- monessu'},
               {name: 'peel off -- pqonaskiye', language: 'peel off -- pqonaskiye'},
               {name: 'pick -- muwisu', language: 'pick -- muwisu'},
               {name: 'pick blueberries -- sahtehke', language: 'pick blueberries -- sahtehke'},
               {name: 'pick fiddleheads -- mahsuske', language: 'pick fiddleheads -- mahsuske'},
               {name: 'pick medicine here and there -- monhinpisunke', language: 'pick medicine here and there -- monhinpisunke'},
               {name: 'pick sweetgrass -- suwitokolaske', language: 'pick sweetgrass -- suwitokolaske'},
               {name: 'pick sweetgrass without cleaning it as s/he goes along -- apuwehtahsu', language: 'pick sweetgrass without cleaning it as s/he goes along -- apuwehtahsu'},
               {name: 'pick things up -- pkonahsu', language: 'pick things up -- pkonahsu'},
               {name: 'pick things up -- pkonahsuwiw', language: 'pick things up -- pkonahsuwiw'},
               {name: 'pick way step by step -- qsihkawe', language: 'pick way step by step -- qsihkawe'},
               {name: 'picture something in mind -- lapiqe', language: 'picture something in mind -- lapiqe'},
               {name: 'pile dirt high retaining it so that it will not wash away -- moloqahkomikhike', language: 'pile dirt high retaining it so that it will not wash away -- moloqahkomikhike'},
               {name: 'pile up dirt -- mawamkukhike', language: 'pile up dirt -- mawamkukhike'},
               {name: 'pile up snow -- mawalihikhike', language: 'pile up snow -- mawalihikhike'},
               {name: 'pilot -- nucituwiye', language: 'pilot -- nucituwiye'},
               {name: 'plan -- lahsuwasu', language: 'plan -- lahsuwasu'},
               {name: 'plan -- olsuwasu', language: 'plan -- olsuwasu'},
               {name: 'plant a whole plant -- neqqenike', language: 'plant a whole plant -- neqqenike'},
               {name: 'plant early -- miyahsahkihke', language: 'plant early -- miyahsahkihke'},
               {name: 'plant late -- metsahkihke', language: 'plant late -- metsahkihke'},
               {name: 'plant seeds -- skoniminhike', language: 'plant seeds -- skoniminhike'},
               {name: 'play -- amke', language: 'play -- amke'},
               {name: 'play -- pahpu', language: 'play -- pahpu'},
               {name: 'play -- wikossu', language: 'play -- wikossu'},
               {name: 'play alone -- qotuhkayye', language: 'play alone -- qotuhkayye'},
               {name: 'play around -- yalayye', language: 'play around -- yalayye'},
               {name: 'play cards with self -- qotuhkayamkamsu', language: 'play cards with self -- qotuhkayamkamsu'},
               {name: 'play ice hockey -- puluwiw', language: 'play ice hockey -- puluwiw'},
               {name: 'play in the dirt -- lamkukhike', language: 'play in the dirt -- lamkukhike'},
               {name: 'play stringed instrument -- lapektuhusu', language: 'play stringed instrument -- lapektuhusu'},
               {name: 'play the drum -- kotuhmahsu', language: 'play the drum -- kotuhmahsu'},
               {name: 'play thus -- layye', language: 'play thus -- layye'},
               {name: 'play together with someone else -- nisayye', language: 'play together with someone else -- nisayye'},
               {name: 'play well -- wolayye', language: 'play well -- wolayye'},
               {name: 'play wind instrument -- pipiqe', language: 'play wind instrument -- pipiqe'},
               {name: 'play with others -- witamke', language: 'play with others -- witamke'},
               {name: 'plead -- wolakusu', language: 'plead -- wolakusu'},
               {name: 'please -- wolasuwiye', language: 'please -- wolasuwiye'},
               {name: 'plow -- lahkesawe', language: 'plow -- lahkesawe'},
               {name: 'plow thus -- olkesawe', language: 'plow thus -- olkesawe'},
               {name: 'plunge in all at once -- qotpehsin', language: 'plunge in all at once -- qotpehsin'},
               {name: 'pocket things -- pitsonakonihike', language: 'pocket things -- pitsonakonihike'},
               {name: 'point in various directions -- miluwike', language: 'point in various directions -- miluwike'},
               {name: 'point out over water -- milawwike', language: 'point out over water -- milawwike'},
               {name: 'point with finger -- oluwike', language: 'point with finger -- oluwike'},
               {name: 'pole self along -- pomamkuhusu', language: 'pole self along -- pomamkuhusu'},
               {name: 'pole self around -- yalamkuhusu', language: 'pole self around -- yalamkuhusu'},
               {name: 'pop with satisfying sound -- wolahkomeksu', language: 'pop with satisfying sound -- wolahkomeksu'},
               {name: 'pound ash log to obtain basket splints -- likpehtihike', language: 'pound ash log to obtain basket splints -- likpehtihike'},
               {name: 'pound rapidly -- nokosakpehtihike', language: 'pound rapidly -- nokosakpehtihike'},
               {name: 'pound rapidly with fist -- kakawtihike', language: 'pound rapidly with fist -- kakawtihike'},
               {name: 'pour -- sukalan(Ma)', language: 'pour -- sukalan(Ma)'},
               {name: 'pour -- sukonike', language: 'pour -- sukonike'},
               {name: 'pour liquid using utensil -- sukahike', language: 'pour liquid using utensil -- sukahike'},
               {name: 'powder face -- wapipkuhusu', language: 'powder face -- wapipkuhusu'},
               {name: 'powder self -- pawotoshusu', language: 'powder self -- pawotoshusu'},
               {name: 'pray -- imiye', language: 'pray -- imiye'},
               {name: 'pray any old way -- milcokimiye', language: 'pray any old way -- milcokimiye'},
               {name: 'pray for help -- pokitonike', language: 'pray for help -- pokitonike'},
               {name: 'pray in Passamaquoddy -- skicinuwimiye', language: 'pray in Passamaquoddy -- skicinuwimiye'},
               {name: 'pray quietly -- sankewimiye', language: 'pray quietly -- sankewimiye'},
               {name: 'pray rapidly -- sahsimiye', language: 'pray rapidly -- sahsimiye'},
               {name: 'pray well -- woliimiye', language: 'pray well -- woliimiye'},
               {name: 'pray with others -- wicimiye', language: 'pray with others -- wicimiye'},
               {name: 'pray without form -- tolcokimiye', language: 'pray without form -- tolcokimiye'},
               {name: 'pray without set form -- wewcokimiye', language: 'pray without set form -- wewcokimiye'},
               {name: 'prepare for winter -- amilkosu', language: 'prepare for winter -- amilkosu'},
               {name: 'prepare meal -- ksawenike', language: 'prepare meal -- ksawenike'},
               {name: 'prepare pitch -- pokuhhike', language: 'prepare pitch -- pokuhhike'},
               {name: 'prepare the soil -- wolamkihike', language: 'prepare the soil -- wolamkihike'},
               {name: 'preserve food with salt -- salawehtike', language: 'preserve food with salt -- salawehtike'},
               {name: 'pretend -- spaskusu', language: 'pretend -- spaskusu'},
               {name: 'pretend not to have been paid in order to charge again -- ksihkalutike', language: 'pretend not to have been paid in order to charge again -- ksihkalutike'},
               {name: 'pretend to be asleep -- esponsuhke', language: 'pretend to be asleep -- esponsuhke'},
               {name: 'pretend to be asleep -- kuwihkalsu', language: 'pretend to be asleep -- kuwihkalsu'},
               {name: 'pretend to be sick -- ksinuhkehkalsu', language: 'pretend to be sick -- ksinuhkehkalsu'},
               {name: 'pretend to be to be someone else -- piluwiwihqehlosu', language: 'pretend to be to be someone else -- piluwiwihqehlosu'},
               {name: 'promise explicitly -- papehcikolusu', language: 'promise explicitly -- papehcikolusu'},
               {name: 'propel self across ice on sled by pushing off with ice-picks -- pomamqehtuhusu', language: 'propel self across ice on sled by pushing off with ice-picks -- pomamqehtuhusu'},
               {name: 'prostitute self -- ankuwekhusu', language: 'prostitute self -- ankuwekhusu'},
               {name: 'pucker up from eating something sour -- suwonhuke', language: 'pucker up from eating something sour -- suwonhuke'},
               {name: 'pull -- wiqsonu', language: 'pull -- wiqsonu'},
               {name: 'pull away from previous position -- wiqhom', language: 'pull away from previous position -- wiqhom'},
               {name: 'pull chair up to table -- sitatopu', language: 'pull chair up to table -- sitatopu'},
               {name: 'pull in that direction -- oloqiwiqhom', language: 'pull in that direction -- oloqiwiqhom'},
               {name: 'purr -- latokonike', language: 'purr -- latokonike'},
               {name: 'purse lips -- qinusqotune', language: 'purse lips -- qinusqotune'},
               {name: 'purse lips -- qinusqotunewu', language: 'purse lips -- qinusqotunewu'},
               {name: 'quake underfoot -- matkomike', language: 'quake underfoot -- matkomike'},
               {name: 'quiet -- cikonahke', language: 'quiet -- cikonahke'},
               {name: 'quiet down -- cikiye', language: 'quiet down -- cikiye'},
               {name: 'race -- enawke', language: 'race -- enawke'},
               {name: 'radiate light -- miwahsu', language: 'radiate light -- miwahsu'},
               {name: 'radiate light -- miwahte', language: 'radiate light -- miwahte'},
               {name: 'rain and snows at same time -- psqopehsan', language: 'rain and snows at same time -- psqopehsan'},
               {name: 'rain big drops -- kinapskolan', language: 'rain big drops -- kinapskolan'},
               {name: 'rain continuously -- munsakomiwon', language: 'rain continuously -- munsakomiwon'},
               {name: 'rain enough -- tepolan', language: 'rain enough -- tepolan'},
               {name: 'rain hard -- wisokolan', language: 'rain hard -- wisokolan'},
               {name: 'rain heavily -- sukalan(Ma)', language: 'rain heavily -- sukalan(Ma)'},
               {name: 'rain lightly -- posoqpelan', language: 'rain lightly -- posoqpelan'},
               {name: 'rain so hard -- tutolan', language: 'rain so hard -- tutolan'},
               {name: 'raise hand and keeps it raised -- ewepiptinewu', language: 'raise hand and keeps it raised -- ewepiptinewu'},
               {name: 'raise prices -- ewepolutike', language: 'raise prices -- ewepolutike'},
               {name: 'rake dirt -- lamkukhike', language: 'rake dirt -- lamkukhike'},
               {name: 'read late -- metsokisu', language: 'read late -- metsokisu'},
               {name: 'recall to mind pleasant memories -- mihqelsu', language: 'recall to mind pleasant memories -- mihqelsu'},
               {name: 'recall unpleasant -- mocimihqelsu', language: 'recall unpleasant -- mocimihqelsu'},
               {name: 'recede -- sehtatoqiw', language: 'recede -- sehtatoqiw'},
               {name: 'recite a litany -- littaniye', language: 'recite a litany -- littaniye'},
               {name: 'recognize taste -- wewotamu', language: 'recognize taste -- wewotamu'},
               {name: 'recoil hard -- ksiosassu', language: 'recoil hard -- ksiosassu'},
               {name: 'recover ball and completes play following an error -- enuwaqhike', language: 'recover ball and completes play following an error -- enuwaqhike'},
               {name: 'recover on own -- enuwehlosu', language: 'recover on own -- enuwehlosu'},
               {name: 'relay commands -- ankuwacomuwiw', language: 'relay commands -- ankuwacomuwiw'},
               {name: 'relay commands -- ankuwoluhke', language: 'relay commands -- ankuwoluhke'},
               {name: 'relay commands -- ankuwoluhkewiw', language: 'relay commands -- ankuwoluhkewiw'},
               {name: 'release self -- monehlosu', language: 'release self -- monehlosu'},
               {name: 'remain there -- otoku', language: 'remain there -- otoku'},
               {name: 'remember -- mihqitahatom', language: 'remember -- mihqitahatom'},
               {name: 'remember -- wewitahasu', language: 'remember -- wewitahasu'},
               {name: 'remember -- wewitahatom', language: 'remember -- wewitahatom'},
               {name: 'remember things well from long ago -- sipitahasu', language: 'remember things well from long ago -- sipitahasu'},
               {name: 'reminisce -- pehqitahasu', language: 'reminisce -- pehqitahasu'},
               {name: 'remove animal waste -- monahce', language: 'remove animal waste -- monahce'},
               {name: 'remove eggs from nest(s) -- monawone', language: 'remove eggs from nest(s) -- monawone'},
               {name: 'remove own name -- semawikhusu', language: 'remove own name -- semawikhusu'},
               {name: 'remove self -- semahlosu', language: 'remove self -- semahlosu'},
               {name: 'remove self from list -- ketuwikhusu', language: 'remove self from list -- ketuwikhusu'},
               {name: 'renail -- minuwikolaqhike', language: 'renail -- minuwikolaqhike'},
               {name: 'render fat -- lahkomuhke', language: 'render fat -- lahkomuhke'},
               {name: 'repeat -- minuwiwihtom', language: 'repeat -- minuwiwihtom'},
               {name: 'repeat self -- napoluhmasu', language: 'repeat self -- napoluhmasu'},
               {name: 'resemble -- linaqot', language: 'resemble -- linaqot'},
               {name: 'resemble -- linaqsu', language: 'resemble -- linaqsu'},
               {name: 'resign -- ketuwikhusu', language: 'resign -- ketuwikhusu'},
               {name: 'resist being moved by bracing feet against something -- ceconuhulike', language: 'resist being moved by bracing feet against something -- ceconuhulike'},
               {name: 'restrain self -- cecononsu', language: 'restrain self -- cecononsu'},
               {name: 'return gradually -- apacuhsewiw', language: 'return gradually -- apacuhsewiw'},
               {name: 'return to religious faith -- apacipapahtom', language: 'return to religious faith -- apacipapahtom'},
               {name: 'ride horse -- pomumqe', language: 'ride horse -- pomumqe'},
               {name: 'ride the waves well -- tawatkuwe', language: 'ride the waves well -- tawatkuwe'},
               {name: 'ring bell -- suwahqihike', language: 'ring bell -- suwahqihike'},
               {name: 'ripen -- accossu', language: 'ripen -- accossu'},
               {name: 'rise early -- miyahsamihku', language: 'rise early -- miyahsamihku'},
               {name: 'rise high -- spiputawessu', language: 'rise high -- spiputawessu'},
               {name: 'rise high -- spuhse', language: 'rise high -- spuhse'},
               {name: 'rise high enough -- tepuhse', language: 'rise high enough -- tepuhse'},
               {name: 'risk -- kiselsu', language: 'risk -- kiselsu'},
               {name: 'risk life -- kiselomosu', language: 'risk life -- kiselomosu'},
               {name: 'roll -- topskansu', language: 'roll -- topskansu'},
               {name: 'roll and pitches -- milhuke', language: 'roll and pitches -- milhuke'},
               {name: 'roll back -- sehtapite', language: 'roll back -- sehtapite'},
               {name: 'roll in something rotten carcass) -- sunkassu', language: 'roll in something rotten carcass) -- sunkassu'},
               {name: 'roll on something smelly -- sunkahsu', language: 'roll on something smelly -- sunkahsu'},
               {name: 'rub self with lotion -- sunpekonosu', language: 'rub self with lotion -- sunpekonosu'},
               {name: 'rub thin -- pipuwihpute', language: 'rub thin -- pipuwihpute'},
               {name: 'rub vinegar on self -- pinikoluhusu', language: 'rub vinegar on self -- pinikoluhusu'},
               {name: 'run -- qasku', language: 'run -- qasku'},
               {name: 'run across -- ksokaqasku', language: 'run across -- ksokaqasku'},
               {name: 'run against others for office -- witamke', language: 'run against others for office -- witamke'},
               {name: 'run around -- yaliqasku', language: 'run around -- yaliqasku'},
               {name: 'run around circular course -- wiwoniqasku', language: 'run around circular course -- wiwoniqasku'},
               {name: 'run behind something -- akuwiphuwe', language: 'run behind something -- akuwiphuwe'},
               {name: 'run by -- tuciqasku', language: 'run by -- tuciqasku'},
               {name: 'run fast -- kakawiqasku', language: 'run fast -- kakawiqasku'},
               {name: 'run for chief -- sakomawamke', language: 'run for chief -- sakomawamke'},
               {name: 'run for office -- amke', language: 'run for office -- amke'},
               {name: 'run in fleeing from something -- ksephuwe', language: 'run in fleeing from something -- ksephuwe'},
               {name: 'run into something -- cocahtehsin', language: 'run into something -- cocahtehsin'},
               {name: 'run itself -- qasqikhusuwiw', language: 'run itself -- qasqikhusuwiw'},
               {name: 'run out fleeing from something -- nutephuwe', language: 'run out fleeing from something -- nutephuwe'},
               {name: 'run over edge and falls -- kaskiqasku', language: 'run over edge and falls -- kaskiqasku'},
               {name: 'run poorly -- mocipomiye', language: 'run poorly -- mocipomiye'},
               {name: 'run smoothly -- wolesson', language: 'run smoothly -- wolesson'},
               {name: 'run smoothly -- wolessu', language: 'run smoothly -- wolessu'},
               {name: 'run thus -- olhute', language: 'run thus -- olhute'},
               {name: 'run thus -- oliqaskuwiw', language: 'run thus -- oliqaskuwiw'},
               {name: 'run thus -- oluhute', language: 'run thus -- oluhute'},
               {name: 'run to something and gets in -- tepiphuwe', language: 'run to something and gets in -- tepiphuwe'},
               {name: 'run with head down -- cituwamqehtawe', language: 'run with head down -- cituwamqehtawe'},
               {name: 'sag -- akonahte', language: 'sag -- akonahte'},
               {name: 'sail along -- pomekson', language: 'sail along -- pomekson'},
               {name: 'sail along -- pomeksu', language: 'sail along -- pomeksu'},
               {name: 'sail along -- pomeksuwiw', language: 'sail along -- pomeksuwiw'},
               {name: 'sail around -- yaleksu', language: 'sail around -- yaleksu'},
               {name: 'sail out -- nutiyeksu', language: 'sail out -- nutiyeksu'},
               {name: 'save for future -- sepuwiw', language: 'save for future -- sepuwiw'},
               {name: 'save game -- enuwaqhike', language: 'save game -- enuwaqhike'},
               {name: 'saw -- liksawe', language: 'saw -- liksawe'},
               {name: 'saw logs into sections -- nalikapskiksawe', language: 'saw logs into sections -- nalikapskiksawe'},
               {name: 'say -- itom', language: 'say -- itom'},
               {name: 'say something wrong -- wapolotunehe', language: 'say something wrong -- wapolotunehe'},
               {name: 'say wrong thing -- wapolewestu', language: 'say wrong thing -- wapolewestu'},
               {name: 'scatter in the wind -- sisselamson', language: 'scatter in the wind -- sisselamson'},
               {name: 'scavenge -- mamatuwessu', language: 'scavenge -- mamatuwessu'},
               {name: 'scavenge -- mamatuwoluhke', language: 'scavenge -- mamatuwoluhke'},
               {name: 'scavenge driftwood for use as firewood -- mamatuwokonse', language: 'scavenge driftwood for use as firewood -- mamatuwokonse'},
               {name: 'scold -- koluluwe', language: 'scold -- koluluwe'},
               {name: 'scold a lot -- koluluwesku', language: 'scold a lot -- koluluwesku'},
               {name: 'scoop sugar -- lamkukhike', language: 'scoop sugar -- lamkukhike'},
               {name: 'scout ahead -- nihkanapu', language: 'scout ahead -- nihkanapu'},
               {name: 'scowl -- kiniqewu', language: 'scowl -- kiniqewu'},
               {name: 'scream -- masqolamu', language: 'scream -- masqolamu'},
               {name: 'see -- nomihtu', language: 'see -- nomihtu'},
               {name: 'see falsely a deer) -- pisuwapiqehe', language: 'see falsely a deer) -- pisuwapiqehe'},
               {name: 'see self -- nomiyusu', language: 'see self -- nomiyusu'},
               {name: 'see solution problem) -- sapapotom', language: 'see solution problem) -- sapapotom'},
               {name: 'see something -- lapiqe', language: 'see something -- lapiqe'},
               {name: 'see something good -- wolapiqe', language: 'see something good -- wolapiqe'},
               {name: 'see something good -- wolapiqetutom', language: 'see something good -- wolapiqetutom'},
               {name: 'see something strange -- assokapiqe', language: 'see something strange -- assokapiqe'},
               {name: 'see something that isn\'t really there -- pisuwinomihtu', language: 'see something that isn\'t really there -- pisuwinomihtu'},
               {name: 'see well -- wolapu', language: 'see well -- wolapu'},
               {name: 'see what will happen to self in future -- asihta', language: 'see what will happen to self in future -- asihta'},
               {name: 'seem easy -- komasitahamqosu', language: 'seem easy -- komasitahamqosu'},
               {name: 'seem easy to do -- komasitahamqot', language: 'seem easy to do -- komasitahamqot'},
               {name: 'seem fast -- nokosatahamqot', language: 'seem fast -- nokosatahamqot'},
               {name: 'seem lonely -- kiwacitahamqosu', language: 'seem lonely -- kiwacitahamqosu'},
               {name: 'seem lonely -- kiwacitahamqot', language: 'seem lonely -- kiwacitahamqot'},
               {name: 'sell everything s/he has -- kihkayankuwe', language: 'sell everything s/he has -- kihkayankuwe'},
               {name: 'sell self -- ankuwekhusu', language: 'sell self -- ankuwekhusu'},
               {name: 'separate fighters -- cocepehtahsu', language: 'separate fighters -- cocepehtahsu'},
               {name: 'serve as soldier -- somakonossuwiw', language: 'serve as soldier -- somakonossuwiw'},
               {name: 'serve beverages -- pithope', language: 'serve beverages -- pithope'},
               {name: 'serve food -- tephike', language: 'serve food -- tephike'},
               {name: 'serve papers -- wikhikonihike', language: 'serve papers -- wikhikonihike'},
               {name: 'serve self -- ahcelessu', language: 'serve self -- ahcelessu'},
               {name: 'serve self food -- tephoma', language: 'serve self food -- tephoma'},
               {name: 'serve the purpose -- kisesson', language: 'serve the purpose -- kisesson'},
               {name: 'serve the purpose -- kisessu', language: 'serve the purpose -- kisessu'},
               {name: 'set -- kiye', language: 'set -- kiye'},
               {name: 'set fires -- psqolehtahsu', language: 'set fires -- psqolehtahsu'},
               {name: 'set the table -- wolicuwehtahsu', language: 'set the table -- wolicuwehtahsu'},
               {name: 'set traps -- punawe', language: 'set traps -- punawe'},
               {name: 'set traps around -- yalipunawe', language: 'set traps around -- yalipunawe'},
               {name: 'set traps in winter -- punsoqe', language: 'set traps in winter -- punsoqe'},
               {name: 'set traps out in all directions -- sissepunawe', language: 'set traps out in all directions -- sissepunawe'},
               {name: 'settle unevenly -- asuwuhsewiw', language: 'settle unevenly -- asuwuhsewiw'},
               {name: 'sew -- liqahsu', language: 'sew -- liqahsu'},
               {name: 'sew continuous straight line -- sasokapektihike', language: 'sew continuous straight line -- sasokapektihike'},
               {name: 'sew fancily -- amalhiqahsu', language: 'sew fancily -- amalhiqahsu'},
               {name: 'sew fast -- sahsapektihike', language: 'sew fast -- sahsapektihike'},
               {name: 'sew rapidly -- nokosaqahsu', language: 'sew rapidly -- nokosaqahsu'},
               {name: 'sew slowly -- nakaqahsu', language: 'sew slowly -- nakaqahsu'},
               {name: 'sew with closely spaced s/he weaves closely -- cihciqapektihike', language: 'sew with closely spaced s/he weaves closely -- cihciqapektihike'},
               {name: 'shake -- matekihpute', language: 'shake -- matekihpute'},
               {name: 'shake due to thunder -- tihkehpute', language: 'shake due to thunder -- tihkehpute'},
               {name: 'shake from illness -- nahnakihpusu', language: 'shake from illness -- nahnakihpusu'},
               {name: 'shake head rapidly -- wisayatpehpusu', language: 'shake head rapidly -- wisayatpehpusu'},
               {name: 'shake loose -- monihpusu', language: 'shake loose -- monihpusu'},
               {name: 'shake self to remove water from fur -- puwaskahpulsu', language: 'shake self to remove water from fur -- puwaskahpulsu'},
               {name: 'shake uncontrollably -- acikcehpute', language: 'shake uncontrollably -- acikcehpute'},
               {name: 'shake uncontrollably -- milikcehpute', language: 'shake uncontrollably -- milikcehpute'},
               {name: 'shake violently -- matoliqessu', language: 'shake violently -- matoliqessu'},
               {name: 'shake water snow off tail -- puwaskahqaluwehpulsu', language: 'shake water snow off tail -- puwaskahqaluwehpulsu'},
               {name: 'shake way out -- nutehpute', language: 'shake way out -- nutehpute'},
               {name: 'shape roughly by chopping with hatchet -- ciktihike', language: 'shape roughly by chopping with hatchet -- ciktihike'},
               {name: 'share bed -- wihpekemu', language: 'share bed -- wihpekemu'},
               {name: 'shave self -- psqihtuwuhusu', language: 'shave self -- psqihtuwuhusu'},
               {name: 'shed hair -- atuwe', language: 'shed hair -- atuwe'},
               {name: 'shed hair -- pskuwhe', language: 'shed hair -- pskuwhe'},
               {name: 'shift abruptly to new location -- atahtuwesson', language: 'shift abruptly to new location -- atahtuwesson'},
               {name: 'shift another position -- atte', language: 'shift another position -- atte'},
               {name: 'shift position in seat -- atopu', language: 'shift position in seat -- atopu'},
               {name: 'shine a light -- wahse', language: 'shine a light -- wahse'},
               {name: 'shine brightly -- seskahtuwe', language: 'shine brightly -- seskahtuwe'},
               {name: 'shine brilliantly -- seskipitiyahte', language: 'shine brilliantly -- seskipitiyahte'},
               {name: 'shine forth -- sakhahtuwe', language: 'shine forth -- sakhahtuwe'},
               {name: 'shine forth brightly -- sakhahtuwessu', language: 'shine forth brightly -- sakhahtuwessu'},
               {name: 'shine from there -- tahtuwe', language: 'shine from there -- tahtuwe'},
               {name: 'shine in -- ksiyahtuwe', language: 'shine in -- ksiyahtuwe'},
               {name: 'shine light around while walking -- yalahsenike', language: 'shine light around while walking -- yalahsenike'},
               {name: 'shine light around with torch -- yalahse', language: 'shine light around with torch -- yalahse'},
               {name: 'shine red -- pqahsu', language: 'shine red -- pqahsu'},
               {name: 'shine white -- wapahtuwe', language: 'shine white -- wapahtuwe'},
               {name: 'shingle -- psiksokuhsishike', language: 'shingle -- psiksokuhsishike'},
               {name: 'shoot ahead of moving target -- etqihike', language: 'shoot ahead of moving target -- etqihike'},
               {name: 'shoot from there -- cipeskhike', language: 'shoot from there -- cipeskhike'},
               {name: 'shoot off -- peskote', language: 'shoot off -- peskote'},
               {name: 'shoot quickly -- sahsipeskote', language: 'shoot quickly -- sahsipeskote'},
               {name: 'shoot self -- peskhusu', language: 'shoot self -- peskhusu'},
               {name: 'shop for groceries -- wihqonike', language: 'shop for groceries -- wihqonike'},
               {name: 'shorten cold -- wiqoton', language: 'shorten cold -- wiqoton'},
               {name: 'shorten contracts suddenly -- catokesson', language: 'shorten contracts suddenly -- catokesson'},
               {name: 'shout as loud as possible -- memhuwikakaluwe', language: 'shout as loud as possible -- memhuwikakaluwe'},
               {name: 'shovel -- sapolihike', language: 'shovel -- sapolihike'},
               {name: 'shovel snow -- okalhike', language: 'shovel snow -- okalhike'},
               {name: 'show -- messunom', language: 'show -- messunom'},
               {name: 'show in form of lump -- sakhapskessu', language: 'show in form of lump -- sakhapskessu'},
               {name: 'show quickly -- wapekesson', language: 'show quickly -- wapekesson'},
               {name: 'show quickly -- wapekessu', language: 'show quickly -- wapekessu'},
               {name: 'show up -- wapikcekson', language: 'show up -- wapikcekson'},
               {name: 'show up in one place and then another -- iqonahte', language: 'show up in one place and then another -- iqonahte'},
               {name: 'show white when it flickers -- wapikcehpute', language: 'show white when it flickers -- wapikcehpute'},
               {name: 'shriek -- cipokocesu', language: 'shriek -- cipokocesu'},
               {name: 'shrink from heat -- wihqihtaqote', language: 'shrink from heat -- wihqihtaqote'},
               {name: 'shrink in width from drying -- cihciqipahsu', language: 'shrink in width from drying -- cihciqipahsu'},
               {name: 'shrink in width from drying -- cihciqipahte', language: 'shrink in width from drying -- cihciqipahte'},
               {name: 'shrug shoulders -- ewephokessu', language: 'shrug shoulders -- ewephokessu'},
               {name: 'shuck corn -- pqonaskehtahsu', language: 'shuck corn -- pqonaskehtahsu'},
               {name: 'shut off -- kahsuwessu', language: 'shut off -- kahsuwessu'},
               {name: 'shut up -- costaqsu', language: 'shut up -- costaqsu'},
               {name: 'sign -- sayonuwiw', language: 'sign -- sayonuwiw'},
               {name: 'signal for curve ball -- pimsqitom', language: 'signal for curve ball -- pimsqitom'},
               {name: 'sing as s/he enters -- ksentu', language: 'sing as s/he enters -- ksentu'},
               {name: 'sing as s/he goes away -- olomintu', language: 'sing as s/he goes away -- olomintu'},
               {name: 'sing differently -- piluwintu', language: 'sing differently -- piluwintu'},
               {name: 'sing hymn religious song -- imiyewintu', language: 'sing hymn religious song -- imiyewintu'},
               {name: 'sing in Passamaquoddy-Maliseet -- skicinuwintu', language: 'sing in Passamaquoddy-Maliseet -- skicinuwintu'},
               {name: 'sing in the distance -- pihcintu', language: 'sing in the distance -- pihcintu'},
               {name: 'sing mockingly -- malikintu', language: 'sing mockingly -- malikintu'},
               {name: 'sing quickly -- kakawintu', language: 'sing quickly -- kakawintu'},
               {name: 'sing so much -- kehsintu', language: 'sing so much -- kehsintu'},
               {name: 'sing thus -- lintu', language: 'sing thus -- lintu'},
               {name: 'sing while moving along -- pomintu', language: 'sing while moving along -- pomintu'},
               {name: 'sink -- ktasson', language: 'sink -- ktasson'},
               {name: 'sink in -- piscoke', language: 'sink in -- piscoke'},
               {name: 'sink until water covers mast of boat -- kaskitomuwehe', language: 'sink until water covers mast of boat -- kaskitomuwehe'},
               {name: 'sire calves -- kuhusiske', language: 'sire calves -- kuhusiske'},
               {name: 'sit -- cikciyepu', language: 'sit -- cikciyepu'},
               {name: 'sit all the way in -- komte', language: 'sit all the way in -- komte'},
               {name: 'sit alone -- qotuhkahqepu', language: 'sit alone -- qotuhkahqepu'},
               {name: 'sit apart -- coceppu', language: 'sit apart -- coceppu'},
               {name: 'sit around -- yalqepu', language: 'sit around -- yalqepu'},
               {name: 'sit at front -- nihkanqepu', language: 'sit at front -- nihkanqepu'},
               {name: 'sit at low level -- nutapu', language: 'sit at low level -- nutapu'},
               {name: 'sit back -- pihcahte', language: 'sit back -- pihcahte'},
               {name: 'sit back -- sehtapu', language: 'sit back -- sehtapu'},
               {name: 'sit badly -- mocopu', language: 'sit badly -- mocopu'},
               {name: 'sit close -- sitopu', language: 'sit close -- sitopu'},
               {name: 'sit differently -- piluwopu', language: 'sit differently -- piluwopu'},
               {name: 'sit down -- opu', language: 'sit down -- opu'},
               {name: 'sit down quickly -- opessu', language: 'sit down quickly -- opessu'},
               {name: 'sit facing away -- olomuhkepu', language: 'sit facing away -- olomuhkepu'},
               {name: 'sit facing this way -- ckuhqepu', language: 'sit facing this way -- ckuhqepu'},
               {name: 'sit facing toward here with mouth open -- ckuhtunepu', language: 'sit facing toward here with mouth open -- ckuhtunepu'},
               {name: 'sit for a long time -- sipkopu', language: 'sit for a long time -- sipkopu'},
               {name: 'sit for so long a time -- qonqepu', language: 'sit for so long a time -- qonqepu'},
               {name: 'sit in -- teppu', language: 'sit in -- teppu'},
               {name: 'sit in -- tepte', language: 'sit in -- tepte'},
               {name: 'sit in dark -- piskopu', language: 'sit in dark -- piskopu'},
               {name: 'sit in different positions -- milopu', language: 'sit in different positions -- milopu'},
               {name: 'sit in water -- cupte', language: 'sit in water -- cupte'},
               {name: 'sit in water -- cuwappu', language: 'sit in water -- cuwappu'},
               {name: 'sit in wrong position -- wapolopu', language: 'sit in wrong position -- wapolopu'},
               {name: 'sit leaning away -- olomaskotepu', language: 'sit leaning away -- olomaskotepu'},
               {name: 'sit low -- cinte', language: 'sit low -- cinte'},
               {name: 'sit on eggs -- ksossiyehsin', language: 'sit on eggs -- ksossiyehsin'},
               {name: 'sit protruding view -- sakhuhqepu', language: 'sit protruding view -- sakhuhqepu'},
               {name: 'sit proudly -- palqepu', language: 'sit proudly -- palqepu'},
               {name: 'sit slumped over -- aqacokopu', language: 'sit slumped over -- aqacokopu'},
               {name: 'sit slumped over -- asuwocokopu', language: 'sit slumped over -- asuwocokopu'},
               {name: 'sit slumped over -- sukacokopu', language: 'sit slumped over -- sukacokopu'},
               {name: 'sit so as to block -- komitqepu', language: 'sit so as to block -- komitqepu'},
               {name: 'sit still -- sankewopu', language: 'sit still -- sankewopu'},
               {name: 'sit there repeatedly -- ahpu', language: 'sit there repeatedly -- ahpu'},
               {name: 'sit thus -- lahte', language: 'sit thus -- lahte'},
               {name: 'sit thus -- olqepu', language: 'sit thus -- olqepu'},
               {name: 'sit to some point -- tokkopu', language: 'sit to some point -- tokkopu'},
               {name: 'sit too much -- samqepu', language: 'sit too much -- samqepu'},
               {name: 'sit up high -- spopu', language: 'sit up high -- spopu'},
               {name: 'sit up straight -- nomotahkapu', language: 'sit up straight -- nomotahkapu'},
               {name: 'sit upright -- eweppu', language: 'sit upright -- eweppu'},
               {name: 'sit with head high -- spatopu', language: 'sit with head high -- spatopu'},
               {name: 'sit with legs crossed -- asuwikonatepu', language: 'sit with legs crossed -- asuwikonatepu'},
               {name: 'sit with legs straight out in front -- sasokuhqepu', language: 'sit with legs straight out in front -- sasokuhqepu'},
               {name: 'sit with legs together -- astuwikonatepu', language: 'sit with legs together -- astuwikonatepu'},
               {name: 'sit with other(s) -- witqepu', language: 'sit with other(s) -- witqepu'},
               {name: 'sit with rear end in something -- tepapskopu', language: 'sit with rear end in something -- tepapskopu'},
               {name: 'skate along -- pomikopomu', language: 'skate along -- pomikopomu'},
               {name: 'skate around -- yalikopomu', language: 'skate around -- yalikopomu'},
               {name: 'skin -- psehtahsu', language: 'skin -- psehtahsu'},
               {name: 'slam shut -- kpasokihtehson', language: 'slam shut -- kpasokihtehson'},
               {name: 'slam shut -- kpotiyahke', language: 'slam shut -- kpotiyahke'},
               {name: 'slaughter by piercing heart with knife -- putakhike', language: 'slaughter by piercing heart with knife -- putakhike'},
               {name: 'sleep day -- spoteqsu', language: 'sleep day -- spoteqsu'},
               {name: 'sleep deeply -- kakawqahsu', language: 'sleep deeply -- kakawqahsu'},
               {name: 'sleep for so long a time -- qonqahsin', language: 'sleep for so long a time -- qonqahsin'},
               {name: 'sleep for so long a time -- qonqahsu', language: 'sleep for so long a time -- qonqahsu'},
               {name: 'sleep forever -- askomoqsu', language: 'sleep forever -- askomoqsu'},
               {name: 'sleep in someone else\'s house so that person won\'t be alone -- nasqam', language: 'sleep in someone else\'s house so that person won\'t be alone -- nasqam'},
               {name: 'sleep late -- ksatqahsu', language: 'sleep late -- ksatqahsu'},
               {name: 'sleep late -- metsoqsu', language: 'sleep late -- metsoqsu'},
               {name: 'sleep naked -- emesokoqsu', language: 'sleep naked -- emesokoqsu'},
               {name: 'sleep naked -- setoleqsu', language: 'sleep naked -- setoleqsu'},
               {name: 'sleep on -- tuwonekosu', language: 'sleep on -- tuwonekosu'},
               {name: 'sleep over -- ktoqonu', language: 'sleep over -- ktoqonu'},
               {name: 'sleep peacefully -- sankewqahsin', language: 'sleep peacefully -- sankewqahsin'},
               {name: 'sleep peacefully -- sankewqahsu', language: 'sleep peacefully -- sankewqahsu'},
               {name: 'sleep peacefully contentedly -- sankewiqahsu', language: 'sleep peacefully contentedly -- sankewiqahsu'},
               {name: 'sleep so soundly -- tutqahsin', language: 'sleep so soundly -- tutqahsin'},
               {name: 'sleep so soundly -- tutqahsu', language: 'sleep so soundly -- tutqahsu'},
               {name: 'sleep through winter -- punoqsu', language: 'sleep through winter -- punoqsu'},
               {name: 'sleep thus -- loqsu', language: 'sleep thus -- loqsu'},
               {name: 'sleep well -- wolqahsu', language: 'sleep well -- wolqahsu'},
               {name: 'sleep with mouth open -- apqotuneqsu', language: 'sleep with mouth open -- apqotuneqsu'},
               {name: 'slice -- leksawe', language: 'slice -- leksawe'},
               {name: 'slice thick -- pahseksawe', language: 'slice thick -- pahseksawe'},
               {name: 'slice thin -- pipuweksawe', language: 'slice thin -- pipuweksawe'},
               {name: 'slide along by holding on to bumper of car as it goes along slowly on icy surface -- conokuhulike', language: 'slide along by holding on to bumper of car as it goes along slowly on icy surface -- conokuhulike'},
               {name: 'slide ashore -- cicokayessu', language: 'slide ashore -- cicokayessu'},
               {name: 'slide back quickly -- sehtayacqesson', language: 'slide back quickly -- sehtayacqesson'},
               {name: 'slide out -- nutiyacqesson', language: 'slide out -- nutiyacqesson'},
               {name: 'slip -- sahsoloqhoma', language: 'slip -- sahsoloqhoma'},
               {name: 'slip -- sashoma', language: 'slip -- sashoma'},
               {name: 'slip when stepping -- sastehkom', language: 'slip when stepping -- sastehkom'},
               {name: 'slip when stepping on something soft -- sascoktehkom', language: 'slip when stepping on something soft -- sascoktehkom'},
               {name: 'slither -- pomtokiye', language: 'slither -- pomtokiye'},
               {name: 'slither along -- pomatokitutom', language: 'slither along -- pomatokitutom'},
               {name: 'slither through difficult passage taking care not to get any part of body caught on something -- monatokitutom', language: 'slither through difficult passage taking care not to get any part of body caught on something -- monatokitutom'},
               {name: 'slope down -- kcitahkomike', language: 'slope down -- kcitahkomike'},
               {name: 'slope down -- wakcuwikon', language: 'slope down -- wakcuwikon'},
               {name: 'slow self down -- nakahkalsu', language: 'slow self down -- nakahkalsu'},
               {name: 'slow self down -- nakayahkalsu', language: 'slow self down -- nakayahkalsu'},
               {name: 'slurp -- motiyapuwe', language: 'slurp -- motiyapuwe'},
               {name: 'smear self -- sissecokuhusu', language: 'smear self -- sissecokuhusu'},
               {name: 'smell bad -- mocimahsu', language: 'smell bad -- mocimahsu'},
               {name: 'smell bad -- mocimahte', language: 'smell bad -- mocimahte'},
               {name: 'smell bad to self -- mocipsuhusu', language: 'smell bad to self -- mocipsuhusu'},
               {name: 'smell dank being closed up -- wehqamahte', language: 'smell dank being closed up -- wehqamahte'},
               {name: 'smell good -- wolimahsu', language: 'smell good -- wolimahsu'},
               {name: 'smell good -- wolimahte', language: 'smell good -- wolimahte'},
               {name: 'smell like excrement -- mickonimahte', language: 'smell like excrement -- mickonimahte'},
               {name: 'smell like pennies -- sensimahsu', language: 'smell like pennies -- sensimahsu'},
               {name: 'smell like sweetgrass -- suwitokolasimahte', language: 'smell like sweetgrass -- suwitokolasimahte'},
               {name: 'smell new -- pileyawimahsu', language: 'smell new -- pileyawimahsu'},
               {name: 'smell of feces -- mickonimahsu', language: 'smell of feces -- mickonimahsu'},
               {name: 'smell sour -- psehson', language: 'smell sour -- psehson'},
               {name: 'smell thus -- limahsu', language: 'smell thus -- limahsu'},
               {name: 'smell thus -- limahte', language: 'smell thus -- limahte'},
               {name: 'smile -- lelomu', language: 'smile -- lelomu'},
               {name: 'smile -- siktelomu', language: 'smile -- siktelomu'},
               {name: 'smile -- tomokonuwessu', language: 'smile -- tomokonuwessu'},
               {name: 'smile slightly -- wolomuwiqewu', language: 'smile slightly -- wolomuwiqewu'},
               {name: 'smile small smile -- siktelomuwiqewu', language: 'smile small smile -- siktelomuwiqewu'},
               {name: 'smoke -- pkotesawe', language: 'smoke -- pkotesawe'},
               {name: 'smoke -- tome', language: 'smoke -- tome'},
               {name: 'smoke -- wikpolastike', language: 'smoke -- wikpolastike'},
               {name: 'smoke a lot -- wikiwtome', language: 'smoke a lot -- wikiwtome'},
               {name: 'smoke continuously -- etutalokittiyewtomat', language: 'smoke continuously -- etutalokittiyewtomat'},
               {name: 'smoke heavily -- tkiqiwtome', language: 'smoke heavily -- tkiqiwtome'},
               {name: 'smoke in public -- messuwiwtome', language: 'smoke in public -- messuwiwtome'},
               {name: 'smoke often -- sawewtome', language: 'smoke often -- sawewtome'},
               {name: 'smoke secretly -- kaciwtome', language: 'smoke secretly -- kaciwtome'},
               {name: 'smoke secretly -- komuciwtome', language: 'smoke secretly -- komuciwtome'},
               {name: 'smoke slightly -- nulomipkotete', language: 'smoke slightly -- nulomipkotete'},
               {name: 'smoke too much -- samiwtome', language: 'smoke too much -- samiwtome'},
               {name: 'smoke with pipe stem held firmly between teeth -- koliwtome', language: 'smoke with pipe stem held firmly between teeth -- koliwtome'},
               {name: 'smolder -- nulomipkotete', language: 'smolder -- nulomipkotete'},
               {name: 'smother in smoke -- siktepskelusu', language: 'smother in smoke -- siktepskelusu'},
               {name: 'snap back -- wiqtoktehson', language: 'snap back -- wiqtoktehson'},
               {name: 'snap backward -- sehtaskehtehson', language: 'snap backward -- sehtaskehtehson'},
               {name: 'snap when flicked -- siptoktehson', language: 'snap when flicked -- siptoktehson'},
               {name: 'snare -- lapektuwe', language: 'snare -- lapektuwe'},
               {name: 'sneak a drink -- komuthope', language: 'sneak a drink -- komuthope'},
               {name: 'sneak a peek -- kikimapu', language: 'sneak a peek -- kikimapu'},
               {name: 'sneak a quick peek -- kikimapessu', language: 'sneak a quick peek -- kikimapessu'},
               {name: 'sneak away -- komucimacephuwe', language: 'sneak away -- komucimacephuwe'},
               {name: 'sneak away -- komuciphuwe', language: 'sneak away -- komuciphuwe'},
               {name: 'sneak away -- komutonalsu', language: 'sneak away -- komutonalsu'},
               {name: 'sneak out silently -- kikiminutehe', language: 'sneak out silently -- kikiminutehe'},
               {name: 'sneak punches -- qoskuhtihikesku', language: 'sneak punches -- qoskuhtihikesku'},
               {name: 'sneak punches -- sqihtihike', language: 'sneak punches -- sqihtihike'},
               {name: 'sneeze -- eksku', language: 'sneeze -- eksku'},
               {name: 'snore -- ciqoleqsu', language: 'snore -- ciqoleqsu'},
               {name: 'snow -- psan', language: 'snow -- psan'},
               {name: 'snow backward -- sehtapsan', language: 'snow backward -- sehtapsan'},
               {name: 'snow flakes that are so big -- tutekipsan', language: 'snow flakes that are so big -- tutekipsan'},
               {name: 'snow in big flakes -- kuhkukhahsuwipsan', language: 'snow in big flakes -- kuhkukhahsuwipsan'},
               {name: 'snow often -- miskipsan', language: 'snow often -- miskipsan'},
               {name: 'snow so hard -- tucipsan', language: 'snow so hard -- tucipsan'},
               {name: 'snowshoe around -- yalakomasu', language: 'snowshoe around -- yalakomasu'},
               {name: 'snowshoe around -- yalakomihom', language: 'snowshoe around -- yalakomihom'},
               {name: 'soak before washing -- kasoqithike', language: 'soak before washing -- kasoqithike'},
               {name: 'soar higher in order to swoop down -- ewepipakskalituwiye', language: 'soar higher in order to swoop down -- ewepipakskalituwiye'},
               {name: 'sober up -- pahkiye', language: 'sober up -- pahkiye'},
               {name: 'soften from water falling on it -- nuhkamkapawe', language: 'soften from water falling on it -- nuhkamkapawe'},
               {name: 'soil pants -- mocihponolsu', language: 'soil pants -- mocihponolsu'},
               {name: 'soil pants accidentally -- pskotolamessu', language: 'soil pants accidentally -- pskotolamessu'},
               {name: 'soil self -- pskocilsu', language: 'soil self -- pskocilsu'},
               {name: 'soil self from laughing -- pskocelomu', language: 'soil self from laughing -- pskocelomu'},
               {name: 'soil self while playing -- pskotayye', language: 'soil self while playing -- pskotayye'},
               {name: 'sound Passamaquoddy -- skicinuwihtaqot', language: 'sound Passamaquoddy -- skicinuwihtaqot'},
               {name: 'sound as if he is over there -- oloqihtaqsu', language: 'sound as if he is over there -- oloqihtaqsu'},
               {name: 'sound as if it is far away -- nawtaqot', language: 'sound as if it is far away -- nawtaqot'},
               {name: 'sound as if it is far away -- pihcehtaqot', language: 'sound as if it is far away -- pihcehtaqot'},
               {name: 'sound as if it is up ahead -- nihkantaqot', language: 'sound as if it is up ahead -- nihkantaqot'},
               {name: 'sound as if nose is stuffed up -- kpihtonehtaqsu', language: 'sound as if nose is stuffed up -- kpihtonehtaqsu'},
               {name: 'sound as if s/he is coming toward here -- ckuhtaqsu', language: 'sound as if s/he is coming toward here -- ckuhtaqsu'},
               {name: 'sound as if s/he is far away -- pihcehtaqsu', language: 'sound as if s/he is far away -- pihcehtaqsu'},
               {name: 'sound as if there is a lot of h/ -- ktanaqtaqsu', language: 'sound as if there is a lot of h/ -- ktanaqtaqsu'},
               {name: 'sound as if there is a lot of it -- ktanaqtaqot', language: 'sound as if there is a lot of it -- ktanaqtaqot'},
               {name: 'sound awkward -- aqtaqot', language: 'sound awkward -- aqtaqot'},
               {name: 'sound awkward -- aqtaqsu', language: 'sound awkward -- aqtaqsu'},
               {name: 'sound bad -- mocihtaqot', language: 'sound bad -- mocihtaqot'},
               {name: 'sound bad -- mocihtaqsu', language: 'sound bad -- mocihtaqsu'},
               {name: 'sound bad to self -- mocsotasu', language: 'sound bad to self -- mocsotasu'},
               {name: 'sound boring -- koluskihtaqsu', language: 'sound boring -- koluskihtaqsu'},
               {name: 'sound close -- wecuwawtaqot', language: 'sound close -- wecuwawtaqot'},
               {name: 'sound close -- wecuwawtaqsu', language: 'sound close -- wecuwawtaqsu'},
               {name: 'sound crazy -- wonatominehtaqsu', language: 'sound crazy -- wonatominehtaqsu'},
               {name: 'sound cute -- miyawtaqot', language: 'sound cute -- miyawtaqot'},
               {name: 'sound cute -- miyawtaqsu', language: 'sound cute -- miyawtaqsu'},
               {name: 'sound cute -- wihqihtaqot', language: 'sound cute -- wihqihtaqot'},
               {name: 'sound cute -- wihqihtaqsu', language: 'sound cute -- wihqihtaqsu'},
               {name: 'sound depressed -- celtaqsu', language: 'sound depressed -- celtaqsu'},
               {name: 'sound different -- piluwihtaqot', language: 'sound different -- piluwihtaqot'},
               {name: 'sound different -- piluwihtaqsu', language: 'sound different -- piluwihtaqsu'},
               {name: 'sound different from what was expected -- piltuhtaqot', language: 'sound different from what was expected -- piltuhtaqot'},
               {name: 'sound different from what was expected -- piltuhtaqsu', language: 'sound different from what was expected -- piltuhtaqsu'},
               {name: 'sound easy -- komastaqsu', language: 'sound easy -- komastaqsu'},
               {name: 'sound easy to do -- komastaqot', language: 'sound easy to do -- komastaqot'},
               {name: 'sound empty -- siqehtaqot', language: 'sound empty -- siqehtaqot'},
               {name: 'sound empty -- siqehtaqsu', language: 'sound empty -- siqehtaqsu'},
               {name: 'sound familiar -- pehqihtaqot', language: 'sound familiar -- pehqihtaqot'},
               {name: 'sound fancy -- paltaqot', language: 'sound fancy -- paltaqot'},
               {name: 'sound far away -- nawtaqsu', language: 'sound far away -- nawtaqsu'},
               {name: 'sound farther and farther away -- olominawtaqot', language: 'sound farther and farther away -- olominawtaqot'},
               {name: 'sound fast -- sastaqot', language: 'sound fast -- sastaqot'},
               {name: 'sound for a long time -- sipkihtaqot', language: 'sound for a long time -- sipkihtaqot'},
               {name: 'sound good -- wolihtaqsu', language: 'sound good -- wolihtaqsu'},
               {name: 'sound good -- woltaqot', language: 'sound good -- woltaqot'},
               {name: 'sound hollow -- piqehtaqot', language: 'sound hollow -- piqehtaqot'},
               {name: 'sound hollow -- piqehtaqsu', language: 'sound hollow -- piqehtaqsu'},
               {name: 'sound like a song -- lintuwakonihtaqot', language: 'sound like a song -- lintuwakonihtaqot'},
               {name: 'sound lonely -- kiwattaqot', language: 'sound lonely -- kiwattaqot'},
               {name: 'sound loud -- kintaqot', language: 'sound loud -- kintaqot'},
               {name: 'sound new -- pileyawihtaqot', language: 'sound new -- pileyawihtaqot'},
               {name: 'sound new -- piltaqot', language: 'sound new -- piltaqot'},
               {name: 'sound odd -- assoktaqot', language: 'sound odd -- assoktaqot'},
               {name: 'sound old -- kanihtaqot', language: 'sound old -- kanihtaqot'},
               {name: 'sound pitiful -- ktomaktaqot', language: 'sound pitiful -- ktomaktaqot'},
               {name: 'sound pitiful -- ktomaktaqsu', language: 'sound pitiful -- ktomaktaqsu'},
               {name: 'sound real -- qinotewihtaqot', language: 'sound real -- qinotewihtaqot'},
               {name: 'sound scary -- cipalokihtaqot', language: 'sound scary -- cipalokihtaqot'},
               {name: 'sound scary -- cipalokihtaqsu', language: 'sound scary -- cipalokihtaqsu'},
               {name: 'sound scary eerie -- cipihtaqot', language: 'sound scary eerie -- cipihtaqot'},
               {name: 'sound slow -- menakattaqot', language: 'sound slow -- menakattaqot'},
               {name: 'sound so many times -- kestaqot', language: 'sound so many times -- kestaqot'},
               {name: 'sound stymied -- coceltaqsu', language: 'sound stymied -- coceltaqsu'},
               {name: 'sound that way -- oloqihtaqot', language: 'sound that way -- oloqihtaqot'},
               {name: 'sound thus -- lihtaqot', language: 'sound thus -- lihtaqot'},
               {name: 'sound thus -- lihtaqsu', language: 'sound thus -- lihtaqsu'},
               {name: 'sound unappealing -- koluskihtaqot', language: 'sound unappealing -- koluskihtaqot'},
               {name: 'space nails close together -- cihciqikolaqhike', language: 'space nails close together -- cihciqikolaqhike'},
               {name: 'space nails wide apart -- kskikolaqhike', language: 'space nails wide apart -- kskikolaqhike'},
               {name: 'spare when hitting -- sqihtehma', language: 'spare when hitting -- sqihtehma'},
               {name: 'sparkle -- milahtuwehpute', language: 'sparkle -- milahtuwehpute'},
               {name: 'sparkle brightly -- ksahtuwesson', language: 'sparkle brightly -- ksahtuwesson'},
               {name: 'sparkle brightly -- seskahtuwehpute', language: 'sparkle brightly -- seskahtuwehpute'},
               {name: 'spatter in all directions -- sissepektehson', language: 'spatter in all directions -- sissepektehson'},
               {name: 'speak \"sky language\" -- musikisqatuwe', language: 'speak \"sky language\" -- musikisqatuwe'},
               {name: 'speak -- kolusu', language: 'speak -- kolusu'},
               {name: 'speak -- latuwe', language: 'speak -- latuwe'},
               {name: 'speak English -- ikolisomanatuwe', language: 'speak English -- ikolisomanatuwe'},
               {name: 'speak French -- polecomonatuwe', language: 'speak French -- polecomonatuwe'},
               {name: 'speak Mi\'kmaq -- mihkomawatuwe', language: 'speak Mi\'kmaq -- mihkomawatuwe'},
               {name: 'speak Passamaquoddy-Maliseet -- skicinuwatuwewiw', language: 'speak Passamaquoddy-Maliseet -- skicinuwatuwewiw'},
               {name: 'speak a different language -- piluwatuwe', language: 'speak a different language -- piluwatuwe'},
               {name: 'speak a variety of languages -- milatuwe', language: 'speak a variety of languages -- milatuwe'},
               {name: 'speak alone -- qotuhkayewestu', language: 'speak alone -- qotuhkayewestu'},
               {name: 'speak angrily -- wisokatuwe', language: 'speak angrily -- wisokatuwe'},
               {name: 'speak at a formal level -- spiqikolusu', language: 'speak at a formal level -- spiqikolusu'},
               {name: 'speak at a high level -- spatuwe', language: 'speak at a high level -- spatuwe'},
               {name: 'speak at a high level -- spiqatuwe', language: 'speak at a high level -- spiqatuwe'},
               {name: 'speak awkwardly -- ahqatuwe', language: 'speak awkwardly -- ahqatuwe'},
               {name: 'speak clearly -- papehtewestu', language: 'speak clearly -- papehtewestu'},
               {name: 'speak differently -- piluwewestuwiw', language: 'speak differently -- piluwewestuwiw'},
               {name: 'speak exactly like -- papehtatuwe', language: 'speak exactly like -- papehtatuwe'},
               {name: 'speak first -- nihkanewestu', language: 'speak first -- nihkanewestu'},
               {name: 'speak from there -- tewestu', language: 'speak from there -- tewestu'},
               {name: 'speak in English -- ikolisomanewestu', language: 'speak in English -- ikolisomanewestu'},
               {name: 'speak in Passamaquoddy -- skicinuwewestu', language: 'speak in Passamaquoddy -- skicinuwewestu'},
               {name: 'speak in secret -- komutewestu', language: 'speak in secret -- komutewestu'},
               {name: 'speak knowledgeably -- kciqahawtaqsu', language: 'speak knowledgeably -- kciqahawtaqsu'},
               {name: 'speak silently -- kikimewestu', language: 'speak silently -- kikimewestu'},
               {name: 'speak slowly -- menakatewestu', language: 'speak slowly -- menakatewestu'},
               {name: 'speak strangely -- assokewestu', language: 'speak strangely -- assokewestu'},
               {name: 'speak thus -- lewestu', language: 'speak thus -- lewestu'},
               {name: 'speak to self -- lewestuwamsu', language: 'speak to self -- lewestuwamsu'},
               {name: 'speak too soon -- miyahsewestu', language: 'speak too soon -- miyahsewestu'},
               {name: 'speak up -- mathuksu', language: 'speak up -- mathuksu'},
               {name: 'speak without meaning to -- kespewestu', language: 'speak without meaning to -- kespewestu'},
               {name: 'spear -- mokahkehke', language: 'spear -- mokahkehke'},
               {name: 'spear -- tokotike', language: 'spear -- tokotike'},
               {name: 'spear flounder -- aneqehsuhke', language: 'spear flounder -- aneqehsuhke'},
               {name: 'spear pollock -- peskotomuhke', language: 'spear pollock -- peskotomuhke'},
               {name: 'spend Sunday -- sonte', language: 'spend Sunday -- sonte'},
               {name: 'spend a lot of time -- skuweyu', language: 'spend a lot of time -- skuweyu'},
               {name: 'spend the fall -- toqaksu', language: 'spend the fall -- toqaksu'},
               {name: 'spend the summer by the ocean -- supeqqe', language: 'spend the summer by the ocean -- supeqqe'},
               {name: 'spend the winter -- punke', language: 'spend the winter -- punke'},
               {name: 'spin follows circular path -- wiwonikiwtahqesson', language: 'spin follows circular path -- wiwonikiwtahqesson'},
               {name: 'spin thread -- sqasuntokuhke', language: 'spin thread -- sqasuntokuhke'},
               {name: 'spit -- sumsqe', language: 'spit -- sumsqe'},
               {name: 'splatter -- sipelcoktehson', language: 'splatter -- sipelcoktehson'},
               {name: 'split hews -- psikhike', language: 'split hews -- psikhike'},
               {name: 'split kindling -- nalahtihike', language: 'split kindling -- nalahtihike'},
               {name: 'split something by following grain -- likonike', language: 'split something by following grain -- likonike'},
               {name: 'split wood following grain by guiding blade as s/he goes along -- psikikonike', language: 'split wood following grain by guiding blade as s/he goes along -- psikikonike'},
               {name: 'spout -- putuwe', language: 'spout -- putuwe'},
               {name: 'spread all over -- nemsotassu', language: 'spread all over -- nemsotassu'},
               {name: 'spread all over -- nemsotayaqhe', language: 'spread all over -- nemsotayaqhe'},
               {name: 'spread as it drips -- sipelpektehson', language: 'spread as it drips -- sipelpektehson'},
               {name: 'spread false stories a lot -- piluwikemosku', language: 'spread false stories a lot -- piluwikemosku'},
               {name: 'spread legs and holds them apart -- mitakonawu', language: 'spread legs and holds them apart -- mitakonawu'},
               {name: 'spread out -- sipelcokesson', language: 'spread out -- sipelcokesson'},
               {name: 'spread out -- sipelekesson', language: 'spread out -- sipelekesson'},
               {name: 'spread out -- sipelekessu', language: 'spread out -- sipelekessu'},
               {name: 'spread out flat -- sokotiyekopu', language: 'spread out flat -- sokotiyekopu'},
               {name: 'spread over whole sky -- nemsotayaluhkiye', language: 'spread over whole sky -- nemsotayaluhkiye'},
               {name: 'spread quickly -- nemsotasson', language: 'spread quickly -- nemsotasson'},
               {name: 'spread sand -- sissiyamkihike', language: 'spread sand -- sissiyamkihike'},
               {name: 'spring into place -- wesuwehtehson', language: 'spring into place -- wesuwehtehson'},
               {name: 'spring it slams shut -- kpasokihtehson', language: 'spring it slams shut -- kpasokihtehson'},
               {name: 'sprinkle -- posoqpelan', language: 'sprinkle -- posoqpelan'},
               {name: 'sprinkle water -- olpekehtahsu', language: 'sprinkle water -- olpekehtahsu'},
               {name: 'sputter -- neqhomuwiw', language: 'sputter -- neqhomuwiw'},
               {name: 'squat down -- nahkahucin', language: 'squat down -- nahkahucin'},
               {name: 'squeeze using hand(s) -- colokonike', language: 'squeeze using hand(s) -- colokonike'},
               {name: 'squeeze using tool -- colokihike', language: 'squeeze using tool -- colokihike'},
               {name: 'squint -- apsalokiqewu', language: 'squint -- apsalokiqewu'},
               {name: 'squint -- puhkalokiqewu', language: 'squint -- puhkalokiqewu'},
               {name: 'stab all the way through -- sapaqtuhusu', language: 'stab all the way through -- sapaqtuhusu'},
               {name: 'stab self -- saptuhusu', language: 'stab self -- saptuhusu'},
               {name: 'stagger -- polomaqe', language: 'stagger -- polomaqe'},
               {name: 'stand -- sehkewiw', language: 'stand -- sehkewiw'},
               {name: 'stand alone -- qotuhkakapuwu', language: 'stand alone -- qotuhkakapuwu'},
               {name: 'stand around -- yalikapuwu', language: 'stand around -- yalikapuwu'},
               {name: 'stand at attention -- tetpitutom', language: 'stand at attention -- tetpitutom'},
               {name: 'stand at front -- nihkanikapuwu', language: 'stand at front -- nihkanikapuwu'},
               {name: 'stand at that distance -- tokkikapuwu', language: 'stand at that distance -- tokkikapuwu'},
               {name: 'stand back -- sehtakapuwu', language: 'stand back -- sehtakapuwu'},
               {name: 'stand completely motionless -- mociyehsuwikapuwu', language: 'stand completely motionless -- mociyehsuwikapuwu'},
               {name: 'stand erect pushing chest out -- somokitutom', language: 'stand erect pushing chest out -- somokitutom'},
               {name: 'stand facing this way -- ckuwikapuwu', language: 'stand facing this way -- ckuwikapuwu'},
               {name: 'stand firmly -- sakolikapuwu', language: 'stand firmly -- sakolikapuwu'},
               {name: 'stand idly -- koluskikapuwu', language: 'stand idly -- koluskikapuwu'},
               {name: 'stand in begging posture -- tutomuwikapuwu', language: 'stand in begging posture -- tutomuwikapuwu'},
               {name: 'stand in sexy -- maskitutom', language: 'stand in sexy -- maskitutom'},
               {name: 'stand in the open -- messuwikapuwu', language: 'stand in the open -- messuwikapuwu'},
               {name: 'stand leaning against something -- apotekapuwu', language: 'stand leaning against something -- apotekapuwu'},
               {name: 'stand shyly -- tqesuwitutom', language: 'stand shyly -- tqesuwitutom'},
               {name: 'stand sideways -- nomocinikapuwu', language: 'stand sideways -- nomocinikapuwu'},
               {name: 'stand still -- sankewikapuwu', language: 'stand still -- sankewikapuwu'},
               {name: 'stand stooped in frightened posture -- ciphucu', language: 'stand stooped in frightened posture -- ciphucu'},
               {name: 'stand straight -- sasokikapuwu', language: 'stand straight -- sasokikapuwu'},
               {name: 'stand tense -- ceconikapuwu', language: 'stand tense -- ceconikapuwu'},
               {name: 'stand thus -- likapuwu', language: 'stand thus -- likapuwu'},
               {name: 'stand up -- sehkesson', language: 'stand up -- sehkesson'},
               {name: 'stand with feet apart -- mitakapuwu', language: 'stand with feet apart -- mitakapuwu'},
               {name: 'stand with hips tilted to one side -- asuwikonikapuwu', language: 'stand with hips tilted to one side -- asuwikonikapuwu'},
               {name: 'stand with legs apart -- mitacaskekapuwu', language: 'stand with legs apart -- mitacaskekapuwu'},
               {name: 'star-gaze -- possesomuhke', language: 'star-gaze -- possesomuhke'},
               {name: 'stare at people -- malaposku', language: 'stare at people -- malaposku'},
               {name: 'stare open-mouthed -- apqotuniyapu', language: 'stare open-mouthed -- apqotuniyapu'},
               {name: 'stare without blinking -- assokalokiqehe', language: 'stare without blinking -- assokalokiqehe'},
               {name: 'start -- stahtuwiw', language: 'start -- stahtuwiw'},
               {name: 'start breathing -- macelatom', language: 'start breathing -- macelatom'},
               {name: 'start digging -- maciyalokihike', language: 'start digging -- maciyalokihike'},
               {name: 'start from there -- cimaciyatokot', language: 'start from there -- cimaciyatokot'},
               {name: 'start going -- macehewiw', language: 'start going -- macehewiw'},
               {name: 'start hauling load -- macenahsuwiw', language: 'start hauling load -- macenahsuwiw'},
               {name: 'start off underwater -- maceqotehe', language: 'start off underwater -- maceqotehe'},
               {name: 'start out carrying items on shoulder -- macenikatom', language: 'start out carrying items on shoulder -- macenikatom'},
               {name: 'start picking berries -- macewisu', language: 'start picking berries -- macewisu'},
               {name: 'start smoking -- macewtome', language: 'start smoking -- macewtome'},
               {name: 'start to braid -- maciyatokonike', language: 'start to braid -- maciyatokonike'},
               {name: 'start to breathe again -- apacinehse', language: 'start to breathe again -- apacinehse'},
               {name: 'start to breathe again -- apatolatom', language: 'start to breathe again -- apatolatom'},
               {name: 'start to burn -- maciyamkolessu', language: 'start to burn -- maciyamkolessu'},
               {name: 'start to carry water -- maciyapilahsu', language: 'start to carry water -- maciyapilahsu'},
               {name: 'start to cook -- maciyahqe', language: 'start to cook -- maciyahqe'},
               {name: 'start to get cold -- macetkeyu', language: 'start to get cold -- macetkeyu'},
               {name: 'start to get sick -- maceksinuhka', language: 'start to get sick -- maceksinuhka'},
               {name: 'start to get windy -- macelamson', language: 'start to get windy -- macelamson'},
               {name: 'start to grow -- macekon', language: 'start to grow -- macekon'},
               {name: 'start to heat up -- macehpote', language: 'start to heat up -- macehpote'},
               {name: 'start to jump -- macetkuhu', language: 'start to jump -- macetkuhu'},
               {name: 'start to leave tracks -- maciyaptu', language: 'start to leave tracks -- maciyaptu'},
               {name: 'start to make a noise -- maciyaku', language: 'start to make a noise -- maciyaku'},
               {name: 'start to make a sound -- macehtaqot', language: 'start to make a sound -- macehtaqot'},
               {name: 'start to pray -- macemiye', language: 'start to pray -- macemiye'},
               {name: 'start to rain -- macelan', language: 'start to rain -- macelan'},
               {name: 'start to scold -- maceluwesu', language: 'start to scold -- maceluwesu'},
               {name: 'start to search -- macekhahsu', language: 'start to search -- macekhahsu'},
               {name: 'start to sing -- macentu', language: 'start to sing -- macentu'},
               {name: 'start to snow -- macepsan', language: 'start to snow -- macepsan'},
               {name: 'start to sprinkle -- macelanisiye', language: 'start to sprinkle -- macelanisiye'},
               {name: 'start to storm -- maciyamoqessu', language: 'start to storm -- maciyamoqessu'},
               {name: 'start to swim -- macehom', language: 'start to swim -- macehom'},
               {name: 'start to talk -- maciyewestu', language: 'start to talk -- maciyewestu'},
               {name: 'start to think -- macetahasu', language: 'start to think -- macetahasu'},
               {name: 'start walking slowly -- macehkenu', language: 'start walking slowly -- macehkenu'},
               {name: 'starve self -- kotuhpikhusu', language: 'starve self -- kotuhpikhusu'},
               {name: 'starve self -- siktelamuhusu', language: 'starve self -- siktelamuhusu'},
               {name: 'stay -- ihhit', language: 'stay -- ihhit'},
               {name: 'stay longer -- ksatke', language: 'stay longer -- ksatke'},
               {name: 'stay longer because of the cold -- ksaccu', language: 'stay longer because of the cold -- ksaccu'},
               {name: 'stay out late at night -- nipehe', language: 'stay out late at night -- nipehe'},
               {name: 'stay out until dawn -- cehqoniye', language: 'stay out until dawn -- cehqoniye'},
               {name: 'stay right there -- ahhit', language: 'stay right there -- ahhit'},
               {name: 'stay right there -- illiqe', language: 'stay right there -- illiqe'},
               {name: 'stay right there -- iyalokittiye', language: 'stay right there -- iyalokittiye'},
               {name: 'stay right there -- lalokittiye', language: 'stay right there -- lalokittiye'},
               {name: 'stay until dark -- piskiyensu', language: 'stay until dark -- piskiyensu'},
               {name: 'stay until the end -- tetoqiiyu', language: 'stay until the end -- tetoqiiyu'},
               {name: 'steal base -- komutone', language: 'steal base -- komutone'},
               {name: 'steal with others -- wicikomutone', language: 'steal with others -- wicikomutone'},
               {name: 'steal wood -- komutse', language: 'steal wood -- komutse'},
               {name: 'steer as s/he goes along -- pomoqittu', language: 'steer as s/he goes along -- pomoqittu'},
               {name: 'step a little farther in a specified direction -- ankuwikapuwu', language: 'step a little farther in a specified direction -- ankuwikapuwu'},
               {name: 'step aside -- semakapuwu', language: 'step aside -- semakapuwu'},
               {name: 'step lively making noise with feet -- tuttestike', language: 'step lively making noise with feet -- tuttestike'},
               {name: 'step over edge and falls -- kaskomelku', language: 'step over edge and falls -- kaskomelku'},
               {name: 'step slowly -- menakatomelku', language: 'step slowly -- menakatomelku'},
               {name: 'stick firing -- mecimahkihtehson', language: 'stick firing -- mecimahkihtehson'},
               {name: 'stiffen -- citoniye', language: 'stiffen -- citoniye'},
               {name: 'sting being cut -- wisokiphukiye', language: 'sting being cut -- wisokiphukiye'},
               {name: 'stink -- mocekimahsu', language: 'stink -- mocekimahsu'},
               {name: 'stock up on food -- nihkaniwihqonike', language: 'stock up on food -- nihkaniwihqonike'},
               {name: 'stoop down -- wakcuhucin', language: 'stoop down -- wakcuhucin'},
               {name: 'stoop with age -- cituwikotone', language: 'stoop with age -- cituwikotone'},
               {name: 'stop -- conopekiw', language: 'stop -- conopekiw'},
               {name: 'stop barking -- eqqile', language: 'stop barking -- eqqile'},
               {name: 'stop being afraid -- eqpayu', language: 'stop being afraid -- eqpayu'},
               {name: 'stop being windy -- conolamsuke', language: 'stop being windy -- conolamsuke'},
               {name: 'stop bleeding -- coniqqiyewiw', language: 'stop bleeding -- coniqqiyewiw'},
               {name: 'stop breathing -- conolatom', language: 'stop breathing -- conolatom'},
               {name: 'stop breathing -- ehqinehse', language: 'stop breathing -- ehqinehse'},
               {name: 'stop breathing -- ehqolamu', language: 'stop breathing -- ehqolamu'},
               {name: 'stop breathing -- ehqolatom', language: 'stop breathing -- ehqolatom'},
               {name: 'stop burning -- ehqamkole', language: 'stop burning -- ehqamkole'},
               {name: 'stop crying -- eqotemu', language: 'stop crying -- eqotemu'},
               {name: 'stop dancing -- eqqa', language: 'stop dancing -- eqqa'},
               {name: 'stop drinking -- ehqossomu', language: 'stop drinking -- ehqossomu'},
               {name: 'stop eating -- ehqihpu', language: 'stop eating -- ehqihpu'},
               {name: 'stop fighting -- conihponosu', language: 'stop fighting -- conihponosu'},
               {name: 'stop fishing -- ehqame', language: 'stop fishing -- ehqame'},
               {name: 'stop for a while -- ankuwakhucu', language: 'stop for a while -- ankuwakhucu'},
               {name: 'stop gathering driftwood -- eqqonse', language: 'stop gathering driftwood -- eqqonse'},
               {name: 'stop jumping -- eqotokku', language: 'stop jumping -- eqotokku'},
               {name: 'stop learning -- eqqehkimsu', language: 'stop learning -- eqqehkimsu'},
               {name: 'stop making noise -- mettaqsu', language: 'stop making noise -- mettaqsu'},
               {name: 'stop moving -- conesson', language: 'stop moving -- conesson'},
               {name: 'stop playing -- mettaqot', language: 'stop playing -- mettaqot'},
               {name: 'stop plowing -- eqqesawe', language: 'stop plowing -- eqqesawe'},
               {name: 'stop pounding -- eqtihike', language: 'stop pounding -- eqtihike'},
               {name: 'stop praying -- ehqimiye', language: 'stop praying -- ehqimiye'},
               {name: 'stop raining -- conolan', language: 'stop raining -- conolan'},
               {name: 'stop raining -- ehqolan', language: 'stop raining -- ehqolan'},
               {name: 'stop running before reaching finish-line -- nuhtoqaqasku', language: 'stop running before reaching finish-line -- nuhtoqaqasku'},
               {name: 'stop shooting -- eqhike', language: 'stop shooting -- eqhike'},
               {name: 'stop singing -- mehcintu', language: 'stop singing -- mehcintu'},
               {name: 'stop sinning -- ehqahkomiksu', language: 'stop sinning -- ehqahkomiksu'},
               {name: 'stop sitting -- eqopu', language: 'stop sitting -- eqopu'},
               {name: 'stop smoking -- ehqiwtome', language: 'stop smoking -- ehqiwtome'},
               {name: 'stop suddenly -- sesomessu', language: 'stop suddenly -- sesomessu'},
               {name: 'stop suddenly in seated position -- conqeptehsin', language: 'stop suddenly in seated position -- conqeptehsin'},
               {name: 'stop swimming -- methom', language: 'stop swimming -- methom'},
               {name: 'stop talking -- ehqewestu', language: 'stop talking -- ehqewestu'},
               {name: 'stop telling lies -- ehqikolusku', language: 'stop telling lies -- ehqikolusku'},
               {name: 'stop traffic -- conehtahsu', language: 'stop traffic -- conehtahsu'},
               {name: 'stop working -- ehqoluhke', language: 'stop working -- ehqoluhke'},
               {name: 'stop working -- mehtoluhke', language: 'stop working -- mehtoluhke'},
               {name: 'stop writing -- ehkuwikhike', language: 'stop writing -- ehkuwikhike'},
               {name: 'storm until dawn -- cehqonamoqessu', language: 'storm until dawn -- cehqonamoqessu'},
               {name: 'straighten out -- wolatokessu', language: 'straighten out -- wolatokessu'},
               {name: 'straighten out -- wolatokiye', language: 'straighten out -- wolatokiye'},
               {name: 'straighten up -- apahqessu', language: 'straighten up -- apahqessu'},
               {name: 'straighten up -- apahqessuwiw', language: 'straighten up -- apahqessuwiw'},
               {name: 'strain -- sikuskanike', language: 'strain -- sikuskanike'},
               {name: 'strain muscles -- cikawsonu', language: 'strain muscles -- cikawsonu'},
               {name: 'strain self -- atkikonessu', language: 'strain self -- atkikonessu'},
               {name: 'strike head first -- olonomtehsin', language: 'strike head first -- olonomtehsin'},
               {name: 'strike in that direction -- oloqihtehson', language: 'strike in that direction -- oloqihtehson'},
               {name: 'strike out -- awahsihike', language: 'strike out -- awahsihike'},
               {name: 'strike sharply against something -- ksatokihtehsin', language: 'strike sharply against something -- ksatokihtehsin'},
               {name: 'strike something and breaks in two -- tomtehson', language: 'strike something and breaks in two -- tomtehson'},
               {name: 'strip -- emesokehlosu', language: 'strip -- emesokehlosu'},
               {name: 'struggle to survive -- sikuwalke', language: 'struggle to survive -- sikuwalke'},
               {name: 'stuff -- pithotuhke', language: 'stuff -- pithotuhke'},
               {name: 'stumble -- qastehsin', language: 'stumble -- qastehsin'},
               {name: 'suck in breath -- wihqolamu', language: 'suck in breath -- wihqolamu'},
               {name: 'suckle -- wiqpekotamu', language: 'suckle -- wiqpekotamu'},
               {name: 'suffer hardship(s) -- sikonoma', language: 'suffer hardship(s) -- sikonoma'},
               {name: 'suffer physical pain -- sikomolsu', language: 'suffer physical pain -- sikomolsu'},
               {name: 'support -- enuwaqhike', language: 'support -- enuwaqhike'},
               {name: 'surround -- wiwonahte', language: 'surround -- wiwonahte'},
               {name: 'survive -- sqawsu', language: 'survive -- sqawsu'},
               {name: 'survive until spring -- siqonawsu', language: 'survive until spring -- siqonawsu'},
               {name: 'swallow -- koloqehtamu', language: 'swallow -- koloqehtamu'},
               {name: 'swallow whole -- psocehtamu', language: 'swallow whole -- psocehtamu'},
               {name: 'swear a lot -- wisokatuwe', language: 'swear a lot -- wisokatuwe'},
               {name: 'swear all the time -- nipskatuwesku', language: 'swear all the time -- nipskatuwesku'},
               {name: 'sweat -- alosu', language: 'sweat -- alosu'},
               {name: 'sweat -- alosuwiw', language: 'sweat -- alosuwiw'},
               {name: 'sweat -- ktanaqalosu', language: 'sweat -- ktanaqalosu'},
               {name: 'sweat profusely -- wisokalosu', language: 'sweat profusely -- wisokalosu'},
               {name: 'sweep -- cihkihike', language: 'sweep -- cihkihike'},
               {name: 'swell so much -- tutoqin', language: 'swell so much -- tutoqin'},
               {name: 'swim -- tkahsomu', language: 'swim -- tkahsomu'},
               {name: 'swim across -- ksokahom', language: 'swim across -- ksokahom'},
               {name: 'swim along -- pomhom', language: 'swim along -- pomhom'},
               {name: 'swim around -- yalhom', language: 'swim around -- yalhom'},
               {name: 'swim around something -- amonohom', language: 'swim around something -- amonohom'},
               {name: 'swim around underwater -- yalamehe', language: 'swim around underwater -- yalamehe'},
               {name: 'swim around underwater -- yaloqotehe', language: 'swim around underwater -- yaloqotehe'},
               {name: 'swim at angle -- asuwohom', language: 'swim at angle -- asuwohom'},
               {name: 'swim at night -- nipahom', language: 'swim at night -- nipahom'},
               {name: 'swim away from shore -- milawhom', language: 'swim away from shore -- milawhom'},
               {name: 'swim backstroke -- sehtahom', language: 'swim backstroke -- sehtahom'},
               {name: 'swim by -- tuthom', language: 'swim by -- tuthom'},
               {name: 'swim from there -- tohom', language: 'swim from there -- tohom'},
               {name: 'swim in circle -- wiwonohom', language: 'swim in circle -- wiwonohom'},
               {name: 'swim into view -- sakhohom', language: 'swim into view -- sakhohom'},
               {name: 'swim left-handed -- pahtahom', language: 'swim left-handed -- pahtahom'},
               {name: 'swim naked -- emesokohom', language: 'swim naked -- emesokohom'},
               {name: 'swim naked -- setolehom', language: 'swim naked -- setolehom'},
               {name: 'swim on surface of the water -- skitoqotehe', language: 'swim on surface of the water -- skitoqotehe'},
               {name: 'swim on top of something submerged -- tehsaqhom', language: 'swim on top of something submerged -- tehsaqhom'},
               {name: 'swim rapidly -- sahsamehe', language: 'swim rapidly -- sahsamehe'},
               {name: 'swim rapidly -- wisayamehe', language: 'swim rapidly -- wisayamehe'},
               {name: 'swim right-handed -- tinahkathom', language: 'swim right-handed -- tinahkathom'},
               {name: 'swim so far underwater -- qonokutehe', language: 'swim so far underwater -- qonokutehe'},
               {name: 'swim straight -- kiyaqhom', language: 'swim straight -- kiyaqhom'},
               {name: 'swim there -- olhom', language: 'swim there -- olhom'},
               {name: 'swim to different location -- athom', language: 'swim to different location -- athom'},
               {name: 'swim under -- neqhom', language: 'swim under -- neqhom'},
               {name: 'swim underwater for a long time -- sipkoqotehe', language: 'swim underwater for a long time -- sipkoqotehe'},
               {name: 'swim underwater in that direction -- oloqoqotehe', language: 'swim underwater in that direction -- oloqoqotehe'},
               {name: 'swim underwater so far long a time -- qonoqotehe', language: 'swim underwater so far long a time -- qonoqotehe'},
               {name: 'swim using breast-stroke -- ktoqhom', language: 'swim using breast-stroke -- ktoqhom'},
               {name: 'swirl -- topiqelamsuke', language: 'swirl -- topiqelamsuke'},
               {name: 'tack -- pihciqehe', language: 'tack -- pihciqehe'},
               {name: 'tack -- pihciqehsin', language: 'tack -- pihciqehsin'},
               {name: 'take someone to a secluded place -- qasiptahsu', language: 'take someone to a secluded place -- qasiptahsu'},
               {name: 'talk a lot -- wikcokewestu', language: 'talk a lot -- wikcokewestu'},
               {name: 'talk better when drinking -- woltunehtaqsu', language: 'talk better when drinking -- woltunehtaqsu'},
               {name: 'talk big -- kintaqsu', language: 'talk big -- kintaqsu'},
               {name: 'talk constantly -- costaqsu', language: 'talk constantly -- costaqsu'},
               {name: 'talk constantly -- wikcokotunehpusu', language: 'talk constantly -- wikcokotunehpusu'},
               {name: 'talk continuously -- munsayewestu', language: 'talk continuously -- munsayewestu'},
               {name: 'talk crazy -- wiwonascokewestu', language: 'talk crazy -- wiwonascokewestu'},
               {name: 'talk crazy -- wiwonasewestu', language: 'talk crazy -- wiwonasewestu'},
               {name: 'talk crazy -- wiwonastaqsu', language: 'talk crazy -- wiwonastaqsu'},
               {name: 'talk crazy -- wonatominewestu', language: 'talk crazy -- wonatominewestu'},
               {name: 'talk elegantly -- amalhewestu', language: 'talk elegantly -- amalhewestu'},
               {name: 'talk fast -- sahsewestu', language: 'talk fast -- sahsewestu'},
               {name: 'talk for a long time -- sipkewestu', language: 'talk for a long time -- sipkewestu'},
               {name: 'talk idly all the time -- koluskacomikemu', language: 'talk idly all the time -- koluskacomikemu'},
               {name: 'talk loud -- cipokewestu', language: 'talk loud -- cipokewestu'},
               {name: 'talk so fast -- tutewestu', language: 'talk so fast -- tutewestu'},
               {name: 'talk too loud -- samtaqsu', language: 'talk too loud -- samtaqsu'},
               {name: 'talk too much -- misuwestune', language: 'talk too much -- misuwestune'},
               {name: 'talk too much -- samewestu', language: 'talk too much -- samewestu'},
               {name: 'talk with mouth full -- psonolutawone', language: 'talk with mouth full -- psonolutawone'},
               {name: 'talk without break -- qottokomehtahsu', language: 'talk without break -- qottokomehtahsu'},
               {name: 'tap weavers down -- nahkakpehtihike', language: 'tap weavers down -- nahkakpehtihike'},
               {name: 'taper by cutting -- sipomosawe', language: 'taper by cutting -- sipomosawe'},
               {name: 'taste bad -- mocihpukot', language: 'taste bad -- mocihpukot'},
               {name: 'taste bad -- mocihpuksu', language: 'taste bad -- mocihpuksu'},
               {name: 'taste bitter -- ksihpukot', language: 'taste bitter -- ksihpukot'},
               {name: 'taste bitter -- ksihpuksu', language: 'taste bitter -- ksihpuksu'},
               {name: 'taste different -- piluwihpukot', language: 'taste different -- piluwihpukot'},
               {name: 'taste different -- piluwihpuksu', language: 'taste different -- piluwihpuksu'},
               {name: 'taste different from what was expected -- piltuwihpukot', language: 'taste different from what was expected -- piltuwihpukot'},
               {name: 'taste different from what was expected -- piltuwihpuksu', language: 'taste different from what was expected -- piltuwihpuksu'},
               {name: 'taste good -- wolihpukot', language: 'taste good -- wolihpukot'},
               {name: 'taste good -- wolihpuksu', language: 'taste good -- wolihpuksu'},
               {name: 'taste old -- kanihpukot', language: 'taste old -- kanihpukot'},
               {name: 'taste old -- kanihpuksu', language: 'taste old -- kanihpuksu'},
               {name: 'taste really good -- tawiwolihpukot', language: 'taste really good -- tawiwolihpukot'},
               {name: 'taste really good -- tawiwolihpuksu', language: 'taste really good -- tawiwolihpuksu'},
               {name: 'taste salty -- salawehpukot', language: 'taste salty -- salawehpukot'},
               {name: 'taste salty -- salawehpuksu', language: 'taste salty -- salawehpuksu'},
               {name: 'taste sour -- suwahpukot', language: 'taste sour -- suwahpukot'},
               {name: 'taste sour -- suwahpuksu', language: 'taste sour -- suwahpuksu'},
               {name: 'taste stale -- ksahpukot', language: 'taste stale -- ksahpukot'},
               {name: 'taste strange -- assokihpukot', language: 'taste strange -- assokihpukot'},
               {name: 'taste strongly of salt -- ksisalawehpukot', language: 'taste strongly of salt -- ksisalawehpukot'},
               {name: 'taste sweet -- mahqanihpukot', language: 'taste sweet -- mahqanihpukot'},
               {name: 'taste sweet -- mahqanihpuksu', language: 'taste sweet -- mahqanihpuksu'},
               {name: 'taste sweet -- sukolihpukot', language: 'taste sweet -- sukolihpukot'},
               {name: 'taste sweet -- sukolihpuksu', language: 'taste sweet -- sukolihpuksu'},
               {name: 'taste terrible -- mocalokittiyehpukot', language: 'taste terrible -- mocalokittiyehpukot'},
               {name: 'tattle -- psikemu', language: 'tattle -- psikemu'},
               {name: 'tear -- spiqone', language: 'tear -- spiqone'},
               {name: 'tell a story -- acomu', language: 'tell a story -- acomu'},
               {name: 'tell bad stories about people -- mocakonikesu', language: 'tell bad stories about people -- mocakonikesu'},
               {name: 'tell funny stories about self -- tawimsu', language: 'tell funny stories about self -- tawimsu'},
               {name: 'tell funny stories about self -- wihqihtaqsuwamsu', language: 'tell funny stories about self -- wihqihtaqsuwamsu'},
               {name: 'tell old story -- atkuhke', language: 'tell old story -- atkuhke'},
               {name: 'tell s/he saw -- akonimke', language: 'tell s/he saw -- akonimke'},
               {name: 'tell scary stories -- sekpakesu', language: 'tell scary stories -- sekpakesu'},
               {name: 'tell scary story -- cipakonutome', language: 'tell scary story -- cipakonutome'},
               {name: 'tell story at length and in detail -- piqacomu', language: 'tell story at length and in detail -- piqacomu'},
               {name: 'tell story incorrectly -- wapolakonutome', language: 'tell story incorrectly -- wapolakonutome'},
               {name: 'tell story late fact -- metsahqacomu', language: 'tell story late fact -- metsahqacomu'},
               {name: 'tell strange story -- assokacomu', language: 'tell strange story -- assokacomu'},
               {name: 'tense muscles -- sakolewokitutom', language: 'tense muscles -- sakolewokitutom'},
               {name: 'tense suddenly -- ceconessu', language: 'tense suddenly -- ceconessu'},
               {name: 'think about helping -- wicuhketahasu', language: 'think about helping -- wicuhketahasu'},
               {name: 'think ahead -- nihkanitahasu', language: 'think ahead -- nihkanitahasu'},
               {name: 'think big -- kinitahasu', language: 'think big -- kinitahasu'},
               {name: 'think differently about it -- piluwitahasu', language: 'think differently about it -- piluwitahasu'},
               {name: 'think for long time -- sipkitahasu', language: 'think for long time -- sipkitahasu'},
               {name: 'think highly of self -- kinitahamsu', language: 'think highly of self -- kinitahamsu'},
               {name: 'think in that direction -- oloqitahasu', language: 'think in that direction -- oloqitahasu'},
               {name: 'think like Native person -- skicinuwitahasu', language: 'think like Native person -- skicinuwitahasu'},
               {name: 'think like white person -- wapitahasu', language: 'think like white person -- wapitahasu'},
               {name: 'think maturely for age -- kcitomitahasu', language: 'think maturely for age -- kcitomitahasu'},
               {name: 'think of many different things -- militahasu', language: 'think of many different things -- militahasu'},
               {name: 'think poorly of self -- mocitahamsu', language: 'think poorly of self -- mocitahamsu'},
               {name: 'think poorly of self -- nutatahamsu', language: 'think poorly of self -- nutatahamsu'},
               {name: 'think proudly -- palapuwitahasu', language: 'think proudly -- palapuwitahasu'},
               {name: 'think quickly -- nokosatahasu', language: 'think quickly -- nokosatahasu'},
               {name: 'think quickly -- tpitahasessu', language: 'think quickly -- tpitahasessu'},
               {name: 'think sympathetically -- wewehsitahasu', language: 'think sympathetically -- wewehsitahasu'},
               {name: 'think that ... -- litahasu', language: 'think that ... -- litahasu'},
               {name: 'think too much -- samitahasu', language: 'think too much -- samitahasu'},
               {name: 'thread -- nashuta', language: 'thread -- nashuta'},
               {name: 'throw a very sharp curve -- ksakonike', language: 'throw a very sharp curve -- ksakonike'},
               {name: 'throw fast -- ksahke', language: 'throw fast -- ksahke'},
               {name: 'throw like boy -- skinuhsisuwahke', language: 'throw like boy -- skinuhsisuwahke'},
               {name: 'throw like girl -- pilsqehsisuwahke', language: 'throw like girl -- pilsqehsisuwahke'},
               {name: 'throw stones -- ponapsqihike', language: 'throw stones -- ponapsqihike'},
               {name: 'throw variety of curves -- milakonike', language: 'throw variety of curves -- milakonike'},
               {name: 'throw variety of pitches -- milamutike', language: 'throw variety of pitches -- milamutike'},
               {name: 'thunder -- petakiw', language: 'thunder -- petakiw'},
               {name: 'tie securely -- mecimtokehtahsu', language: 'tie securely -- mecimtokehtahsu'},
               {name: 'tie something soft tightly inside cloth so that it bursts -- paskocokopolike', language: 'tie something soft tightly inside cloth so that it bursts -- paskocokopolike'},
               {name: 'tie tightly -- sakolopolike', language: 'tie tightly -- sakolopolike'},
               {name: 'tighten -- sonatokuhse', language: 'tighten -- sonatokuhse'},
               {name: 'tighten up -- skihkesson', language: 'tighten up -- skihkesson'},
               {name: 'tip chair back while sitting in it -- cikalkasu', language: 'tip chair back while sitting in it -- cikalkasu'},
               {name: 'tip over -- ktoqessu', language: 'tip over -- ktoqessu'},
               {name: 'tip with wind -- kuskalamson', language: 'tip with wind -- kuskalamson'},
               {name: 'tiptoe -- qinusqosithom', language: 'tiptoe -- qinusqosithom'},
               {name: 'toil -- tkiqoluhke', language: 'toil -- tkiqoluhke'},
               {name: 'toll -- awistaqot', language: 'toll -- awistaqot'},
               {name: 'track -- pomaptahsu', language: 'track -- pomaptahsu'},
               {name: 'track around -- yalaptahsu', language: 'track around -- yalaptahsu'},
               {name: 'track from there -- taptahsu', language: 'track from there -- taptahsu'},
               {name: 'track in -- ksiyaptu', language: 'track in -- ksiyaptu'},
               {name: 'trample -- skihkipolahsu', language: 'trample -- skihkipolahsu'},
               {name: 'trample down hay -- skihkipolike', language: 'trample down hay -- skihkipolike'},
               {name: 'transform self into boy -- skinuhsisuwessu', language: 'transform self into boy -- skinuhsisuwessu'},
               {name: 'transform self into girl -- pilsqehsisuwessu', language: 'transform self into girl -- pilsqehsisuwessu'},
               {name: 'translate -- ankuwacomuwiw', language: 'translate -- ankuwacomuwiw'},
               {name: 'transplant -- atkenike', language: 'transplant -- atkenike'},
               {name: 'transplant -- atkihke', language: 'transplant -- atkihke'},
               {name: 'trap -- ciyahkehsuhke', language: 'trap -- ciyahkehsuhke'},
               {name: 'trap -- kiwhosuhke', language: 'trap -- kiwhosuhke'},
               {name: 'trap -- kiwonikihke', language: 'trap -- kiwonikihke'},
               {name: 'trap -- kotunke', language: 'trap -- kotunke'},
               {name: 'trap -- soqehsuhke', language: 'trap -- soqehsuhke'},
               {name: 'travel aimlessly -- koluskiye', language: 'travel aimlessly -- koluskiye'},
               {name: 'travel all day -- kekkom', language: 'travel all day -- kekkom'},
               {name: 'travel around -- olomiyaliye', language: 'travel around -- olomiyaliye'},
               {name: 'travel for so long a time -- qontehkom', language: 'travel for so long a time -- qontehkom'},
               {name: 'treat everyone equally -- tetpeyutike', language: 'treat everyone equally -- tetpeyutike'},
               {name: 'treat self poorly -- tomakiyusu', language: 'treat self poorly -- tomakiyusu'},
               {name: 'treat self well -- woleyasu', language: 'treat self well -- woleyasu'},
               {name: 'trim limbs from felled tree -- musikhike', language: 'trim limbs from felled tree -- musikhike'},
               {name: 'trip and falls forward -- cituwipokossin', language: 'trip and falls forward -- cituwipokossin'},
               {name: 'trip itself -- nasokihtehson', language: 'trip itself -- nasokihtehson'},
               {name: 'turn -- qolopokiskot', language: 'turn -- qolopokiskot'},
               {name: 'turn around -- ptokehlosu', language: 'turn around -- ptokehlosu'},
               {name: 'turn blue -- sinpisiye', language: 'turn blue -- sinpisiye'},
               {name: 'turn cold -- tkopekiye', language: 'turn cold -- tkopekiye'},
               {name: 'turn sour -- suwahqosson', language: 'turn sour -- suwahqosson'},
               {name: 'twinkle red -- pqahtuwessu', language: 'twinkle red -- pqahtuwessu'},
               {name: 'twist ankle -- koloqoskessu', language: 'twist ankle -- koloqoskessu'},
               {name: 'twist dry ear of corn with hand(s) to remove dry kernels -- niposqenike', language: 'twist dry ear of corn with hand(s) to remove dry kernels -- niposqenike'},
               {name: 'umpire -- empayewiw', language: 'umpire -- empayewiw'},
               {name: 'uncover face -- paneqensu', language: 'uncover face -- paneqensu'},
               {name: 'understand -- sotom', language: 'understand -- sotom'},
               {name: 'understand English -- ikolisomaninsotom', language: 'understand English -- ikolisomaninsotom'},
               {name: 'understand Passamaquoddy-Maliseet -- skicinuwinsotom', language: 'understand Passamaquoddy-Maliseet -- skicinuwinsotom'},
               {name: 'understand quickly -- nokosansotom', language: 'understand quickly -- nokosansotom'},
               {name: 'understand self -- sotasu', language: 'understand self -- sotasu'},
               {name: 'understand well -- wolinsotom', language: 'understand well -- wolinsotom'},
               {name: 'unload -- ketkosewe', language: 'unload -- ketkosewe'},
               {name: 'unload automatically after firing -- ketkosewiw', language: 'unload automatically after firing -- ketkosewiw'},
               {name: 'unload cargo -- kahpotanahqe', language: 'unload cargo -- kahpotanahqe'},
               {name: 'unlock -- apqasokihike', language: 'unlock -- apqasokihike'},
               {name: 'unlock -- apqasokihikewiw', language: 'unlock -- apqasokihikewiw'},
               {name: 'unsnag -- wolatokessu', language: 'unsnag -- wolatokessu'},
               {name: 'untie self -- ketopilsu', language: 'untie self -- ketopilsu'},
               {name: 'unwrap -- apqekonike', language: 'unwrap -- apqekonike'},
               {name: 'urinate -- soku', language: 'urinate -- soku'},
               {name: 'urinate from laughing -- sokelomu', language: 'urinate from laughing -- sokelomu'},
               {name: 'urinate in sleep -- skiqam', language: 'urinate in sleep -- skiqam'},
               {name: 'urinate on self -- skilsu', language: 'urinate on self -- skilsu'},
               {name: 'visit -- wikuwamke', language: 'visit -- wikuwamke'},
               {name: 'vomit -- sokku', language: 'vomit -- sokku'},
               {name: 'vomit all over the place -- yalcoksokku', language: 'vomit all over the place -- yalcoksokku'},
               {name: 'vomit in sleep -- soqqam', language: 'vomit in sleep -- soqqam'},
               {name: 'vote in such a way -- olokuhke', language: 'vote in such a way -- olokuhke'},
               {name: 'wade across -- ksokayasuke', language: 'wade across -- ksokayasuke'},
               {name: 'wade along -- pomasuke', language: 'wade along -- pomasuke'},
               {name: 'wade along the edge -- sonutasuke', language: 'wade along the edge -- sonutasuke'},
               {name: 'wade around -- yalasuke', language: 'wade around -- yalasuke'},
               {name: 'wade out into the water -- milawasuke', language: 'wade out into the water -- milawasuke'},
               {name: 'wade under -- neqasuke', language: 'wade under -- neqasuke'},
               {name: 'wait -- askuwasu', language: 'wait -- askuwasu'},
               {name: 'wake people up by making noise -- tuhkikemu', language: 'wake people up by making noise -- tuhkikemu'},
               {name: 'wake self up -- tuhkimsu', language: 'wake self up -- tuhkimsu'},
               {name: 'wake up -- minuhtihike', language: 'wake up -- minuhtihike'},
               {name: 'wake up -- tuhkiye', language: 'wake up -- tuhkiye'},
               {name: 'wake up because of having urinated -- tuhkisku', language: 'wake up because of having urinated -- tuhkisku'},
               {name: 'wake up cold -- tuhkikuwocu', language: 'wake up cold -- tuhkikuwocu'},
               {name: 'wake up cold -- tukocu', language: 'wake up cold -- tukocu'},
               {name: 'wake up crying -- tukotemu', language: 'wake up crying -- tukotemu'},
               {name: 'wake up early in morning -- spasuwituhkiye', language: 'wake up early in morning -- spasuwituhkiye'},
               {name: 'wake up from having defecated -- tuhkicokuhke', language: 'wake up from having defecated -- tuhkicokuhke'},
               {name: 'wake up from having vomited -- tuhkisokku', language: 'wake up from having vomited -- tuhkisokku'},
               {name: 'wake up having urinated on self -- tuhkiskilsu', language: 'wake up having urinated on self -- tuhkiskilsu'},
               {name: 'wake up laughing -- tuhkelomu', language: 'wake up laughing -- tuhkelomu'},
               {name: 'wake up scared -- tukpayu', language: 'wake up scared -- tukpayu'},
               {name: 'wake up screaming -- tuhkimasqolamu', language: 'wake up screaming -- tuhkimasqolamu'},
               {name: 'walk -- moteqonehtehsin', language: 'walk -- moteqonehtehsin'},
               {name: 'walk alone -- qotuhkehe', language: 'walk alone -- qotuhkehe'},
               {name: 'walk along making noise with feet -- pomtestike', language: 'walk along making noise with feet -- pomtestike'},
               {name: 'walk along on thin ice without falling through -- pomanokiye', language: 'walk along on thin ice without falling through -- pomanokiye'},
               {name: 'walk back and forth holding and soothing baby -- esuwustahsu', language: 'walk back and forth holding and soothing baby -- esuwustahsu'},
               {name: 'walk downhill making noise with feet -- motapehtestike', language: 'walk downhill making noise with feet -- motapehtestike'},
               {name: 'walk hurriedly to there -- liksihke', language: 'walk hurriedly to there -- liksihke'},
               {name: 'walk into high grass -- pisokonike', language: 'walk into high grass -- pisokonike'},
               {name: 'walk lightly -- nahnakiphusu', language: 'walk lightly -- nahnakiphusu'},
               {name: 'walk sideways quickly -- nomocintestike', language: 'walk sideways quickly -- nomocintestike'},
               {name: 'walk slowly -- menakatkenu', language: 'walk slowly -- menakatkenu'},
               {name: 'walk until head is underwater -- kaskitomuwehe', language: 'walk until head is underwater -- kaskitomuwehe'},
               {name: 'walk up hill making noise with feet -- kisahqehtestike', language: 'walk up hill making noise with feet -- kisahqehtestike'},
               {name: 'walk with cane -- pomapotuhusu', language: 'walk with cane -- pomapotuhusu'},
               {name: 'walk with head down making noise with feet -- cituwamqehtestike', language: 'walk with head down making noise with feet -- cituwamqehtestike'},
               {name: 'want to be in a safe place -- kotuwaposu', language: 'want to be in a safe place -- kotuwaposu'},
               {name: 'want to live -- kotuwawsu', language: 'want to live -- kotuwawsu'},
               {name: 'want too much -- samipawatom', language: 'want too much -- samipawatom'},
               {name: 'warm -- siptokaqote', language: 'warm -- siptokaqote'},
               {name: 'warm self -- uwosu', language: 'warm self -- uwosu'},
               {name: 'warm up -- apuwisawe', language: 'warm up -- apuwisawe'},
               {name: 'warm up -- apuwisawewiw', language: 'warm up -- apuwisawewiw'},
               {name: 'wave are big -- ksatqin', language: 'wave are big -- ksatqin'},
               {name: 'wave are gentle -- wolatqin', language: 'wave are gentle -- wolatqin'},
               {name: 'wave are heading away -- olomatqin', language: 'wave are heading away -- olomatqin'},
               {name: 'wave are so high -- tutatqin', language: 'wave are so high -- tutatqin'},
               {name: 'wave limbs around -- yalenskiye', language: 'wave limbs around -- yalenskiye'},
               {name: 'wave well -- tawatkuwe', language: 'wave well -- tawatkuwe'},
               {name: 'wear any old clothes -- milcoksewe', language: 'wear any old clothes -- milcoksewe'},
               {name: 'wear clothes inside-out -- aputsewe', language: 'wear clothes inside-out -- aputsewe'},
               {name: 'wear clothes too thin for cold weather -- kuhutsewe', language: 'wear clothes too thin for cold weather -- kuhutsewe'},
               {name: 'wear clothing -- nassewotahsu', language: 'wear clothing -- nassewotahsu'},
               {name: 'wear clothing inside out -- apuckolahsewe', language: 'wear clothing inside out -- apuckolahsewe'},
               {name: 'wear dirty clothes -- moccoksewe', language: 'wear dirty clothes -- moccoksewe'},
               {name: 'wear fancy dress -- amalhekiye', language: 'wear fancy dress -- amalhekiye'},
               {name: 'wear hat -- ahsusuwonhom', language: 'wear hat -- ahsusuwonhom'},
               {name: 'wear heavy clothing -- tkiqsewe', language: 'wear heavy clothing -- tkiqsewe'},
               {name: 'wear heavy shoes -- tkikuwemu', language: 'wear heavy shoes -- tkikuwemu'},
               {name: 'wear long dress -- pihtekiye', language: 'wear long dress -- pihtekiye'},
               {name: 'wear long dress other garment) -- pihteku', language: 'wear long dress other garment) -- pihteku'},
               {name: 'wear mittens -- molocessohom', language: 'wear mittens -- molocessohom'},
               {name: 'wear moccasins -- olonaksonhom', language: 'wear moccasins -- olonaksonhom'},
               {name: 'wear old-fashioned clothes -- ulpesonuwekiye', language: 'wear old-fashioned clothes -- ulpesonuwekiye'},
               {name: 'wear outer layer of clothing -- skitsewe', language: 'wear outer layer of clothing -- skitsewe'},
               {name: 'wear pants -- polcishom', language: 'wear pants -- polcishom'},
               {name: 'wear red -- pqahsewe', language: 'wear red -- pqahsewe'},
               {name: 'wear ring -- nahsahqehtakonohom', language: 'wear ring -- nahsahqehtakonohom'},
               {name: 'wear shirt -- ahtulhawhom', language: 'wear shirt -- ahtulhawhom'},
               {name: 'wear shoes -- oluwemu', language: 'wear shoes -- oluwemu'},
               {name: 'wear shoes -- pkosonohom', language: 'wear shoes -- pkosonohom'},
               {name: 'wear short dress -- qasqekossu', language: 'wear short dress -- qasqekossu'},
               {name: 'wear skirt -- askathom', language: 'wear skirt -- askathom'},
               {name: 'wear socks -- saksohom', language: 'wear socks -- saksohom'},
               {name: 'wear summer clothes -- niponahsewe', language: 'wear summer clothes -- niponahsewe'},
               {name: 'wear thick clothes -- paspahsewe', language: 'wear thick clothes -- paspahsewe'},
               {name: 'wear thin clothing -- pipuwahsewe', language: 'wear thin clothing -- pipuwahsewe'},
               {name: 'wear underclothes -- lamsewe', language: 'wear underclothes -- lamsewe'},
               {name: 'wear underwear -- tolashom', language: 'wear underwear -- tolashom'},
               {name: 'wear winter clothes -- punsewe', language: 'wear winter clothes -- punsewe'},
               {name: 'weave -- loskonuwe', language: 'weave -- loskonuwe'},
               {name: 'weave around -- wiwonoskonuwe', language: 'weave around -- wiwonoskonuwe'},
               {name: 'weave clockwise -- pahtaskonuwe', language: 'weave clockwise -- pahtaskonuwe'},
               {name: 'weave closely -- cihciqapektihike', language: 'weave closely -- cihciqapektihike'},
               {name: 'weave cloth -- ahtulhawekonuhke', language: 'weave cloth -- ahtulhawekonuhke'},
               {name: 'weave fancily -- amalhakopehtahsu', language: 'weave fancily -- amalhakopehtahsu'},
               {name: 'weave fancy -- amalhatokuhutahsu', language: 'weave fancy -- amalhatokuhutahsu'},
               {name: 'weave fancy -- amalhatokuhutahsuwiw', language: 'weave fancy -- amalhatokuhutahsuwiw'},
               {name: 'weave fast -- kakawoskonuwe', language: 'weave fast -- kakawoskonuwe'},
               {name: 'weave fast -- sahsapektihike', language: 'weave fast -- sahsapektihike'},
               {name: 'weave through the split made in a stander -- kskomtokhike', language: 'weave through the split made in a stander -- kskomtokhike'},
               {name: 'weave thus -- lapektihike', language: 'weave thus -- lapektihike'},
               {name: 'weep as s/he talks -- sehsewestu', language: 'weep as s/he talks -- sehsewestu'},
               {name: 'weigh five pounds -- nanitkiqon', language: 'weigh five pounds -- nanitkiqon'},
               {name: 'weigh four pounds -- newitkiqon', language: 'weigh four pounds -- newitkiqon'},
               {name: 'weigh one pound -- qocitkiqol', language: 'weigh one pound -- qocitkiqol'},
               {name: 'weigh one pound -- qocitkiqon', language: 'weigh one pound -- qocitkiqon'},
               {name: 'weigh so much -- olkamu', language: 'weigh so much -- olkamu'},
               {name: 'weigh three pounds -- sitkiqon', language: 'weigh three pounds -- sitkiqon'},
               {name: 'weigh two pounds -- nisitkiqon', language: 'weigh two pounds -- nisitkiqon'},
               {name: 'weld -- astuwaqosike', language: 'weld -- astuwaqosike'},
               {name: 'whisper -- kikimuwasu', language: 'whisper -- kikimuwasu'},
               {name: 'whistle -- qiqsu', language: 'whistle -- qiqsu'},
               {name: 'whistle a tune -- qiqossuwintu', language: 'whistle a tune -- qiqossuwintu'},
               {name: 'whistle fancy tune -- amalhiqiqsu', language: 'whistle fancy tune -- amalhiqiqsu'},
               {name: 'whoop -- cipokewehtaqsu', language: 'whoop -- cipokewehtaqsu'},
               {name: 'widen out -- kskopeke', language: 'widen out -- kskopeke'},
               {name: 'win -- tomhuwe', language: 'win -- tomhuwe'},
               {name: 'win prize -- cihtihike', language: 'win prize -- cihtihike'},
               {name: 'wink -- macikcehpute', language: 'wink -- macikcehpute'},
               {name: 'wink -- qotalokiqessu', language: 'wink -- qotalokiqessu'},
               {name: 'wipe dishes -- kahsicukhike', language: 'wipe dishes -- kahsicukhike'},
               {name: 'wipe self -- kashusu', language: 'wipe self -- kashusu'},
               {name: 'wolf food -- psocehtamu', language: 'wolf food -- psocehtamu'},
               {name: 'work ahead -- nihkanoluhke', language: 'work ahead -- nihkanoluhke'},
               {name: 'work all day -- kekoluhke', language: 'work all day -- kekoluhke'},
               {name: 'work along -- pomoluhke', language: 'work along -- pomoluhke'},
               {name: 'work along making progress -- wahqapoluhke', language: 'work along making progress -- wahqapoluhke'},
               {name: 'work around at different places -- yalluhke', language: 'work around at different places -- yalluhke'},
               {name: 'work at steady even pace -- tetpoluhke', language: 'work at steady even pace -- tetpoluhke'},
               {name: 'work backward -- sehtaluhke', language: 'work backward -- sehtaluhke'},
               {name: 'work downward -- nahkaluhke', language: 'work downward -- nahkaluhke'},
               {name: 'work fast -- nokosaluhke', language: 'work fast -- nokosaluhke'},
               {name: 'work fast -- nokosaluhkewiw', language: 'work fast -- nokosaluhkewiw'},
               {name: 'work hard -- sikoluhke', language: 'work hard -- sikoluhke'},
               {name: 'work hurriedly -- wisaluhke', language: 'work hurriedly -- wisaluhke'},
               {name: 'work in spite of something -- cikawoluhke', language: 'work in spite of something -- cikawoluhke'},
               {name: 'work in the open -- messuluhke', language: 'work in the open -- messuluhke'},
               {name: 'work into -- pisoluhke', language: 'work into -- pisoluhke'},
               {name: 'work into -- pisoluhkewiw', language: 'work into -- pisoluhkewiw'},
               {name: 'work its way farther in -- kcitaluhkewiw', language: 'work its way farther in -- kcitaluhkewiw'},
               {name: 'work its way upward -- ewepuhsewiw', language: 'work its way upward -- ewepuhsewiw'},
               {name: 'work late -- metsoluhke', language: 'work late -- metsoluhke'},
               {name: 'work on top of -- tehsahqoluhke', language: 'work on top of -- tehsahqoluhke'},
               {name: 'work overtime -- pasitoluhke', language: 'work overtime -- pasitoluhke'},
               {name: 'work overtime -- pasitoluhkewiw', language: 'work overtime -- pasitoluhkewiw'},
               {name: 'work quickly -- kakawoluhke', language: 'work quickly -- kakawoluhke'},
               {name: 'work sloppily -- meqoluhke', language: 'work sloppily -- meqoluhke'},
               {name: 'work slowly -- menakatoluhke', language: 'work slowly -- menakatoluhke'},
               {name: 'work slowly -- nakaluhke', language: 'work slowly -- nakaluhke'},
               {name: 'work so hard -- tutoluhke', language: 'work so hard -- tutoluhke'},
               {name: 'work so hard that s/he cries -- sehsoluhke', language: 'work so hard that s/he cries -- sehsoluhke'},
               {name: 'work steadily -- winpatoluhke', language: 'work steadily -- winpatoluhke'},
               {name: 'work thus -- olluhke', language: 'work thus -- olluhke'},
               {name: 'work too much -- samoluhke', language: 'work too much -- samoluhke'},
               {name: 'work until dawn -- cehqonoluhke', language: 'work until dawn -- cehqonoluhke'},
               {name: 'work upward -- ewepoluhke', language: 'work upward -- ewepoluhke'},
               {name: 'work well -- wolluhke', language: 'work well -- wolluhke'},
               {name: 'work with others -- mawoluhke', language: 'work with others -- mawoluhke'},
               {name: 'work with others -- witoluhke', language: 'work with others -- witoluhke'},
               {name: 'work with someone -- nisoluhke', language: 'work with someone -- nisoluhke'},
               {name: 'worm self in -- piscokhusu', language: 'worm self in -- piscokhusu'},
               {name: 'wound self -- cileyasu', language: 'wound self -- cileyasu'},
               {name: 'wrap around -- wiwonatokesson', language: 'wrap around -- wiwonatokesson'},
               {name: 'wrap self -- wissekonosu', language: 'wrap self -- wissekonosu'},
               {name: 'wrap self quickly -- wissekiphusu', language: 'wrap self quickly -- wissekiphusu'},
               {name: 'wriggle in under something -- neqatokihpusu', language: 'wriggle in under something -- neqatokihpusu'},
               {name: 'wriggle in under something -- neqihpusu', language: 'wriggle in under something -- neqihpusu'},
               {name: 'wrinkle as s/he moves -- aqacokessu', language: 'wrinkle as s/he moves -- aqacokessu'},
               {name: 'write a place where s/he shouldn\'t -- moccokuwikhike', language: 'write a place where s/he shouldn\'t -- moccokuwikhike'},
               {name: 'write back -- apatuwikhike', language: 'write back -- apatuwikhike'},
               {name: 'write backward -- sehtawikhike', language: 'write backward -- sehtawikhike'},
               {name: 'write fast -- kakawwikhike', language: 'write fast -- kakawwikhike'},
               {name: 'write for purpose of adding -- mawwikhike', language: 'write for purpose of adding -- mawwikhike'},
               {name: 'write from memory -- nulomuwikhike', language: 'write from memory -- nulomuwikhike'},
               {name: 'write knowledgeably -- wewwikhike', language: 'write knowledgeably -- wewwikhike'},
               {name: 'write long letter -- pittokuwikhike', language: 'write long letter -- pittokuwikhike'},
               {name: 'write scribbles -- wiwonasuwikhike', language: 'write scribbles -- wiwonasuwikhike'},
               {name: 'write thus -- oluwikhike', language: 'write thus -- oluwikhike'},
               {name: 'wrong self -- wapolehlosu', language: 'wrong self -- wapolehlosu'},
               {name: 'yard -- lossone', language: 'yard -- lossone'},
               {name: 'yawn -- ikotahma', language: 'yawn -- ikotahma'},
               {name: 'yawn -- ikotohom', language: 'yawn -- ikotohom'},
               {name: 'yawn prolongedly h/ yawn lasts a long time -- sipkiikotohom', language: 'yawn prolongedly h/ yawn lasts a long time -- sipkiikotohom'},
               {name: 'yawn very hard -- memhuwiikotohom', language: 'yawn very hard -- memhuwiikotohom'},
               {name: 'year go by quickly -- nokosakoton', language: 'year go by quickly -- nokosakoton'},
               {name: 'year go by slowly -- nakakoton', language: 'year go by slowly -- nakakoton'},
               {name: 'zigzag through water -- yalamehe', language: 'zigzag through water -- yalamehe'},];
        }
    }

    checkGoButtonState = () => {
        if(this.state.langSelect !== ''
            && this.state.value !== ''){
            this.setState(
                {goButtonDisabled: false,
                goButtonValue: 'Translate!'});
        }else
        {
            this.setState(
                {goButtonDisabled: true,
                    goButtonValue: 'Make sure to select a language!'});
        }
    }
    //todo keep submit button disabled until all fields added. Ensure that a dropdown selection is made - this will mean that folks
    // would have to ask for a translation outside of the normal search.

    render (){
        const { value, suggestions } = this.state;

        // Autosuggest will pass through all these props to the input.
        const inputProps = {
            placeholder: 'Enter a verb',
            value,
            onChange: this.onChange
        };

        return (
                <div className="searchFormContainer">
                    <Formik initialValues={{searchInput: '',
                            tenseSelectors: 'none',
                            langSelectors: this.props.prefLang}} //
                    validationSchema={validationSchema}
                            onSubmit={(data,
                            ) => {
                                this.newSearchTerm(data);
                            }}
                    >
                        {({values, isSubmitting,  handleChange, handleBlur, handleSubmit }) => (
                  <Form className="searchForm" onSubmit={handleSubmit} >
                        <Form.Row>
                            <Form.Group>
                                <Form.Label className="formLabel">Select a Language</Form.Label>
                                <ButtonToolbar className="langSelectors">
                                    <ToggleButtonGroup name="langSelectors" required={true} onChange={this.langSelect} onBlur={handleBlur} defaultValue={values.langSelectors}>
                                        <ToggleButton value="Mikmaw">Mikmaw</ToggleButton>
                                        <ToggleButton value="Maliseet">Maliseet</ToggleButton>
                                    </ToggleButtonGroup>
                                </ButtonToolbar>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Autosuggest className="col-sm-8"
                                         name="searchInput"
                                         suggestions={suggestions}
                                         onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                         onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                         getSuggestionValue={getSuggestionValue}
                                         renderSuggestion={renderSuggestion}
                                         inputProps={inputProps}
                            />
                        </Form.Row>
                      <Form.Row>
                        <Button variant="success" className="btn btn-success" type="submit" disabled={this.state.goButtonDisabled}>{this.state.goButtonValue}</Button>
                      </Form.Row>
                        {this.state.langSupportsDupAndAbs && (
                          <Form.Row>
                              <p className="copyrightText">© 2020: All rights to the Maliseet dictionary are reserved by David A. Francis and Robert M. Leavit and the UNB Libraries.</p>
                          </Form.Row>
                         )}
                    </Form>
                        )}
                    </Formik>

                </div>
        );
    }
}
export default SearchBar;