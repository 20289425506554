import React  from 'react';
import {Modal, Form, Image} from "react-bootstrap";
import ReactDOM from 'react-dom';

const SifModal = props => {
  const content = (
          <div className={`modal ${props.className}`} style={props.style}>
              <Modal
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  show={props.show}
                  centered >
                  <Modal.Header className={`modal__header ${props.headerClass}`} closeButton={true}>
                      <Modal.Title id="contained-modal-title-vcenter">
                          <h2>{props.header}</h2>
                      </Modal.Title>
                  </Modal.Header>
                  <Form onSubmit={
                            props.onSubmit ? props.onSubmit : event => event.preventDefault()
                        }
                  >
                      <Modal.Body className={`modal__content $ {props.contentClass`}>
                          {props.children}
                      </Modal.Body >

                      <Modal.Footer className={`modal__footer ${props.footerClass}`}>
                          {props.footer}
                          <Image className="animated bounce" src={props.charSource} fluid width="30%"/>
                      </Modal.Footer>
                  </Form>
              </Modal>
          </div>

  );
  return ReactDOM.createPortal(content, document.getElementById('modal-hook'));

};
export default SifModal;

/*

   <div className={`modal ${props.className}`} style={props.style}>
              <Modal
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  show={true}
                  centered >
                  <Modal.Header className={`modal__header ${props.headerClass}`}>
                      <Modal.Title id="contained-modal-title-vcenter">
                          <h2>Request a New Translation</h2>
                      </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                      <h3>Squeak! Thank you for suggesting a new word!</h3>
                      <h3> What word would you like translated?</h3>
                      <Form>
                          <p>I would like the word <FormControl type="text" inline="true" className="wordRequested col-sm-6" /> translated, please.</p>
                      </Form>
                  </Modal.Body>
                  <Modal.Footer>
                      <img width="150px" src="/imgs/bunn_bigsmile.png" alt="Smiling Bunny"/>
                      <a href="/">Close</a>
                  </Modal.Footer>
              </Modal>
          </div>

  );
function SifModal() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (

        );
}*/
