import React from "react";
//see session 179 to see how to do this - need to attach token to post request for it to work
//for certain requests, browsers will send an "OPTIONS" request to see what route options there are
//need to deal with this by adding a check to see if method==='OPTIONS' if so then just next() ;
const AddTranslation = () => {
    return (
        <div>
            new translations!
        </div>
    )
};

export default AddTranslation;