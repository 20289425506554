import React, {useContext, useState} from "react";
import SifModal from "./SifModal";
import {Button, Form, FormControl} from "react-bootstrap";
import {Formik} from "formik";
import axios from "axios";
import {AuthContext} from "../../../context/auth-context";


const RequestTrans_Modal: React.FC = props => {

    const auth = useContext(AuthContext);
    const [error, setError] = useState();

    const options = {   headers: { 'Authorization': auth.token } };
    axios.defaults.headers.common['Authorization'] = auth.token;

    const submitTranslationRequestForm = async (data) => {
        console.log('this should be sent in ' + options);
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/translations/request/newTranslation`,
            {
                english: data.english,
                language: auth.preferredLanguage,
                translation: data.translation,
                userId: auth.userId,
            token: auth.token},

        { headers:
                    { Authorization: 'Bearer ' + auth.token }
                }

        )
            .then(function(response) {
                console.log(response);
                if(response.ok) {

                }
            })
            .catch(function (error) {
                setError('Either your username or password are incorrect. Please try again.');
            });
    }

    const errorHandler = () => {
        setError(null);
    }

//todo add formik and hook to backend

    return (
        <SifModal
            show={props.show}
            onCancel={props.onClear}
            header="Translation Request"
            contentClass="transReq"
            footerClass="place-item"
            footer={<Button onClick={props.onClear}>Okay</Button>}
            charSource="/imgs/bunny@4x.png">
            <Formik initialValues={{english: '', translation: ''}}
                    onSubmit={(data,
                               {setSubmitting}) => {
                        //can add async method in here
                        setSubmitting(true);
                        submitTranslationRequestForm(data);
                        setSubmitting(false);
                    }}
            >
                {({values, isSubmitting,  handleChange, handleBlur, handleSubmit }) => (
                    <Form onSubmit={handleSubmit} className="modalForm">
                        <Form.Group controlId="formEnglish">
                            <Form.Control name="english" type="text" placeholder="What is the word you want translated?" value={values.english} onChange={handleChange} onBlur={handleBlur}  />
                        </Form.Group>
                        <Form.Group controlId="formTranslation">
                            <Form.Label>Translation Suggestion</Form.Label>
                            <Form.Control name="translation" type="text" placeholder="What do you think the translation is?" value={values.translation} onChange={handleChange} onBlur={handleBlur}  />
                            <Form.Text className="text-muted">If you think you know what the translation is, let us know!</Form.Text>
                        </Form.Group>
                        <Button disabled={isSubmitting} variant="primary" type="submit">Send in translation</Button>
                        <br/>
                    </Form>
                )}
            </Formik>
        </SifModal>
    )
};
export default RequestTrans_Modal;