import React, {useContext} from "react";
import { Navbar, Nav} from "react-bootstrap";
import {AuthContext} from "../../../context/auth-context";
import './SIFNavBar.css';

const SIFNavbar = props => {
   const auth = useContext(AuthContext); //this will update whenever the context changes automagically.
    //auth is now an object that holds the properties and methods that we passed innto the context
        return (
            <Navbar className="SIFNav" bg="light" expand="lg" >
                <Navbar.Brand href="#home"><img width="125px" src="/imgs/sif-logo.png" alt="Say It First logo" /></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <Nav.Link href="/">&nbsp;&nbsp;Search</Nav.Link>
                        {!auth.isLoggedIn && (
                            <Nav.Link href="/login">Login</Nav.Link>

                        )}
                        {!auth.isLoggedIn && (
                            <Nav.Link href="/signup">Sign up</Nav.Link>
                        )}
                        {auth.isLoggedIn && (
                            <Nav.Link onClick={auth.logout}>Logout</Nav.Link>
                        )}
                        {auth.isLoggedIn && (
                            <Navbar.Text className="navSignin">
                                Signed in as:
                                <a href="##">
                                    <img className="avatarNavbar" alt="user avatar" src={auth.avatar}/> {auth.firstName} {auth.lastName}
                                </a>
                            </Navbar.Text>
                        )}
                    </Nav>

                </Navbar.Collapse>
            </Navbar>
        )
}
export default SIFNavbar;